import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Card, CardContent, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { Delete } from "@mui/icons-material";
import { useGetAllSubscriptionMutation, useDeleteSubscriptionMutation } from 'state/api';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next'; // Importation pour la traduction

const Webhook = () => {
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedWebhookId, setSelectedWebhookId] = useState(null);
  const [webhooks, setWebhooks] = useState([]);
  const ID_Etablissement = useSelector((state) => state.adminAuth.ID_Etablissement);
  
  const [getAllSubscriptions] = useGetAllSubscriptionMutation();
  const [deleteSubscription] = useDeleteSubscriptionMutation();
  const theme = useTheme();
  const { t } = useTranslation(); // Hook de traduction

  // useEffect pour récupérer tous les webhooks
  useEffect(() => {
    const fetchWebhooks = async () => {
      try {
        const response = await getAllSubscriptions(ID_Etablissement).unwrap();
        setWebhooks(response);
      } catch (error) {
        console.error(t('Webhook.ErrorFetching'), error);
      }
    };

    if (ID_Etablissement) {
      fetchWebhooks();
    }
  }, [ID_Etablissement, getAllSubscriptions, t]);

  // Ouvrir la fenêtre de confirmation pour supprimer
  const handleOpenDeleteDialog = (webhookId) => {
    setSelectedWebhookId(webhookId);
    setOpenDeleteDialog(true);
  };

  // Fermer la fenêtre de confirmation
  const handleCloseDeleteDialog = () => {
    setSelectedWebhookId(null);
    setOpenDeleteDialog(false);
  };

  // Supprimer un webhook
  const handleDeleteWebhook = async () => {
    try {
      await deleteSubscription(selectedWebhookId).unwrap();
      setWebhooks(webhooks.filter(webhook => webhook._id !== selectedWebhookId));
      setOpenDeleteDialog(false);
    } catch (error) {
      console.error(t('Webhook.ErrorDeleting'), error);
    }
  };

  return (
    <Box>
      <Typography variant="h4" sx={{ marginBottom: 2 }}>{t('Webhook.ManageWebhooks')}</Typography>
      <Typography variant="body1" sx={{ marginBottom: 3 }}>
        {t('Webhook.Description')}
      </Typography>

      {/* Affichage des webhooks sous forme de cartes */}
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        {webhooks.length > 0 ? (
          webhooks.map((webhook) => (
            <Card
              key={webhook._id}
              sx={{
                borderRadius: '8px',
                boxShadow: 2,
                padding: 2,
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
              }}
            >
              <CardContent>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Typography variant="h6">{webhook.webhookUrl}</Typography>
                  <IconButton color="error" onClick={() => handleOpenDeleteDialog(webhook._id)}>
                    <Delete />
                  </IconButton>
                </Box>
                <Typography variant="body2" sx={{ marginTop: 1 }}>
                  {t('Webhook.SubscriptionType')}: {webhook.subscriptionType}
                </Typography>
                <Typography variant="body2" sx={{ marginTop: 1 }}>
                  {t('Webhook.CreationDate')}: {new Date(webhook.createdAt).toLocaleDateString()}
                </Typography>
              </CardContent>
            </Card>
          ))
        ) : (
          <Typography variant="body2">{t('Webhook.NoWebhooks')}</Typography>
        )}
      </Box>

      {/* Popup de confirmation de suppression */}
      <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
        <DialogTitle>{t('Webhook.ConfirmDeleteTitle')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('Webhook.ConfirmDeleteText')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog}>{t('Webhook.Cancel')}</Button>
          <Button onClick={handleDeleteWebhook} variant="contained" color="error">
            {t('Webhook.Delete')}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Webhook;
