import React, { useEffect, useState, useRef } from 'react';
import { Box, TextField, Button, Paper, Typography, Avatar, CircularProgress } from '@mui/material';
import { Send as SendIcon } from '@mui/icons-material';
import discussionService from '../services/discussionService';
import moment from 'moment';
import { useGetDiscussionHistoryQuery, useSendMessageMutation, useUpdateTypingStatusMutation } from '../state/api';
import io from 'socket.io-client';

const Discussion = ({ incidentId, utilisateurId, type, userSession }) => {
  const [newMessage, setNewMessage] = useState('');
  const { data: messages = [], isLoading } = useGetDiscussionHistoryQuery(incidentId);
  const [sendMessage] = useSendMessageMutation();
  const [localMessages, setLocalMessages] = useState([]);
  const [typingUsers, setTypingUsers] = useState(new Set());
  const messagesEndRef = useRef(null);
  const messageListRef = useRef(null);
  const socketRef = useRef(null);
  const messagesRef = useRef(messages);

  useEffect(() => {
    if (JSON.stringify(messagesRef.current) !== JSON.stringify(messages)) {
      messagesRef.current = messages;
      if (Array.isArray(messages)) {
        setLocalMessages(messages);
      }
    }
  }, [messages]);

  useEffect(() => {
    if (!incidentId || !type) return;

    if (socketRef.current) {
      socketRef.current.disconnect();
    }

    const socket = io(process.env.REACT_APP_API_URL || 'http://localhost:5001', {
      transports: ['websocket'],
      reconnection: true,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      reconnectionAttempts: 5,
      multiplex: false
    });
    
    socketRef.current = socket;

    socket.emit('joinIncident', { incidentId, type });

    const handleNewMessage = (data) => {
      if (!data.message) return;
      
      setLocalMessages(prevMessages => {
        const messageExists = prevMessages.some(msg => 
          msg.ID_Message === data.message.ID_Message
        );
        if (messageExists) {
          return prevMessages;
        }
        return [...prevMessages, data.message];
      });
      scrollToBottom();
    };

    const handleTyping = (data) => {
      if (data.utilisateurId !== utilisateurId) {
        setTypingUsers(prev => {
          const newSet = new Set(prev);
          if (data.isTyping) {
            newSet.add(data.userName);
          } else {
            newSet.delete(data.userName);
          }
          return newSet;
        });
      }
    };

    socket.on('connect', () => {
      console.log('Connected to socket server', socket.id);
    });

    socket.on('messageRecu', (data) => {
      console.log('Message reçu:', data);
      handleNewMessage(data);
    });

    socket.on('isTyping', (data) => {
      console.log('Typing status received:', data);
      handleTyping(data);
    });

    socket.on('disconnect', () => {
      console.log('Disconnected from socket server');
    });

    return () => {
      if (socketRef.current) {
        socketRef.current.off('messageRecu', handleNewMessage);
        socketRef.current.off('isTyping', handleTyping);
        socketRef.current.emit('leaveIncident', { incidentId, type });
        socketRef.current.disconnect();
        socketRef.current = null;
      }
    };
  }, [incidentId, type, utilisateurId]);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleSendMessage = async (e) => {
    e?.preventDefault();
    if (!newMessage.trim()) return;

    try {
      await sendMessage({
        incidentId,
        message: {
          contenu: newMessage,
          utilisateurId,
          Nom: userSession?.name,
          Prenom: userSession?.prenom,
          piecesJointes: []
        },
        type
      });
      
      setNewMessage('');
      scrollToBottom();
    } catch (error) {
      console.error('Erreur lors de l\'envoi du message:', error);
    }
  };

  const handleTyping = async (isTyping) => {
    try {
      if (socketRef.current) {
        socketRef.current.emit('isTyping', {
          incidentId,
          type,
          isTyping,
          utilisateurId,
          userName: `${userSession?.prenom} ${userSession?.name}`
        });
      }
    } catch (error) {
      console.error('Erreur lors de la mise à jour du statut de frappe:', error);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="400px">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Paper elevation={3} sx={{ height: '600px', display: 'flex', flexDirection: 'column', p: 2 }}>
      <Box ref={messageListRef} sx={{ 
        flexGrow: 1, 
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
        mb: 2,
        p: 2
      }}>
        {localMessages?.map((message, index) => (
          <Box
            key={index}
            sx={{
              display: 'flex',
              flexDirection: message.ID_Utilisateur === utilisateurId ? 'row-reverse' : 'row',
              gap: 2,
              alignItems: 'flex-start',
              maxWidth: '80%',
              alignSelf: message.ID_Utilisateur === utilisateurId ? 'flex-end' : 'flex-start'
            }}
          >
            <Avatar sx={{ 
              bgcolor: message.ID_Utilisateur === utilisateurId ? 'primary.main' : 'secondary.main',
              width: 40,
              height: 40
            }}>
              {message.nom_utilisateur?.charAt(0) || 'U'}
            </Avatar>
            <Box sx={{ maxWidth: '100%' }}>
              <Typography 
                variant="subtitle2" 
                sx={{ 
                  mb: 0.5,
                  color: 'text.secondary',
                  textAlign: message.ID_Utilisateur === utilisateurId ? 'right' : 'left'
                }}
              >
                {`${message.Prenom} ${message.Nom}`}
              </Typography>
              <Paper
                elevation={1}
                sx={{
                  p: 2,
                  minWidth: '120px',
                  maxWidth: '100%',
                  bgcolor: theme => message.ID_Utilisateur === utilisateurId 
                    ? theme.palette.mode === 'dark' 
                      ? 'primary.dark'  // Bleu plus foncé en mode sombre
                      : 'primary.light' // Garde la couleur claire en mode clair
                    : theme.palette.mode === 'dark'
                      ? 'grey.900'      // Fond très foncé pour les messages des autres en mode sombre
                      : 'background.paper',
                  borderRadius: 2,
                  wordBreak: 'break-word',
                  '& .MuiTypography-root': {
                    color: theme => theme.palette.mode === 'dark' 
                      ? theme.palette.common.white 
                      : theme.palette.text.primary
                  }
                }}
              >
                <Typography 
                  variant="body1" 
                  sx={{ 
                    whiteSpace: 'pre-wrap',
                    color: theme => theme.palette.mode === 'dark' 
                      ? 'common.white' 
                      : 'text.primary'
                  }}
                >
                  {message.Message}
                </Typography>
              </Paper>
              <Typography 
                variant="caption" 
                color="textSecondary"
                sx={{ 
                  display: 'block',
                  mt: 0.5,
                  textAlign: message.ID_Utilisateur === utilisateurId ? 'right' : 'left'
                }}
              >
                {moment(message.Date_envoi).format('HH:mm')}
              </Typography>
            </Box>
          </Box>
        ))}
        <div ref={messagesEndRef} />
      </Box>

      {typingUsers.size > 0 && (
        <Typography variant="caption" sx={{ ml: 2, fontStyle: 'italic' }}>
          {Array.from(typingUsers).join(', ')} {typingUsers.size === 1 ? 'est' : 'sont'} en train d'écrire...
        </Typography>
      )}

      <form onSubmit={handleSendMessage} style={{ display: 'flex', gap: '8px' }}>
        <TextField
          fullWidth
          multiline
          maxRows={4}
          value={newMessage}
          onChange={(e) => {
            setNewMessage(e.target.value);
            handleTyping(e.target.value.length > 0);
          }}
          onBlur={() => handleTyping(false)}
          onKeyPress={handleKeyPress}
          placeholder="Écrivez votre message..."
        />
        <Button 
          variant="contained" 
          onClick={handleSendMessage}
          disabled={!newMessage.trim()}
        >
          Envoyer
        </Button>
      </form>
    </Paper>
  );
};

export default Discussion;
