import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, TextField, Dialog, DialogTitle, DialogContent, DialogActions, Grid, List, ListItem, ListItemText, IconButton, TablePagination, Chip } from '@mui/material';
import { useAddSectionMutation, useDeleteSectionMutation, useModifySectionMutation, useGetAllSectionMutation } from 'state/api';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import ErrorMessage from './ErrorMessage';
import ValidationMessage from './ValidationMessage';
import InfoMessage from './InfoMessage';
import RenderInputs from './RenderInputs';
import { useTranslation } from 'react-i18next';

const RenderSection = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [nomSection, setNomSection] = useState('');
  const [descriptionSection, setDescriptionSection] = useState('');
  const [compositionDinput, setCompositionDinput] = useState([]);
  const [sections, setSections] = useState([]);
  const [filteredSections, setFilteredSections] = useState([]);
  const [editSection, setEditSection] = useState(null);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [sectionToDelete, setSectionToDelete] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [isLoading, setIsLoading] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [messageText, setMessageText] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [inputErrors, setInputErrors] = useState([]);

  const theme = useTheme();
  const buttonColor = theme.palette.primary.main;
  const textColor = theme.palette.mode === 'dark' ? theme.palette.primary.light : theme.palette.secondary.main;

  const userSession = useSelector((state) => state.adminAuth);

  const [getAllSection] = useGetAllSectionMutation();
  const [addSection] = useAddSectionMutation();
  const [modifySection] = useModifySectionMutation();
  const [deleteSection] = useDeleteSectionMutation();

  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [validation, setValidation] = useState('');

  const popUpError = (message) => {
    setError(message);
  };

  const popUpInfo = (message) => {
    setMessage(message);
  };

  const popUpValidation = (message) => {
    setValidation(message);
  };

  const showTempMessage = (text) => {
    setMessageText(text);
    setShowMessage(true);
    setTimeout(() => {
      setShowMessage(false);
    }, 5000);
  };

  const fetchSections = async () => {
    try {
      setIsLoading(true);
      const response = await getAllSection({ ID_Etablissement: userSession.ID_Etablissement });
      if (response.data) {
        setSections(response.data);
        setFilteredSections(response.data);
      }
    } catch (error) {
      if (error.originalStatus === 401) {
        alert('Votre session a expiré. Veuillez vous reconnecter.');
        window.location = '/loginuser';
      }
      popUpError('Erreur lors de la récupération des sections.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchSections();
  }, [getAllSection, userSession.ID_Etablissement]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    filterSections(event.target.value);
  };

  const filterSections = (term) => {
    const lowercasedTerm = term.toLowerCase();
    const filtered = sections.filter(section => section.Nom_Section.toLowerCase().includes(lowercasedTerm));
    setFilteredSections(filtered);
  };

  const handleOpen = () => {
    setNomSection('');
    setDescriptionSection('');
    setCompositionDinput([]);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const validateInputs = () => {
    let isValid = true;
    let newErrors = [];

    compositionDinput.forEach((input, index) => {
      let error = {};

      if (!input.Nom_Input) {
        error.Nom_Input = 'Le nom est requis';
        isValid = false;
      }

      if (!input.Instruction_Input) {
        error.Instruction_Input = "L'instruction est requise";
        isValid = false;
      }

      switch (input.Type_Dinput) {
        case 'checkbox':
        case 'radio':
        case 'dropdown':
          if (!input.Options || !input.Options.choices || input.Options.choices.length === 0) {
            error.choices = 'Au moins un choix est requis';
            isValid = false;
          }
          break;
        case 'file':
          if (!input.Options || !input.Options.accept) {
            error.accept = 'Format est requis';
            isValid = false;
          } else if (!/^(\.\w+,)*\.\w+$/.test(input.Options.accept)) {
            error.accept = 'Format invalide';
            isValid = false;
          }
          break;
        case 'varchar':
          if (!input.Options || !input.Options.maxlength) {
            error.maxlength = 'Maxlength est requis';
            isValid = false;
          }
          break;
        case 'slider':
          if (!input.Options || (input.Options.min === undefined && input.Options.min !== 0)) {
            error.min = 'Min est requis';
            isValid = false;
          }
          if (!input.Options || (input.Options.max === undefined && input.Options.max !== 0)) {
            error.max = 'Max est requis';
            isValid = false;
          }
          break;
        default:
          break;
      }

      newErrors[index] = error;
    });

    setInputErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async () => {
    if (!validateInputs()) {
      return;
    }
    try {
      setIsLoading(true);
      const response = await addSection({
        Nom_Section: nomSection,
        Description_Section: descriptionSection,
        Composition_dinput: compositionDinput,
        ID_Etablissement: userSession.ID_Etablissement
      });
      if (response.error) {
        popUpError('Erreur lors de la création de la section.');
      } else {
        handleClose();
        fetchSections();
        popUpValidation('La section a bien été créée.');
      }
    } catch (err) {
      if (err.originalStatus === 401) {
        alert('Votre session a expiré. Veuillez vous reconnecter.');
        window.location = '/loginuser';
      }
      popUpError('Erreur lors de la création de la section.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleEdit = (section) => {
    setEditSection(section);
    setNomSection(section.Nom_Section);
    setDescriptionSection(section.Description_Section);
    setCompositionDinput(Array.isArray(section.Composition_dinput) ? section.Composition_dinput : []);
    setOpenEditDialog(true);
  };

  const handleCloseEdit = () => {
    setEditSection(null);
    setOpenEditDialog(false);
  };

  const handleSaveEdit = async () => {
    if (!validateInputs()) {
      return;
    }
    try {
      setIsLoading(true);
      if (editSection) {
        const { ID_Section } = editSection;
        const response = await modifySection({
          ID_Section,
          Nom_Section: nomSection,
          Description_Section: descriptionSection,
          Composition_dinput: compositionDinput
        });
        if (response.error) {
          popUpError('Erreur lors de la modification de la section.');
        } else {
          handleCloseEdit();
          fetchSections();
          popUpValidation('Les modifications ont bien été appliquées.');
        }
      }
    } catch (err) {
      if (err.originalStatus === 401) {
        alert('Votre session a expiré. Veuillez vous reconnecter.');
        window.location = '/loginuser';
      }
      popUpError('Erreur lors de la modification de la section.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditChange = (field, value) => {
    if (field === 'Composition_dinput') {
      setCompositionDinput(value);
    } else {
      setEditSection((prevEditSection) => ({
        ...prevEditSection,
        [field]: value,
      }));
    }
  };

  const handleDeleteSection = async () => {
    try {
      if (sectionToDelete) {
        const { ID_Section } = sectionToDelete;
        const response = await deleteSection({
          ID_Section: ID_Section,
        });
        if (response.error) {
          popUpError('Erreur lors de la suppression de la section.');
        } else {
          setSections(prevSections => prevSections.filter(section => section.ID_Section !== ID_Section));
          fetchSections();
          popUpValidation('La section a bien été supprimée.');
          setOpenDeleteDialog(false);
        }
      }
    } catch (err) {
      if (err.originalStatus === 401) {
        alert('Votre session a expiré. Veuillez vous reconnecter.');
        window.location = '/loginuser';
      }
      popUpError('Erreur lors de la suppression de la section.');
    }
  };

  const handleDeleteDialogOpen = (section) => {
    setSectionToDelete(section);
    setOpenDeleteDialog(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box m="1.5rem 2.5rem">
      <Typography variant="h3" gutterBottom style={{ marginTop: '60px', marginBottom: '20px' }}>
        {t('RenderSection.GestionSections')}
      </Typography>
      <ErrorMessage message={error} setError={setError} />
      <InfoMessage message={message} setInfo={setMessage} />
      <ValidationMessage message={validation} setValidation={setValidation} />
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={5} style={{ textAlign: 'left' }}>
          <Button variant="contained" onClick={handleOpen} style={{ height: '55px' }}>
            {t('RenderSection.CreerNouvelleSection')}
          </Button>
        </Grid>
        <Grid item xs={7} style={{ textAlign: 'right', display: 'flex', alignItems: 'center' }}>
          <TextField label={t('RenderSection.RechercherSection')} value={searchTerm} onChange={handleSearchChange} style={{ width: '50%' }} />
        </Grid>
      </Grid>
      
      <List style={{ marginTop: '32px' }}>
        {filteredSections.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((section, index) => (
          <ListItem key={index} secondaryAction={
            <>
              <IconButton edge="end" aria-label="edit" onClick={() => handleEdit(section)}>
                <EditIcon />
              </IconButton>
              <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteDialogOpen(section)}>
                <DeleteIcon />
              </IconButton>
            </>
          }>
            <ListItemText
              primary={section.Nom_Section}
              secondary={
                <div style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
                  {section.Composition_dinput.map((input, i) => (
                    <Chip
                      key={i}
                      label={`${input.Type_Dinput}: ${input.Nom_Input}`}
                      style={{ marginRight: 4, marginBottom: 2 }}
                    />
                  ))}
                </div>
              }
            />
          </ListItem>
        ))}
      </List>

      <TablePagination
        component="div"
        count={filteredSections.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 25, 100]}
      />

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{t('RenderSection.AjouterNouvelleSection')}</DialogTitle>
        <DialogContent>
          <form>
            <TextField label={t('RenderSection.NomSection')} value={nomSection} onChange={(e) => setNomSection(e.target.value)} fullWidth margin="normal" />
            <TextField label={t('RenderSection.DescriptionSection')} value={descriptionSection} onChange={(e) => setDescriptionSection(e.target.value)} fullWidth margin="normal" />
          </form>
          <Typography variant="h6" gutterBottom style={{ marginTop: '20px' }}>{t('RenderSection.ListeSection')}</Typography>
          <RenderInputs inputs={compositionDinput} onInputsChange={setCompositionDinput} inputErrors={inputErrors} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} style={{ backgroundColor: buttonColor, color: textColor }}>{t('RenderSection.Annuler')}</Button>
          <Button onClick={handleSubmit} style={{ backgroundColor: buttonColor, color: textColor }}>{t('RenderSection.Soumettre')}</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openEditDialog} onClose={handleCloseEdit}>
        <DialogTitle>{t('RenderSection.EditerSection')}</DialogTitle>
        <DialogContent>
          <form>
            <TextField
              label={t('RenderSection.NomSection')}
              value={nomSection}
              onChange={(e) => setNomSection(e.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              label={t('RenderSection.DescriptionSection')}
              value={descriptionSection}
              onChange={(e) => setDescriptionSection(e.target.value)}
              fullWidth
              margin="normal"
            />
            <RenderInputs inputs={compositionDinput} onInputsChange={setCompositionDinput} inputErrors={inputErrors} />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEdit} style={{ backgroundColor: buttonColor, color: textColor }}>{t('RenderSection.Annuler')}</Button>
          <Button onClick={handleSaveEdit} style={{ backgroundColor: buttonColor, color: textColor }}>{t('RenderSection.Sauvegarder')}</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)}>
        <DialogTitle>{t('RenderSection.SupprimerSection')}</DialogTitle>
        <DialogContent>
          <Typography>{t('RenderSection.ConfirmerSuppression')}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)} style={{ backgroundColor: buttonColor, color: textColor }}>{t('RenderSection.Annuler')}</Button>
          <Button onClick={handleDeleteSection} style={{ backgroundColor: buttonColor, color: textColor }}>{t('RenderSection.Supprimer')}</Button>
        </DialogActions>
      </Dialog>

      {isLoading && <p>{t('RenderSection.Chargement')}</p>}
    </Box>
  );
};

export default RenderSection;