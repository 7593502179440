import React, { useState } from 'react';
import { useNavigate, Navigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useInscriptionMutation } from 'state/api';
import './inscription.css';

function Inscription() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [pseudo, setPseudo] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [name, setName] = useState('');
    const [prenom, setPrenom] = useState('');
    const [phonePrefix, setPhonePrefix] = useState('+33');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [authError, setAuthError] = useState(null);
    const [passwordCriteria, setPasswordCriteria] = useState({
      length: false,
      lowercase: false,
      uppercase: false,
      number: false,
      special: false
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
  
    const [inscription] = useInscriptionMutation();
  
    const validatePassword = (password) => {
      const criteria = {
        length: password.length >= 8,
        lowercase: /[a-z]/.test(password),
        uppercase: /[A-Z]/.test(password),
        number: /\d/.test(password),
        special: /[@$!%*?&]/.test(password)
      };
      setPasswordCriteria(criteria);
      return Object.values(criteria).every(Boolean);
    };
  
    const validateEmail = (email) => {
      const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
      return regex.test(email);
    };
  
    const validatePhoneNumber = (number) => {
        const regex = /^[0-9]{9,10}$/;
        return regex.test(number);
    };
      
    const isFormValid = () => {
        return name && prenom && pseudo && email && password && confirmPassword && phoneNumber &&
               password === confirmPassword &&
               Object.values(passwordCriteria).every(Boolean) && !emailError && !phoneError && !authError && !isSubmitting;
    };

    const handlePasswordChange = (e) => {
      const newPassword = e.target.value;
      setPassword(newPassword);
      validatePassword(newPassword);
      setAuthError(null);
    };
  
    const handleEmailChange = (e) => {
      const newEmail = e.target.value;
      setEmail(newEmail);
      if (!validateEmail(newEmail)) {
        setEmailError(t('Inscription.emailError'));
      } else {
        setEmailError('');
      }
      setAuthError(null);
    };
  
    const handlePhoneNumberChange = (e) => {
      const newPhone = e.target.value;
      const sanitizedPhone = newPhone.replace(/[^0-9]/g, '').slice(0, 10);
      setPhoneNumber(sanitizedPhone);
      
      if (!validatePhoneNumber(sanitizedPhone)) {
        setPhoneError(t('Inscription.phoneError'));
      } else {
        setPhoneError('');
      }
      setAuthError(null);
    };
  
    const handleSignup = async (event) => {
      event.preventDefault();
      if (!isFormValid()) {
        setAuthError(t('Inscription.authError'));
        return;
      }
  
      if (password !== confirmPassword) {
        setAuthError(t('Inscription.passwordMismatch'));
        return;
      }
  
      if (!validatePassword(password) || !validateEmail(email) || !validatePhoneNumber(phoneNumber)) {
        setAuthError(t('Inscription.correctErrors'));
        return;
      }
  
      setIsSubmitting(true);
      try {
          const formattedPhoneNumber = phonePrefix + (phoneNumber.startsWith('0') ? phoneNumber.slice(1) : phoneNumber);
          
          const userData = { 
            pseudo, 
            password, 
            email, 
            name, 
            prenom, 
            phoneNumber: formattedPhoneNumber 
          };
          
          await inscription(userData);
          navigate('/');
      } catch (error) {
          let errorMessage;
          if (error.status === 409) {
              errorMessage = t('Inscription.userExists');
          } else if (error.status === 400) {
              errorMessage = t('Inscription.invalidData');
          } else {
              errorMessage = error.data?.message || t('Inscription.signupError');
          }
          setAuthError(errorMessage);
      } finally {
          setIsSubmitting(false);
      }
    };

  return (
    <div className="signup-container" style={{ paddingTop: '40px', paddingBottom: '40px' }}>
      <form onSubmit={handleSignup} className="signup-form" style={{ maxWidth: '400px', margin: '0 auto', padding: '40px 20px' }}>
        {authError && <p className="signup-error">{authError}</p>}
        {emailError && <p className="signup-error">{emailError}</p>}
        {phoneError && <p className="signup-error">{phoneError}</p>}
        <div className="signup-header">
          <h2>{t('Inscription.signup')}</h2>
          <p>{t('Inscription.fillFields')}</p>
        </div>
        
        <input
          type="text"
          value={name}
          onChange={(e) => {setName(e.target.value); setAuthError(null);}}
          placeholder={t('Inscription.namePlaceholder')}
          className="signup-input"
          id="name"
        />
        <input
          type="text"
          value={prenom}
          onChange={(e) => {setPrenom(e.target.value); setAuthError(null);}}
          placeholder={t('Inscription.prenomPlaceholder')}
          className="signup-input"
          id="prenom"
        />
        <input
          type="email"
          value={email}
          onChange={handleEmailChange}
          placeholder={t('Inscription.emailPlaceholder')}
          className="signup-input"
          id="email"
        />
        <input
          type="text"
          value={pseudo}
          onChange={(e) => {setPseudo(e.target.value); setAuthError(null);}}
          placeholder={t('Inscription.pseudoPlaceholder')}
          className="signup-input"
          id="pseudo"
        />
        <div style={{ width: '100%' }}>
          <input
            type="password"
            value={password}
            onChange={handlePasswordChange}
            placeholder={t('Inscription.passwordPlaceholder')}
            className="signup-input"
            style={{ width: '100%' }}
            id="password"
          />
          <ul style={{ listStyle: 'none', padding: '10px', margin: '0', color: '#1976d2', fontSize: '0.85rem' }}>
            {!passwordCriteria.length && <li>{t('Inscription.passwordLength')}</li>}
            {!passwordCriteria.lowercase && <li>{t('Inscription.passwordLowercase')}</li>}
            {!passwordCriteria.uppercase && <li>{t('Inscription.passwordUppercase')}</li>}
            {!passwordCriteria.number && <li>{t('Inscription.passwordNumber')}</li>}
            {!passwordCriteria.special && <li>{t('Inscription.passwordSpecial')}</li>}
          </ul>
        </div>
        <input
          type="password"
          value={confirmPassword}
          onChange={(e) => {setConfirmPassword(e.target.value); setAuthError(null);}}
          placeholder={t('Inscription.confirmPasswordPlaceholder')}
          className="signup-input"
          id="confirmPassword"
        />
        <div style={{ display: 'flex', gap: '10px' }}>
          <input
            type="text"
            value={phonePrefix}
            onChange={(e) => setPhonePrefix(e.target.value)}
            placeholder="+33"
            className="signup-input"
            style={{ width: '80px' }}
            id="phonePrefix"
          />
          <input
            type="text"
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
            placeholder={t('Inscription.phonePlaceholder')}
            className="signup-input"
            style={{ flex: 1 }}
            id="phoneNumber"
          />
        </div>
        <button type="submit" className="signup-button" disabled={!isFormValid()}>
          {isSubmitting ? t('Inscription.submitting') : t('Inscription.signupButton')}
        </button>
      </form>
    </div>
  );
}

export default Inscription;
