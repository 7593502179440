import React from "react";
import { Box } from "@mui/material";
import Header from "components/Header";
import TreeSG from "../../components/service_generaux/TreeSG";
import { useTranslation } from "react-i18next";

const SecteurSG = () => {
  const { t } = useTranslation();

  return (
    <Box m="1.5rem 2.5rem">
      <Header title={t('SecteurSG.title')} subtitle={t('SecteurSG.subtitle')} />
      <div className="app-container">
        <h1>{t('SecteurSG.header')}</h1>
        <TreeSG />
      </div>
    </Box>
  );
};

export default SecteurSG;
