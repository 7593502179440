import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Paper,
  Menu,
  MenuItem,
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  useTheme,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import InfoIcon from "@mui/icons-material/Info";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  useUpdateIncidentITTitleMutation,
  useUpdateIncidentITStateMutation,
  useMarkAsAbuseIncidentITMutation,
  useCloseTicketIncidentITMutation,
  useAddCommentIncidentITMutation,
  useUpdateCommentIncidentITMutation,
  useDeleteCommentIncidentITMutation,
  useAddAttachmentIncidentITMutation,
  useDeleteAttachmentIncidentITMutation,
  useGetIncidentITByIdMutation,
  useGetUsersInfoIncidentITMutation,
  useExportAttachmentIncidentITMutation,
  useGetTrustIncidentITMutation,
} from "state/api";
import ErrorMessage from "../ErrorMessage";
import ValidationMessage from "../ValidationMessage";
import i18n from "i18next";
import Discussion from '../Discussion';

const DetailIncidentIT = ({ incident, refreshIncident }) => {
  const [editableIncident, setEditableIncident] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [title, setTitle] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [isAddingComment, setIsAddingComment] = useState(false);
  const [commentText, setCommentText] = useState("");
  const [editCommentId, setEditCommentId] = useState(null);
  const [editCommentText, setEditCommentText] = useState("");
  const [userInfoMap, setUserInfoMap] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);
  const [isAddingAttachment, setIsAddingAttachment] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [attachmentToDelete, setAttachmentToDelete] = useState(null);
  const [validationMessage, setValidationMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [trustScore, setTrustScore] = useState(null);
  const [openImageDialog, setOpenImageDialog] = useState(false);

  const userSession = useSelector((state) => state.userAuth);

  const theme = useTheme();
  const { t } = useTranslation();

  const [updateIncidentTitle] = useUpdateIncidentITTitleMutation();
  const [updateIncidentState] = useUpdateIncidentITStateMutation();
  const [markAsAbuse] = useMarkAsAbuseIncidentITMutation();
  const [closeTicket] = useCloseTicketIncidentITMutation();
  const [addComment] = useAddCommentIncidentITMutation();
  const [updateComment] = useUpdateCommentIncidentITMutation();
  const [deleteComment] = useDeleteCommentIncidentITMutation();
  const [addAttachment] = useAddAttachmentIncidentITMutation();
  const [deleteAttachment] = useDeleteAttachmentIncidentITMutation();
  const [getIncidentITById] = useGetIncidentITByIdMutation();
  const [getUsersInfo] = useGetUsersInfoIncidentITMutation();
  const [exportAttachmentIncidentIT] = useExportAttachmentIncidentITMutation();
  const [getTrustIncident] = useGetTrustIncidentITMutation();

  useEffect(() => {
    setEditableIncident(incident);
    setTitle(incident ? incident.Titre_ticket : "");
    fetchUserInfos();
    fetchTrustScore();
  }, [incident]);

  useEffect(() => {
    if (editableIncident) {
      setTitle(editableIncident.Titre_ticket);
    }
  }, [editableIncident]);

  const fetchUserInfos = async () => {
    if (incident && incident.Commentaires && incident.Commentaires.length > 0) {
      const userIds = incident.Commentaires.map((comment) => comment.ID_Utilisateur);
      try {
        const response = await getUsersInfo({ userIds }).unwrap();
        const userInfoMap = {};
        response.forEach((user) => {
          userInfoMap[user.ID_Utilisateur] = user;
        });
        setUserInfoMap(userInfoMap);
      } catch (error) {
        console.error(t("DetailIncidentIT.error_fetching_user_info"), error);
      }
    }
  };

  const fetchTrustScore = async () => {
    if (incident && incident.Signaleurs && incident.Signaleurs.length > 0) {
      try {
        const response = await getTrustIncident({ userIds: incident.Signaleurs }).unwrap();
        setTrustScore(response.trustScore);
      } catch (error) {
        console.error(t("DetailIncidentIT.error_fetching_trust_score"), error);
      }
    }
  };

  const handleEditClick = () => {
    setIsEditing(!isEditing);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setEditableIncident({ ...editableIncident, [name]: value });
  };

  const handleSaveClick = async () => {
    if (!editableIncident) return;
    await updateIncidentTitle({ ID_Incident_IT: editableIncident.ID_Incident_IT, Titre_ticket: title });
    setIsEditing(false);
    refreshIncident();
  };

  const handleCloseTicket = async () => {
    if (!editableIncident) return;
    await closeTicket({ ID_Incident_IT: editableIncident.ID_Incident_IT });
    refreshIncident();
  };

  const handleReportAbuse = async () => {
    if (!editableIncident) return;
    await markAsAbuse({ ID_Incident_IT: editableIncident.ID_Incident_IT });
    refreshIncident();
  };

  const handleTitleEditClick = () => {
    setIsEditingTitle(true);
  };

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleTitleSave = async () => {
    if (!editableIncident) return;
    await updateIncidentTitle({ ID_Incident_IT: editableIncident.ID_Incident_IT, Titre_ticket: title });
    setIsEditingTitle(false);
    setEditableIncident({ ...editableIncident, Titre_ticket: title });
    refreshIncident();
  };

  const handleTitleCancel = () => {
    setIsEditingTitle(false);
    setTitle(editableIncident ? editableIncident.Titre_ticket : "");
  };

  const getUrgenceText = (level) => {
    switch (level) {
      case 4:
        return t("DetailIncidentIT.urgence_level_4");
      case 3:
        return t("DetailIncidentIT.urgence_level_3");
      case 2:
        return t("DetailIncidentIT.urgence_level_2");
      case 1:
        return t("DetailIncidentIT.urgence_level_1");
      default:
        return t("DetailIncidentIT.urgence_level_unknown");
    }
  };

  const getEtatText = (etat) => {
    switch (etat) {
      case "creation":
        return t("DetailIncidentIT.etat_creation");
      case "assigne":
        return t("DetailIncidentIT.etat_assigne");
      case "en cours":
        return t("DetailIncidentIT.etat_en_cours");
      case "termine":
        return t("DetailIncidentIT.etat_termine");
      case "cloture":
        return t("DetailIncidentIT.etat_cloture");
      case "abus":
        return t("DetailIncidentIT.etat_abus");
      default:
        return t("DetailIncidentIT.etat_unknown");
    }
  };

  const renderNom = () => {
    if (!editableIncident) return t("DetailIncidentIT.nom_unavailable");
    if (editableIncident.Type_de_ticket === "service") {
      return editableIncident.details?.specificDetails?.Titre || t("DetailIncidentIT.service_name_unavailable");
    } else if (editableIncident.Type_de_ticket === "equipement") {
      return editableIncident.details?.specificDetails?.Nom_Equipement || t("DetailIncidentIT.equipment_name_unavailable");
    } else if (editableIncident.Type_de_ticket === "equipement_particulier") {
      return editableIncident.details?.specificDetails?.Nom_Ep || t("DetailIncidentIT.particular_equipment_name_unavailable");
    }
  };

  const renderLieuOuPortail = () => {
    if (!editableIncident) return t("DetailIncidentIT.location_unavailable");
    if (editableIncident.ID_Noeud) {
      return editableIncident.details?.locationDetails?.Nom_Element || t("DetailIncidentIT.location_unavailable");
    } else if (editableIncident.ID_Portail) {
      return editableIncident.details?.etablissementDetails?.Nom_Etablissement || t("DetailIncidentIT.portail_unavailable");
    }
  };

  const renderServiceDetails = () => {
    if (editableIncident.Type_de_ticket !== "service") return null;

    const {
      Besoin,
      Precision_Besoin,
      Date_Requise_Type,
      Date_unique,
      Intervalle_de_date,
      Date_unique_avec_heure_unique,
      Data_unique_avec_intervalle_dheure,
      Intervalle_de_date_avec_heure_unique,
      Intervalle_de_date_avec_intervalle_dheure,
    } = editableIncident;

    return (
      <Box sx={{ marginTop: "16px" }}>
        <Typography variant="h6" sx={{ fontStyle: "italic", color: "grey" }}>
          {t("DetailIncidentIT.service_details_title")}
        </Typography>
        <Table sx={{ marginTop: "8px" }}>
          <TableBody>
            {Besoin && Besoin.length > 0 && (
              <TableRow>
                <TableCell>
                  <span role="img" aria-label="besoin">📝</span> {t("DetailIncidentIT.besoin")}:
                </TableCell>
                <TableCell>{Besoin.join(", ")}</TableCell>
              </TableRow>
            )}
            {Precision_Besoin && Precision_Besoin.length > 0 && (
              <TableRow>
                <TableCell>
                  <span role="img" aria-label="precision">🔍</span> {t("DetailIncidentIT.precision_besoin")}:
                </TableCell>
                <TableCell>{Precision_Besoin.join(", ")}</TableCell>
              </TableRow>
            )}
            {Date_Requise_Type && Date_Requise_Type !== "Non" && (
              <>
                <TableRow>
                  <TableCell>
                    <span role="img" aria-label="date-type">📅</span> {t("DetailIncidentIT.date_type")}:
                  </TableCell>
                  <TableCell>{Date_Requise_Type}</TableCell>
                </TableRow>
                {Date_unique && (
                  <TableRow>
                    <TableCell>
                      <span role="img" aria-label="date">📅</span> {t("DetailIncidentIT.date_unique")}:
                    </TableCell>
                    <TableCell>{new Date(Date_unique).toLocaleString()}</TableCell>
                  </TableRow>
                )}
                {Intervalle_de_date && (
                  <>
                    <TableRow>
                      <TableCell>
                        <span role="img" aria-label="start-date">⏳</span> {t("DetailIncidentIT.start_date")}:
                      </TableCell>
                      <TableCell>{new Date(Intervalle_de_date.Date_debut).toLocaleString()}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <span role="img" aria-label="end-date">⌛</span> {t("DetailIncidentIT.end_date")}:
                      </TableCell>
                      <TableCell>{new Date(Intervalle_de_date.Date_fin).toLocaleString()}</TableCell>
                    </TableRow>
                  </>
                )}
                {Date_unique_avec_heure_unique && (
                  <TableRow>
                    <TableCell>
                      <span role="img" aria-label="date-time">⏰</span> {t("DetailIncidentIT.date_unique_with_time")}:
                    </TableCell>
                    <TableCell>
                      {new Date(Date_unique_avec_heure_unique.Date).toLocaleString()} à {Date_unique_avec_heure_unique.Heure}
                    </TableCell>
                  </TableRow>
                )}
                {Data_unique_avec_intervalle_dheure && (
                  <TableRow>
                    <TableCell>
                      <span role="img" aria-label="date-time-interval">⏳⏰</span> {t("DetailIncidentIT.date_unique_with_time_interval")}:
                    </TableCell>
                    <TableCell>
                      {new Date(Data_unique_avec_intervalle_dheure.Date).toLocaleString()} de {Data_unique_avec_intervalle_dheure.Heure_debut} à {Data_unique_avec_intervalle_dheure.Heure_fin}
                    </TableCell>
                  </TableRow>
                )}
                {Intervalle_de_date_avec_heure_unique && (
                  <>
                    <TableRow>
                      <TableCell>
                        <span role="img" aria-label="start-date-time">⏳⏰</span> {t("DetailIncidentIT.start_date_with_time")}:
                      </TableCell>
                      <TableCell>{new Date(Intervalle_de_date_avec_heure_unique.Date_debut).toLocaleString()}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <span role="img" aria-label="end-date-time">⌛⏰</span> {t("DetailIncidentIT.end_date_with_time")}:
                      </TableCell>
                      <TableCell>{new Date(Intervalle_de_date_avec_heure_unique.Date_fin).toLocaleString()} à {Intervalle_de_date_avec_heure_unique.Heure}</TableCell>
                    </TableRow>
                  </>
                )}
                {Intervalle_de_date_avec_intervalle_dheure && (
                  <>
                    <TableRow>
                      <TableCell>
                        <span role="img" aria-label="start-date-time-interval">⏳⏰</span> {t("DetailIncidentIT.start_date_with_time_interval")}:
                      </TableCell>
                      <TableCell>
                        {new Date(Intervalle_de_date_avec_intervalle_dheure.Date_debut).toLocaleString()} de {Intervalle_de_date_avec_intervalle_dheure.Heure_debut} à {Intervalle_de_date_avec_intervalle_dheure.Heure_fin}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <span role="img" aria-label="end-date-time-interval">⌛⏰</span> {t("DetailIncidentIT.end_date_with_time_interval")}:
                      </TableCell>
                      <TableCell>
                        {new Date(Intervalle_de_date_avec_intervalle_dheure.Date_fin).toLocaleString()} de {Intervalle_de_date_avec_intervalle_dheure.Heure_debut} à {Intervalle_de_date_avec_intervalle_dheure.Heure_fin}
                      </TableCell>
                    </TableRow>
                  </>
                )}
              </>
            )}
          </TableBody>
        </Table>
      </Box>
    );
  };

  const handleAddCommentClick = () => {
    setIsAddingComment(true);
  };

  const handleSaveComment = async () => {
    if (!editableIncident) return;
    await addComment({
      ID_Incident_IT: editableIncident.ID_Incident_IT,
      ID_Utilisateur: userSession.user.ID_Utilisateur,
      Commentaire: commentText,
    });
    setCommentText("");
    setIsAddingComment(false);
    refreshIncident();
  };

  const handleCancelComment = () => {
    setIsAddingComment(false);
    setCommentText("");
  };

  const handleEditCommentClick = (comment) => {
    setEditCommentId(comment._id);
    setEditCommentText(comment.Commentaire);
  };

  const handleSaveEditComment = async () => {
    if (!editableIncident || !editCommentId) return;
    await updateComment({
      ID_Incident_IT: editableIncident.ID_Incident_IT,
      CommentID: editCommentId,
      ID_Utilisateur: userSession.user.ID_Utilisateur,
      Commentaire: editCommentText,
    });
    setEditCommentId(null);
    setEditCommentText("");
    refreshIncident();
  };

  const handleCancelEditComment = () => {
    setEditCommentId(null);
    setEditCommentText("");
  };

  const handleDeleteComment = async (comment) => {
    if (!editableIncident) return;
    await deleteComment({
      ID_Incident_IT: editableIncident.ID_Incident_IT,
      CommentID: comment._id,
    });
    setConfirmDelete(false);
    refreshIncident();
  };

  const handleAddAttachment = async () => {
    if (!editableIncident || !selectedFile) return;
    const formData = new FormData();
    formData.append("docs", selectedFile);
    formData.append("ID_Incident_IT", editableIncident.ID_Incident_IT);
    formData.append("ID_Utilisateur", userSession.user.ID_Utilisateur);
    await addAttachment(formData);
    setSelectedFile(null);
    setIsAddingAttachment(false);
    refreshIncident();
  };

  const handleDownloadAttachment = async (attachment) => {
    if (!editableIncident) return;
    try {
      const response = await exportAttachmentIncidentIT({
        ID_Incident_IT: editableIncident.ID_Incident_IT,
        filename: attachment,
      }).unwrap();
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", attachment.split("\\").pop().split("--")[0]);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setValidationMessage(t("DetailIncidentIT.download_success"));
    } catch (error) {
      console.error(t("DetailIncidentIT.download_error"), error);
      setErrorMessage(t("DetailIncidentIT.download_error"));
    }
  };

  const handleDeleteAttachment = async (attachment) => {
    if (!editableIncident) return;
    await deleteAttachment({
      ID_Incident_IT: editableIncident.ID_Incident_IT,
      filename: attachment,
      ID_Utilisateur: userSession.user.ID_Utilisateur,
    });
    setAttachmentToDelete(null);
    refreshIncident();
  };

  const handleStatusMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleStatusMenuClose = () => {
    setAnchorEl(null);
  };

  const handleStatusChange = async (newStatus) => {
    if (!editableIncident) return;

    if (
      editableIncident.Assigne &&
      editableIncident.Assigne !== userSession.user.ID_Utilisateur &&
      !userSession.ID_Groupe.includes(editableIncident.ID_Groupe)
    ) {
      setSnackbarMessage(t("DetailIncidentIT.not_authorized"));
      setSnackbarOpen(true);
      return;
    }

    await updateIncidentState({
      ID_Incident_IT: editableIncident.ID_Incident_IT,
      Etat_du_ticket: newStatus,
      ID_Utilisateur: userSession.user.ID_Utilisateur,
    });
    setAnchorEl(null);
    refreshIncident();
  };

  const renderStatusOptions = () => {
    const { Etat_du_ticket } = editableIncident;
    switch (Etat_du_ticket) {
      case "creation":
        return <MenuItem onClick={() => handleStatusChange("assigne")}>{t("DetailIncidentIT.status_assigne")}</MenuItem>;
      case "assigne":
        return [
          <MenuItem key="en_cours" onClick={() => handleStatusChange("en cours")}>{t("DetailIncidentIT.status_en_cours")}</MenuItem>,
          <MenuItem key="creation" onClick={() => handleStatusChange("creation")}>{t("DetailIncidentIT.status_creation")}</MenuItem>,
        ];
      case "en cours":
        return <MenuItem onClick={() => handleStatusChange("termine")}>{t("DetailIncidentIT.status_termine")}</MenuItem>;
      case "termine":
        return [
          <MenuItem key="cloture" onClick={() => handleStatusChange("cloture")}>{t("DetailIncidentIT.status_cloture")}</MenuItem>,
          <MenuItem key="assigne" onClick={() => handleStatusChange("assigne")}>{t("DetailIncidentIT.status_assigne")}</MenuItem>,
        ];
      case "cloture":
        return <MenuItem onClick={() => handleStatusChange("creation")}>{t("DetailIncidentIT.status_creation")}</MenuItem>;
      default:
        return null;
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const getFileName = (attachment) => {
    const parts = attachment.split("\\");
    const fileNameWithSalt = parts[parts.length - 1];
    return fileNameWithSalt.split("--")[0];
  };

  const getTrustColor = (score) => {
    const r = Math.round((255 * (100 - score)) / 100);
    const g = Math.round((255 * score) / 100);
    const b = 0;
    return `rgb(${r},${g},${b})`;
  };

  const handleOpenImageDialog = () => {
    setOpenImageDialog(true);
  };

  const handleCloseImageDialog = () => {
    setOpenImageDialog(false);
  };

  if (!editableIncident) {
    return <Typography variant="h6">{t("DetailIncidentIT.select_incident_to_view_details")}</Typography>;
  }

  const isAbus = editableIncident.Etat_du_ticket === "abus";
  const isCloture = editableIncident.Etat_du_ticket === "cloture";

  return (
    <Box sx={{ margin: "20px", padding: "20px", borderRadius: "12px", backgroundColor: theme.palette.background.alt }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", marginTop: "16px" }}>
        {/* Première section */}
        <Box sx={{ width: "60%" }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {isEditingTitle ? (
              <Box sx={{ width: "100%" }}>
                <TextField
                  value={title}
                  onChange={handleTitleChange}
                  fullWidth
                  multiline
                  rows={3}
                  sx={{ fontSize: "1rem", marginBottom: "8px" }}
                  inputProps={{ style: { color: "black" } }}
                />
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Button variant="contained" color="primary" onClick={handleTitleSave}>
                    {t("DetailIncidentIT.save")}
                  </Button>
                  <Button variant="contained" onClick={handleTitleCancel}>
                    {t("DetailIncidentIT.cancel")}
                  </Button>
                </Box>
              </Box>
            ) : (
              <Typography variant="h4" sx={{ fontSize: "1rem", flexGrow: 1 }}>
                {editableIncident.Titre_ticket}
              </Typography>
            )}
            {!isEditingTitle && (
              <IconButton onClick={handleTitleEditClick} sx={{ marginLeft: 1 }}>
                <EditIcon />
              </IconButton>
            )}
          </Box>
          <Box sx={{ marginTop: "16px" }}>
            <Typography variant="h6" sx={{ fontStyle: "italic", color: "grey" }}>
              {t("DetailIncidentIT.essential_title")}
            </Typography>
            <Table sx={{ marginTop: "8px" }}>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <span role="img" aria-label="responsible">👤</span> {t("DetailIncidentIT.responsible")}:
                  </TableCell>
                  <TableCell>{editableIncident.details?.groupDetails?.Nom_groupe}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <span role="img" aria-label="urgency">⏰</span> {t("DetailIncidentIT.urgency")}:
                  </TableCell>
                  <TableCell>{getUrgenceText(editableIncident.Niveau_urgence_intervention)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <span role="img" aria-label="location">📍</span> {editableIncident.ID_Noeud ? t("DetailIncidentIT.location") : t("DetailIncidentIT.portal")}:
                  </TableCell>
                  <TableCell>{renderLieuOuPortail()}</TableCell>
                </TableRow>
                {editableIncident.Type_de_ticket !== "service" && (
                  <TableRow>
                    <TableCell>
                      <span role="img" aria-label="reporters">👥</span> {t("DetailIncidentIT.reporters_count")}:
                    </TableCell>
                    <TableCell>{editableIncident.Email_signaleur.length}</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Box>
        </Box>

        {/* Deuxième section */}
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", marginTop: "16px", marginLeft: "20px" }}>
          {/* Bouton pour ouvrir la popup avec l'image explicative */}
          <Box sx={{ position: "relative" }}>
            <IconButton
              onClick={handleOpenImageDialog}
              sx={{ position: "absolute", top: -30, right: 10 }}
            >
              <InfoIcon />
            </IconButton>
          </Box>
          {trustScore !== null && (
            <Typography variant="body1" sx={{ fontWeight: "bold", marginBottom: "8px", color: getTrustColor(trustScore) }}>
              {t("DetailIncidentIT.trust_score")}: {trustScore}%
            </Typography>
          )}
          <Typography variant="body1" sx={{ fontWeight: "bold", marginBottom: "8px" }}>
            {t("DetailIncidentIT.ticket_status")} : {getEtatText(editableIncident.Etat_du_ticket)}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleStatusMenuClick}
            sx={{ marginBottom: "8px" }}
            disabled={isAbus}
          >
            {t("DetailIncidentIT.modify")}
          </Button>
          <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleStatusMenuClose}>
            {renderStatusOptions()}
          </Menu>
          <Button
            variant="contained"
            color="error"
            onClick={handleCloseTicket}
            sx={{ marginBottom: "8px" }}
            disabled={isAbus || isCloture}
          >
            {t("DetailIncidentIT.close_ticket")}
          </Button>
          <Button variant="contained" color="warning" onClick={handleReportAbuse} disabled={isAbus}>
            {t("DetailIncidentIT.report_abuse")}
          </Button>
        </Box>
      </Box>

      {/* Troisième section */}
      <Box sx={{ marginTop: "32px" }}>
        <Typography variant="h6" sx={{ fontStyle: "italic", color: "grey" }}>
          {t("DetailIncidentIT.details")}
        </Typography>
        <Table sx={{ marginTop: "8px" }}>
          <TableBody>
            <TableRow>
              <TableCell>
                <span role="img" aria-label="type">📋</span> {t("DetailIncidentIT.equipment_type")}:
              </TableCell>
              <TableCell>
                {editableIncident.Type_de_ticket === "equipement_particulier" && t("DetailIncidentIT.particular_equipment")}
                {editableIncident.Type_de_ticket === "equipement" && t("DetailIncidentIT.equipment")}
                {editableIncident.Type_de_ticket === "service" && t("DetailIncidentIT.service")}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <span role="img" aria-label="name">🏷️</span> {t("DetailIncidentIT.name")}:
              </TableCell>
              <TableCell>{renderNom()}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <span role="img" aria-label="description">📝</span> {t("DetailIncidentIT.description")}:
              </TableCell>
              <TableCell>
                {editableIncident.Description.map((desc, index) => (
                  <Typography key={index} variant="subtitle1" sx={{ color: "grey" }}>
                    {desc}
                  </Typography>
                ))}
              </TableCell>
            </TableRow>
            {editableIncident.Nombre_de_particule_impacte > 1 && (
              <TableRow>
                <TableCell>
                  <span role="img" aria-label="particles">🔢</span> {t("DetailIncidentIT.particles_count")}:
                </TableCell>
                <TableCell>{editableIncident.Nombre_de_particule_impacte}</TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell>
                <span role="img" aria-label="open">📅</span> {t("DetailIncidentIT.opened_on")}:
              </TableCell>
              <TableCell>{new Date(editableIncident.Creation).toLocaleString()}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <span role="img" aria-label="update">🕒</span> {t("DetailIncidentIT.last_updated_on")}:
              </TableCell>
              <TableCell>{new Date(editableIncident.Updated[editableIncident.Updated.length - 1]).toLocaleString()}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
        {renderServiceDetails()}
      </Box>

      {/* Discussion en temps réel */}
      <Box sx={{ marginTop: "32px" }}>
        <Paper elevation={2} sx={{ 
          padding: "16px", 
          borderRadius: "8px", 
          backgroundColor: theme.palette.background.card 
        }}>
          {userSession.user?.ID_Utilisateur && incident && (
            <>
              <Discussion 
                incidentId={incident.ID_Incident_IT}
                utilisateurId={userSession.user.ID_Utilisateur}
                type="IT"
                userSession={userSession.user}
              />
            </>
          )}
        </Paper>
      </Box>

      {/* Commentaires */}
      <Box sx={{ marginTop: "32px" }}>
        <Paper elevation={2} sx={{ padding: "8px", borderRadius: "8px", backgroundColor: theme.palette.background.card }}>
          <Typography variant="h6" sx={{ fontStyle: "italic", color: "white", marginBottom: "8px" }}>
            {t("DetailIncidentIT.comments")}
          </Typography>
          {editableIncident.Commentaires.length === 0 ? (
            <Typography variant="body2" sx={{ color: "grey", textAlign: "center", marginBottom: "8px" }}>
              {t("DetailIncidentIT.no_comments")}
            </Typography>
          ) : (
            editableIncident.Commentaires.map((comment, index) => (
              <Box key={index} sx={{ marginBottom: "8px", padding: "8px", borderRadius: "8px", backgroundColor: "#f5f5f5" }}>
                {editCommentId === comment._id ? (
                  <>
                    <TextField
                      value={editCommentText}
                      onChange={(e) => setEditCommentText(e.target.value)}
                      fullWidth
                      multiline
                      rows={2}
                      placeholder={t("DetailIncidentIT.edit_comment_placeholder")}
                      inputProps={{ style: { color: "black" } }}
                    />
                    <Box sx={{ display: "flex", justifyContent: "space-between", marginTop: "8px" }}>
                      <Button variant="contained" color="primary" onClick={handleSaveEditComment}>
                        {t("DetailIncidentIT.apply")}
                      </Button>
                      <Button variant="contained" onClick={handleCancelEditComment}>
                        {t("DetailIncidentIT.cancel")}
                      </Button>
                    </Box>
                  </>
                ) : (
                  <>
                    <Typography variant="body2" sx={{ color: "grey", marginBottom: "4px" }}>
                      <strong>{userInfoMap[comment.ID_Utilisateur]?.name} {userInfoMap[comment.ID_Utilisateur]?.prenom}</strong> -{" "}
                      {new Date(comment.Date_commentaire).toLocaleString()}
                      <Typography variant="caption" sx={{ fontStyle: "italic", color: "grey", display: "block" }}>
                        {userInfoMap[comment.ID_Utilisateur]?.email}
                      </Typography>
                    </Typography>
                    <Typography variant="body2" sx={{ color: "black" }}>
                      {comment.Commentaire}
                    </Typography>
                    {comment.ID_Utilisateur === userSession.user.ID_Utilisateur && (
                      <>
                        <Button size="small" onClick={() => handleEditCommentClick(comment)}>
                          {t("DetailIncidentIT.edit")}
                        </Button>
                        <Button size="small" onClick={() => setConfirmDelete(true)}>
                          {t("DetailIncidentIT.delete")}
                        </Button>
                        <Dialog
                          open={confirmDelete}
                          onClose={() => setConfirmDelete(false)}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                        >
                          <DialogTitle id="alert-dialog-title">
                            {t("DetailIncidentIT.confirm_deletion")}
                          </DialogTitle>
                          <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                              {t("DetailIncidentIT.confirm_delete_comment")}
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={() => setConfirmDelete(false)} color="primary">
                              {t("DetailIncidentIT.cancel")}
                            </Button>
                            <Button onClick={() => handleDeleteComment(comment)} color="primary" autoFocus>
                              {t("DetailIncidentIT.delete")}
                            </Button>
                          </DialogActions>
                        </Dialog>
                      </>
                    )}
                  </>
                )}
              </Box>
            ))
          )}
          {isAddingComment ? (
            <Box sx={{ marginTop: "8px" }}>
              <TextField
                value={commentText}
                onChange={(e) => setCommentText(e.target.value)}
                fullWidth
                multiline
                rows={2}
                placeholder={t("DetailIncidentIT.add_comment_placeholder")}
                inputProps={{ style: { color: "black" } }}
              />
              <Box sx={{ display: "flex", justifyContent: "space-between", marginTop: "8px" }}>
                <Button variant="contained" color="primary" onClick={handleSaveComment}>
                  {t("DetailIncidentIT.send")}
                </Button>
                <Button variant="contained" onClick={handleCancelComment}>
                  {t("DetailIncidentIT.cancel")}
                </Button>
              </Box>
            </Box>
          ) : (
            <Button variant="outlined" startIcon={<AddIcon />} onClick={handleAddCommentClick}>
              {t("DetailIncidentIT.add_comment")}
            </Button>
          )}
        </Paper>
      </Box>

      {/* Fichiers */}
      <Box sx={{ marginTop: "32px" }}>
        <Paper elevation={2} sx={{ padding: "8px", borderRadius: "8px", backgroundColor: theme.palette.background.card }}>
          <Typography variant="h6" sx={{ fontStyle: "italic", color: "white" }}>
            {t("DetailIncidentIT.available_files")}
          </Typography>
          <List>
            {editableIncident.Attachements.map((attachment, index) => (
              <ListItem key={index}>
                <ListItemText primary={getFileName(attachment)} />
                <ListItemSecondaryAction>
                  <IconButton edge="end" onClick={() => handleDownloadAttachment(attachment)}>
                    <DownloadIcon />
                  </IconButton>
                  <IconButton edge="end" onClick={() => setAttachmentToDelete(attachment)}>
                    <DeleteIcon />
                  </IconButton>
                  <Dialog
                    open={!!attachmentToDelete}
                    onClose={() => setAttachmentToDelete(null)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">{t("DetailIncidentIT.confirm_deletion")}</DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        {t("DetailIncidentIT.confirm_delete_file")}
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={() => setAttachmentToDelete(null)} color="primary">
                        {t("DetailIncidentIT.cancel")}
                      </Button>
                      <Button onClick={() => handleDeleteAttachment(attachmentToDelete)} color="primary" autoFocus>
                        {t("DetailIncidentIT.delete")}
                      </Button>
                    </DialogActions>
                  </Dialog>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
          {isAddingAttachment ? (
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "8px" }}>
              <input
                type="file"
                onChange={(e) => setSelectedFile(e.target.files[0])}
                style={{ marginBottom: "8px" }}
              />
              <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                <Button variant="contained" color="primary" onClick={handleAddAttachment}>
                  {t("DetailIncidentIT.send")}
                </Button>
                <Button variant="contained" onClick={() => setIsAddingAttachment(false)}>
                  {t("DetailIncidentIT.cancel")}
                </Button>
              </Box>
            </Box>
          ) : (
            <Button variant="outlined" startIcon={<AddIcon />} onClick={() => setIsAddingAttachment(true)}>
              {t("DetailIncidentIT.add_file")}
            </Button>
          )}
        </Paper>
      </Box>

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity="error">
          {snackbarMessage}
        </Alert>
      </Snackbar>
      {validationMessage && <ValidationMessage message={validationMessage} setValidation={setValidationMessage} />}
      {errorMessage && <ErrorMessage message={errorMessage} setError={setErrorMessage} />}

      {/* Popup avec l'image explicative */}
      <Dialog
        open={openImageDialog}
        onClose={handleCloseImageDialog}
        aria-labelledby="image-dialog-title"
        aria-describedby="image-dialog-description"
      >
        <DialogContent>
          <img
            src={i18n.language === 'fr' ? require('../../assets/Schéma_des_états_et_transition_ticket_hurflym_fr.svg').default : require('../../assets/Schéma_des_états_et_transition_ticket_hurflym_fond_blanc_en.svg').default}
            alt={t("DetailIncidentIT.image_dialog_alt")}
            style={{ width: "100%", borderRadius: "8px" }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseImageDialog} color="primary">
            {t("DetailIncidentIT.close")}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default DetailIncidentIT;
