import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useCheckStripeSessionQuery } from 'state/api'; 
import './SubscriptionResult.css'; 

const SubscriptionResult = () => {
  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get('session_id');
  
  // Utilisation du hook Redux pour vérifier la session
  const { data, error, isLoading } = useCheckStripeSessionQuery(sessionId);

  // Gestion de l'état en fonction des données récupérées
  const [status, setStatus] = useState(null); // 'success' ou 'error'

  useEffect(() => {
    if (data && data.status === 'success') {
      setStatus('success');
    } else if (data && data.status === 'error') {
      setStatus('error');
    } else if (error) {
      setStatus('error');
    }
  }, [data, error]);

  if (isLoading) {
    return <div className="loading">Chargement...</div>;
  }

  return (
    <div className={`subscription-result ${status === 'success' ? 'success' : 'error'}`}>
      {status === 'success' ? (
        <div>
          <h1>Merci pour votre abonnement !</h1>
          <p>Votre transaction a été effectuée avec succès.</p>
          <button onClick={() => window.location.href = '/listeetablissement'}>Retour au tableau de bord</button>
        </div>
      ) : (
        <div>
          <h1>Échec de la transaction</h1>
          <p>Il semble que la transaction ait échoué. Veuillez réessayer.</p>
          <button onClick={() => window.location.href = '/abonnements'}>Réessayer</button>
        </div>
      )}
    </div>
  );
};

export default SubscriptionResult;
