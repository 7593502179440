import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { setAdminAuthenticated } from "./adminAuth"; // Middleware d'authentification admin
import { setUserAuthenticated } from "./userAuth";



export const api = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: `${process.env.REACT_APP_BASE_URL}` ,credentials: 'include' }), // process.env.REACT_APP_BASE_URL
  reducerPath: "adminApi",
  tagTypes: [
    "User",
    "Products",
    "Customers",
    "Transactions",
    "Geography",
    "Sales",
    "Admins",
    "Performance",
    "Dashboard",
  ],
  endpoints: (build) => ({
    getUser: build.query({
      query: (id) => `general/user/${id}`,
      providesTags: ["User"],
    }),
    getProducts: build.query({
      query: () => "client/products",
      providesTags: ["Products"],
    }),
    getCustomers: build.query({
      query: () => "client/customers",
      providesTags: ["Customers"],
    }),
    getTransactions: build.query({
      query: ({ page, pageSize, sort, search }) => ({
        url: "client/transactions",
        method: "GET",
        params: { page, pageSize, sort, search },
      }),
      providesTags: ["Transactions"],
    }),
    getGeography: build.query({
      query: () => "client/geography",
      providesTags: ["Geography"],
    }),
    getSales: build.query({
      query: () => "sales/sales",
      providesTags: ["Sales"],
    }),
    getAdmins: build.query({
      query: () => "management/admins",
      providesTags: ["Admins"],
    }),
    getUserPerformance: build.query({
      query: (id) => `management/performance/${id}`,
      providesTags: ["Performance"],
    }),
    getDashboard: build.query({
      query: () => "general/dashboard",
      providesTags: ["Dashboard"],
    }),
    loginUser: build.mutation({
      query: (credentials) => ({
        url: `public/loginuser`,
        method: 'POST',
        body: credentials,
      }),
    }),
    inscription: build.mutation({
      query: (credentials) => ({
        url: `public/addutilisateur`,
        method: 'POST',
        body: credentials,
      }),
    }),
    verifyemail: build.mutation({
      query: (credentials) => ({
        url: `public/verifyemail`,
        method: 'POST',
        body: credentials,
      }),
    }),
    loginPostUser: build.mutation({
      query: (credentials) => ({
        url: 'public/loginpost',
        method: 'POST',
        body: credentials, // Envoie les informations d'identification dans le corps de la requête POST
      }),
      onMutate: (variables) => {
        // Utilisez setUserAuthenticated avec les données de l'utilisateur authentifié
        setUserAuthenticated({ isAuthenticated: true, user: variables });
      },
      invalidatesTags: ['User'],
    }),
    loginPostAdmin: build.mutation({
      query: (credentials) => ({
        url: 'auth/loginpostadmin',
        method: 'POST',
        body: credentials, // Envoie les informations d'identification dans le corps de la requête POST
      }),
      onMutate: (variables) => {
        // Utilisez setAdminAuthenticated avec les données de l'admin authentifié
        setAdminAuthenticated({ isAuthenticated: true, admin: variables });
      },
      invalidatesTags: ['User'],
    }),
    uploadImage: build.mutation({
      query: (formData) => ({
        url: 'images/upload',
        method: 'POST',
        body: formData,
      }),
      invalidatesTags: ['Admins'],
    }),
    logout: build.mutation({
      query: () => ({
        url: 'public/logout',
        method: 'POST',
      }),
      onMutate: () => {
        setUserAuthenticated({ isAuthenticated: false, user: null });
        setAdminAuthenticated({ isAuthenticated: false, admin: null });
      },
      invalidatesTags: ['User', 'Admins'], 
    }),
    logoutEtablissement: build.mutation({
      query: () => ({
        url: 'public/logoutetablissement',
        method: 'POST',
      }),
      onMutate: () => {
        setAdminAuthenticated({ isAuthenticated: false, roleInfo: null, ID_Etablissement: null });
      },
      invalidatesTags: ['User', 'Admins'], 
    }),
    creationEntreprise: build.mutation({
      query: (data) => ({
        url: 'hurflymadmin/creationEntreprise',
        method: 'POST',
        body: data,
      }),
    }),
    recuperationImage: build.query({
      query: (nom) => ({
        url: `images/image/${nom}`,
      }),
    }),
    recuperationEquipement: build.query({
      query: (nom) => ({
        url: `images/equipement/${nom}`,
      }),
    }),
    getPersonnalisation: build.query({
      query: (id) => ({
        url: `personnalisation/getpersonnalisation/${id}`,
      }),
      providesTags: (result, error, id) => [{ type: 'Personnalisation', id }]
    }),
    setPersonnalisation: build.mutation({
      query: (data) => ({
        url: 'personnalisation/setpersonnalisation',
        method: 'POST',
        body: data, // Envoie les informations d'identification dans le corps de la requête POST
      }),
      invalidatesTags: (result, error, { ID_Etablissement }) => [{ type: 'Personnalisation', id: ID_Etablissement }],
    }),
    getAllInfoSector: build.mutation({
      query: (data) => ({
        url: 'sector/getallinfosector',
        method: 'POST',
        body: data,
      }),
    }),
    getInfoSector: build.mutation({
      query: (data) => ({
        url: 'sector/getinfosector',
        method: 'POST',
        body: data,
      }),
    }),
    addSector: build.mutation({
      query: (data) => ({
        url: 'sector/addsector',
        method: 'POST',
        body: data,
      })
    }),
    deleteSector: build.mutation({
      query: (data) => ({
        url: 'sector/deletesector',
        method: 'POST',
        body: data,
      })
    }),
    modifySector: build.mutation({
      query: (data) => ({
        url: 'sector/modifysector',
        method: 'POST',
        body: data,
      }),
    }),
    searchSector: build.mutation({
      query: (data) => ({
        url: 'sector/searchsector',
        method: 'POST',
        body: data,
      }),
    }),
    moveSector: build.mutation({
      query: (data) => ({
        url: 'sector/movesector',
        method: 'POST',
        body: data,
      }),
    }),

    // routes pour secteur IT

    addSignalementToNoeudIT: build.mutation({
      query: (data) => ({
        url: 'secteurIT/noeud/signalement/add',
        method: 'POST',
        body: data,
      }),
    }),
    removeSignalementFromNoeudIT: build.mutation({
      query: (data) => ({
        url: 'secteurIT/noeud/signalement/remove',
        method: 'POST',
        body: data,
      }),
    }),
    addEquipementToNoeudIT: build.mutation({
      query: (data) => ({
        url: 'secteurIT/noeud/equipement/add',
        method: 'POST',
        body: data,
      }),
    }),
    removeEquipementFromNoeudIT: build.mutation({
      query: (data) => ({
        url: 'secteurIT/noeud/equipement/remove',
        method: 'POST',
        body: data,
      }),
    }),
    getAllInfoSectorIT: build.mutation({
      query: (data) => ({
        url: 'secteurIT/noeuds',
        method: 'POST',
        body: data,
      }),
    }),

    // routes pour secteur SG

    addSignalementToNoeudSG: build.mutation({
      query: (data) => ({
        url: 'secteurSG/noeud/signalement/add',
        method: 'POST',
        body: data,
      }),
    }),
    removeSignalementFromNoeudSG: build.mutation({
      query: (data) => ({
        url: 'secteurSG/noeud/signalement/remove',
        method: 'POST',
        body: data,
      }),
    }),
    addEquipementToNoeudSG: build.mutation({
      query: (data) => ({
        url: 'secteurSG/noeud/equipement/add',
        method: 'POST',
        body: data,
      }),
    }),
    removeEquipementFromNoeudSG: build.mutation({
      query: (data) => ({
        url: 'secteurSG/noeud/equipement/remove',
        method: 'POST',
        body: data,
      }),
    }),
    getAllInfoSectorSG: build.mutation({
      query: (data) => ({
        url: 'secteurSG/noeuds',
        method: 'POST',
        body: data,
      }),
    }),

    // routes pour equipement classique a degager

    getAllInfoEquipement: build.mutation({
      query: (data) => ({
        url: 'equipement/getallinfoequipement',
        method: 'POST',
        body: data,
      }),
    }),
    addEquipement: build.mutation({
      query: (data) => ({
        url: 'equipement/addequipement',
        method: 'POST',
        body: data,
      })
    }),
    deleteEquipement: build.mutation({
      query: (data) => ({
        url: 'equipement/deleteequipement',
        method: 'POST',
        body: data,
      })
    }),
    modifyEquipement: build.mutation({
      query: (data) => ({
        url: 'equipement/modifyequipement',
        method: 'POST',
        body: data,
      }),
    }),
    searchEquipement: build.mutation({
      query: (data) => ({
        url: 'equipement/searchequipement',
        method: 'POST',
        body: data,
      }),
    }),
    moveEquipement: build.mutation({
      query: (data) => ({
        url: 'equipement/moveequipement',
        method: 'POST',
        body: data,
      }),
    }),
    getInfosEquipement: build.mutation({
      query: (data) => ({
        url: 'equipement/getinfosequipement',
        method: 'POST',
        body: data,
      }),
    }),
    getAllInfosCategorie: build.mutation({
      query: (data) => ({
        url: 'categorie/getallinfocategorie',
        method: 'POST',
        body: data,
      }),
    }),
    getAllImageCategorie: build.mutation({
      query: (data) => ({
        url: 'images/getAllImageCategorie',
        method: 'POST', // Utilisez la méthode POST
        body: data, // Envoyez les données dans le corps de la requête
      }),
    }),
    getAllInfoSignalement: build.mutation({
      query: (data) => ({
        url: 'signalement/getallinfosignalement',
        method: 'POST',
        body: data,
      }),
    }),
    getInfoSignalement: build.mutation({
      query: (data) => ({
        url: 'signalement/getinfosignalement',
        method: 'POST',
        body: data,
      }),
    }),
    addSignalement: build.mutation({
      query: (data) => ({
        url: 'signalement/addsignalement',
        method: 'POST',
        body: data,
      }),
    }),
    modifySignalement: build.mutation({
      query: (data) => ({
        url: 'signalement/modifysignalement',
        method: 'POST',
        body: data,
      }),
    }),
    deleteSignalement: build.mutation({
      query: (data) => ({
        url: 'signalement/deletesignalement',
        method: 'POST',
        body: data,
      }),
    }),
    searchSignalement: build.mutation({
      query: (data) => ({
        url: 'signalement/searchsignalement',
        method: 'POST',
        body: data,
      }),
    }),
    moveSignalement: build.mutation({
      query: (data) => ({
        url: 'signalement/movesignalement',
        method: 'POST',
        body: data,
      }),
    }),
    getAllInfoTemplate: build.mutation({
      query: (data) => ({
        url: 'template/getallinfotemplate',
        method: 'POST',
        body: data,
      }),
    }),
    getInfoTemplate: build.mutation({
      query: (data) => ({
        url: 'template/getinfotemplate',
        method: 'POST',
        body: data,
      }),
    }),
    addTemplate: build.mutation({
      query: (data) => ({
        url: 'template/addtemplate',
        method: 'POST',
        body: data,
      }),
    }),
    modifyTemplate: build.mutation({
      query: (data) => ({
        url: 'template/modifytemplate',
        method: 'POST',
        body: data,
      }),
    }),
    deleteTemplate: build.mutation({
      query: (data) => ({
        url: 'template/deletetemplate',
        method: 'POST',
        body: data,
      }),
    }),
    searchTemplate: build.mutation({
      query: (data) => ({
        url: 'template/searchtemplate',
        method: 'POST',
        body: data,
      }),
    }),
    getAllInfoEquipementParticulier: build.mutation({
      query: (data) => ({
        url: 'equipementparticulier/getallinfoequipementparticulier',
        method: 'POST',
        body: data,
      }),
    }),
    addEquipementParticulier: build.mutation({
      query: (data) => ({
        url: 'equipementparticulier/addequipementparticulier',
        method: 'POST',
        body: data,
      })
    }),
    deleteEquipementParticulier: build.mutation({
      query: (data) => ({
        url: 'equipementparticulier/deleteequipementparticulier',
        method: 'POST',
        body: data,
      })
    }),
    modifyEquipementParticulier: build.mutation({
      query: (data) => ({
        url: 'equipementparticulier/modifyequipementparticulier',
        method: 'POST',
        body: data,
      }),
    }),
    searchEquipementParticulier: build.mutation({
      query: (data) => ({
        url: 'equipementparticulier/searchequipementparticulier',
        method: 'POST',
        body: data,
      }),
    }),

    // route equipement particulier pour IT

    getAllInfoEquipementParticulierIT: build.mutation({
      query: (data) => ({
        url: 'equipementparticulierIT/getallinfoequipementparticulier',
        method: 'POST',
        body: data,
      }),
    }),
    getAllInfoEquipementParticulierITIncomplet: build.mutation({
      query: (data) => ({
        url: 'equipementparticulierIT/getallinfoequipementparticulierincomplet',
        method: 'POST',
        body: data,
      }),
    }),
    addEquipementParticulierIT: build.mutation({
      query: (data) => ({
        url: 'equipementparticulierIT/addequipementparticulier',
        method: 'POST',
        body: data,
      })
    }),
    deleteEquipementParticulierIT: build.mutation({
      query: (data) => ({
        url: 'equipementparticulierIT/deleteequipementparticulier',
        method: 'DELETE',
        body: data,
      })
    }),
    modifyEquipementParticulierIT: build.mutation({
      query: (data) => ({
        url: 'equipementparticulierIT/modifyequipementparticulier',
        method: 'PUT',
        body: data,
      }),
    }),
    searchEquipementParticulierIT: build.mutation({
      query: (data) => ({
        url: 'equipementparticulierIT/searchequipementparticulier',
        method: 'POST',
        body: data,
      }),
    }),
    

    // route equipement particulier pour SG

    getAllInfoEquipementParticulierSG: build.mutation({
      query: (data) => ({
        url: 'equipementparticulierSG/getallinfoequipementparticulier',
        method: 'POST',
        body: data,
      }),
    }),
    getAllInfoEquipementParticulierSGIncomplet: build.mutation({
      query: (data) => ({
        url: 'equipementparticulierSG/getallinfoequipementparticulierincomplet',
        method: 'POST',
        body: data,
      }),
    }),
    addEquipementParticulierSG: build.mutation({
      query: (data) => ({
        url: 'equipementparticulierSG/addequipementparticulier',
        method: 'POST',
        body: data,
      })
    }),
    deleteEquipementParticulierSG: build.mutation({
      query: (data) => ({
        url: 'equipementparticulierSG/deleteequipementparticulier',
        method: 'DELETE',
        body: data,
      })
    }),
    modifyEquipementParticulierSG: build.mutation({
      query: (data) => ({
        url: 'equipementparticulierSG/modifyequipementparticulier',
        method: 'PUT',
        body: data,
      }),
    }),
    searchEquipementParticulierSG: build.mutation({
      query: (data) => ({
        url: 'equipementparticulierSG/searchequipementparticulier',
        method: 'POST',
        body: data,
      }),
    }),
    


    findIDEtablissementByIDSignalement: build.mutation({
      query: (data) => ({
        url: 'public/findIDEtablissementByIDSignalement',
        method: 'POST',
        body: data,
      }),
    }),
    AllInfoSignalement: build.mutation({
      query: (data)=> ({
        url: 'public/signalement',
        method: 'POST',
        body: data,
      }),
    }),
    AllInfoNoeud: build.mutation({
      query: (data)=> ({
        url: 'public/noeud',
        method: 'POST',
        body: data,
      }),
    }),
    MailSignalement: build.mutation({
      query: (data) => ({
        url: 'public/mailsignalement',
        method: 'POST',
        body: data,
      })
    }),
    AllInfoEp: build.mutation({
      query: (data)=> ({
        url: 'public/equipementparticulier',
        method:'POST',
        body: data,
      })
    }),
    MailEp: build.mutation({
      query: (data)=>({
        url: 'public/mailEp',
        method: 'POST',
        body: data,
      })
    }),
    getAllInfoRole: build.mutation({
      query: (data) => ({
        url: 'role/getAllInfoRole',
        method: 'POST',
        body: data,
      }),
    }),
    addRole: build.mutation({
      query: (data) => ({
        url: 'role/addRole',
        method: 'POST',
        body: data,
      }),
    }),
    deleteRole: build.mutation({
      query: (data) => ({
        url: 'role/deleteRole',
        method: 'POST',
        body: data,
      }),
    }),
    modifyRole: build.mutation({
      query: (data) => ({
        url: 'role/modifyRole',
        method: 'POST',
        body: data,
      }),
    }),
    searchRole: build.mutation({
      query: (data) => ({
        url: 'role/searchRole',
        method: 'POST',
        body: data,
      }),
    }),
    getInfoUtilisateur: build.mutation({
      query: (data) => ({
        url: 'utilisateur/getinfoutilisateur',
        method: 'POST',
        body: data,
      }),
    }),
    modifyInfoUtilisateur: build.mutation({
      query: (data) => ({
        url: 'utilisateur/modifyinfoutilisateur',
        method: 'POST',
        body: data,
      }),
    }),
    deleteUtilisateur: build.mutation({
      query: (data) => ({
        url: 'utilisateur/deleteutilisateur',
        method: 'POST',
        body: data,
      }),
    }),
    loginUtilisateurEtablissement: build.mutation({
      query: (data) => ({
        url: 'public/loginutilisateuretablissement',
        method: 'POST',
        body: data,
      }),
    }),
    addAdministrateur: build.mutation({
      query: (data) => ({
        url: 'utilisateur/invitationetablissement',
        method: 'POST',
        body: data,
      }),
    }),
    rejoindreEtablissement: build.mutation({
      query: (data) => ({
        url: 'utilisateur/rejoindreetablissement',
        method: 'POST',
        body: data,
      }),
    }),
    inscriptionUtilisateurEtablissement: build.mutation({
      query: (data) => ({
        url: 'public/inscriptionetablissement',
        method: 'POST',
        body: data,
      }),
    }),
    modifyListeUtilisateurEtablissement: build.mutation({
      query: (data) => ({
        url: 'listeutilisateuretablissement/modifyListeUtilisateurEtablissement',
        method: 'POST',
        body: data,
      }),
    }),
    deleteListeUtilisateurEtablissement: build.mutation({
      query: (data) => ({
        url: 'listeutilisateuretablissement/deleteListeUtilisateurEtablissement',
        method: 'POST',
        body: data,
      }),
    }),
    searchListeUtilisateurEtablissement: build.mutation({
      query: (data) => ({
        url: 'listeutilisateuretablissement/searchListeUtilisateurEtablissement',
        method: 'POST',
        body: data,
      }),
    }),
    getAllInfoListeUtilisateurEtablissement: build.mutation({
      query: (data) => ({
        url: 'listeutilisateuretablissement/getAllInfoListeUtilisateurEtablissement',
        method: 'POST',
        body: data,
      }),
    }),
    getInfoRegleSpecial: build.mutation({
      query: (data) => ({
        url: 'reglespecial/getinforeglespecial',
        method: 'POST',
        body: data,
      }),
    }),
    modifyRegleSpecial: build.mutation({
      query: (data) => ({
        url: 'reglespecial/modifyreglespecial',
        method: 'POST',
        body: data,
      }),
    }),
    getPortalRules: build.mutation({
      query: (data) => ({
        url: 'reglespecial/getportalrules',
        method: 'POST',
        body: data,
      }),
    }),
    modifyPortalRules: build.mutation({
      query: (data) => ({  
        url: 'reglespecial/modifyportalrules',
        method: 'POST',
        body: data,
      }),
    }),

//----------------------------------------------------------------

    getAllInfoCategorieServiceIT: build.mutation({
      query: (data) => ({
        url: 'categorieservice_it/getallinfocategorie',
        method: 'POST',
        body: data,
      }),
    }),
    getInfoCategorieServiceIT: build.mutation({
      query: (data) => ({
        url: 'categorieservice_it/getinfocategorie',
        method: 'POST',
        body: data,
      }),
    }),
    addCategorieServiceIT: build.mutation({
      query: (formData) => ({
        url: 'categorieservice_it/addcategorie',
        method: 'POST',
        body: formData,
      }),
    }),
    modifyCategorieServiceIT: build.mutation({
      query: (data) => ({
        url: 'categorieservice_it/modifycategorie',
        method: 'POST',
        body: data,
      }),
    }),
    deleteCategorieServiceIT: build.mutation({
      query: (data) => ({
        url: 'categorieservice_it/deletecategorie',
        method: 'POST',
        body: data,
      }),
    }),
    getImageCategorieServiceIT: build.query({
      query: (ID_Categorie_Service_IT) => ({
        url: `categorieservice_it/image/${ID_Categorie_Service_IT}`,
      }),
    }),

    getAllInfoCategorieServiceSG: build.mutation({
      query: (data) => ({
        url: 'categorieservice_sg/getallinfocategorie',
        method: 'POST',
        body: data,
      }),
    }),
    getInfoCategorieServiceSG: build.mutation({
      query: (data) => ({
        url: 'categorieservice_sg/getinfocategorie',
        method: 'POST',
        body: data,
      }),
    }),
    addCategorieServiceSG: build.mutation({
      query: (data) => ({
        url: 'categorieservice_sg/addcategorie',
        method: 'POST',
        body: data,
      }),
    }),
    modifyCategorieServiceSG: build.mutation({
      query: (data) => ({
        url: 'categorieservice_sg/modifycategorie',
        method: 'POST',
        body: data,
      }),
    }),
    deleteCategorieServiceSG: build.mutation({
      query: (data) => ({
        url: 'categorieservice_sg/deletecategorie',
        method: 'POST',
        body: data,
      }),
    }),
    getImageCategorieServiceSG: build.query({
      query: (ID_Categorie_Service_SG) => ({
        url: `categorieservice_sg/image/${ID_Categorie_Service_SG}`,
      }),
    }),





    getAllInfoServiceIT: build.mutation({
      query: (data) => ({
        url: 'service_it/getallinfoservice',
        method: 'POST',
        body: data,
      }),
    }),
    getInfoServiceIT: build.mutation({
      query: (data) => ({
        url: 'service_it/getinfoservice',
        method: 'POST',
        body: data,
      }),
    }),
    addServiceIT: build.mutation({
      query: (data) => ({
        url: 'service_it/addservice',
        method: 'POST',
        body: data,
      }),
    }),
    modifyServiceIT: build.mutation({
      query: (data) => ({
        url: 'service_it/modifyservice',
        method: 'POST',
        body: data,
      }),
    }),
    deleteServiceIT: build.mutation({
      query: (data) => ({
        url: 'service_it/deleteservice',
        method: 'POST',
        body: data,
      }),
    }),

    getAllInfoServiceSG: build.mutation({
      query: (data) => ({
        url: 'service_sg/getallinfoservice',
        method: 'POST',
        body: data,
      }),
    }),
    getInfoServiceSG: build.mutation({
      query: (data) => ({
        url: 'service_sg/getinfoservice',
        method: 'POST',
        body: data,
      }),
    }),
    addServiceSG: build.mutation({
      query: (data) => ({
        url: 'service_sg/addservice',
        method: 'POST',
        body: data,
      }),
    }),
    modifyServiceSG: build.mutation({
      query: (data) => ({
        url: 'service_sg/modifyservice',
        method: 'POST',
        body: data,
      }),
    }),
    deleteServiceSG: build.mutation({
      query: (data) => ({
        url: 'service_sg/deleteservice',
        method: 'POST',
        body: data,
      }),
    }),

    // les requetes pour le modèles categorieSignalementIT

    getAllInfoCategorieSignalementIT: build.mutation({
      query: (data) => ({
        url: 'categoriesignalementIT/getallcategoriesignalement',
        method: 'POST',
        body: data,
      }),
    }),
    getCategorieSignalementITById: build.mutation({
      query: (id) => ({
        url: `categoriesignalementIT/getcategoriesignalement/${id}`,
        method: 'GET',
      }),
    }),
    addCategorieSignalementIT: build.mutation({
      query: (data) => ({
        url: 'categoriesignalementIT/addcategoriesignalement',
        method: 'POST',
        body: data,
      }),
    }),
    modifyCategorieSignalementIT: build.mutation({
      query: (data) => ({
        url: 'categoriesignalementIT/modifycategoriesignalement',
        method: 'PUT',
        body: data,
      }),
    }),
    deleteCategorieSignalementIT: build.mutation({
      query: (data) => ({
        url: 'categoriesignalementIT/deletecategoriesignalement',
        method: 'DELETE',
        body: data,
      }),
    }),
    getAllImageCategorieSignalementIT: build.mutation({
      query: (data) => ({
        url: 'images/getAllImageCategorieSignalementIT',
        method: 'POST',
        body: data,
      }),
    }),
    getAllImageEquipementIT: build.mutation({
      query: (data) => ({
        url: 'images/getAllImageEquipementIT',
        method: 'POST',
        body: data,
      }),
    }),
    displayImageCategorieSignalementIT: build.query({
      query: (nom) => ({
        url: `images/displayimagecategoriesignalementIT/${nom}`,
      }),
    }),
  
    // Les requêtes de categorieSignalementSG
    getAllInfoCategorieSignalementSG: build.mutation({
      query: (data) => ({
        url: 'categoriesignalementSG/getallcategoriesignalement',
        method: 'POST',
        body: data,
      }),
    }),
    getCategorieSignalementSGById: build.mutation({
      query: (id) => ({
        url: `categoriesignalementSG/getcategoriesignalement/${id}`,
        method: 'GET',
      }),
    }),
    addCategorieSignalementSG: build.mutation({
      query: (data) => ({
        url: 'categoriesignalementSG/addcategoriesignalement',
        method: 'POST',
        body: data,
      }),
    }),
    modifyCategorieSignalementSG: build.mutation({
      query: (data) => ({
        url: 'categoriesignalementSG/modifycategoriesignalement',
        method: 'PUT',
        body: data,
      }),
    }),
    deleteCategorieSignalementSG: build.mutation({
      query: (data) => ({
        url: 'categoriesignalementSG/deletecategoriesignalement',
        method: 'DELETE',
        body: data,
      }),
    }),
    getAllImageCategorieSignalementSG: build.mutation({
      query: (data) => ({
        url: 'images/getAllImageCategorieSignalementSG',
        method: 'POST',
        body: data,
      }),
    }),
    getAllImageEquipementSG: build.mutation({
      query: (data) => ({
        url: 'images/getAllImageEquipementSG',
        method: 'POST',
        body: data,
      }),
    }),
    displayImageCategorieSignalementSG: build.query({
      query: (nom) => ({
        url: `images/displayimagecategoriesignalementSG/${nom}`,
      }),
    }),

    // Les requêtes de equipementIT
getAllInfoEquipementIT: build.mutation({
  query: (data) => ({
    url: 'equipementIT/getallinfoequipement',
    method: 'POST',
    body: data,
  }),
}),
getAllInfoEquipementITIncomplet: build.mutation({
  query: (data) => ({
    url: 'equipementIT/getallinfoequipementincomplet',
    method: 'POST',
    body: data,
  }),
}),
getEquipementITById: build.mutation({
  query: (id) => ({
    url: `equipementIT/getinfosequipement/${id}`,
    method: 'GET',
  }),
}),
addEquipementIT: build.mutation({
  query: (data) => ({
    url: 'equipementIT/addequipement',
    method: 'POST',
    body: data,
  }),
}),
modifyEquipementIT: build.mutation({
  query: (data) => ({
    url: 'equipementIT/modifyequipement',
    method: 'PUT',
    body: data,
  }),
}),
deleteEquipementIT: build.mutation({
  query: (data) => ({
    url: 'equipementIT/deleteequipement',
    method: 'DELETE',
    body: data,
  }),
}),
searchEquipementIT: build.mutation({
  query: (data) => ({
    url: 'equipementIT/searchequipement',
    method: 'POST',
    body: data,
  }),
}),
moveEquipementIT: build.mutation({
  query: (data) => ({
    url: 'equipementIT/moveequipement',
    method: 'POST',
    body: data,
  }),
}),

// Les requêtes de signalementIT
getAllInfoSignalementIT: build.mutation({
  query: (data) => ({
    url: 'signalementIT/getallinfosignalement',
    method: 'POST',
    body: data,
  }),
}),
getSignalementITById: build.mutation({
  query: (id) => ({
    url: `signalementIT/getinfossignalement/${id}`,
    method: 'GET',
  }),
}),
addSignalementIT: build.mutation({
  query: (data) => ({
    url: 'signalementIT/addsignalement',
    method: 'POST',
    body: data,
  }),
}),
modifySignalementIT: build.mutation({
  query: (data) => ({
    url: 'signalementIT/modifysignalement',
    method: 'PUT',
    body: data,
  }),
}),
deleteSignalementIT: build.mutation({
  query: (data) => ({
    url: 'signalementIT/deletesignalement',
    method: 'DELETE',
    body: data,
  }),
}),
searchSignalementIT: build.mutation({
  query: (data) => ({
    url: 'signalementIT/searchsignalement',
    method: 'POST',
    body: data,
  }),
}),
moveSignalementIT: build.mutation({
  query: (data) => ({
    url: 'signalementIT/movesignalement',
    method: 'POST',
    body: data,
  }),
}),



// Les requêtes de equipementSG
getAllInfoEquipementSG: build.mutation({
  query: (data) => ({
    url: 'equipementSG/getallinfoequipement',
    method: 'POST',
    body: data,
  }),
}),
getAllInfoEquipementSGIncomplet: build.mutation({
  query: (data) => ({
    url: 'equipementSG/getallinfoequipementincomplet',
    method: 'POST',
    body: data,
  }),
}),
getEquipementSGById: build.mutation({
  query: (id) => ({
    url: `equipementSG/getinfosequipement/${id}`,
    method: 'GET',
  }),
}),
addEquipementSG: build.mutation({
  query: (data) => ({
    url: 'equipementSG/addequipement',
    method: 'POST',
    body: data,
  }),
}),
modifyEquipementSG: build.mutation({
  query: (data) => ({
    url: 'equipementSG/modifyequipement',
    method: 'PUT',
    body: data,
  }),
}),
deleteEquipementSG: build.mutation({
  query: (data) => ({
    url: 'equipementSG/deleteequipement',
    method: 'DELETE',
    body: data,
  }),
}),
searchEquipementSG: build.mutation({
  query: (data) => ({
    url: 'equipementSG/searchequipement',
    method: 'POST',
    body: data,
  }),
}),
moveEquipementSG: build.mutation({
  query: (data) => ({
    url: 'equipementSG/moveequipement',
    method: 'POST',
    body: data,
  }),
}),

// Les requêtes de signalementSG
getAllInfoSignalementSG: build.mutation({
  query: (data) => ({
    url: 'signalementSG/getallinfosignalement',
    method: 'POST',
    body: data,
  }),
}),
getSignalementSGById: build.mutation({
  query: (id) => ({
    url: `signalementSG/getinfossignalement/${id}`,
    method: 'GET',
  }),
}),
addSignalementSG: build.mutation({
  query: (data) => ({
    url: 'signalementSG/addsignalement',
    method: 'POST',
    body: data,
  }),
}),
modifySignalementSG: build.mutation({
  query: (data) => ({
    url: 'signalementSG/modifysignalement',
    method: 'PUT',
    body: data,
  }),
}),
deleteSignalementSG: build.mutation({
  query: (data) => ({
    url: 'signalementSG/deletesignalement',
    method: 'DELETE',
    body: data,
  }),
}),
searchSignalementSG: build.mutation({
  query: (data) => ({
    url: 'signalementSG/searchsignalement',
    method: 'POST',
    body: data,
  }),
}),
moveSignalementSG: build.mutation({
  query: (data) => ({
    url: 'signalementSG/movesignalement',
    method: 'POST',
    body: data,
  }),
}),


// Route pour Section 

getAllSection: build.mutation({
  query: (data) => ({
    url: 'section/getall',
    method: 'POST',
    body: data,
  }),
}),
addSection: build.mutation({
  query: (data) => ({
    url: 'section/add',
    method: 'POST',
    body: data,
  }),
}),
modifySection: build.mutation({
  query: (data) => ({
    url: 'section/modify',
    method: 'PUT',
    body: data,
  }),
}),
deleteSection: build.mutation({
  query: (data) => ({
    url: 'section/delete',
    method: 'DELETE',
    body: data,
  }),
}),


// Route pour Feedback

getAllFeedback: build.mutation({
  query: (data) => ({
    url: 'feedback/getAll',
    method: 'POST',
    body: data,
  }),
}),
addFeedback: build.mutation({
  query: (data) => ({
    url: 'feedback/add',
    method: 'POST',
    body: data,
  }),
}),
modifyFeedback: build.mutation({
  query: (data) => ({
    url: 'feedback/modify',
    method: 'PUT',
    body: data,
  }),
}),
deleteFeedback: build.mutation({
  query: (data) => ({
    url: 'feedback/delete',
    method: 'DELETE',
    body: data,
  }),
}),
getAllImageFeedback: build.mutation({
  query: (data) => ({
    url: 'feedback/getallimage',
    method: 'POST',
    body: data,
  }),
}),
updateFeedbackLocation: build.mutation({
  query: (data) => ({
    url: 'feedback/updateFeedbackLocation',
    method: 'POST',
    body: data,
  }),
}),





publicgetAllInfoCategorieServiceSG: build.mutation({
  query: (data) => ({
    url: 'public/getallinfocategorieservicesg',
    method: 'POST',
    body: data,
  }),
}),
publicgetAllInfoCategorieServiceIT: build.mutation({
  query: (data) => ({
    url: 'public/getallinfocategorieserviceit',
    method: 'POST',
    body: data,
  }),
}),
publicgetAllInfoServiceIT: build.mutation({
  query: (data) => ({
    url: 'public/getallinfoserviceit',
    method: 'POST',
    body: data,
  }),
}),
publicgetAllInfoServiceSG: build.mutation({
  query: (data) => ({
    url: 'public/getallinfoservicesg',
    method: 'POST',
    body: data,
  }),
}),
publicgetAllInfoSector: build.mutation({
  query: (data) => ({
    url: 'public/getallinfosector',
    method: 'POST',
    body: data,
  }),
}),

publicAddDemandeDeServiceIT: build.mutation({
  query: (data) => ({
    url: 'public/adddemandedeserviceit',
    method: 'POST',
    body: data,
  }),
}),
publicAddDemandeDeServiceSG: build.mutation({
  query: (data) => ({
    url: 'public/adddemandedeservicesg',
    method: 'POST',
    body: data,
  }),
}),

  // route admin pour demande de service it

addDemandeDeServiceIT: build.mutation({
  query: (data) => ({
    url: 'demandedeserviceit/adddemandedeserviceit',
    method: 'POST',
    body: data,
  }),
}),
getAllInfoDemandeDeServiceIT: build.mutation({
  query: (data) => ({
    url: 'demandedeserviceit/',
    method: 'POST',
    body: data,
  }),
}),
modifyDemandeDeServiceIT: build.mutation({
  query: (data) => ({
    url: `demandedeserviceit/${data.ID_Demande_de_Service_IT}`,
    method: 'PUT',
    body: data,
  }),
}),
deleteDemandeDeServiceIT: build.mutation({
  query: (data) => ({
    url: `demandedeserviceit/${data.ID_Demande_de_Service_IT}`,
    method: 'DELETE',
    body: data,
  }),
}),

// route admin pour demande de service sg

addDemandeDeServiceSG: build.mutation({
  query: (data) => ({
    url: 'demandedeservicesg/adddemandedeservicesg',
    method: 'POST',
    body: data,
  }),
}),
getAllInfoDemandeDeServiceSG: build.mutation({
  query: (data) => ({
    url: 'demandedeservicesg/',
    method: 'POST',
    body: data,
  }),
}),
modifyDemandeDeServiceSG: build.mutation({
  query: (data) => ({
    url: `demandedeservicesg/${data.ID_Demande_de_Service_SG}`,
    method: 'PUT',
    body: data,
  }),
}),
deleteDemandeDeServiceSG: build.mutation({
  query: (data) => ({
    url: `demandedeservicesg/${data.ID_Demande_de_Service_SG}`,
    method: 'DELETE',
    body: data,
  }),
}),

publicGetAllFeedback: build.mutation({
  query: (data) => ({
    url: `public/getallfeedback`,
    method: 'POST',
    body: data,
  }),
}),

getFeedbacksByNode: build.mutation({
  query: (data) => ({
    url: `feedback/getFeedbacksByNode`,
    method: 'POST',
    body: data,
  }),
}),

publicGetFeedbackSections: build.mutation({
  query: (data) => ({
    url: `public/getFeedbackSections`,
    method: 'POST',
    body: data,
  }),
}),

publicAddRetourFeedback: build.mutation({
  query: (data) => ({
    url: `public/add-retour-feedback`,
    method: 'POST',
    body: data,
  }),
}),

publicSignalementAllInfo: build.mutation({
  query: (data) => ({
    url: `public/signalement-all-info`,
    method: 'POST',
    body: data,
  }),
}),

publicGetInfoEquipementParticulier: build.mutation({
  query: (data) => ({
    url: `public/get-info-equipement-particulier`,
    method: 'POST',
    body: data,
  }),
}),
// routes pour type d'action IT

getAllTypeDactionIT: build.mutation({
  query: (data) => ({
    url: `typedactionit/getalltypedaction`,
    method: 'POST',
    body: data,
  }),
}),
getTypeDactionIT: build.mutation({
  query: (id) => ({
    url: `typedactionit/gettypedaction/${id}`,
    method: 'GET',
  }),
}),
addTypeDactionIT: build.mutation({
  query: (data) => ({
    url: `typedactionit/addtypedaction`,
    method: 'POST',
    body: data,
  }),
}),
deleteTypeDactionIT: build.mutation({
  query: (data) => ({
    url: `typedactionit/deletetypedaction`,
    method: 'POST',
    body: data,
  }),
}),
modifyTypeDactionIT: build.mutation({
  query: (data) => ({
    url: `typedactionit/modifytypedaction`,
    method: 'POST',
    body: data,
  }),
}),

// route pour type daction SG

getAllTypeDactionSG: build.mutation({
  query: (data) => ({
    url: `typedactionsg/getalltypedaction`,
    method: 'POST',
    body: data,
  }),
}),
getTypeDactionSG: build.mutation({
  query: (id) => ({
    url: `typedactionsg/gettypedaction/${id}`,
    method: 'GET',
  }),
}),
addTypeDactionSG: build.mutation({
  query: (data) => ({
    url: `typedactionsg/addtypedaction`,
    method: 'POST',
    body: data,
  }),
}),
deleteTypeDactionSG: build.mutation({
  query: (data) => ({
    url: `typedactionsg/deletetypedaction`,
    method: 'POST',
    body: data,
  }),
}),
modifyTypeDactionSG: build.mutation({
  query: (data) => ({
    url: `typedactionsg/modifytypedaction`,
    method: 'POST',
    body: data,
  }),
}),

// ajout d'un nouveau groupe administrateur
addGroupedAdmin: build.mutation({
  query: (data) => ({
    url: 'groupe/groupedAdmin',
    method: 'POST',
    body: data,
  }),
}),

// Obtenir tous les groupes d'administration pour un établissement donné
getGroupedAdmins: build.mutation({
  query: (data) => ({
    url: 'groupe/groupedAdmins',
    method: 'POST',
    body: data,
  }),
}),

// Obtenir un groupe d'administration par ID
getGroupedAdminById: build.query({
  query: (id) => `groupe/groupedAdmin/${id}`,
}),

// Mettre à jour un groupe d'administration par ID
updateGroupedAdmin: build.mutation({
  query: ({ id, ...data }) => ({
    url: `groupe/groupedAdmin/${id}`,
    method: 'PUT',
    body: data,
  }),
}),

// Supprimer un groupe d'administration par ID
deleteGroupedAdmin: build.mutation({
  query: (id) => ({
    url: `groupe/groupedAdmin/${id}`,
    method: 'DELETE',
  }),
}),

publicAddIncident: build.mutation({
  query: (data) => ({
    url: `public/publicAddIncident`,
    method: 'POST',
    body: data,
  }),
}),

// route public pour récupérer les règles spéciales d'un etablissement comme la connexion
publicGetInfoRegleSpecial: build.mutation({
  query: (data) => ({
    url: `public/get-regle-special`,
    method: 'POST',
    body: data,
  }),
}),


// route pour incidentIT 
getAllIncidentsIT: build.mutation({
  query: (data) => ({
    url: `incident-it/getallincidents`,
    method: 'POST',
    body: data,
  }),
}),
getIncidentITById: build.mutation({
  query: (data) => ({
    url: `incident-it/getincident`,
    method: 'POST',
    body: data,
  }),
}),
addIncidentIT: build.mutation({
  query: (data) => ({
    url: `incident-it/addincident`,
    method: 'POST',
    body: data,
  }),
}),
deleteIncidentIT: build.mutation({
  query: (data) => ({
    url: `incident-it/deleteincident`,
    method: 'POST',
    body: data,
  }),
}),
modifyIncidentIT: build.mutation({
  query: (data) => ({
    url: `incident-it/modifyincident`,
    method: 'POST',
    body: data,
  }),
}),
exportAttachmentIncidentIT: build.mutation({
  query: (data) => ({
    url: `incident-it/exportattachment`,
    method: 'POST',
    body: data,
  }),
}),
updateIncidentITState: build.mutation({
  query: (data) => ({
    url: `incident-it/updateincidentstate`,
    method: 'POST',
    body: data,
  }),
}),
updateIncidentITTitle: build.mutation({
  query: (data) => ({
    url: `incident-it/updateincidenttitle`,
    method: 'POST',
    body: data,
  }),
}),
addAttachmentIncidentIT: build.mutation({
  query: (data) => ({
    url: `incident-it/addattachment`,
    method: 'POST',
    body: data,
  }),
}),
deleteAttachmentIncidentIT: build.mutation({
  query: (data) => ({
    url: `incident-it/deleteattachment`,
    method: 'POST',
    body: data,
  }),
}),
addCommentIncidentIT: build.mutation({
  query: (data) => ({
    url: `incident-it/addcomment`,
    method: 'POST',
    body: data,
  }),
}),
updateCommentIncidentIT: build.mutation({
  query: (data) => ({
    url: `incident-it/updatecomment`,
    method: 'POST',
    body: data,
  }),
}),
deleteCommentIncidentIT: build.mutation({
  query: (data) => ({
    url: `incident-it/deletecomment`,
    method: 'POST',
    body: data,
  }),
}),
markAsAbuseIncidentIT: build.mutation({
  query: (data) => ({
    url: `incident-it/markasabuse`,
    method: 'POST',
    body: data,
  }),
}),
closeTicketIncidentIT: build.mutation({
  query: (data) => ({
    url: `incident-it/closeticket`,
    method: 'POST',
    body: data,
  }),
}),
getUsersInfoIncidentIT: build.mutation({
  query: (data) => ({
    url: `incident-it/getusersinfo`,
    method: 'POST',
    body: data,
  }),
}),
getTrustIncidentIT: build.mutation({
  query: (data) => ({
    url: `incident-it/get-trust-incident`,
    method: 'POST',
    body: data,
  }),
}),

// route pour incidentSG 
getAllIncidentsSG: build.mutation({
  query: (data) => ({
    url: `incident-sg/getallincidents`,
    method: 'POST',
    body: data,
  }),
}),
getIncidentSGById: build.mutation({
  query: (data) => ({
    url: `incident-sg/getincident`,
    method: 'POST',
    body: data,
  }),
}),
addIncidentSG: build.mutation({
  query: (data) => ({
    url: `incident-sg/addincident`,
    method: 'POST',
    body: data,
  }),
}),
deleteIncidentSG: build.mutation({
  query: (data) => ({
    url: `incident-sg/deleteincident`,
    method: 'POST',
    body: data,
  }),
}),
modifyIncidentSG: build.mutation({
  query: (data) => ({
    url: `incident-sg/modifyincident`,
    method: 'POST',
    body: data,
  }),
}),
exportAttachmentIncidentSG: build.mutation({
  query: (data) => ({
    url: `incident-sg/exportattachment`,
    method: 'POST',
    body: data,
  }),
}),
updateIncidentSGState: build.mutation({
  query: (data) => ({
    url: `incident-sg/updateincidentstate`,
    method: 'POST',
    body: data,
  }),
}),
updateIncidentSGTitle: build.mutation({
  query: (data) => ({
    url: `incident-sg/updateincidenttitle`,
    method: 'POST',
    body: data,
  }),
}),
addAttachmentIncidentSG: build.mutation({
  query: (data) => ({
    url: `incident-sg/addattachment`,
    method: 'POST',
    body: data,
  }),
}),
deleteAttachmentIncidentSG: build.mutation({
  query: (data) => ({
    url: `incident-sg/deleteattachment`,
    method: 'POST',
    body: data,
  }),
}),
addCommentIncidentSG: build.mutation({
  query: (data) => ({
    url: `incident-sg/addcomment`,
    method: 'POST',
    body: data,
  }),
}),
updateCommentIncidentSG: build.mutation({
  query: (data) => ({
    url: `incident-sg/updatecomment`,
    method: 'POST',
    body: data,
  }),
}),
deleteCommentIncidentSG: build.mutation({
  query: (data) => ({
    url: `incident-sg/deletecomment`,
    method: 'POST',
    body: data,
  }),
}),
markAsAbuseIncidentSG: build.mutation({
  query: (data) => ({
    url: `incident-sg/markasabuse`,
    method: 'POST',
    body: data,
  }),
}),
closeTicketIncidentSG: build.mutation({
  query: (data) => ({
    url: `incident-sg/closeticket`,
    method: 'POST',
    body: data,
  }),
}),
getUsersInfoIncidentSG: build.mutation({
  query: (data) => ({
    url: `incident-sg/getusersinfo`,
    method: 'POST',
    body: data,
  }),
}),
getTrustIncidentSG: build.mutation({
  query: (data) => ({
    url: `incident-sg/get-trust-incident`,
    method: 'POST',
    body: data,
  }),
}),

// route pour portail

addPortail: build.mutation({
  query: (data) => ({
    url: `portail/portail`,
    method: 'POST',
    body: data,
  }),
}),
verifyPortail: build.query({
  query: (token) => ({
    url: `portail/verifyportail`,
    method: 'GET',
    params: { token },
  }),
}),
deletePortail: build.mutation({
  query: (ID_Portail) => ({
    url: `portail/portail/${ID_Portail}`,
    method: 'DELETE',
  }),
}),
getAllInfoEtablissementPortail: build.query({
  query: () => ({
    url: `portail/etablissement/info`,
    method: 'GET',
  }),
}),
updateServicePortail: build.mutation({
  query: (data) => ({
    url: `portail/service/associate`,
    method: 'POST',
    body: data,
  }),
}),
removeServicePortail: build.mutation({
  query: (data) => ({
    url: `portail/service/remove`,
    method: 'POST',
    body: data,
  }),
}),
getAllInfoServiceITPortail: build.mutation({
  query: (data) => ({
    url: `portail/service/info/it`,
    method: 'POST',
    body: data,
  }),
}),
getAllInfoServiceSGPortail: build.mutation({
  query: (data) => ({
    url: `portail/service/info/sg`,
    method: 'POST',
    body: data,
  }),
}),
updateEquipementPortail: build.mutation({
  query: (data) => ({
    url: `portail/equipement/associate`,
    method: 'POST',
    body: data,
  }),
}),
deleteEquipementChainITPortail: build.mutation({
  query: (data) => ({
    url: `portail/equipement/removeIT`,
    method: 'POST',
    body: data,
  }),
}),
deleteEquipementChainSGPortail: build.mutation({
  query: (data) => ({
    url: `portail/equipement/removeSG`,
    method: 'POST',
    body: data,
  }),
}),
getAllInfoEquipementITPortail: build.mutation({
  query: (data) => ({
    url: `portail/equipement/info/it`,
    method: 'POST',
    body: data,
  }),
}),
getAllInfoEquipementSGPortail: build.mutation({
  query: (data) => ({
    url: `portail/equipement/info/sg`,
    method: 'POST',
    body: data,
  }),
}),
updateEquipementParticulierPortail: build.mutation({
  query: (data) => ({
    url: `portail/equipementParticulier/associate`,
    method: 'POST',
    body: data,
  }),
}),
deleteEquipementParticulierChainITPortail: build.mutation({
  query: (data) => ({
    url: `portail/equipementParticulier/removeIT`,
    method: 'POST',
    body: data,
  }),
}),
deleteEquipementParticulierChainSGPortail: build.mutation({
  query: (data) => ({
    url: `portail/equipementParticulier/removeSG`,
    method: 'POST',
    body: data,
  }),
}),
getAllInfoEquipementParticulierITPortail: build.mutation({
  query: (data) => ({
    url: `portail/equipementParticulier/info/it`,
    method: 'POST',
    body: data,
  }),
}),
getAllInfoEquipementParticulierSGPortail: build.mutation({
  query: (data) => ({
    url: `portail/equipementParticulier/info/sg`,
    method: 'POST',
    body: data,
  }),
}),
getAllInfoPortail: build.mutation({
  query: (data) => ({
    url: `portail/getAllInfoPortails`,
    method: 'POST',
    body: data,
  }),
}),



// route portail client

getAllInfoPortailClient: build.mutation({
  query: (data) => ({
    url: `portailclient/portail`,
    method: 'POST',
    body: data,
  }),
}),

// route pour avoir info etablissement

getInfoEtablissement: build.mutation({
  query: (data) => ({
    url: `portail/get-info-etablissement`,
    method: 'POST',
    body: data,
  }),
}),




// route de souscription etablissement

souscriptionEtablissement: build.mutation({
  query: (data) => ({
    url: `etablissement/souscription-etablissement`,
    method: 'POST',
    body: data,
  }),
}),

// route pour obtenair les portails partenaire

getAllInfoPortailPartenaire: build.mutation({
  query: (data) => ({
    url: `portail/get-all-info-portail-partenaire`,
    method: 'POST',
    body: data,
  }),
}),

// route pour récupérer tout les tickets partenaires

getAllTicketPartenaire: build.mutation({
  query: (data) => ({
    url: `portail/get-all-ticket-partenaire`,
    method: 'POST',
    body: data,
  }),
}),

// route pour récupérer les stat d'un etablissement

getAllStatEtablissement: build.mutation({
  query: (data) => ({
    url: `statistique/get-all-stat-etablissement`,
    method: 'POST',
    body: data,
  }),
}),
getEssentialStatEtablissement: build.mutation({
  query: (data) => ({
    url: `statistique/get-essential-stat-etablissement`,
    method: 'POST',
    body: data,
  }),
}),


// route pour notification publie

getPublishedNotification: build.mutation({
  query: (data) => ({
    url: `notification/publier`,
    method: 'GET',
    body: data,
  }),
}),

// route pour avoir les derniers tickets à modifier

getLastTicket: build.mutation({
  query: (data) => ({
    url: `acceuil/last-ticket`,
    method: 'POST',
    body: data,
  }),
}),

// route pour obtenir la liste des retours feedbacks des établissements

getAllInfoRetourFeedbackByEtablissment: build.mutation({
  query: (data) => ({
    url: `retour-feedback/get-all-info-retour-feedback-by-etablissment`,
    method: 'POST',
    body: data,
  }),
}),


getImageRetourFeedback: build.query({
  query: (fileName) => ({
    url: `retour-feedback/image/${fileName}`,
    method: 'GET',
    responseType: 'blob', // Spécifier que la réponse est une image ou un fichier blob
  }),
}),

downloadFileRetourFeedback: build.query({
  query: (fileName) => ({
    url: `retour-feedback/file/${fileName}`,
    method: 'GET',
    responseType: 'blob', // Spécifier que la réponse est un fichier blob
  }),
}),

// auth SSO

// google 

initiateGoogleAuth: build.mutation({
  queryFn: () => {
    window.location.href = `${process.env.REACT_APP_BASE_URL}/auth/google`; // Redirect to the Google auth route
    return { data: null }; // Return null because this is a redirect
  }
}),

// Mutation pour créer une session Stripe Checkout
createStripeCheckoutSession: build.mutation({
  query: (data) => ({
    url: `stripe/create-checkout-session`,
    method: 'POST',
    body: data,  // Ici vous passerez les informations nécessaires pour Stripe, comme le type d'abonnement
  }),
}),

// Mutation pour gérer le webhook Stripe (après le paiement)
stripeWebhook: build.mutation({
  query: (data) => ({
    url: `stripe/webhook`,  // URL à laquelle Stripe envoie les événements après le paiement
    method: 'POST',
    body: data,
  }),
}),

// Mutation pour mettre à jour un abonnement Stripe
updateStripeSubscription: build.mutation({
  query: (data) => ({
    url: `stripe/update-subscription`,
    method: 'POST',
    body: data,  // Inclure l'ID de l'abonnement Stripe et les données à mettre à jour
  }),
}),

// Mutation pour supprimer (annuler) un abonnement Stripe
deleteStripeSubscription: build.mutation({
  query: (data) => ({
    url: `stripe/delete-subscription`,
    method: 'POST',
    body: data,  // Inclure l'ID de l'abonnement Stripe à annuler
  }),
}),

// Requête pour vérifier le statut d'une session Stripe (succès ou échec)
checkStripeSession: build.query({
  query: (sessionId) => ({
    url: `stripe/check-session/${sessionId}`,  // Utilisez l'ID de session Stripe
    method: 'GET',
  }),
}),

gestionAbonnementStripe: build.mutation({
  query: (data) => ({
    url: `stripe/billing-portal`,
    method: 'POST',
    body: data,  
  }),
}),


// Mutation pour créer un compte machine
createMachineAccount: build.mutation({
  query: (data) => ({
    url: `comptemachine/create`,  // URL pour la création du compte machine
    method: 'POST',
    body: data,
  }),
}),

// Mutation pour modifier un compte machine
updateMachineAccount: build.mutation({
  query: ({ id, ...data }) => ({
    url: `comptemachine/update/${id}`,  // URL pour modifier le compte machine avec l'ID
    method: 'PUT',
    body: data,
  }),
}),

// Mutation pour supprimer un compte machine
deleteMachineAccount: build.mutation({
  query: (id) => ({
    url: `comptemachine/delete/${id}`,  // URL pour supprimer le compte machine avec l'ID
    method: 'DELETE',
  }),
}),

getMachineAccounts: build.mutation({
  query: (etablissementId) => ({
    url: `comptemachine/etablissement/${etablissementId}`,  // URL pour supprimer le compte machine avec l'ID
    method: 'GET',
  }),
}),


// route pour gérer les subscription (webhook) d'un établissement
deleteSubscription: build.mutation({
  query: (webhookId) => ({
    url: `subscription/webhooks/${webhookId}`,  // URL pour supprimer le compte machine avec l'ID
    method: 'DELETE',
  }),
}),

getAllSubscription: build.mutation({
  query: (ID_Etablissement) => ({
    url: `subscription/webhooks/${ID_Etablissement}`,  // URL pour supprimer le compte machine avec l'ID
    method: 'GET',
  }),
}),


// updatesession utilisateur

updateSessionUtilisateur: build.mutation({
  query: (data) => ({
    url: `updatesession/updateUserInfo`,  // URL pour la création du compte machine
    method: 'POST',
    body: data,
  }),
}),

  modifyIsVisiblePartenaire: build.mutation({
    query: (data) => ({
      url: `reglespecial/modifyisvisiblepartenaire`,
      method: 'POST',
      body: data,
    }),
  }),

  getIsVisiblePartenaire: build.query({
    query: (ID_Etablissement) => `reglespecial/getisvisiblepartenaire/${ID_Etablissement}`,
  }),

  // Routes pour les clés partenaires
  createPartnerKey: build.mutation({
    query: (data) => ({
      url: 'partnerkey/create',
      method: 'POST',
      body: data,
    }),
  }),

  usePartnerKey: build.mutation({
    query: (data) => ({
      url: 'partnerkey/use',
      method: 'POST',
      body: data,
    }),
  }),

  getPartnerKeysForSource: build.query({
    query: (ID_Etablissement_Source) => `partnerkey/source/${ID_Etablissement_Source}`,
  }),

  checkPartnerKey: build.query({
    query: (Token) => `partnerkey/check/${Token}`,
  }),

  // route pour les clés de groupe

  // Routes pour les clés de groupe
  createGroupKeys: build.mutation({
    query: (data) => ({
      url: 'groupkey/create',
      method: 'POST', 
      body: data,
    }),
  }),

  verifyAndActivateKeys: build.mutation({
    query: (data) => ({
      url: 'groupkey/activate',
      method: 'POST',
      body: data,
    }),
  }),

  getGroupKeys: build.query({
    query: (ID_Groupe) => `groupkey/${ID_Groupe}`,
  }),

  invalidateGroupKeys: build.mutation({
    query: (ID_Groupe) => ({
      url: `groupkey/invalidate/${ID_Groupe}`,
      method: 'PATCH',
    }),
  }),

  getIncidentsUtilisateur: build.mutation({
    query: (data) => ({
      url: `utilisateur/getincidentutilisateur`,
      method: 'POST',
      body: data,
    }),
  }),

  getDiscussionHistory: build.query({
    query: (incidentId) => `discussion/${incidentId}`,
  }),

  sendMessage: build.mutation({
    query: ({ incidentId, message, type }) => ({
      url: `discussion/send`,
      method: 'POST',
      body: { incidentId, message, type }
    }),
  }),

  updateTypingStatus: build.mutation({
    query: ({ incidentId, isTyping, utilisateurId, type }) => ({
      url: `discussion/typing`,
      method: 'POST',
      body: { incidentId, isTyping, utilisateurId, type }
    }),
  }),

  // Ajoutez ces mutations à votre API
  sendIncidentMessage: build.mutation({
    query: ({ incidentId, message, utilisateurId }) => ({
      url: `incident-it/${incidentId}/messages`,
      method: 'POST',
      body: { message, utilisateurId }
    }),
  }),

  markMessageAsRead: build.mutation({
    query: ({ incidentId, messageId, utilisateurId }) => ({
      url: `incident-it/${incidentId}/messages/${messageId}/read`,
      method: 'POST',
      body: { utilisateurId }
    }),
  }),
}),
});

export const {

  // connexion SSO 

  // google 

  useInitiateGoogleAuthMutation,

  // update session

  useUpdateSessionUtilisateurMutation,

  useGetDiscussionHistoryQuery,

  // route pour les incidents utilisateur

  useGetIncidentsUtilisateurMutation,


  // requete stripe 

  useCreateStripeCheckoutSessionMutation,
  useStripeWebhookMutation,
  useUpdateStripeSubscriptionMutation,
  useDeleteStripeSubscriptionMutation,
  useCheckStripeSessionQuery,
  useGestionAbonnementStripeMutation,

  // requete pour les clés partenaires

  useCreatePartnerKeyMutation,
  useUsePartnerKeyMutation,
  useGetPartnerKeysForSourceQuery,
  useCheckPartnerKeyQuery,

  // requete pour les comptes machines 

  useCreateMachineAccountMutation,
  useUpdateMachineAccountMutation,
  useDeleteMachineAccountMutation,
  useGetMachineAccountsMutation,

  // requete pour gérer les subscriptions

  useDeleteSubscriptionMutation,
  useGetAllSubscriptionMutation,

  useGetUserQuery,
  useGetProductsQuery,
  useGetCustomersQuery,
  useGetTransactionsQuery,
  useGetGeographyQuery,
  useGetSalesQuery,
  useGetAdminsQuery,
  useGetUserPerformanceQuery,
  useGetDashboardQuery,
  useLoginUserMutation,
  useLoginPostUserMutation,
  useLoginPostAdminMutation,
  useUploadImageMutation,
  useLogoutMutation,
  useCreationEntrepriseMutation,
  useRecuperationImageQuery,
  useGetPersonnalisationQuery,
  useSetPersonnalisationMutation,
  useGetAllInfoSectorMutation,
  useGetInfoSectorMutation,
  useAddSectorMutation,
  useDeleteSectorMutation,
  useModifySectorMutation,
  useSearchSectorMutation,
  useMoveSectorMutation,

  // route etablissement souscription etablissement

  useSouscriptionEtablissementMutation,
  useGetInfoEtablissementMutation,

  // route portail partenaire

  useGetAllInfoPortailPartenaireMutation,

  // route pour avoir tout les tickets partenaires

  useGetAllTicketPartenaireMutation,

  // route pour avoir toutes les statistiques d'un établissement

  useGetAllStatEtablissementMutation,
  useGetEssentialStatEtablissementMutation,

  // route pour avoir les notifications de Hurflym pour les mises à jour

  useGetPublishedNotificationMutation,

  // route pour avoir les derniers ticket arrivés

  useGetLastTicketMutation,

  // route pour Section

  useAddSectionMutation,
  useDeleteSectionMutation,
  useModifySectionMutation,
  useGetAllSectionMutation,

  // route pour Feedback

  useAddFeedbackMutation,
  useDeleteFeedbackMutation,
  useGetAllFeedbackMutation,
  useModifyFeedbackMutation,
  useGetAllImageFeedbackMutation,
  useUpdateFeedbackLocationMutation,
  useGetFeedbacksByNodeMutation,
  usePublicGetFeedbackSectionsMutation,

  // route front pour feedback

  usePublicAddRetourFeedbackMutation,

  // route admin pour retourfeedback

  useGetAllInfoRetourFeedbackByEtablissmentMutation,
  useGetImageRetourFeedbackQuery,
  useDownloadFileRetourFeedbackQuery,


  // route pour equipement particulier front client 

  usePublicGetInfoEquipementParticulierMutation,

  // route front incident public

  usePublicAddIncidentMutation,

  // route public pour les regles speciales de l'etablissement
  usePublicGetInfoRegleSpecialMutation,
  useGetPortalRulesMutation,
  useModifyPortalRulesMutation,
  useModifyIsVisiblePartenaireMutation,
  useGetIsVisiblePartenaireQuery,
  //route pour secteur IT

  useAddEquipementToNoeudITMutation,
  useAddSignalementToNoeudITMutation,
  useRemoveEquipementFromNoeudITMutation,
  useRemoveSignalementFromNoeudITMutation,
  useGetAllInfoSectorITMutation,

  //route pour secteur SG

  useAddEquipementToNoeudSGMutation,
  useAddSignalementToNoeudSGMutation,
  useRemoveEquipementFromNoeudSGMutation,
  useRemoveSignalementFromNoeudSGMutation,
  useGetAllInfoSectorSGMutation,

  // route pour incident IT

  useGetAllIncidentsITMutation,
  useGetIncidentITByIdMutation,
  useAddIncidentITMutation,
  useDeleteIncidentITMutation,
  useModifyIncidentITMutation,
  useExportAttachmentIncidentITMutation,
  useUpdateIncidentITStateMutation,
  useUpdateIncidentITTitleMutation,
  useAddAttachmentIncidentITMutation,
  useDeleteAttachmentIncidentITMutation,
  useAddCommentIncidentITMutation,
  useUpdateCommentIncidentITMutation,
  useDeleteCommentIncidentITMutation,
  useMarkAsAbuseIncidentITMutation,
  useCloseTicketIncidentITMutation,
  useGetUsersInfoIncidentITMutation,
  useGetTrustIncidentITMutation,

  // route pour Incident SG

  useGetAllIncidentsSGMutation,
  useGetIncidentSGByIdMutation,
  useAddIncidentSGMutation,
  useDeleteIncidentSGMutation,
  useModifyIncidentSGMutation,
  useExportAttachmentIncidentSGMutation,
  useUpdateIncidentSGStateMutation,
  useUpdateIncidentSGTitleMutation,
  useAddAttachmentIncidentSGMutation,
  useDeleteAttachmentIncidentSGMutation,
  useAddCommentIncidentSGMutation,
  useUpdateCommentIncidentSGMutation,
  useDeleteCommentIncidentSGMutation,
  useMarkAsAbuseIncidentSGMutation,
  useCloseTicketIncidentSGMutation,
  useGetUsersInfoIncidentSGMutation,
  useGetTrustIncidentSGMutation,

  // route pour Portail

  useAddPortailMutation,
  useVerifyPortailQuery,
  useDeletePortailMutation,
  useGetAllInfoEtablissementPortailQuery,
  useGetAllInfoPortailMutation,
  useUpdateServicePortailMutation,
  useRemoveServicePortailMutation,
  useGetAllInfoServiceITPortailMutation,
  useGetAllInfoServiceSGPortailMutation,
  useUpdateEquipementPortailMutation,
  useDeleteEquipementChainITPortailMutation,
  useDeleteEquipementChainSGPortailMutation,
  useGetAllInfoEquipementITPortailMutation,
  useGetAllInfoEquipementSGPortailMutation,
  useUpdateEquipementParticulierPortailMutation,
  useDeleteEquipementParticulierChainITPortailMutation,
  useDeleteEquipementParticulierChainSGPortailMutation,
  useGetAllInfoEquipementParticulierITPortailMutation,
  useGetAllInfoEquipementParticulierSGPortailMutation,

  // route portail client

  useGetAllInfoPortailClientMutation,


  // route utilise par d'ancien composant à benner par la suite mais bloquante donc laisse

  useAddEquipementMutation,
  useDeleteEquipementMutation,
  useGetAllInfoEquipementMutation,
  useModifyEquipementMutation,
  useSearchEquipementMutation,
  useMoveEquipementMutation,
  useGetInfosEquipementMutation,
  useGetAllInfosCategorieMutation,
  useGetAllImageCategorieMutation,
  useRecuperationEquipementQuery,
  useGetAllInfoSignalementMutation,
  useAllInfoNoeudMutation,
  useGetInfoSignalementMutation,
  useAddSignalementMutation,
  useModifySignalementMutation,
  useDeleteSignalementMutation,
  useSearchSignalementMutation,
  useMoveSignalementMutation,


  //route EquipementIT
  useAddEquipementITMutation,
  useDeleteEquipementITMutation,
  useGetAllInfoEquipementITMutation,
  useGetAllInfoEquipementITIncompletMutation,
  useModifyEquipementITMutation,
  useSearchEquipementITMutation,
  useMoveEquipementITMutation,
  useGetEquipementITByIdMutation,


  //route EquipementSG
  useAddEquipementSGMutation,
  useDeleteEquipementSGMutation,
  useGetAllInfoEquipementSGMutation,
  useGetAllInfoEquipementSGIncompletMutation,
  useModifyEquipementSGMutation,
  useSearchEquipementSGMutation,
  useGetEquipementSGByIdMutation,
  useMoveEquipementSGMutation,


  //route SIgnalementIT
  useAddSignalementITMutation,
  useDeleteSignalementITMutation,
  useGetAllInfoSignalementITMutation,
  useModifySignalementITMutation,
  useSearchSignalementITMutation,
  useMoveSignalementITMutation,
  useGetSignalementITByIdMutation,


  // route SignalementSG
  useAddSignalementSGMutation,
  useDeleteSignalementSGMutation,
  useGetAllInfoSignalementSGMutation,
  useModifySignalementSGMutation,
  useSearchSignalementSGMutation,
  useMoveSignalementSGMutation,
  useGetSignalementSGByIdMutation,

  // route pour front client signalement
  usePublicSignalementAllInfoMutation,

  // route pour Type Daction IT

  useAddTypeDactionITMutation,
  useDeleteTypeDactionITMutation,
  useGetAllTypeDactionITMutation,
  useModifyTypeDactionITMutation,
  useGetTypeDactionITMutation,

  // route pour Type Daction SG

  useAddTypeDactionSGMutation,
  useDeleteTypeDactionSGMutation,
  useGetAllTypeDactionSGMutation,
  useGetTypeDactionSGMutation,
  useModifyTypeDactionSGMutation,


  useAddTemplateMutation,
  useDeleteTemplateMutation,
  useGetAllInfoTemplateMutation,
  useGetInfoTemplateMutation,
  useSearchTemplateMutation,
  useModifyTemplateMutation,

  // ancien equipement particulier à bener

  useAddEquipementParticulierMutation,
  useDeleteEquipementParticulierMutation,
  useGetAllInfoEquipementParticulierMutation,
  useSearchEquipementParticulierMutation,
  useModifyEquipementParticulierMutation,

  // nouveau équipement particulier pour IT

  useAddEquipementParticulierITMutation,
  useDeleteEquipementParticulierITMutation,
  useGetAllInfoEquipementParticulierITMutation,
  useGetAllInfoEquipementParticulierITIncompletMutation,
  useSearchEquipementParticulierITMutation,
  useModifyEquipementParticulierITMutation,

  // nouveau équipement particulier pour SG
  useAddEquipementParticulierSGMutation,
  useDeleteEquipementParticulierSGMutation,
  useGetAllInfoEquipementParticulierSGMutation,
  useGetAllInfoEquipementParticulierSGIncompletMutation,
  useSearchEquipementParticulierSGMutation,
  useModifyEquipementParticulierSGMutation,

  // route pour groupe d'admins

  useAddGroupedAdminMutation,
  useDeleteGroupedAdminMutation,
  useGetGroupedAdminByIdQuery,
  useGetGroupedAdminsMutation,
  useUpdateGroupedAdminMutation,

  // route pour les clés de groupe

  useCreateGroupKeysMutation,
  useVerifyAndActivateKeysMutation,
  useGetGroupKeysQuery,
  useInvalidateGroupKeysMutation,

  useFindIDEtablissementByIDSignalementMutation,
  useAllInfoSignalementMutation,
  useMailSignalementMutation,
  useAllInfoEpMutation,
  useMailEpMutation,
  useAddRoleMutation,
  useGetAllInfoRoleMutation,
  useDeleteRoleMutation,
  useModifyRoleMutation,
  useSearchRoleMutation,
  useInscriptionMutation,
  useVerifyemailMutation,
  useGetInfoUtilisateurMutation,
  useModifyInfoUtilisateurMutation,
  useDeleteUtilisateurMutation,
  useLoginUtilisateurEtablissementMutation,
  useLogoutEtablissementMutation,
  useAddAdministrateurMutation,
  useInscriptionUtilisateurEtablissementMutation,
  useRejoindreEtablissementMutation,
  useDeleteListeUtilisateurEtablissementMutation,
  useModifyListeUtilisateurEtablissementMutation,
  useSearchListeUtilisateurEtablissementMutation,
  useGetAllInfoListeUtilisateurEtablissementMutation,
  useGetInfoRegleSpecialMutation,
  useModifyRegleSpecialMutation,
  useAddCategorieServiceITMutation,
  useAddCategorieServiceSGMutation,
  useAddServiceITMutation,
  useAddServiceSGMutation,
  useDeleteCategorieServiceITMutation,
  useDeleteCategorieServiceSGMutation,
  useDeleteServiceITMutation,
  useDeleteServiceSGMutation,
  useGetAllInfoCategorieServiceITMutation,
  useGetAllInfoCategorieServiceSGMutation,
  useGetAllInfoServiceITMutation,
  useGetAllInfoServiceSGMutation,
  useGetInfoCategorieServiceITMutation,
  useGetInfoCategorieServiceSGMutation,
  useGetInfoServiceITMutation,
  useGetInfoServiceSGMutation,
  useModifyCategorieServiceITMutation,
  useModifyCategorieServiceSGMutation,
  useModifyServiceITMutation,
  useModifyServiceSGMutation,
  useGetImageCategorieServiceITQuery,

  // route categorieSignalementIT / SG
  useAddCategorieSignalementITMutation,
  useAddCategorieSignalementSGMutation,
  useDeleteCategorieSignalementITMutation,
  useDeleteCategorieSignalementSGMutation,
  useGetAllInfoCategorieSignalementITMutation,
  useGetAllInfoCategorieSignalementSGMutation,
  useGetCategorieSignalementITByIdMutation,
  useGetCategorieSignalementSGByIdMutation,
  useModifyCategorieSignalementITMutation,
  useModifyCategorieSignalementSGMutation,
  useGetAllImageCategorieSignalementITMutation,
  useGetAllImageCategorieSignalementSGMutation,
  useGetAllImageEquipementITMutation,
  useGetAllImageEquipementSGMutation,
  useDisplayImageCategorieSignalementITQuery,
  useDisplayImageCategorieSignalementSGQuery,

  // route public pour service

  usePublicgetAllInfoCategorieServiceITMutation,
  usePublicgetAllInfoCategorieServiceSGMutation,
  usePublicgetAllInfoServiceITMutation,
  usePublicgetAllInfoServiceSGMutation,
  usePublicgetAllInfoSectorMutation,

  // route public pour DemandeDeService IT et SG

  usePublicAddDemandeDeServiceITMutation,
  usePublicAddDemandeDeServiceSGMutation,

  //route admin pour DemandeDeService IT

  useAddDemandeDeServiceITMutation,
  useDeleteDemandeDeServiceITMutation,
  useModifyDemandeDeServiceITMutation,
  useGetAllInfoDemandeDeServiceITMutation,

  // routes admin pour DemandeDeService SG

  useAddDemandeDeServiceSGMutation,
  useDeleteDemandeDeServiceSGMutation,
  useModifyDemandeDeServiceSGMutation,
  useGetAllInfoDemandeDeServiceSGMutation,

  // route pour le front feedback

  usePublicGetAllFeedbackMutation,

  useSendMessageMutation,
  useUpdateTypingStatusMutation,

  useSendIncidentMessageMutation,
  useMarkMessageAsReadMutation,

} = api;
