import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import FlexBetween from "components/FlexBetween";
import Header from "components/Header";
import { Box, Grid } from "@mui/material";
import ActionNecessaire from "components/acceuil/ActionNecessaire";
import NotificationAcceuil from "components/acceuil/NotificationAcceuil";
import IncidentITAcceuil from "components/acceuil/IncidentITAcceuil";
import IncidentSGAcceuil from "components/acceuil/IncidentSGAcceuil";

const Dashboard = () => {
  const navigate = useNavigate();
  const userSession = useSelector((state) => state.userAuth);
  const { t } = useTranslation();

  useEffect(() => {
    if (!userSession.isAuthenticated) {
      alert(t('session_expired'));
      navigate('/loginuser');
    }
  }, [userSession, navigate, t]);

  return (
    <Box 
      m="1.5rem 2.5rem" 
      sx={{ 
        display: 'flex',
        flexDirection: 'column',
        height: '100vh', // Utiliser toute la hauteur de la fenêtre
        boxSizing: 'border-box',
      }}
    >
      <FlexBetween>
        <Header title={t('dashboard_title')} subtitle={t('dashboard_subtitle')} />
      </FlexBetween>
      <Grid container spacing={3} sx={{ flex: 1, marginTop: '20px', marginBottom: '20px' }}>
        {/* Première ligne (33% de la hauteur disponible) */}
        <Grid item xs={12} sx={{ display: 'flex', height: '33%' }}>
          <Grid container spacing={3} sx={{ flex: 1 }}>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <ActionNecessaire sx={{ height: '100%' }} />
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8}>
              <NotificationAcceuil sx={{ height: '100%' }} />
            </Grid>
          </Grid>
        </Grid>

        {/* Deuxième ligne (prend le reste de la hauteur) */}
        <Grid item xs={12} sx={{ display: 'flex', height: '67%' }}>
          <Grid container spacing={3} sx={{ flex: 1 }}>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <IncidentITAcceuil sx={{ height: '100%' }} />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <IncidentSGAcceuil sx={{ height: '100%' }} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;
