import React, { useState } from 'react';
import { Box, Button, Typography, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useTranslation } from 'react-i18next';

const BASE_URL = process.env.REACT_APP_BASE_URL || 'http://localhost:5001';

const ImageCarousel = ({ images, selectedImage, onImageSelect }) => {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(0);

  const theme = useTheme();
  const buttonColor = theme.palette.primary.main;
  const textColor = theme.palette.mode === 'dark' ? theme.palette.primary.light : theme.palette.secondary.main;

  if (!images || images.length === 0) {
    return <div>{t('ImageCarouselSignalementIT.no_images')}</div>;
  }

  const imagesPerPage = 9;
  const totalPages = Math.ceil(images.length / imagesPerPage);

  const handlePrevClick = () => {
    setCurrentPage((prevPage) => (prevPage === 0 ? totalPages - 1 : prevPage - 1));
  };

  const handleNextClick = () => {
    setCurrentPage((prevPage) => (prevPage === totalPages - 1 ? 0 : prevPage + 1));
  };

  const handleImageClick = (image) => {
    onImageSelect(image);
  };

  const startIndex = currentPage * imagesPerPage;
  const displayedImages = images.slice(startIndex, startIndex + imagesPerPage);

  return (
    <Box style={{ marginTop: '15px' }}>
      <Typography variant="subtitle1">{t('ImageCarouselSignalementIT.available_images')} :</Typography>
      <Box display="flex" flexDirection="column" alignItems="center" style={{ marginTop: '15px' }}>
        <Grid container spacing={2} justifyContent="center">
          {displayedImages.map((image, index) => (
            <Grid item xs={4} key={index}>
              <Box
                onClick={() => handleImageClick(image)}
                style={{
                  cursor: 'pointer',
                  backgroundColor: image === selectedImage ? theme.palette.primary.light : undefined,
                  transition: 'background-color 0.3s ease',
                  borderRadius: '10%',
                  height: '150px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <img
                  src={`${BASE_URL}/images/equipementIT/${image}`}
                  alt={image}
                  style={{ maxWidth: '90%', maxHeight: '90%', objectFit: 'contain' }}
                />
              </Box>
            </Grid>
          ))}
        </Grid>
        <Box display="flex" justifyContent="center" mt={2}>
          <Button
            variant="outlined"
            onClick={handlePrevClick}
            style={{ backgroundColor: buttonColor, color: textColor, marginRight: '20px' }}
          >
            <ArrowBackIosIcon />
          </Button>
          <Button
            variant="outlined"
            onClick={handleNextClick}
            style={{ backgroundColor: buttonColor, color: textColor, marginLeft: '20px' }}
          >
            <ArrowForwardIosIcon />
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ImageCarousel;
