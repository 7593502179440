import React, { useState, useEffect } from 'react';
import {
  Box, Typography, Button, TextField, Dialog, DialogTitle, DialogContent, DialogActions,
  Grid, List, ListItem, ListItemText, IconButton, TablePagination, Select, MenuItem, FormControlLabel, Checkbox
} from '@mui/material';
import {
  useGetAllInfoEquipementParticulierSGMutation, useAddEquipementParticulierSGMutation,
  useModifyEquipementParticulierSGMutation, useDeleteEquipementParticulierSGMutation,
  useGetAllInfoCategorieSignalementSGMutation, useGetAllImageCategorieSignalementSGMutation,
  useGetGroupedAdminsMutation, useGetAllTypeDactionSGMutation
} from 'state/api';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import ErrorMessage from '../ErrorMessage';
import ValidationMessage from '../ValidationMessage';
import InfoMessage from '../InfoMessage';
import QrCodeIcon from '@mui/icons-material/QrCode';
import QRCodeInformations from '../QRCodeInformations';
import ImageCarousel from '../ImageCarouselCategorieSignalementSG';

const RenderParticulierSG = () => {
  const { t } = useTranslation();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [nomEquipementRecherche, setNomEquipementRecherche] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [eps, setEp] = useState([]);
  const [filteredEp, setFilteredEp] = useState([]);
  const [currentEp, setCurrentEp] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [epToDelete, setEquipementToDelete] = useState(null);
  const [selectedCategorie, setSelectedCategorie] = useState('');
  const [categorieRecherche, setCategorieRecherche] = useState('');
  const [descriptionEp, setDescriptionEp] = useState('');
  const [categories, setCategories] = useState([]);
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState('');
  const [showMessage, setShowMessage] = useState(false);
  const [messageText, setMessageText] = useState('');
  const [nomEp, setNomEp] = useState('');
  const [potentielUrgenceIntervention, setPotentielUrgenceIntervention] = useState(false);
  const [priorite, setPriorite] = useState(1);
  const [severite, setSeverite] = useState(1);
  const [selectedGroupe, setSelectedGroupe] = useState('');
  const [selectedTypeDaction, setSelectedTypeDaction] = useState('');
  const [groupes, setGroupes] = useState([]);
  const [typesDaction, setTypesDaction] = useState([]);
  const [qrCodeDialogOpen, setQrCodeDialogOpen] = useState(false);
  const [selectedEpForQrCode, setSelectedEpForQrCode] = useState(null);

  const theme = useTheme();
  const buttonColor = theme.palette.primary.main;
  const textColor = theme.palette.mode === 'dark' ? theme.palette.primary.light : theme.palette.secondary.main;

  const [deleteEpSG] = useDeleteEquipementParticulierSGMutation();

  const [getAllInfosCategorie] = useGetAllInfoCategorieSignalementSGMutation();
  const [getAllInfoEquipementParticulierSG] = useGetAllInfoEquipementParticulierSGMutation();
  const [getAllImage] = useGetAllImageCategorieSignalementSGMutation();
  const [getGroupedAdmins] = useGetGroupedAdminsMutation();
  const [getAllTypeDactionSG] = useGetAllTypeDactionSGMutation();

  const userSession = useSelector((state) => state.adminAuth);

  const [addSignalementSG] = useAddEquipementParticulierSGMutation();
  const [modifySignalementSG] = useModifyEquipementParticulierSGMutation();

  const showTempMessage = (text) => {
    setMessageText(text);
    setShowMessage(true);
    setTimeout(() => {
      setShowMessage(false);
    }, 5000);
  };

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        setIsLoading(true);
        const response = await getAllInfosCategorie({ ID_Etablissement: userSession.ID_Etablissement });
        if (response.data) {
          const categorized = response.data.map((cat) => ({
            ...cat,
            type: cat.ID_Categorie_Precedente === 0 ? 'Primaire' : 'Secondaire'
          }));
          setCategories(categorized);
        }
      } catch (err) {
        if (err.originalStatus === 401) {
          alert('Votre session a expiré. Veuillez vous reconnecter.');
          window.location = '/loginuser';
        }
        popUpError(t('RenderParticulierSG.error_fetching_categories'));
      } finally {
        setIsLoading(false);
      }
    };

    fetchCategories();
  }, [getAllInfosCategorie, userSession.ID_Etablissement]);

  useEffect(() => {
    const fetchGroupes = async () => {
      try {
        setIsLoading(true);
        const response = await getGroupedAdmins({ ID_Etablissement: userSession.ID_Etablissement });
        if (response.data) {
          setGroupes(response.data);
        }
      } catch (err) {
        if (err.originalStatus === 401) {
          alert('Votre session a expiré. Veuillez vous reconnecter.');
          window.location = '/loginuser';
        }
        popUpError(t('RenderParticulierSG.error_fetching_groups'));
      } finally {
        setIsLoading(false);
      }
    };

    fetchGroupes();
  }, [getGroupedAdmins, userSession.ID_Etablissement]);

  useEffect(() => {
    const fetchTypesDaction = async () => {
      try {
        setIsLoading(true);
        const response = await getAllTypeDactionSG({ ID_Etablissement: userSession.ID_Etablissement });
        if (response.data) {
          setTypesDaction(response.data);
        }
      } catch (err) {
        if (err.originalStatus === 401) {
          alert('Votre session a expiré. Veuillez vous reconnecter.');
          window.location = '/loginuser';
        }
        popUpError(t('RenderParticulierSG.error_fetching_action_types'));
      } finally {
        setIsLoading(false);
      }
    };

    fetchTypesDaction();
  }, [getAllTypeDactionSG, userSession.ID_Etablissement]);

  const fetchEp = async () => {
    try {
      setIsLoading(true);
      const responseSG = await getAllInfoEquipementParticulierSG({ ID_Etablissement: userSession.ID_Etablissement });
      if (responseSG.data) {
        setEp(responseSG.data);
        setFilteredEp(responseSG.data);
      }
    } catch (err) {
      if (err.originalStatus === 401) {
        alert('Votre session a expiré. Veuillez vous reconnecter.');
        window.location = '/loginuser';
      }
      popUpError(t('RenderParticulierSG.error_fetching_equipments'));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchEp();
  }, [getAllInfoEquipementParticulierSG, userSession.ID_Etablissement]);

  useEffect(() => {
    const filtered = eps.filter(ep => {
      const matchName = ep.Nom_Ep.toLowerCase().includes(nomEquipementRecherche.toLowerCase());
      const matchCategory = categorieRecherche ? ep.ID_Categorie_Signalement_SG === categorieRecherche : true;
      return matchName && matchCategory;
    });
    setFilteredEp(filtered);
  }, [nomEquipementRecherche, categorieRecherche, eps]);

  const handleOpenQrCodeDialog = (ep) => {
    setSelectedEpForQrCode(ep);
    setQrCodeDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setCurrentEp(null);
    setEditMode(false);
  };

  const handleSave = async () => {
    try {
      if (!selectedCategorie) {
        showTempMessage(t('RenderParticulierSG.choose_category'));
        return;
      }
      if (!selectedImage) {
        showTempMessage(t('RenderParticulierSG.choose_category'));
        return;
      }
      if (!selectedGroupe) {
        showTempMessage(t('RenderParticulierSG.choose_category'));
        return;
      }
      if (!selectedTypeDaction) {
        showTempMessage(t('RenderParticulierSG.choose_category'));
        return;
      }
      setIsLoading(true);

      const commonData = {
        Nom_Ep: nomEp,
        Description_Ep: descriptionEp,
        ID_Etablissement: userSession.ID_Etablissement,
        ID_Categorie_Signalement_SG: selectedCategorie,
        Lien_Image: selectedImage,
        Potentiel_urgence_intervention: potentielUrgenceIntervention,
        Priorite: priorite,
        Severite: severite,
        ID_Groupe: selectedGroupe,
        ID_Type_Daction_SG: selectedTypeDaction
      };

      let response;
      if (editMode) {
        commonData.ID_Ep_SG = currentEp.ID_Ep_SG;
        response = await modifySignalementSG(commonData);
      } else {
        response = await addSignalementSG(commonData);
      }

      if (response.error) {
        throw new Error(response.error.data?.message || t('RenderParticulierSG.error_saving_equipment'));
      }

      await fetchEp();
      handleCloseDialog();
      popUpValidation(t('RenderParticulierSG.success_saving_equipment'));
    } catch (err) {
      if (err.originalStatus === 401) {
        alert('Votre session a expiré. Veuillez vous reconnecter.');
        window.location = '/loginuser';
      }
      popUpError(err.message || t('RenderParticulierSG.error_saving_equipment'));
    } finally {
      setIsLoading(false);
    }
  };

  const handleEdit = (ep) => {
    setCurrentEp(ep);
    setNomEp(ep.Nom_Ep);
    setDescriptionEp(ep.Description_Ep);
    setSelectedImage(ep.Lien_Image);
    setSelectedCategorie(ep.ID_Categorie_Signalement_SG);
    setPotentielUrgenceIntervention(ep.Potentiel_urgence_intervention);
    setPriorite(ep.Priorite);
    setSeverite(ep.Severite);
    setSelectedGroupe(ep.ID_Groupe);
    setSelectedTypeDaction(ep.ID_Type_Daction_SG);
    setEditMode(true);
    setDialogOpen(true);
  };

  const handleDeleteEquipement = async () => {
    try {
      if (epToDelete) {
        const { ID_Ep_SG } = epToDelete;
        const response = await deleteEpSG({ ID_Ep_SG });

        if (response.error) {
          throw new Error(response.error.data?.message || t('RenderParticulierSG.error_saving_equipment'));
        }

        setEp(prevSignalement => prevSignalement.filter(ep => ep.ID_Ep_SG !== ID_Ep_SG));
        await fetchEp();
        popUpValidation(t('RenderParticulierSG.success_deleting_equipment'));
        setOpenDeleteDialog(false);
      }
    } catch (err) {
      if (err.originalStatus === 401) {
        alert('Votre session a expiré. Veuillez vous reconnecter.');
        window.location = '/loginuser';
      }
      popUpError(err.message || t('RenderParticulierSG.error_saving_equipment'));
    }
  };

  const handleDeleteDialogOpen = (ep) => {
    setEquipementToDelete(ep);
    setOpenDeleteDialog(true);
  };

  const handleImageSelect = (selectedIndex) => {
    setSelectedImage(selectedIndex);
  };

  useEffect(() => {
    const fetchImages = async () => {
      try {
        setIsLoading(true);
        const response = await getAllImage();
        if (response.data) {
          setImages(response.data.images);
        }
      } catch (err) {
        popUpError(t('RenderParticulierSG.error_fetching_categories'));
      } finally {
        setIsLoading(false);
      }
    };

    fetchImages();
  }, [getAllImage]);

  const [error, setError] = useState('');
  const popUpError = (message) => {
    setError(message);
  };

  const [message, setMessage] = useState('');
  const popUpInfo = (message) => {
    setMessage(message);
  };

  const [validation, setValidation] = useState('');
  const popUpValidation = (message) => {
    setValidation(message);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpenAdd = async () => {
    setDialogOpen(true);
    setCurrentEp(null);
    setNomEp('');
    setDescriptionEp('');
    setSelectedImage('');
    setSelectedCategorie('');
    setPotentielUrgenceIntervention(false);
    setPriorite(1);
    setSeverite(1);
    setSelectedGroupe('');
    setSelectedTypeDaction('');
  };

  return (
    <Box m="1.5rem 2.5rem">
      <Typography variant="h3" gutterBottom style={{ marginTop: '60px', marginBottom: '20px' }}>
        {t('RenderParticulierSG.manage_special_equipments')}
      </Typography>
      <ErrorMessage message={error} setError={setError} />
      <InfoMessage message={message} setInfo={setMessage} />
      <ValidationMessage message={validation} setValidation={setValidation} />
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={5} style={{ textAlign: 'left' }}>
          <Button variant="contained" color="primary" onClick={handleOpenAdd} style={{ height: '55px' }}>
            {t('RenderParticulierSG.create_new_special_equipment')}
          </Button>
        </Grid>
        <Grid item xs={7} style={{ textAlign: 'right', display: 'flex', alignItems: 'center' }}>
          <TextField label={t('RenderParticulierSG.special_equipment_name')} value={nomEquipementRecherche} onChange={(e) => setNomEquipementRecherche(e.target.value)} />
          <Select
            value={categorieRecherche}
            onChange={(e) => setCategorieRecherche(e.target.value)}
            style={{ width: '30%', marginLeft: '8px' }}
          >
            <MenuItem value="">
              <em>{t('RenderParticulierSG.select_category')}</em>
            </MenuItem>
            {categories.map(cat => (
              <MenuItem key={cat.ID_Categorie_Signalement_SG} value={cat.ID_Categorie_Signalement_SG}>
                {cat.Nom_Categorie} ({cat.type})
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>

      <List style={{ marginTop: '32px' }}>
        {filteredEp.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((ep, index) => {
          const categorie = categories.find(cat => cat.ID_Categorie_Signalement_SG === ep.ID_Categorie_Signalement_SG);
          const categorieNom = categorie ? categorie.Nom_Categorie : t('RenderParticulierSG.unknown_category');
          const groupe = groupes.find(grp => grp.ID_Groupe === ep.ID_Groupe);
          const groupeNom = groupe ? groupe.Nom_groupe : t('RenderParticulierSG.unknown_group');
          const typeDaction = typesDaction.find(type => type.ID_Type_Daction_SG === ep.ID_Type_Daction_SG);
          const typeDactionNom = typeDaction ? typeDaction.Titre : t('RenderParticulierSG.unknown_action_type');
          const color = typeDaction ? typeDaction.Couleur.replace('0x', '#') : '#fff';

          return (
            <ListItem key={index} secondaryAction={
              <>
                <IconButton edge="end" aria-label="qrcode" onClick={() => handleOpenQrCodeDialog(ep)}>
                  <QrCodeIcon />
                </IconButton>
                <IconButton edge="end" aria-label="edit" onClick={() => { handleEdit(ep) }}>
                  <EditIcon />
                </IconButton>
                <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteDialogOpen(ep)}>
                  <DeleteIcon />
                </IconButton>
              </>
            }>
              <div style={{ width: '20px', height: '20px', backgroundColor: color, marginRight: '10px', borderRadius: '4px', boxShadow: '0 0 3px rgba(0, 0, 0, 0.3)' }}></div>
              <ListItemText
                primary={ep.Nom_Ep}
                secondary={
                  <>
                    <Typography
                      component="span"
                      variant="body2"
                      color="textSecondary"
                    >
                      {`${t('RenderParticulierSG.category')}: ${categorieNom}, ${t('RenderParticulierSG.group')}: ${groupeNom}, ${t('RenderParticulierSG.action_type')}: ${typeDactionNom}, ${t('RenderParticulierSG.potential_urgency')}: ${ep.Potentiel_urgence_intervention ? 'Oui' : 'Non'}, ${t('RenderParticulierSG.priority')}: ${ep.Priorite}, ${t('RenderParticulierSG.severity')}: ${ep.Severite}`}
                    </Typography>
                  </>
                }
              />
            </ListItem>
          );
        })}
      </List>
      <TablePagination
        component="div"
        count={filteredEp.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 25, 100]}
      />
      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>{editMode ? t('RenderParticulierSG.edit') : t('RenderParticulierSG.add')} {t('RenderParticulierSG.special_equipment_name')}</DialogTitle>
        <DialogContent>
          <form>
            <TextField label={t('RenderParticulierSG.special_equipment_name')} value={nomEp} onChange={(e) => setNomEp(e.target.value)} fullWidth margin="normal" />
            <TextField label={t('RenderParticulierSG.special_equipment_name')} value={descriptionEp} onChange={(e) => setDescriptionEp(e.target.value)} fullWidth margin="normal" />
            <Typography variant="h3" style={{ marginTop: '20px', marginBottom: '10px' }}>{t('RenderParticulierSG.choose_category')}</Typography>
            <Select
              value={selectedCategorie}
              onChange={(e) => {
                setSelectedCategorie(e.target.value);
              }}
              fullWidth
              margin="normal"
            >
              <MenuItem value="">
                <em>{t('RenderParticulierSG.select_category')}</em>
              </MenuItem>
              {categories.map(cat => (
                <MenuItem key={cat.ID_Categorie_Signalement_SG} value={cat.ID_Categorie_Signalement_SG}>
                  {cat.Nom_Categorie} ({cat.type})
                </MenuItem>
              ))}
            </Select>
            <Typography variant="h3" style={{ marginTop: '20px', marginBottom: '10px' }}>{t('RenderParticulierSG.choose_action_type')}</Typography>
            <Select
              value={selectedTypeDaction}
              onChange={(e) => setSelectedTypeDaction(e.target.value)}
              fullWidth
              margin="normal"
            >
              <MenuItem value="">
                <em>{t('RenderParticulierSG.select_category')}</em>
              </MenuItem>
              {typesDaction.map(type => (
                <MenuItem key={type.ID_Type_Daction_SG} value={type.ID_Type_Daction_SG}>
                  {type.Titre}
                </MenuItem>
              ))}
            </Select>
            <TextField
              label={t('RenderParticulierSG.priority')}
              type="number"
              value={priorite}
              onChange={(e) => setPriorite(e.target.value)}
              inputProps={{ min: 1, max: 3 }}
              fullWidth
              margin="normal"
            />
            <TextField
              label={t('RenderParticulierSG.severity')}
              type="number"
              value={severite}
              onChange={(e) => setSeverite(e.target.value)}
              inputProps={{ min: 1, max: 3 }}
              fullWidth
              margin="normal"
            />
            <Select
              value={selectedGroupe}
              onChange={(e) => setSelectedGroupe(e.target.value)}
              fullWidth
              margin="normal"
            >
              <MenuItem value="">
                <em>{t('RenderParticulierSG.select_category')}</em>
              </MenuItem>
              {groupes.map(grp => (
                <MenuItem key={grp.ID_Groupe} value={grp.ID_Groupe}>
                  {grp.Nom_groupe}
                </MenuItem>
              ))}
            </Select>
            <FormControlLabel
              control={
                <Checkbox
                  checked={potentielUrgenceIntervention}
                  onChange={(e) => setPotentielUrgenceIntervention(e.target.checked)}
                />
              }
              label={t('RenderParticulierSG.potential_urgency')}
            />
            <ImageCarousel images={images} selectedImage={selectedImage} onImageSelect={handleImageSelect} />
            {showMessage && <div style={{ textAlign: 'center', fontSize: '1em', marginTop: '10px' }}>{messageText}</div>}
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} style={{ backgroundColor: buttonColor, color: textColor }}>{t('RenderParticulierSG.cancel')}</Button>
          <Button onClick={handleSave} style={{ backgroundColor: buttonColor, color: textColor }}>{editMode ? t('RenderParticulierSG.save') : t('RenderParticulierSG.submit')}</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)}>
        <DialogTitle>{t('RenderParticulierSG.delete_special_equipment')}</DialogTitle>
        <DialogContent>
          <Typography>{t('RenderParticulierSG.confirm_delete')}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)} style={{ backgroundColor: buttonColor, color: textColor }}>{t('RenderParticulierSG.cancel')}</Button>
          <Button onClick={handleDeleteEquipement} style={{ backgroundColor: buttonColor, color: textColor }}>{t('RenderParticulierSG.delete')}</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={qrCodeDialogOpen} onClose={() => setQrCodeDialogOpen(false)}>
        <DialogTitle>{t('RenderParticulierSG.qr_code_information')}</DialogTitle>
        <DialogContent>
          {selectedEpForQrCode && (
            <QRCodeInformations ID_Ep_SG={selectedEpForQrCode.ID_Ep_SG} ID_Etablissement={selectedEpForQrCode.ID_Etablissement} signalement={false} />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setQrCodeDialogOpen(false)} style={{ backgroundColor: buttonColor, color: textColor }}>{t('RenderParticulierSG.cancel')}</Button>
        </DialogActions>
      </Dialog>
      {isLoading && <p>{t('RenderParticulierSG.loading')}</p>}
    </Box>
  );
};

export default RenderParticulierSG;
