import React, { useState, useEffect } from 'react';
import { Typography, TextField, Button, IconButton, MenuItem, Slider, Box, Select, InputLabel, FormControl } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

// Styled components for custom styling
const FormContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-color: ${(props) => props.couleur_de_fond || '#FFFFFF'};
  color: ${(props) => props.couleur_ecriture || '#000000'};
  font-family: ${(props) => props.police_de_caractere}, sans-serif;
  position: relative;
`;

const CloseButton = styled(IconButton)`
  position: absolute;
  top: 16px;
  right: 16px;
`;

const LogoContainer = styled('div')`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 32px; /* Increase margin-bottom for more space */

  img {
    max-height: 60px;
  }

  @media (max-width: 767px) {
    margin-bottom: 16px;
  }
`;

const TitleContainer = styled('div')`
  text-align: center;
  width: 100%;
  margin-bottom: 16px;
`;

const InputField = styled(TextField)`
  width: 80%;
  margin-bottom: 16px;

  @media (max-width: 767px) {
    width: 95%;
  }
`;

const SubmitButton = styled(Button)`
  margin-top: 16px;
  background-color: ${(props) => props.couleur_bouton};
  color: ${(props) => props.couleur_bouton_ecriture};
  transition: transform 0.2s;
  &:hover {
    transform: scale(1.05);
    background-color: ${(props) => props.couleur_bouton};
    color: ${(props) => props.couleur_bouton_ecriture};
  }
`;

const FormControlStyled = styled(FormControl)`
  width: 80%;
  margin-bottom: 16px;

  @media (max-width: 767px) {
    width: 95%;
  }
`;

const EquipementForm = ({
  onClose,
  couleur_de_fond,
  couleur_ecriture,
  couleur_bouton,
  couleur_bouton_ecriture,
  police_de_caractere,
  maxParticules = 1,
  logoUrl,
  onFormSubmit
}) => {
  const { t } = useTranslation();
  const userAuth = useSelector((state) => state.userAuth);
  const [formData, setFormData] = useState({
    Description: '',
    Niveau_urgence_intervention: 4,
    Nombre_de_particule_impacte: 1,
    Attachment: null,
    Email_signaleur: userAuth.user ? userAuth.user.email : ''
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setFormData({
      ...formData,
      Nombre_de_particule_impacte: maxParticules === 1 ? 1 : 1,
      Email_signaleur: userAuth.user ? userAuth.user.email : ''
    });
  }, [maxParticules, userAuth.user]);

  const handleChange = (event) => {
    const { name, value, files } = event.target;
    const newFormData = {
      ...formData,
      [name]: files ? files[0] : value
    };
    setFormData(newFormData);
  };

  const handleSliderChange = (event, newValue) => {
    const newFormData = {
      ...formData,
      Nombre_de_particule_impacte: newValue
    };
    setFormData(newFormData);
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.Description) newErrors.Description = t('description_required');
    if (!formData.Email_signaleur) {
      newErrors.Email_signaleur = t('email_required');
    } else if (!formData.Email_signaleur.includes('@')) {
      newErrors.Email_signaleur = t('invalid_email');
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      onFormSubmit(formData);
      onClose();
    }
  };

  return (
    <FormContainer
      couleur_de_fond={couleur_de_fond}
      couleur_ecriture={couleur_ecriture}
      police_de_caractere={police_de_caractere}
    >
      <CloseButton onClick={onClose}>
        <CloseIcon />
      </CloseButton>
      {logoUrl && (
        <LogoContainer>
          <img src={logoUrl} alt="Logo" />
        </LogoContainer>
      )}
      <TitleContainer>
        <Typography
          variant="h5"
          style={{
            color: couleur_ecriture,
            fontFamily: police_de_caractere,
            fontWeight: 'bold',
          }}
        >
          {t('report_equipment_problem')}
        </Typography>
      </TitleContainer>
      <InputField
        label={t('problem_description')}
        variant="outlined"
        multiline
        rows={4}
        name="Description"
        value={formData.Description}
        onChange={handleChange}
        couleur_de_fond={couleur_de_fond}
        couleur_ecriture={couleur_ecriture}
        error={!!errors.Description}
        helperText={errors.Description}
      />
      <FormControlStyled variant="outlined">
        <InputLabel>{t('urgency_level')}</InputLabel>
        <Select
          value={formData.Niveau_urgence_intervention}
          onChange={handleChange}
          label={t('urgency_level')}
          name="Niveau_urgence_intervention"
        >
          <MenuItem value={1}>{t('urgency_immediate')}</MenuItem>
          <MenuItem value={2}>{t('urgency_hour')}</MenuItem>
          <MenuItem value={3}>{t('urgency_day')}</MenuItem>
          <MenuItem value={4}>{t('urgency_few_days')}</MenuItem>
        </Select>
      </FormControlStyled>
      {maxParticules > 1 && (
        <Box width="80%" marginBottom="16px">
          <Typography variant="body1" gutterBottom>
            {t('number_of_particles_affected')}
          </Typography>
          <Slider
            value={formData.Nombre_de_particule_impacte}
            onChange={handleSliderChange}
            aria-labelledby="Nombre_de_particule_impacte-slider"
            min={1}
            max={maxParticules}
            step={1}
            valueLabelDisplay="auto"
          />
        </Box>
      )}
      <InputField
        label={t('requester_email')}
        variant="outlined"
        name="Email_signaleur"
        value={formData.Email_signaleur}
        onChange={handleChange}
        couleur_de_fond={couleur_de_fond}
        couleur_ecriture={couleur_ecriture}
        error={!!errors.Email_signaleur}
        helperText={errors.Email_signaleur}
        disabled={userAuth.user !== null}
      />
      <InputField
        type="file"
        name="Attachment"
        onChange={handleChange}
        couleur_de_fond={couleur_de_fond}
        couleur_ecriture={couleur_ecriture}
      />
      <SubmitButton
        onClick={handleSubmit}
        couleur_bouton={couleur_bouton}
        couleur_bouton_ecriture={couleur_bouton_ecriture}
      >
        {t('send')}
      </SubmitButton>
    </FormContainer>
  );
};

export default EquipementForm;
