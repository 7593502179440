import React, { useState, useEffect } from 'react';
import { Typography, TextField, Button, IconButton, MenuItem, Select, InputLabel, FormControl } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

// Styled components for custom styling
const FormContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-color: ${(props) => props.couleur_de_fond || '#FFFFFF'};
  color: ${(props) => props.couleur_ecriture || '#000000'};
  font-family: ${(props) => props.police_de_caractere}, sans-serif;
  position: relative;
`;

const CloseButton = styled(IconButton)`
  position: absolute;
  top: 16px;
  right: 16px;
`;

const LogoContainer = styled('div')`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 16px;

  img {
    max-height: 60px;
  }

  @media (max-width: 767px) {
    margin-bottom: 8px;
  }
`;

const InputField = styled(TextField)`
  width: 80%;
  margin-bottom: 16px;

  @media (max-width: 767px) {
    width: 95%;
  }
`;

const SubmitButton = styled(Button)`
  margin-top: 16px;
  background-color: ${(props) => props.couleur_bouton};
  color: ${(props) => props.couleur_bouton_ecriture};
  transition: transform 0.2s;
  &:hover {
    transform: scale(1.05);
    background-color: ${(props) => props.couleur_bouton};
    color: ${(props) => props.couleur_bouton_ecriture};
  }
`;

const FormControlStyled = styled(FormControl)`
  width: 80%;
  margin-bottom: 16px;

  @media (max-width: 767px) {
    width: 95%;
  }
`;

const IncidentForm = ({
  onClose,
  couleur_de_fond,
  couleur_ecriture,
  couleur_bouton,
  couleur_bouton_ecriture,
  police_de_caractere,
  logoUrl,
  onFormSubmit
}) => {
  const { t } = useTranslation();
  const userSession = useSelector((state) => state.userAuth);
  const [formData, setFormData] = useState({
    Description: '',
    Niveau_urgence_intervention: 4,
    Attachment: null,
    Email_signaleur: ''
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (userSession && userSession.user.email) {
      setFormData(prevState => ({
        ...prevState,
        Email_signaleur: userSession.user.email
      }));
    }
  }, [userSession]);

  const handleChange = (event) => {
    const { name, value, files } = event.target;
    const newFormData = {
      ...formData,
      [name]: files ? files[0] : value
    };
    setFormData(newFormData);
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.Description) newErrors.Description = t('incident_form.description_required');
    if (!formData.Email_signaleur) {
      newErrors.Email_signaleur = t('incident_form.email_required');
    } else if (!formData.Email_signaleur.includes('@')) {
      newErrors.Email_signaleur = t('incident_form.email_invalid');
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      onFormSubmit(formData);
      onClose();
    }
  };

  return (
    <FormContainer
      couleur_de_fond={couleur_de_fond}
      couleur_ecriture={couleur_ecriture}
      police_de_caractere={police_de_caractere}
    >
      <CloseButton onClick={onClose}>
        <CloseIcon />
      </CloseButton>
      {logoUrl && (
        <LogoContainer>
          <img src={logoUrl} alt="Logo" />
        </LogoContainer>
      )}
      <Typography
        variant="h5"
        style={{
          color: couleur_ecriture,
          fontFamily: police_de_caractere,
          fontWeight: 'bold',
          marginBottom: '16px',
        }}
      >
        {t('incident_form.title')}
      </Typography>
      <InputField
        label={t('incident_form.description_label')}
        variant="outlined"
        multiline
        rows={4}
        name="Description"
        value={formData.Description}
        onChange={handleChange}
        couleur_de_fond={couleur_de_fond}
        couleur_ecriture={couleur_ecriture}
        error={!!errors.Description}
        helperText={errors.Description}
      />
      <FormControlStyled variant="outlined">
        <InputLabel>{t('incident_form.urgency_label')}</InputLabel>
        <Select
          value={formData.Niveau_urgence_intervention}
          onChange={handleChange}
          label={t('incident_form.urgency_label')}
          name="Niveau_urgence_intervention"
        >
          <MenuItem value={1}>{t('incident_form.urgency_option1')}</MenuItem>
          <MenuItem value={2}>{t('incident_form.urgency_option2')}</MenuItem>
          <MenuItem value={3}>{t('incident_form.urgency_option3')}</MenuItem>
          <MenuItem value={4}>{t('incident_form.urgency_option4')}</MenuItem>
        </Select>
      </FormControlStyled>
      <InputField
        label={t('incident_form.email_label')}
        variant="outlined"
        name="Email_signaleur"
        value={formData.Email_signaleur}
        onChange={handleChange}
        couleur_de_fond={couleur_de_fond}
        couleur_ecriture={couleur_ecriture}
        error={!!errors.Email_signaleur}
        helperText={errors.Email_signaleur}
        disabled={userSession && userSession.user.email}
      />
      <InputField
        type="file"
        name="Attachment"
        onChange={handleChange}
        couleur_de_fond={couleur_de_fond}
        couleur_ecriture={couleur_ecriture}
      />
      <SubmitButton
        onClick={handleSubmit}
        couleur_bouton={couleur_bouton}
        couleur_bouton_ecriture={couleur_bouton_ecriture}
      >
        {t('incident_form.submit')}
      </SubmitButton>
    </FormContainer>
  );
};

export default IncidentForm;
