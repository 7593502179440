import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  List,
  Alert,
  ListItem,
  ListItemText,
  IconButton,
  TablePagination
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  useGetAllInfoCategorieServiceITMutation,
  useAddCategorieServiceITMutation,
  useModifyCategorieServiceITMutation,
  useDeleteCategorieServiceITMutation,
} from 'state/api';

// Utilisation des variables d'environnement pour l'URL de base
const BASE_URL = process.env.REACT_APP_BASE_URL || 'http://localhost:5001';

const RenderCategorieServiceIT = () => {
  const { t } = useTranslation();
  const [categories, setCategories] = useState([]);
  const [open, setOpen] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [categoryToDelete, setCategoryToDelete] = useState(null);
  const [editingCategory, setEditingCategory] = useState(null);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [logo, setLogo] = useState(null); // Store file object
  const [fileError, setFileError] = useState(false); // For file input validation
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [getAllCategories] = useGetAllInfoCategorieServiceITMutation();
  const [addCategory] = useAddCategorieServiceITMutation();
  const [modifyCategory] = useModifyCategorieServiceITMutation();
  const [deleteCategory] = useDeleteCategorieServiceITMutation();

  const userSession = useSelector((state) => state.adminAuth);

  useEffect(() => {
    const fetchCategories = async () => {
      const fetchedCategories = await getAllCategories({ ID_Etablissement: userSession.ID_Etablissement });
      setCategories(fetchedCategories.data || []);
    };
    fetchCategories();
  }, [getAllCategories, userSession.ID_Etablissement]);

  const handleOpenNewCategoryDialog = () => {
    setEditingCategory(null);
    setName('');
    setEmail('');
    setLogo(null);
    setFileError(false);
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
    setEditingCategory(null);
    setFileError(false);
  };

  const handleDeleteConfirmation = (category) => {
    setCategoryToDelete(category);
    setConfirmationOpen(true);
  };

  const handleFileChange = (event) => {
    setLogo(event.target.files[0]); // Set the first file as the logo
    setFileError(false);
  };

  const handleAddOrEditCategory = async () => {
    if (!editingCategory && !logo) {
      setFileError(true);
      return; // Prevent submission if file is required and not provided
    }

    const formData = new FormData();
    formData.append('Titre', name);
    formData.append('Adresse_mail_alarming', email);
    formData.append('ID_Etablissement', userSession.ID_Etablissement);
    if (editingCategory) {
      formData.append('ID_Categorie_Service_IT', editingCategory.ID_Categorie_Service_IT);
    }
    if (logo) formData.append('logo', logo);

    if (editingCategory) {
      await modifyCategory(formData);
    } else {
      await addCategory(formData);
    }
    setOpen(false);
    setName('');
    setEmail('');
    setLogo(null);
    setFileError(false);
    // Refetch categories after the update
    const updatedCategories = await getAllCategories();
    setCategories(updatedCategories.data || []);
  };

  const handleEdit = async (category) => {
    setEditingCategory(category);
    setName(category.Titre || '');
    setEmail(category.Adresse_mail_alarming || '');
    setLogo(null); // Clear previous file if any
    setOpen(true);
  };

  const handleDelete = async () => {
    await deleteCategory({ ID_Categorie_Service_IT: categoryToDelete.ID_Categorie_Service_IT });
    setConfirmationOpen(false);
    setCategoryToDelete(null);
    const updatedCategories = await getAllCategories();
    setCategories(updatedCategories.data || []);
  };

  const handleCloseConfirmationDialog = () => {
    setConfirmationOpen(false);
    setCategoryToDelete(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box m="1.5rem 2.5rem">
      <Typography variant="h3" gutterBottom style={{ marginTop: '60px', marginBottom: '20px' }}>
        {t("RenderCategorieServiceIT.manage_categories")}
      </Typography>
      <Button variant="contained" color="primary" onClick={handleOpenNewCategoryDialog}>
        {t("RenderCategorieServiceIT.add_category")}
      </Button>
      <List style={{ marginTop: '20px' }}>
        {categories.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((category) => (
          <ListItem key={category.ID_Categorie_Service_IT} secondaryAction={
            <>
              <IconButton edge="end" aria-label={t("RenderCategorieServiceIT.edit_category")} onClick={() => handleEdit(category)}>
                <EditIcon />
              </IconButton>
              <IconButton edge="end" aria-label={t("RenderCategorieServiceIT.delete_category")} onClick={() => handleDeleteConfirmation(category)}>
                <DeleteIcon />
              </IconButton>
            </>
          }>
            <ListItemText primary={category.Titre} secondary={category.Adresse_mail_alarming} />
          </ListItem>
        ))}
      </List>
      <TablePagination
        component="div"
        count={categories.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      />

      {/* Dialog for Adding/Editing Category */}
      <Dialog open={open} onClose={handleCloseDialog}>
        <DialogTitle>{editingCategory ? t("RenderCategorieServiceIT.edit_category") : t("RenderCategorieServiceIT.add_new_category")}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label={t("RenderCategorieServiceIT.category_title")}
            type="text"
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            margin="dense"
            label={t("RenderCategorieServiceIT.email_alert")}
            type="email"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {editingCategory && (
            <Box display="flex" flexDirection="column" alignItems="center" marginTop={2}>
              <Typography variant="subtitle1">{t("RenderCategorieServiceIT.current_logo")}</Typography>
              <img 
                src={`${BASE_URL}/categorieservice_it/image/${editingCategory.ID_Categorie_Service_IT}`} 
                alt="Category" 
                style={{ maxWidth: '250px', margin: '10px auto' }} // Center the image with some space around it
              />
            </Box>
          )}
          <Box marginTop={2}>
            <input
              type="file"
              accept=".png, .jpg, .jpeg"
              onChange={handleFileChange}
              style={{ display: 'block', width: '100%' }}
            />
          </Box>
          {fileError && <Alert severity="error">{t("RenderCategorieServiceIT.logo_required")}</Alert>}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>{t("RenderCategorieServiceIT.cancel")}</Button>
          <Button onClick={handleAddOrEditCategory}>{editingCategory ? t("RenderCategorieServiceIT.edit_category") : t("RenderCategorieServiceIT.add_category")}</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={confirmationOpen} onClose={handleCloseConfirmationDialog}>
        <DialogTitle>{t("RenderCategorieServiceIT.confirm_deletion")}</DialogTitle>
        <DialogContent>
          <Typography>{t("RenderCategorieServiceIT.delete_confirmation_text")}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmationDialog}>{t("RenderCategorieServiceIT.cancel")}</Button>
          <Button onClick={handleDelete} color="error">{t("RenderCategorieServiceIT.delete_category")}</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default RenderCategorieServiceIT;
