import React, { useState } from "react";
import { Box, useTheme, Button, Typography } from "@mui/material";
import Header from "components/Header";
import RenderRole from "components/RenderRole";
import RenderUtilisateur from "components/RenderUtilisateurs";
import RenderRegle from "components/RenderRegle";
import RenderGroupe from "components/RenderGroupe";
import { useTranslation } from "react-i18next";
import { motion, AnimatePresence } from "framer-motion";

const Admin = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [activeSection, setActiveSection] = useState('roles');

  const renderSection = () => {
    switch (activeSection) {
      case 'roles':
        return <RenderRole />;
      case 'groupes':
        return <RenderGroupe />;
      case 'administrateurs':
        return <RenderUtilisateur />;
      case 'regles':
        return <RenderRegle />;
      default:
        return <RenderRole />;
    }
  };

  const buttonStyle = (isActive) => ({
    fontSize: '18px',
    fontWeight: '600',
    margin: '8px',
    padding: '14px 28px',
    boxShadow: isActive 
      ? `0px 6px 12px ${theme.palette.neutral.third}33` 
      : `0px 4px 8px ${theme.palette.neutral.third}26`,
    backgroundColor: isActive ? theme.palette.primary.secondary : theme.palette.neutral.secondary,
    color: isActive ? theme.palette.neutral.secondary : theme.palette.primary.main,
    borderRadius: '6px',
    textTransform: 'none',
    transition: 'all 0.3s ease',
    width: '180px',
    '&:hover': {
      backgroundColor: isActive ? theme.palette.primary.third : theme.palette.neutral.dark,
      boxShadow: `0px 8px 16px ${theme.palette.neutral.third}33`,
      transform: 'translateY(-2px)',
    },
  });

  const buttonTextStyle = {
    letterSpacing: '1px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  };

  const containerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
    exit: { opacity: 0, y: -20, transition: { duration: 0.5 } }
  };

  return (
    <Box m="0.5rem 2.5rem">
      <Header title={t('Admin.Title')} subtitle={t('Admin.Subtitle')} />

      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
        <Button sx={buttonStyle(activeSection === 'roles')} onClick={() => setActiveSection('roles')}>
          <Typography sx={buttonTextStyle}>{t('Admin.Roles').toUpperCase()}</Typography>
        </Button>
        <Button sx={buttonStyle(activeSection === 'groupes')} onClick={() => setActiveSection('groupes')}>
          <Typography sx={buttonTextStyle}>{t('Admin.Groups').toUpperCase()}</Typography>
        </Button>
        <Button sx={buttonStyle(activeSection === 'administrateurs')} onClick={() => setActiveSection('administrateurs')}>
          <Typography sx={buttonTextStyle}>{t('Admin.Administrators').toUpperCase()}</Typography>
        </Button>
        <Button sx={buttonStyle(activeSection === 'regles')} onClick={() => setActiveSection('regles')}>
          <Typography sx={buttonTextStyle}>{t('Admin.Rules').toUpperCase()}</Typography>
        </Button>
      </Box>

      <AnimatePresence mode="wait">
        <motion.div
          key={activeSection}
          variants={containerVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
        >
          <Box sx={{
            padding: '20px',
          }}>
            {renderSection()}
          </Box>
        </motion.div>
      </AnimatePresence>
    </Box>
  );
};

export default Admin;
