import React, { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useTranslation } from 'react-i18next';

// Utilisation de la variable d'environnement REACT_APP_BASE_URL pour la base de l'URL
const BASE_URL = process.env.REACT_APP_BASE_URL || 'http://localhost:5001';

const ImageCarousel = ({ images, selectedImage, onImageSelect }) => {
  const { t } = useTranslation(); // Hook de traduction
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const theme = useTheme();
  const buttonColor = theme.palette.primary.main;
  const textColor = theme.palette.mode === 'dark' ? theme.palette.primary.light : theme.palette.secondary.main;

  // Vérifier si les images sont définies
  if (!images || images.length === 0) {
    return <div>{t('ImageCarouselSignalementIT.no_images')}</div>;
  }

  const handlePrevClick = () => {
    setSelectedImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const handleNextClick = () => {
    setSelectedImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handleImageClick = (index) => {
    setSelectedImageIndex(index);
    onImageSelect(images[index]);
  };

  return (
    <Box style={{ marginTop: '15px' }}>
      <Typography variant="subtitle">{t('ImageCarouselSignalementIT.available_images')} :</Typography>
      <Box display="flex" alignItems="center" style={{ marginTop: '15px' }}>
        <Button
          variant="outlined"
          onClick={handlePrevClick}
          style={{ backgroundColor: buttonColor, color: textColor, marginRight: '20px' }}
        >
          <ArrowBackIosIcon />
        </Button>
        <Box flexGrow={1} textAlign="center">
          <div
            onClick={() => handleImageClick(selectedImageIndex)}
            style={{
              cursor: 'pointer',
              backgroundColor: images[selectedImageIndex] === selectedImage ? theme.palette.primary.light : undefined,
              transition: 'background-color 0.3s ease',
              borderRadius: '10%',
              height: '400px',
            }}
          >
            <img
              src={`${BASE_URL}/images/displayimagecategoriesignalementIT/${images[selectedImageIndex]}`}
              alt={images[selectedImageIndex]}
              style={{ maxWidth: '80%', maxHeight: '80%', marginTop: '10%' }}
            />
          </div>
        </Box>
        <Button
          variant="outlined"
          onClick={handleNextClick}
          style={{ backgroundColor: buttonColor, color: textColor, marginLeft: '20px' }}
        >
          <ArrowForwardIosIcon />
        </Button>
      </Box>
    </Box>
  );
};

export default ImageCarousel;
