import React, { useState, useEffect } from 'react';
import FeedbackDataInput from './FeedbackDataInput';
import ListeRetourFeedback from './ListeRetourFeedback';
import DetailRetourFeedback from './DetailRetourFeedback';
import { useGetAllInfoRetourFeedbackByEtablissmentMutation, useGetImageRetourFeedbackQuery, useDownloadFileRetourFeedbackQuery } from 'state/api';

const FeedbackData = () => {
  const [feedbacks, setFeedbacks] = useState([]);
  const [selectedFeedback, setSelectedFeedback] = useState(null);
  const [filters, setFilters] = useState({ Nom_Feedback: '', Feedback_Actif: null });
  const [getAllInfoRetourFeedbackByEtablissment] = useGetAllInfoRetourFeedbackByEtablissmentMutation();
  
  const ID_Etablissement = 1; // Remplacer par la bonne valeur d'ID_Etablissement

  useEffect(() => {
    const fetchFeedbacks = async () => {
      try {
        const data = await getAllInfoRetourFeedbackByEtablissment({ ID_Etablissement }).unwrap();
        setFeedbacks(data);
      } catch (error) {
        console.error("Erreur lors de la récupération des feedbacks :", error);
      }
    };
    fetchFeedbacks();
  }, [getAllInfoRetourFeedbackByEtablissment, ID_Etablissement]);

  const handleFilterChange = (newFilters) => {
    setFilters(newFilters);
  };

  const filteredFeedbacks = feedbacks.filter(feedback => {
    const matchesName = feedback.Nom_Feedback.toLowerCase().includes(filters.Nom_Feedback.toLowerCase());
    const matchesActive = filters.Feedback_Actif === null || feedback.FeedBack_Actif === filters.Feedback_Actif;
    return matchesName && matchesActive;
  });

  // Ajout des hooks pour passer aux composants enfants
  const getImageRetourFeedback = useGetImageRetourFeedbackQuery;
  const downloadFileRetourFeedback = useDownloadFileRetourFeedbackQuery;

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ width: '40%' }}>
        <FeedbackDataInput filters={filters} onFilterChange={handleFilterChange} />
        <ListeRetourFeedback feedbacks={filteredFeedbacks} onSelectFeedback={setSelectedFeedback} selectedFeedback={selectedFeedback} />
      </div>
      <div style={{ width: '60%', marginLeft: '20px' }}>
        {selectedFeedback ? (
          <DetailRetourFeedback 
            feedback={selectedFeedback}
            getImageRetourFeedback={getImageRetourFeedback} 
            downloadFileRetourFeedback={downloadFileRetourFeedback}
          />
        ) : (
          <p>Veuillez sélectionner un feedback</p>
        )}
      </div>
    </div>
  );
};

export default FeedbackData;
