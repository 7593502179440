import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Box, Grid, Paper, Typography, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import NavbarUtilisateur from 'components_public/navbarUtilisateur';
import ListeTickets from './components/ListeTickets';
import DetailsTicket from './components/DetailsTicket';
import { useGetIncidentsUtilisateurMutation } from 'state/api';

const ListeTicketsPage = () => {
  const { t } = useTranslation();
  const [tickets, setTickets] = useState({ incidentsIT: [], incidentsSG: [] });
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const userId = useSelector(state => state.userAuth.user?.ID_Utilisateur);
  const [getIncidentsUtilisateur] = useGetIncidentsUtilisateurMutation();
  const userSession = useSelector(state => state.userAuth.user);
  useEffect(() => {
    const fetchTickets = async () => {
      try {
        setIsLoading(true);
        const response = await getIncidentsUtilisateur({
          ID_Utilisateur: userId
        });
        if (response.data) {
          setTickets({
            incidentsIT: response.data.incidentsIT || [],
            incidentsSG: response.data.incidentsSG || []
          });
        }
        setIsLoading(false);
      } catch (error) {
        console.error("Erreur lors de la récupération des tickets:", error);
        setIsLoading(false);
      }
    };

    if (userId) {
      fetchTickets();
    }
  }, [userId, getIncidentsUtilisateur]);

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <NavbarUtilisateur />
      <Box p={3} sx={{ paddingTop: '15vh' }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Box sx={{ 
              maxHeight: '70vh',
              height: 'fit-content',
              minHeight: '200px'
            }}>
              <ListeTickets 
                tickets={tickets}
                selectedTicket={selectedTicket}
                onTicketSelect={setSelectedTicket}
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={8}>
            <DetailsTicket ticket={selectedTicket} userSession={userSession} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ListeTicketsPage;
