import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Select, MenuItem } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Input from './Input';
import { useTranslation } from 'react-i18next';

const RenderInputs = ({ inputs, onInputsChange, inputErrors }) => {
  const { t } = useTranslation();
  const [inputType, setInputType] = useState('');
  const [showInputForm, setShowInputForm] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [messageText, setMessageText] = useState('');

  useEffect(() => {
    console.log('useEffect - inputs:', inputs);
    onInputsChange(inputs);
  }, [inputs, onInputsChange]);

  const handleAddInput = () => {
    if (inputType) {
      const newInput = {
        Type_Dinput: inputType,
        Nom_Input: '',
        Obligatoire: false,
        Options: {}
      };
      console.log('handleAddInput - newInput:', newInput);
      onInputsChange([...inputs, newInput]);
      setInputType('');
      setShowInputForm(false);
    }
  };

  const handleInputChange = (index, field, value) => {
    const updatedInputs = inputs.map((input, i) => (i === index ? { ...input, [field]: value } : input));
    console.log('handleInputChange - updatedInputs:', updatedInputs);
    onInputsChange(updatedInputs);
  };

  const handleRemoveInput = (index) => {
    const updatedInputs = inputs.filter((_, i) => i !== index);
    console.log('handleRemoveInput - updatedInputs:', updatedInputs);
    onInputsChange(updatedInputs);
  };

  console.log('RenderInputs - inputs:', inputs);
  console.log('RenderInputs - inputErrors:', inputErrors);

  return (
    <Box>
      <Typography variant="h5" fontWeight="bold" mb={3} align="center" style={{ marginTop: '20px' }}>
        {t('RenderInputs.title')}
      </Typography>
      {Array.isArray(inputs) && inputs.map((input, index) => (
        <Input
          key={index}
          input={input}
          onChange={(field, value) => handleInputChange(index, field, value)}
          onRemove={() => handleRemoveInput(index)}
          error={inputErrors[index]}
        />
      ))}
      {showMessage && <div style={{ textAlign: 'center', fontSize: '1em', marginTop: '10px' }}>{messageText}</div>}
      <Box mt={4} display="flex" justifyContent="center">
        <Button variant="contained" color="primary" onClick={() => setShowInputForm(true)}>
          <AddIcon /> {t('RenderInputs.add_input_button')}
        </Button>
      </Box>
      {showInputForm && (
        <Box mt={2}>
          <Select value={inputType} onChange={(e) => setInputType(e.target.value)} fullWidth>
            <MenuItem value="boolean">{t('RenderInputs.input_type.boolean')}</MenuItem>
            <MenuItem value="slider">{t('RenderInputs.input_type.slider')}</MenuItem>
            <MenuItem value="varchar">{t('RenderInputs.input_type.varchar')}</MenuItem>
            <MenuItem value="checkbox">{t('RenderInputs.input_type.checkbox')}</MenuItem>
            <MenuItem value="radio">{t('RenderInputs.input_type.radio')}</MenuItem>
            <MenuItem value="file">{t('RenderInputs.input_type.file')}</MenuItem>
            <MenuItem value="date">{t('RenderInputs.input_type.date')}</MenuItem>
            <MenuItem value="dropdown">{t('RenderInputs.input_type.dropdown')}</MenuItem>
            <MenuItem value="time">{t('RenderInputs.input_type.time')}</MenuItem>
            <MenuItem value="number">{t('RenderInputs.input_type.number')}</MenuItem>
          </Select>
          <Box display="flex" justifyContent="center" mt={2}>
            <Button variant="contained" color="primary" onClick={handleAddInput}>
              {t('RenderInputs.add_button')}
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default RenderInputs;
