import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Box, TextField, Button, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton, Divider } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveIcon from '@mui/icons-material/Remove';
import { useGetAllFeedbackMutation, useUpdateFeedbackLocationMutation, useGetFeedbacksByNodeMutation } from "state/api";
import { useSelector } from 'react-redux';

const FeedbackModal = ({ open, onClose, ID_Noeud, currentFeedbacks, refreshTree }) => {
  const { t } = useTranslation(); // Initialize translation hook
  const [searchFeedback, setSearchFeedback] = useState('');
  const [searchResultsFeedback, setSearchResultsFeedback] = useState([]);
  const [selectedFeedbacks, setSelectedFeedbacks] = useState([]);
  const [getAllFeedback] = useGetAllFeedbackMutation();
  const [updateFeedbackLocation] = useUpdateFeedbackLocationMutation();
  const [getFeedbacksByNode] = useGetFeedbacksByNodeMutation();

  const userSession = useSelector((state) => state.adminAuth);

  useEffect(() => {
    const fetchFeedbacks = async () => {
      if (open) {
        const allFeedbackResponse = await getAllFeedback({ ID_Etablissement: userSession.ID_Etablissement }).unwrap();
        const nodeFeedbackResponse = await getFeedbacksByNode({ ID_Etablissement: userSession.ID_Etablissement, ID_Noeud }).unwrap();

        setSelectedFeedbacks(nodeFeedbackResponse);

        const feedbacksWithStatus = allFeedbackResponse.map(f => ({
          ...f,
          added: nodeFeedbackResponse.some(nf => nf.ID_Feedback === f.ID_Feedback)
        }));

        setSearchResultsFeedback(feedbacksWithStatus);
      }
    };

    fetchFeedbacks();
  }, [open, userSession, getAllFeedback, getFeedbacksByNode, ID_Noeud]);

  const handleAddFeedback = (feedback) => {
    setSelectedFeedbacks([...selectedFeedbacks, feedback]);
    setSearchResultsFeedback(searchResultsFeedback.filter(f => f.ID_Feedback !== feedback.ID_Feedback));
  };

  const handleRemoveFeedback = (feedback) => {
    setSelectedFeedbacks(selectedFeedbacks.filter(f => f.ID_Feedback !== feedback.ID_Feedback));
    setSearchResultsFeedback([...searchResultsFeedback, feedback]);
  };

  const handleSaveFeedbacks = async () => {
    try {
      for (const feedback of selectedFeedbacks) {
        if (!currentFeedbacks.some(cf => cf.ID_Feedback === feedback.ID_Feedback)) {
          await updateFeedbackLocation({ ID_Feedback: feedback.ID_Feedback, ID_Noeud, action: 'ajout' }).unwrap();
        }
      }
      for (const feedback of currentFeedbacks) {
        if (!selectedFeedbacks.some(sf => sf.ID_Feedback === feedback.ID_Feedback)) {
          await updateFeedbackLocation({ ID_Feedback: feedback.ID_Feedback, ID_Noeud, action: 'suppression' }).unwrap();
        }
      }
      onClose();
      refreshTree();
    } catch (err) {
      if (err.originalStatus === 401) {
        alert(t('session_expired'));
        window.location = '/loginuser';
      }
    }
  };

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        <h2 id="modal-modal-title">{t('FeedbackModal.title')}</h2>
        <TextField
          label={t('FeedbackModal.feedback_name_label')}
          variant="outlined"
          fullWidth
          margin="normal"
          value={searchFeedback}
          onChange={(e) => setSearchFeedback(e.target.value)}
        />
        <List>
          {searchResultsFeedback.filter(feedback => !selectedFeedbacks.some(sf => sf.ID_Feedback === feedback.ID_Feedback)).map(feedback => (
            <ListItem key={feedback.ID_Feedback} button>
              <ListItemText primary={feedback.Nom_Feedback} />
              <ListItemSecondaryAction>
                <IconButton edge="end" onClick={() => handleAddFeedback(feedback)}>
                  <AddCircleOutlineIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
        <Divider sx={{ my: 2 }} />
        <List>
          {selectedFeedbacks.map(feedback => (
            <ListItem key={feedback.ID_Feedback}>
              <ListItemText primary={feedback.Nom_Feedback} />
              <ListItemSecondaryAction>
                <IconButton edge="end" onClick={() => handleRemoveFeedback(feedback)}>
                  <RemoveIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
        <Button variant="contained" color="primary" onClick={handleSaveFeedbacks} sx={{ mt: 2 }}>
          {t('FeedbackModal.validate_button')}
        </Button>
        <Button variant="outlined" onClick={onClose} sx={{ mt: 2, ml: 2 }}>
          {t('FeedbackModal.cancel_button')}
        </Button>
      </Box>
    </Modal>
  );
};

export default FeedbackModal;
