import React, { useState } from 'react';
import { Typography, Button, FormControl, Select, InputLabel, MenuItem } from '@mui/material';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const StyledSection = styled('div')`
  width: 57.6%;
  margin: 14.4px auto;
  padding: 14.4px;
  background-color: ${(props) => props.couleur_de_fond || '#FFFFFF'};
  color: ${(props) => props.couleur_ecriture || '#000000'};
  border-radius: 7.2px;
  box-shadow: 3.6px 3.6px 7.2px ${(props) => props.couleur_dombre_de_case || 'rgba(0, 0, 0, 0.1)'};
  position: relative;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to bottom right, rgba(255, 255, 255, 0.5), transparent);
    z-index: 0;
  }
`;

const FormControlStyled = styled(FormControl)`
  width: 70%;
  margin-right: 14.4px;
`;

const DeplacementButton = styled(Button)`
  background-color: ${(props) => props.couleur_bouton || '#007bff'} !important;
  color: ${(props) => props.couleur_bouton_ecriture || '#FFFFFF'} !important;
  box-shadow: 3.6px 3.6px 7.2px ${(props) => props.couleur_de_lombre_du_bouton || 'rgba(0, 0, 0, 0.1)'} !important;
`;

const FlexContainer = styled('div')`
  display: flex;
  align-items: center;
  margin-top: 14.4px;
`;

const NoeudSelector = ({ styleProps, noeuds, ID_Etablissement }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedNoeud, setSelectedNoeud] = useState('');

  const handleNoeudChange = (event) => {
    setSelectedNoeud(event.target.value);
  };

  const handleDeplacement = () => {
    if (selectedNoeud) {
      navigate(`/client/${selectedNoeud}/${ID_Etablissement}`);
    }
  };

  const flattenNoeuds = (noeuds, profondeur = 0) => {
    return noeuds.reduce((acc, noeud) => {
      acc.push({ ...noeud, profondeur });
      if (noeud.children) {
        acc = acc.concat(flattenNoeuds(noeud.children, profondeur + 1));
      }
      return acc;
    }, []);
  };

  const flattenedNoeuds = flattenNoeuds(noeuds);

  return (
    <StyledSection
      couleur_de_fond={styleProps.couleur_de_fond}
      couleur_ecriture={styleProps.couleur_ecriture}
      couleur_dombre_de_case={styleProps.couleur_dombre_de_case}
    >
      <Typography variant="h6" style={{ color: styleProps.couleur_ecriture, fontFamily: styleProps.police_de_caractere, fontSize: '0.72em' }}>
        {t('NoeudSelector.title')}
      </Typography>
      <FlexContainer>
        <FormControlStyled>
          <InputLabel id="noeud-select-label" style={{ color: styleProps.couleur_ecriture }}>
            {t('NoeudSelector.select_label')}
          </InputLabel>
          <Select
            labelId="noeud-select-label"
            id="noeud-select"
            value={selectedNoeud}
            onChange={handleNoeudChange}
            style={{ color: styleProps.couleur_ecriture, backgroundColor: styleProps.couleur_de_fond, fontFamily: styleProps.police_de_caractere }}
            MenuProps={{
              PaperProps: {
                style: {
                  backgroundColor: styleProps.couleur_de_fond,
                  color: styleProps.couleur_ecriture,
                  padding: '0 14.4px',
                },
              },
            }}
          >
            {flattenedNoeuds.map((noeud) => (
              <MenuItem
                key={noeud.ID_Noeud}
                value={noeud.ID_Noeud}
                style={{ paddingLeft: noeud.profondeur * 14.4, color: styleProps.couleur_ecriture, fontFamily: styleProps.police_de_caractere }}
              >
                {noeud.Nom_Element}
              </MenuItem>
            ))}
          </Select>
        </FormControlStyled>
        {selectedNoeud && (
          <DeplacementButton
            onClick={handleDeplacement}
            couleur_bouton={styleProps.couleur_bouton}
            couleur_bouton_ecriture={styleProps.couleur_bouton_ecriture}
            couleur_de_lombre_du_bouton={styleProps.couleur_de_lombre_du_bouton}
          >
            {t('NoeudSelector.button')}
          </DeplacementButton>
        )}
      </FlexContainer>
    </StyledSection>
  );
};

export default NoeudSelector;
