import React from 'react';
import { Container, Typography, Box, useMediaQuery, useTheme } from '@mui/material';
import Navbar from '../../components_public/navbar';
import logoHurflym from '../../assets/logo_hurflym_sans_fond_blanc.png';

const PrivacyPolitic = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box sx={{ backgroundColor: '#3e577c', minHeight: '100vh' }}>
      <Navbar />

      <Container maxWidth="md" sx={{ paddingTop: isMobile ? '20%' : '8%', paddingBottom: 8 }}>
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'center', 
          marginBottom: isMobile ? 8 : 4,
          marginTop: isMobile ? 4 : 0
        }}>
          <img src={logoHurflym} alt="Logo Hurflym" style={{ width: '200px' }} />
        </Box>

        <Box sx={{ marginBottom: 4, padding: 4, borderRadius: 2 }}>
          <Typography variant="h4" component="h1" gutterBottom sx={{ fontWeight: 'bold', marginBottom: 2 }}>
            Politique de confidentialité
          </Typography>
          <Typography variant="subtitle1" gutterBottom sx={{ marginBottom: 2 }}>
            Dernière mise à jour : 15 septembre 2024
          </Typography>

          <Typography variant="body1" paragraph sx={{ marginBottom: 3 }}>
            Cette Politique de confidentialité décrit nos politiques et procédures sur la collecte, l'utilisation et la divulgation de vos informations lorsque vous utilisez le Service et vous informe de vos droits à la vie privée et de la manière dont la loi vous protège.
          </Typography>

          <Typography variant="body1" paragraph sx={{ marginBottom: 3 }}>
            Nous utilisons vos données personnelles pour fournir et améliorer le Service. En utilisant le Service, vous acceptez la collecte et l'utilisation d'informations conformément à cette Politique de confidentialité. Cette Politique de confidentialité a été créée à l'aide du Générateur de Politique de confidentialité.
          </Typography>

          <Typography variant="h5" component="h2" gutterBottom sx={{ fontWeight: 'bold', marginBottom: 3 }}>
            Interprétation et Définitions
          </Typography>

          <Typography variant="h6" component="h3" gutterBottom sx={{ fontWeight: 'bold', marginBottom: 2 }}>
            Interprétation
          </Typography>
          <Typography variant="body1" paragraph sx={{ marginBottom: 3 }}>
            Les mots dont la lettre initiale est en majuscule ont des significations définies dans les conditions suivantes. Les définitions suivantes auront la même signification qu'elles apparaissent au singulier ou au pluriel.
          </Typography>

          <Typography variant="h6" component="h3" gutterBottom sx={{ fontWeight: 'bold', marginBottom: 2 }}>
            Définitions
          </Typography>
          <Typography variant="body1" paragraph sx={{ marginBottom: 3 }}>
            Aux fins de cette Politique de confidentialité :
          </Typography>

          <Typography variant="body1" paragraph sx={{ marginBottom: 3 }}>
            <strong>Compte</strong> signifie un compte unique créé pour vous permettre d'accéder à notre Service ou à des parties de notre Service.
          </Typography>
          <Typography variant="body1" paragraph sx={{ marginBottom: 3 }}>
            <strong>Affilié</strong> désigne une entité qui contrôle, est contrôlée par ou est sous contrôle commun avec une partie, où "contrôle" signifie la propriété de 50% ou plus des actions, des participations ou d'autres titres donnant droit de vote pour l'élection des administrateurs ou d'une autre autorité de gestion.
          </Typography>
          <Typography variant="body1" paragraph sx={{ marginBottom: 3 }}>
            <strong>Société</strong> (désignée par "la Société", "Nous", "Notre" ou "Nos" dans cet Accord) fait référence à Hurflym.
          </Typography>
          <Typography variant="body1" paragraph sx={{ marginBottom: 3 }}>
            <strong>Cookies</strong> sont de petits fichiers placés sur votre ordinateur, appareil mobile ou tout autre appareil par un site web, contenant les détails de votre historique de navigation sur ce site web parmi ses nombreuses utilisations.
          </Typography>
          <Typography variant="body1" paragraph sx={{ marginBottom: 3 }}>
            <strong>Pays</strong> fait référence à : France
          </Typography>
          <Typography variant="body1" paragraph sx={{ marginBottom: 3 }}>
            <strong>Appareil</strong> désigne tout appareil pouvant accéder au Service tel qu'un ordinateur, un téléphone portable ou une tablette numérique.
          </Typography>
          <Typography variant="body1" paragraph sx={{ marginBottom: 3 }}>
            <strong>Données personnelles</strong> désigne toute information relative à une personne identifiée ou identifiable.
          </Typography>
          <Typography variant="body1" paragraph sx={{ marginBottom: 3 }}>
            <strong>Service</strong> fait référence au Site web.
          </Typography>
          <Typography variant="body1" paragraph sx={{ marginBottom: 3 }}>
            <strong>Prestataire de services</strong> désigne toute personne physique ou morale qui traite les données pour le compte de la Société. Il fait référence aux sociétés tierces ou aux personnes employées par la Société pour faciliter le Service, fournir le Service au nom de la Société, exécuter des services liés au Service ou aider la Société à analyser l'utilisation du Service.
          </Typography>
          <Typography variant="body1" paragraph sx={{ marginBottom: 3 }}>
            <strong>Service de médias sociaux tiers</strong> désigne tout site web ou tout site web de réseau social par lequel un Utilisateur peut se connecter ou créer un compte pour utiliser le Service.
          </Typography>
          <Typography variant="body1" paragraph sx={{ marginBottom: 3 }}>
            <strong>Données d'utilisation</strong> désigne les données collectées automatiquement, soit générées par l'utilisation du Service, soit par l'infrastructure du Service elle-même (par exemple, la durée d'une visite de page).
          </Typography>
          <Typography variant="body1" paragraph sx={{ marginBottom: 3 }}>
            <strong>Site web</strong> fait référence à Hurflym, accessible depuis hurflym.com
          </Typography>
          <Typography variant="body1" paragraph sx={{ marginBottom: 3 }}>
            <strong>Vous</strong> désigne la personne accédant ou utilisant le Service, ou la société, ou toute autre entité juridique au nom de laquelle cette personne accède ou utilise le Service, selon le cas.
          </Typography>

          <Typography variant="h5" component="h2" gutterBottom sx={{ fontWeight: 'bold', marginBottom: 3 }}>
            Collecte et utilisation de vos données personnelles
          </Typography>

          <Typography variant="h6" component="h3" gutterBottom sx={{ fontWeight: 'bold', marginBottom: 2 }}>
            Types de données collectées
          </Typography>

          <Typography variant="body1" paragraph sx={{ marginBottom: 3 }}>
            <strong>Données personnelles</strong>
          </Typography>
          <Typography variant="body1" paragraph sx={{ marginBottom: 3 }}>
            Lors de l'utilisation de notre Service, nous pouvons vous demander de nous fournir certaines informations personnellement identifiables qui peuvent être utilisées pour vous contacter ou vous identifier. Les informations personnellement identifiables peuvent inclure, mais ne sont pas limitées à :
          </Typography>

          <Typography variant="body1" paragraph sx={{ marginBottom: 3 }}>
            - Adresse e-mail
          </Typography>
          <Typography variant="body1" paragraph sx={{ marginBottom: 3 }}>
            - Prénom et nom de famille
          </Typography>
          <Typography variant="body1" paragraph sx={{ marginBottom: 3 }}>
            - Numéro de téléphone
          </Typography>
          <Typography variant="body1" paragraph sx={{ marginBottom: 3 }}>
            - Adresse, État, Province, Code postal, Ville
          </Typography>
          <Typography variant="body1" paragraph sx={{ marginBottom: 3 }}>
            - Données d'utilisation
          </Typography>

          <Typography variant="h6" component="h3" gutterBottom sx={{ fontWeight: 'bold', marginBottom: 2 }}>
            Données d'utilisation
          </Typography>
          <Typography variant="body1" paragraph sx={{ marginBottom: 3 }}>
            Les Données d'utilisation sont collectées automatiquement lors de l'utilisation du Service. Elles peuvent inclure des informations telles que l'adresse de protocole Internet de votre appareil (par exemple, adresse IP), le type de navigateur, la version du navigateur, les pages de notre Service que vous visitez, l'heure et la date de votre visite, le temps passé sur ces pages, les identifiants uniques des appareils et d'autres données de diagnostic.
          </Typography>

          {/* Ajoutez d'autres sections si nécessaire en suivant cette structure */}
        </Box>
      </Container>
    </Box>
  );
};

export default PrivacyPolitic;
