import React from 'react';
import { useTranslation } from 'react-i18next';
import { Select, MenuItem, FormControl } from '@mui/material';
import ReactCountryFlag from "react-country-flag";
import { useDispatch, useSelector } from 'react-redux';
import { setLanguage } from '../state/languageSlice';

const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const language = useSelector((state) => state.language.language);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng); // Change la langue dans i18next
    dispatch(setLanguage(lng)); // Met à jour l'état global et localStorage
  };

  return (
    <FormControl variant="outlined" sx={{ minWidth: 50, border: 'none', boxShadow: 'none' }}>
      <Select
        value={language} // Utilise la langue stockée dans Redux
        onChange={(e) => changeLanguage(e.target.value)}
        displayEmpty
        inputProps={{ 'aria-label': 'Select Language' }}
        sx={{
          border: 'none',
          boxShadow: 'none',
          '.MuiOutlinedInput-notchedOutline': { border: 0 },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: 0,
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            border: 0,
          },
        }}
      >
        <MenuItem value="en">
          <ReactCountryFlag
            countryCode="GB"
            svg
            style={{
              width: '2em',
              height: '2em',
            }}
            title="GB"
          />
        </MenuItem>
        <MenuItem value="fr">
          <ReactCountryFlag
            countryCode="FR"
            svg
            style={{
              width: '2em',
              height: '2em',
            }}
            title="FR"
          />
        </MenuItem>
      </Select>
    </FormControl>
  );
};

export default LanguageSelector;
