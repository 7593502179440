import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";
import { setUserAuthenticated } from "../state/userAuth"; // Assurez-vous que le chemin d'import est correct
import { setAdminAuthenticated } from "../state/adminAuth"; // Assurez-vous d'importer les actions nécessaires

const PrivateRoute = ({ children }) => {
  const dispatch = useDispatch();
  const isUserAuthenticated = useSelector((state) => state.userAuth.isAuthenticated);
  const isAdminAuthenticated = useSelector((state) => state.adminAuth.isAuthenticated);
  const adminID = useSelector((state) => state.adminAuth.ID_Etablissement);

  let authStatus = localStorage.getItem("isUserAuthenticated");
  const userInfoString = localStorage.getItem("userInfo");
  const adminInfoString = localStorage.getItem("adminInfo");

  if (authStatus === "true" && !isUserAuthenticated) {
    const userInfo = JSON.parse(userInfoString);
    dispatch(setUserAuthenticated({ isAuthenticated: true, user: userInfo }));
  }

  // Tentative de réhydratation de l'état admin si nécessaire
  let adminAuthStatus = localStorage.getItem("isAdminAuthenticated");
  if (adminAuthStatus === "true" && !isAdminAuthenticated) {
    const adminInfo = JSON.parse(adminInfoString);
    dispatch(setAdminAuthenticated({ isAuthenticated: true, admin: adminInfo.ID_Etablissement, roleInfo: adminInfo.roleInfo }));
  }

  // Assurez-vous que l'ID_Etablissement est un nombre
  return isUserAuthenticated && isAdminAuthenticated ? children : <Navigate to="/loginuser" />;
};

export default PrivateRoute;

