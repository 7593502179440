import React, { useState } from "react";
import { Box, useTheme, Button, IconButton, Typography } from "@mui/material";
import Header from "components/Header";
import RenderTypeDactionSG from "components/service_generaux/RenderTypeDactionSG";
import RenderIncidentSG from "components/service_generaux/RenderIncidentSG";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";
import { motion, AnimatePresence } from "framer-motion";

const IncidentSG = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [activeSection, setActiveSection] = useState('incident');
  const [isExpanded, setIsExpanded] = useState(true);
  const [isChildExpanded, setIsChildExpanded] = useState(true);

  const renderSection = () => {
    switch (activeSection) {
      case 'typedaction':
        return <RenderTypeDactionSG isExpanded={isChildExpanded} />;
      case 'incident':
        return <RenderIncidentSG isExpanded={isChildExpanded} />;
      default:
        return <RenderIncidentSG isExpanded={isChildExpanded} />;
    }
  };

  const buttonStyle = (isActive) => ({
    fontSize: '18px',
    fontWeight: '600',
    margin: '8px',
    padding: '14px 28px',
    boxShadow: isActive 
      ? `0px 6px 12px ${theme.palette.neutral.third}33` 
      : `0px 4px 8px ${theme.palette.neutral.third}26`,
    backgroundColor: isActive ? theme.palette.primary.secondary : theme.palette.neutral.secondary,
    color: isActive ? theme.palette.neutral.secondary : theme.palette.primary.main,
    borderRadius: '6px',
    textTransform: 'none',
    transition: 'all 0.3s ease',
    width: '180px',
    '&:hover': {
      backgroundColor: isActive ? theme.palette.primary.third : theme.palette.neutral.dark,
      boxShadow: `0px 8px 16px ${theme.palette.neutral.third}33`,
      transform: 'translateY(-2px)',
    },
  });

  const buttonTextStyle = {
    letterSpacing: '1px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  };

  const handleToggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const handleToggleChildExpand = () => {
    setIsChildExpanded(!isChildExpanded);
  };

  const containerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
    exit: { opacity: 0, y: -20, transition: { duration: 0.5 } }
  };

  return (
    <Box sx={{ marginLeft: "10px", marginRight: "10px" }}>
      <Header title={t('IncidentSG.Title')} subtitle={t('IncidentSG.Subtitle')} />
      {isExpanded && (
        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
          <Button sx={buttonStyle(activeSection === 'typedaction')} onClick={() => setActiveSection('typedaction')}>
            <Typography sx={buttonTextStyle}>{t('IncidentSG.TypeActionButton').toUpperCase()}</Typography>
          </Button>
          <Button sx={buttonStyle(activeSection === 'incident')} onClick={() => setActiveSection('incident')}>
            <Typography sx={buttonTextStyle}>{t('IncidentSG.IncidentButton').toUpperCase()}</Typography>
          </Button>
        </Box>
      )}

      <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '14px', marginTop: '20px' }}>
        <IconButton onClick={handleToggleExpand}>
          {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
        {activeSection === 'incident' && (
          <IconButton onClick={handleToggleChildExpand}>
            {isChildExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        )}
      </Box>
      <AnimatePresence mode="wait">
        <motion.div
          key={activeSection}
          variants={containerVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
        >
          <Box sx={{
            padding: '20px',
          }}>
            {renderSection()}
          </Box>
        </motion.div>
      </AnimatePresence>
    </Box>
  );
};

export default IncidentSG;
