import React, { useState, useEffect } from 'react';
import { Card, IconButton, Tooltip, Modal, Box, TextField, Button, Divider, List, ListItem, ListItemText, ListItemSecondaryAction, Typography, Dialog } from '@mui/material';
import { useTranslation } from 'react-i18next';  // Import useTranslation hook
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ReportIcon from '@mui/icons-material/Report';
import DevicesIcon from '@mui/icons-material/Devices';
import QrCodeIcon from '@mui/icons-material/QrCode';
import QRCodeInformations from '../QRCodeInformations';
import { useTheme } from '@mui/material/styles';

import { 
  useAddEquipementToNoeudSGMutation, 
  useAddSignalementToNoeudSGMutation, 
  useRemoveEquipementFromNoeudSGMutation, 
  useRemoveSignalementFromNoeudSGMutation,
  useGetAllInfoSignalementSGMutation,
  useGetAllInfoEquipementParticulierSGMutation
} from "state/api";
import { useSelector } from 'react-redux';

const TreeNodeSG = ({ data, fetchTreeData }) => {
  const { t } = useTranslation();  // Initialize the useTranslation hook
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(true);
  const [openSignalementModal, setOpenSignalementModal] = useState(false);
  const [openEquipementModal, setOpenEquipementModal] = useState(false);
  const [nomSignalement, setNomSignalement] = useState('');
  const [nomEquipement, setNomEquipement] = useState('');
  const [allSignalements, setAllSignalements] = useState([]);
  const [allEquipements, setAllEquipements] = useState([]);
  const [searchResultsSignalement, setSearchResultsSignalement] = useState([]);
  const [searchResultsEquipement, setSearchResultsEquipement] = useState([]);
  const [selectedSignalements, setSelectedSignalements] = useState([]);
  const [selectedEquipements, setSelectedEquipements] = useState([]);
  const [toRemoveEquipements, setToRemoveEquipements] = useState([]);
  const [openConflictModal, setOpenConflictModal] = useState(false);
  const [conflictEquipement, setConflictEquipement] = useState(null);
  const [conflictEquipementNode, setConflictEquipementNode] = useState(null);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [itemToRemove, setItemToRemove] = useState(null);
  const [removalType, setRemovalType] = useState('');
  const [openQRCodeDialog, setOpenQRCodeDialog] = useState(false);

  const [addEquipementToNoeud] = useAddEquipementToNoeudSGMutation();
  const [addSignalementToNoeud] = useAddSignalementToNoeudSGMutation();
  const [removeEquipementFromNoeud] = useRemoveEquipementFromNoeudSGMutation();
  const [removeSignalementFromNoeud] = useRemoveSignalementFromNoeudSGMutation();
  const [getAllInfoSignalementSG] = useGetAllInfoSignalementSGMutation();
  const [getAllInfoEquipementParticulierSG] = useGetAllInfoEquipementParticulierSGMutation();

  const userSession = useSelector((state) => state.adminAuth);

  useEffect(() => {
    const fetchSignalements = async () => {
      try {
        const response = await getAllInfoSignalementSG({ ID_Etablissement: userSession.ID_Etablissement }).unwrap();
        setAllSignalements(response);
        setSearchResultsSignalement(response);
        setSelectedSignalements(data.signalements || []);
      } catch (error) {
        // Handle error silently
      }
    };
    
    const fetchEquipements = async () => {
      try {
        const response = await getAllInfoEquipementParticulierSG({ ID_Etablissement: userSession.ID_Etablissement }).unwrap();
        setAllEquipements(response);
        setSearchResultsEquipement(response);
        setSelectedEquipements(data.equipements || []);
      } catch (error) {
        // Handle error silently
      }
    };

    fetchSignalements();
    fetchEquipements();
  }, [userSession, getAllInfoSignalementSG, getAllInfoEquipementParticulierSG, data.signalements, data.equipements]);

  useEffect(() => {
    setSearchResultsSignalement(
      allSignalements.filter(s => s.Nom_signalement.toLowerCase().includes(nomSignalement.toLowerCase()))
    );
  }, [nomSignalement, allSignalements]);

  useEffect(() => {
    setSearchResultsEquipement(
      allEquipements.filter(e => e.Nom_Ep.toLowerCase().includes(nomEquipement.toLowerCase()))
    );
  }, [nomEquipement, allEquipements]);

  const handleOpenSignalementModal = () => setOpenSignalementModal(true);
  const handleCloseSignalementModal = () => {
    setOpenSignalementModal(false);
    setNomSignalement('');
  };

  const handleOpenEquipementModal = () => setOpenEquipementModal(true);
  const handleCloseEquipementModal = () => {
    setOpenEquipementModal(false);
    setNomEquipement('');
  };

  const handleAddSignalement = (signalement) => {
    setSelectedSignalements([...selectedSignalements, signalement]);
  };

  const handleAddEquipement = (equipement) => {
    if ((equipement.ID_Noeud !== 0 && equipement.ID_Noeud !== null) && equipement.ID_Noeud !== data.ID_Noeud) {
      const node = data.children.find(child => child.ID_Noeud === equipement.ID_Noeud);
      setConflictEquipement(equipement);
      setConflictEquipementNode(node ? node.Nom_Element : 'Inconnu');
      setOpenConflictModal(true);
    } else {
      setSelectedEquipements([...selectedEquipements, equipement]);
    }
  };

  const handleCloseQRCodeDialog = () => {
    setOpenQRCodeDialog(false);
  };

  const handleShowQRCode = (signalement) => {
    setOpenQRCodeDialog(true);
  };

  const handleSaveSignalement = async () => {
    try {
      for (const signalement of selectedSignalements) {
        const dataForm = {
          ID_Noeud: data.ID_Noeud,
          ID_Signalement_SG: signalement.ID_Signalement_SG,
        };
        await addSignalementToNoeud(dataForm).unwrap();
      }
      handleCloseSignalementModal();
      fetchTreeData();
    } catch (err) {
      if (err.originalStatus === 401) {
        alert('Votre session a expiré. Veuillez vous reconnecter.');
        window.location = '/loginuser';
      }
    }
  };

  const handleSaveEquipement = async () => {
    try {
      for (const equipement of toRemoveEquipements) {
        const dataForm = {
          ID_Noeud: data.ID_Noeud,
          ID_Ep_SG: equipement.ID_Ep_SG,
        };
        await removeEquipementFromNoeud(dataForm).unwrap();
      }
      for (const equipement of selectedEquipements) {
        const dataForm = {
          ID_Noeud: data.ID_Noeud,
          ID_Ep_SG: equipement.ID_Ep_SG,
        };
        await addEquipementToNoeud(dataForm).unwrap();
      }
      handleCloseEquipementModal();
      fetchTreeData();
    } catch (err) {
      if (err.originalStatus === 401) {
        alert('Votre session a expiré. Veuillez vous reconnecter.');
        window.location = '/loginuser';
      }
    }
  };

  const confirmRemove = (type, item) => {
    setRemovalType(type);
    setItemToRemove(item);
    setOpenConfirmationModal(true);
  };

  const handleRemoveSignalement = async () => {
    try {
      const dataForm = {
        ID_Noeud: data.ID_Noeud,
        ID_Signalement_SG: itemToRemove.ID_Signalement_SG,
      };
      await removeSignalementFromNoeud(dataForm).unwrap();
      setSelectedSignalements(selectedSignalements.filter(s => s.ID_Signalement_SG !== itemToRemove.ID_Signalement_SG));
      setOpenConfirmationModal(false);
      fetchTreeData(); // Actualiser l'arbre après la suppression
    } catch (err) {
      if (err.originalStatus === 401) {
        alert('Votre session a expiré. Veuillez vous reconnecter.');
        window.location = '/loginuser';
      }
    }
  };

  const handleRemoveEquipement = async () => {
    try {
      const dataForm = {
        ID_Noeud: data.ID_Noeud,
        ID_Ep_SG: itemToRemove.ID_Ep_SG,
      };
      await removeEquipementFromNoeud(dataForm).unwrap();
      setSelectedEquipements(selectedEquipements.filter(e => e.ID_Ep_SG !== itemToRemove.ID_Ep_SG));
      setToRemoveEquipements([...toRemoveEquipements, itemToRemove]);
      setOpenConfirmationModal(false);
      fetchTreeData(); // Actualiser l'arbre après la suppression
    } catch (err) {
      if (err.originalStatus === 401) {
        alert('Votre session a expiré. Veuillez vous reconnecter.');
        window.location = '/loginuser';
      }
    }
  };

  const handleConfirmRemoval = () => {
    if (removalType === 'signalement') {
      handleRemoveSignalement();
    } else if (removalType === 'equipement') {
      handleRemoveEquipement();
    }
  };

  const handleCancelRemoval = () => {
    setOpenConfirmationModal(false);
    setItemToRemove(null);
    setRemovalType('');
  };

  const handleConfirmConflictEquipement = () => {
    setSelectedEquipements([...selectedEquipements, conflictEquipement]);
    setConflictEquipement(null);
    setConflictEquipementNode(null);
    setOpenConflictModal(false);
  };

  const handleCloseConflictModal = () => {
    setConflictEquipement(null);
    setConflictEquipementNode(null);
    setOpenConflictModal(false);
  };

  const cardStyle = {
    margin: '10px',
    padding: '10px',
    backgroundColor: theme.palette.background.alt,
    borderColor: theme.palette.primary.third,
  };

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90vw',
    maxWidth: '600px',
    maxHeight: '90vh',
    overflow: 'auto',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };

  const listContainerStyle = {
    maxHeight: '150px',
    overflowY: 'auto',
    marginBottom: '16px',
    flex: '1 1 auto',
  };

  const modalContentStyle = {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  };

  const listWrapperStyle = {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: 'hidden',
  };

  const buttonContainerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '16px',
  };

  return (
    <Card variant="outlined" style={cardStyle}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {data.children && data.children.length > 0 && (
          <IconButton onClick={() => setIsOpen(!isOpen)}>
            {isOpen ? <ExpandLessIcon style={{ color: theme.palette.neutral.main }} /> : <ExpandMoreIcon style={{ color: theme.palette.neutral.main }} />}
          </IconButton>
        )}
        <Typography variant="h6" style={{ color: theme.palette.neutral.white }}>{data.Nom_Element}</Typography>
        <div style={{ marginLeft: 'auto' }}>
          <IconButton edge="end" onClick={() => handleShowQRCode(data)}>
            <QrCodeIcon style={{ color: theme.palette.neutral.main }} />
          </IconButton>
          <Tooltip title={t('TreeNodeSG.add_report')}>
            <IconButton onClick={handleOpenSignalementModal}><ReportIcon style={{ color: theme.palette.neutral.main }} /></IconButton>
          </Tooltip>
          <Tooltip title={t('TreeNodeSG.add_equipment')}>
            <IconButton onClick={handleOpenEquipementModal}><DevicesIcon style={{ color: theme.palette.neutral.main }} /></IconButton>
          </Tooltip>
        </div>
      </div>

      {isOpen && (
        <div style={{ paddingLeft: '20px', color: theme.palette.neutral.white }}>
          {data.signalements && data.signalements.length > 0 && (
            <List>
              {data.signalements.map(signalement => (
                <ListItem key={signalement.ID_Signalement_SG}>
                  <ListItemText primary={`${signalement.Nom_signalement} (${t('TreeNodeSG.selected_reports')})`} />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" onClick={() => confirmRemove('signalement', signalement)}>
                      <RemoveIcon style={{ color: theme.palette.neutral.main }} />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          )}
          {data.equipements && data.equipements.length > 0 && (
            <List>
              {data.equipements.map(equipement => (
                <ListItem key={equipement.ID_Ep_SG}>
                  <ListItemText primary={`${equipement.Nom_Ep} (${t('TreeNodeSG.selected_equipments')})`} />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" onClick={() => confirmRemove('equipement', equipement)}>
                      <RemoveIcon style={{ color: theme.palette.neutral.main }} />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          )}
          {data.children && data.children.length > 0 && (
            <div>
              {data.children.map(child => <TreeNodeSG key={child.ID_Noeud} data={child} fetchTreeData={fetchTreeData} />)}
            </div>
          )}
        </div>
      )}

      <Modal
        open={openSignalementModal}
        onClose={handleCloseSignalementModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <div style={modalContentStyle}>
            <Typography id="modal-modal-title" variant="h6">{t('TreeNodeSG.add_report')} :</Typography>
            <TextField
              label={t('TreeNodeSG.report_name')}
              variant="outlined"
              fullWidth
              margin="normal"
              value={nomSignalement}
              onChange={(e) => setNomSignalement(e.target.value)}
            />
            <div style={listWrapperStyle}>
              <div style={listContainerStyle}>
                <Typography variant="subtitle1">{t('TreeNodeSG.search_results')} :</Typography>
                <List>
                  {searchResultsSignalement.map(signalement => (
                    <ListItem key={signalement.ID_Signalement_SG} button>
                      <ListItemText primary={signalement.Nom_signalement} />
                      <ListItemSecondaryAction>
                        <IconButton edge="end" onClick={() => handleAddSignalement(signalement)}>
                          <AddIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
              </div>
              <Divider sx={{ my: 2 }} />
              <div style={listContainerStyle}>
                <Typography variant="subtitle1">{t('TreeNodeSG.selected_reports')} :</Typography>
                <List>
                  {selectedSignalements.map(signalement => (
                    <ListItem key={signalement.ID_Signalement_SG}>
                      <ListItemText primary={signalement.Nom_signalement} />
                      <ListItemSecondaryAction>
                        <IconButton edge="end" onClick={() => confirmRemove('signalement', signalement)}>
                          <RemoveIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
              </div>
            </div>
            <div style={buttonContainerStyle}>
              <Button variant="contained" color="neutral" onClick={handleSaveSignalement} sx={{ flex: '1 1 50%' }}>
                {t('TreeNodeSG.validate')}
              </Button>
              <Button variant="outlined" color="neutral" onClick={handleCloseSignalementModal} sx={{ flex: '1 1 50%', ml: 2 }}>
                {t('TreeNodeSG.cancel')}
              </Button>
            </div>
          </div>
        </Box>
      </Modal>

      <Modal
        open={openEquipementModal}
        onClose={handleCloseEquipementModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <div style={modalContentStyle}>
            <Typography id="modal-modal-title" variant="h6">{t('TreeNodeSG.add_equipment')} :</Typography>
            <TextField
              label={t('TreeNodeSG.equipment_name')}
              variant="outlined"
              fullWidth
              margin="normal"
              value={nomEquipement}
              onChange={(e) => setNomEquipement(e.target.value)}
            />
            <div style={listWrapperStyle}>
              <div style={listContainerStyle}>
                <Typography variant="subtitle1">{t('TreeNodeSG.search_results')} :</Typography>
                <List>
                  {searchResultsEquipement.map(equipement => (
                    <ListItem key={equipement.ID_Ep_SG} button>
                      <ListItemText primary={equipement.Nom_Ep} />
                      <ListItemSecondaryAction>
                        <IconButton edge="end" onClick={() => handleAddEquipement(equipement)}>
                          <AddIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
              </div>
              <Divider sx={{ my: 2 }} />
              <div style={listContainerStyle}>
                <Typography variant="subtitle1">{t('TreeNodeSG.selected_equipments')} :</Typography>
                <List>
                  {selectedEquipements.map(equipement => (
                    <ListItem key={equipement.ID_Ep_SG}>
                      <ListItemText primary={equipement.Nom_Ep} />
                      <ListItemSecondaryAction>
                        <IconButton edge="end" onClick={() => confirmRemove('equipement', equipement)}>
                          <RemoveIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
              </div>
            </div>
            <div style={buttonContainerStyle}>
              <Button variant="contained" color="neutral" onClick={handleSaveEquipement} sx={{ flex: '1 1 50%' }}>
                {t('TreeNodeSG.validate')}
              </Button>
              <Button variant="outlined" color="neutral" onClick={handleCloseEquipementModal} sx={{ flex: '1 1 50%', ml: 2 }}>
                {t('TreeNodeSG.cancel')}
              </Button>
            </div>
          </div>
        </Box>
      </Modal>

      <Modal
        open={openConflictModal}
        onClose={handleCloseConflictModal}
        aria-labelledby="conflict-modal-title"
        aria-describedby="conflict-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="conflict-modal-title" variant="h6">{t('TreeNodeSG.conflict_title')}</Typography>
          <Typography>
            {t('TreeNodeSG.conflict_message', { 0: conflictEquipement?.Nom_Ep, 1: conflictEquipementNode })}
          </Typography>
          <div style={buttonContainerStyle}>
            <Button variant="contained" color="neutral" onClick={handleConfirmConflictEquipement} sx={{ flex: '1 1 50%' }}>
              {t('TreeNodeSG.validate')}
            </Button>
            <Button variant="outlined" color="neutral" onClick={handleCloseConflictModal} sx={{ flex: '1 1 50%', ml: 2 }}>
              {t('TreeNodeSG.cancel')}
            </Button>
          </div>
        </Box>
      </Modal>

      <Modal
        open={openConfirmationModal}
        onClose={handleCancelRemoval}
        aria-labelledby="confirmation-modal-title"
        aria-describedby="confirmation-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="confirmation-modal-title" variant="h6">{t('TreeNodeSG.remove_confirmation_title')}</Typography>
          <Typography>
            {t('TreeNodeSG.remove_confirmation_message', { 0: removalType === 'signalement' ? t('TreeNodeSG.selected_reports') : t('TreeNodeSG.selected_equipments'), 1: itemToRemove?.Nom_signalement || itemToRemove?.Nom_Ep })}
          </Typography>
          <div style={buttonContainerStyle}>
            <Button variant="contained" color="neutral" onClick={handleConfirmRemoval} sx={{ flex: '1 1 50%' }}>
              {t('TreeNodeSG.yes')}
            </Button>
            <Button variant="outlined" color="neutral" onClick={handleCancelRemoval} sx={{ flex: '1 1 50%', ml: 2 }}>
              {t('TreeNodeSG.no')}
            </Button>
          </div>
        </Box>
      </Modal>

      <Dialog
        open={openQRCodeDialog}
        onClose={handleCloseQRCodeDialog}
        aria-labelledby="qr-code-dialog-title"
        PaperProps={{
          style: {
            borderRadius: '16px',
            padding: '20px',
            margin: '20px'
          }
        }}
      >
        <QRCodeInformations ID_Noeud={data.ID_Noeud} ID_Etablissement={data.ID_Etablissement} signalement={true} />
      </Dialog>

    </Card>
  );
};

export default TreeNodeSG;
