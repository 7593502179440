import React from 'react';
import { Typography } from '@mui/material';
import { styled } from '@mui/system';

// Utiliser la variable d'environnement REACT_APP_BASE_URL pour définir la base de l'URL
const BASE_URL = process.env.REACT_APP_BASE_URL || 'http://localhost:5001';

const StyledPageContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '35vh',
  flexDirection: 'column',
});

const StyledContainer = styled('div')(({ theme, style }) => ({
  width: '250px',
  height: 'auto',
  marginBottom: '20px',
  borderRadius: '10px',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '20px',
  overflow: 'hidden',
  boxShadow: `5px 5px 10px rgba(0, 0, 0, 0.2)`,
  cursor: 'pointer',
  '& > img': {
    maxWidth: '80%',
    maxHeight: '80%',
    marginBottom: '10px',
    zIndex: 1,
  },
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'linear-gradient(to bottom right, rgba(255, 255, 255, 0.5), transparent)',
    zIndex: 0,
  },
  ...style,
}));

const CategorieDisplay = ({ styleProps, categorie, srcImage, ID_Image }) => {
  // Utilisation de REACT_APP_BASE_URL pour l'URL de l'image
  const urlImage = styleProps
    ? `${BASE_URL}/public/imageequipement/${srcImage}-${ID_Image}.png`
    : `${BASE_URL}/public/imageequipement/2-0.png`;

  const dynamicStyles = {
    backgroundColor: styleProps ? styleProps.couleur_de_case : '#000',
    color: styleProps ? styleProps.couleur_ecriture : '#FFF',
    boxShadow: `5px 5px 10px ${styleProps ? styleProps.couleur_dombre_de_case : '#000'}`,
  };

  return (
    <StyledPageContainer>
      <StyledContainer style={dynamicStyles}>
        <img src={urlImage} alt="Catégorie" />
        <Typography
          variant="body1"
          style={{
            color: styleProps ? styleProps.couleur_ecriture : '#000',
            fontFamily: styleProps ? styleProps.police_de_caractere : 'sans-serif',
          }}
        >
          {categorie}
        </Typography>
      </StyledContainer>
    </StyledPageContainer>
  );
};

export default CategorieDisplay;
