import React, { useState, useEffect } from 'react';
import { Container, Typography } from '@mui/material';
import CategorieButton from './FormulaireBouton'; // Assurez-vous que le chemin d'import est correct
import PrecedentButton from './ForumulaireBoutonPrecedent';
import FormulaireBoutonSuivant from './FormulaireBoutonSuivant';
import NombreParticulesComponent from './FormulaireNombreParticules';

const FormulaireCategorieSecondaire = ({ style, categorie_secondaire, shown, setShown, setCategorieSecondaire, liste_equipement, setDataSignalement, dataSignalement }) => {
  const [equipements, setEquipements] = useState([]);
  const [nombreParticules, setNombreParticules] = useState({});
  
  useEffect(() => {
    // Filtrer les équipements qui appartiennent à la catégorie secondaire actuelle
    if (liste_equipement) {
      const equipementsFiltres = liste_equipement.filter(equipement => equipement.ID_Categorie_Equipement === categorie_secondaire)
        .map(equipement => ({
          ID_Categorie_Equipement: equipement.ID_Categorie_Equipement,
          Nom_Equipement: equipement.Nom_Equipement,
          ID_Image_Equipement: equipement.ID_Image_Equipement,
          ID_Equipement: equipement.ID_Equipement,
          Nombre_particules: equipement.Nombre_particules,
          // Vous pouvez ajouter d'autres propriétés ici si nécessaire
        }));
      setEquipements(equipementsFiltres);
    }
  }, [liste_equipement, categorie_secondaire]);

  const handleEquipementClick = (equipement) => {
    const isEquipementPresent = dataSignalement.some(item => item.ID_Equipement === equipement.ID_Equipement);
    if (isEquipementPresent) {
      const updatedDataSignalement = dataSignalement.filter(item => item.ID_Equipement !== equipement.ID_Equipement);
      setDataSignalement(updatedDataSignalement);
    } else {
      const updatedDataSignalement = [...dataSignalement, { ID_Equipement: equipement.ID_Equipement, Nombre_particules: 1 }];
      setDataSignalement(updatedDataSignalement);
    }
  };

  const handleNombreParticulesChange = (equipementId, value) => {
    setDataSignalement((prevDataSignalement) =>
      prevDataSignalement.map((item) =>
        item.ID_Equipement === equipementId ? { ...item, Nombre_particules: value } : item
      )
    );
  };

  return (
    <Container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
      {shown === "S" && (
        <>
          <div style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'column', justifyContent: 'center' }}>
            {equipements.map((equipement, index) => (
              <div key={index}>
                <CategorieButton
                  styleProps={style}
                  categorie={equipement.Nom_Equipement}
                  onClick={() => { handleEquipementClick(equipement) }}
                  srcImage={equipement.ID_Categorie_Equipement}
                  ID_Image={equipement.ID_Image_Equipement}
                  shown={shown}
                  ID_Equipement={equipement.ID_Equipement}
                  dataSignalement={dataSignalement}
                />
                {dataSignalement.some(item => item.ID_Equipement === equipement.ID_Equipement) && equipement.Nombre_particules !== 1 && (
                  <NombreParticulesComponent
                    equipement={equipement}
                    nombreParticules={
                      dataSignalement.find(item => item.ID_Equipement === equipement.ID_Equipement)?.Nombre_particules || 1
                    }
                    onNombreParticulesChange={(value) => handleNombreParticulesChange(equipement.ID_Equipement, value)}
                    maxParticules={equipement.Nombre_particules}
                  />
                )}
              </div>
            ))}
            <PrecedentButton styleProps={style} setCategorieSecondaire={setCategorieSecondaire} setShown={setShown} shown={shown} />
            <FormulaireBoutonSuivant styleProps={style} setShown={setShown} />
          </div>
        </>
      )}
    </Container>
  );
};

export default FormulaireCategorieSecondaire;
