import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <Box component="footer" sx={{ py: 3, mt: 4, bgcolor: 'background.paper', textAlign: 'center' }}>
      <Typography variant="body2" color="text.secondary">
        © {new Date().getFullYear()} HURFLYM - {t('AcceuilPublic.footerText')}
      </Typography>
    </Box>
  );
};

export default Footer;
