import React from 'react';
import styled from '@emotion/styled';
import { Typography } from '@mui/material';

// Utilisation des variables d'environnement pour les URLs
const BASE_URL = process.env.REACT_APP_BASE_URL || 'http://localhost:5001';

// Styled components for custom styling
const Card = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 16px;
  background-color: ${(props) => props.couleur_de_case || '#FFFFFF'};
  color: ${(props) => props.couleur_ecriture || '#000000'};
  box-shadow: 5px 5px 10px ${(props) => props.couleur_dombre_de_case || 'rgba(0, 0, 0, 0.1)'};
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.2s;
  height: 237.5px;

  &:hover {
    transform: scale(1.05);
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom right, rgba(255, 255, 255, 0.5), transparent);
    z-index: 0;
  }
`;

const Image = styled('img')`
  width: 120px;
  height: 120px;
  object-fit: contain;
  margin-bottom: 8px;
  z-index: 1;
  border-radius: 10px;
  filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.3));
`;

const Title = styled(Typography)`
  color: ${(props) => props.couleur_bouton_ecriture || '#000000'};
  font-family: ${(props) => props.police_de_caractere || 'sans-serif'};
  font-weight: bold;
  z-index: 1;
  font-size: 0.9em;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

const EquipementCard = ({
  equipement,
  onClick,
  couleur_de_case,
  couleur_ecriture,
  couleur_dombre_de_case,
  couleur_bouton_ecriture,
  police_de_caractere
}) => {
  const imageUrl = equipement.ID_Equipement_IT
    ? `${BASE_URL}/images/displayimagecategoriesignalementIT/${equipement.Lien_Image_Equipement}`
    : `${BASE_URL}/images/displayimagecategoriesignalementSG/${equipement.Lien_Image_Equipement}`;

  return (
    <Card
      couleur_de_case={couleur_de_case}
      couleur_ecriture={couleur_ecriture}
      couleur_dombre_de_case={couleur_dombre_de_case}
      onClick={onClick}
    >
      <Image
        src={imageUrl}
        alt={equipement.Nom_Equipement}
      />
      <Title
        variant="body1"
        couleur_bouton_ecriture={couleur_bouton_ecriture}
        police_de_caractere={police_de_caractere}
      >
        {equipement.Nom_Equipement}
      </Title>
    </Card>
  );
};

export default EquipementCard;
