import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAuthenticated: false,
  ID_Etablissement: null,
  roleInfo: null,
  loginTime: null,
  abonnementType: null, // Ajout du type d'abonnement
};

const adminAuthSlice = createSlice({
  name: "adminAuth",
  initialState,
  reducers: {
    setAdminAuthenticated: (state, action) => {
      state.isAuthenticated = action.payload.isAuthenticated;
      state.ID_Etablissement = action.payload.ID_Etablissement;
      state.roleInfo = action.payload.roleInfo;
      state.loginTime = Date.now(); // Set login time to current time
      state.abonnementType = action.payload.abonnementType; // Stockage du type d'abonnement
    },
    adminLogout: (state) => {
      state.isAuthenticated = false;
      state.ID_Etablissement = null;
      state.roleInfo = null;
      state.loginTime = null;
      state.abonnementType = null; // Réinitialisation du type d'abonnement
    },
  },
});

export const { setAdminAuthenticated, adminLogout } = adminAuthSlice.actions;

export default adminAuthSlice.reducer;
