import React, { useEffect, useState } from 'react';
import { Container, Typography, Button } from '@mui/material';
import { styled } from '@mui/system';
import CategorieButton from './FormulaireBouton';


const FormulaireAcceuil = ({ style, categorie_principale, categorie_secondaire, shown, setShown, setCategoriePrincipale, liste_complete_categorie, liste_categorie_principale }) => {
  const [categoriesPrincipales, setCategoriesPrincipales] = useState([]);

  useEffect(() => {
    // Effect to fetch attributes of categories from liste_complete_categorie
    const categories = liste_categorie_principale.map(categoryId => {
      const category = liste_complete_categorie.find(cat => cat.ID_Categorie === categoryId);
      return { ID_Categorie: category.ID_Categorie, Nom_Categorie: category.Nom_Categorie };
    });
    setCategoriesPrincipales(categories);
  }, [liste_complete_categorie, liste_categorie_principale]);

  const handleCategoryClick = (category) => {
    setShown("P");
    setCategoriePrincipale(category.ID_Categorie);
  };

  return (
    <Container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      {shown === "A" && (
        <div>
          {categoriesPrincipales.map((category, index) => (
            <CategorieButton styleProps={style}  categorie={category.Nom_Categorie} onClick={() => handleCategoryClick(category)} srcImage={category.ID_Categorie} setCategoriePrincipale={setCategoriePrincipale} shown={shown}/>
          ))}
        </div>
      )}
    </Container>
  );
};

export default FormulaireAcceuil;

