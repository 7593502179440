import React, { useState } from 'react';
import { AppBar, Toolbar, Button, Typography, useScrollTrigger, CssBaseline, Link, Box, IconButton, Menu, MenuItem, Drawer, List, ListItem, ListItemText } from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { useLogoutMutation } from 'state/api';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { userLogout } from 'state/userAuth';
import LanguageIcon from '@mui/icons-material/Language';
import HomeIcon from '@mui/icons-material/Home';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import logoHurflym from '../assets/logo_hurflym_sans_fond.png';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';


function ElevationScroll(props) {
  const { children } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

const CustomButton = styled(Button)(({ theme }) => ({
  color: '#fff',
  backgroundColor: theme.palette.secondary.main,
  fontFamily: 'Montserrat, sans-serif',
  fontWeight: 'bold',
  margin: theme.spacing(1),
  width: '80%',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.palette.secondary.dark,
    transform: 'scale(1.05)',
    transition: 'background-color 0.3s, transform 0.3s',
  },
}));

const NavLink = styled(Link)(({ theme }) => ({
  color: 'black',
  fontFamily: 'Montserrat, sans-serif',
  fontWeight: 'bold',
  margin: theme.spacing(1.5),
  textDecoration: 'none',
  fontSize: '0.9rem',
  transition: 'color 0.3s ease',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  whiteSpace: 'nowrap',
  '&:hover': {
    textDecoration: 'none',
    color: theme.palette.secondary.light,
  },
  '@media (max-width: 1200px)': {
    fontSize: '0.8rem',
    margin: theme.spacing(1),
  }
}));

const LogoutButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  color: '#fff',
  fontWeight: 'bold',
  borderRadius: '20px',
  padding: '8px 16px',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.palette.secondary.dark,
  },
}));

export default function NavbarUtilisateur() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [logout] = useLogoutMutation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileOpen, setMobileOpen] = useState(false);
  const open = Boolean(anchorEl);
  const theme = useTheme();
  const isMobile = useMediaQuery('(max-width:900px)'); // Changé pour 900px

  const { user } = useSelector((state) => state.userAuth);

  const handleLanguageMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLanguageMenuClose = (lang) => {
    setAnchorEl(null);
    if (lang) {
      i18n.changeLanguage(lang);
    }
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLogout = async () => {
    try {
      await logout().unwrap();
      dispatch(userLogout());
      navigate('/accueilpublic');
    } catch (error) {
      console.error('Erreur lors de la déconnexion:', error);
    }
  };

  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      sx={{ textAlign: 'center', backgroundColor: 'white', height: '100%', display: 'flex', flexDirection: 'column' }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', my: 2 }}>
        <Link href="/" underline="none" sx={{ display: 'flex', alignItems: 'center' }}>
          <img src={logoHurflym} alt="Logo Hurflym" style={{ height: '40px', marginRight: '10px' }} />
          <Typography
            variant="h6"
            component="div"
            sx={{
              color: 'black',
              fontFamily: 'Montserrat, sans-serif',
              fontWeight: 'bold',
              letterSpacing: '0.3rem',
              textTransform: 'uppercase',
              cursor: 'pointer',
            }}
          >
            HURFLYM
          </Typography>
        </Link>
      </Box>
      <List sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <ListItem button onClick={() => navigate('/infoutilisateur')} sx={{ cursor: 'pointer' }}>
          <ListItemText primary={t('NavbarUtilisateur.PersonalInfo')} sx={{ color: 'black' }} />
        </ListItem>
        <ListItem button onClick={() => navigate('/listeetablissement')} sx={{ cursor: 'pointer' }}>
          <ListItemText primary={t('NavbarUtilisateur.Establishments')} sx={{ color: 'black' }} />
        </ListItem>
        <ListItem button onClick={() => navigate('/abonnement')} sx={{ cursor: 'pointer' }}>
          <ListItemText primary={t('NavbarUtilisateur.ServicesBilling')} sx={{ color: 'black' }} />
        </ListItem>
        <ListItem button onClick={() => navigate('/listetickets')} sx={{ cursor: 'pointer' }}>
          <ListItemText primary="Tickets" sx={{ color: 'black' }} />
        </ListItem>
      </List>
      <Box sx={{ mb: 2, display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
        <CustomButton onClick={handleLogout} fullWidth sx={{ mb: 1 }}>{t('NavbarUtilisateur.Logout')}</CustomButton>
        <IconButton onClick={handleLanguageMenuClick} sx={{ color: theme.palette.neutral.main, mt: 1, cursor: 'pointer' }}>
          <LanguageIcon />
        </IconButton>
      </Box>
    </Box>
  );

  return (
    <>
      <CssBaseline />
      <ElevationScroll>
        <AppBar 
          position="fixed" 
          sx={{
            background: 'rgba(255, 255, 255, 0.5)', 
            backdropFilter: 'blur(10px)',
            borderRadius: '20px',
            margin: '20px',
            width: 'calc(100% - 40px)',
            top: '10px'
          }}
        >
          <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', padding: '0 20px', minHeight: '64px' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', width: isMobile ? '80%' : '25%' }}>
              <Link href="/" underline="none" sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                <img src={logoHurflym} alt="Logo Hurflym" style={{ height: '40px', marginRight: '10px' }} />
                <Typography
                  variant="h6"
                  component="div"
                  sx={{
                    color: 'black',
                    fontFamily: 'Montserrat, sans-serif',
                    fontWeight: 'bold',
                    letterSpacing: '0.3rem',
                    textTransform: 'uppercase',
                    cursor: 'pointer',
                  }}
                >
                  HURFLYM
                </Typography>
              </Link>
            </Box>

            {isMobile ? (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton
                  onClick={handleLanguageMenuClick}
                  sx={{ color: theme.palette.neutral.main, mr: 1, cursor: 'pointer' }}
                >
                  <LanguageIcon />
                </IconButton>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  sx={{ cursor: 'pointer' }}
                >
                  <MenuIcon />
                </IconButton>
              </Box>
            ) : (
              <>
                <Box sx={{ 
                  display: 'flex', 
                  justifyContent: 'center', 
                  alignItems: 'center',
                  width: '50%',
                  height: '64px'
                }}>
                  <NavLink onClick={() => navigate('/infoutilisateur')}>{t('NavbarUtilisateur.PersonalInfo')}</NavLink>
                  <NavLink onClick={() => navigate('/listeetablissement')}>{t('NavbarUtilisateur.Establishments')}</NavLink>
                  <NavLink onClick={() => navigate('/abonnement')}>{t('NavbarUtilisateur.ServicesBilling')}</NavLink>
                  <NavLink onClick={() => navigate('/listetickets')}>Tickets</NavLink>
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '25%' }}>
                  <LogoutButton onClick={handleLogout} sx={{ marginRight: 2 }}>
                    {t('NavbarUtilisateur.Logout')}
                  </LogoutButton>

                  <IconButton onClick={handleLanguageMenuClick} sx={{ color: theme.palette.neutral.main, cursor: 'pointer' }}>
                    <LanguageIcon />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={() => handleLanguageMenuClose(null)}
                  >
                    <MenuItem onClick={() => handleLanguageMenuClose('fr')} sx={{ cursor: 'pointer' }}>Français</MenuItem>
                    <MenuItem onClick={() => handleLanguageMenuClose('en')} sx={{ cursor: 'pointer' }}>English</MenuItem>
                  </Menu>
                </Box>
              </>
            )}
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <Box component="nav">
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '100%' },
          }}
        >
          <IconButton
            onClick={handleDrawerToggle}
            sx={{ position: 'absolute', right: 8, top: 8, color: 'black', cursor: 'pointer' }}
          >
            <CloseIcon />
          </IconButton>
          {drawer}
        </Drawer>
      </Box>
    </>
  );
}
