import React from 'react';
import styled from '@emotion/styled';
import { Typography } from '@mui/material';

// Utiliser la variable d'environnement REACT_APP_BASE_URL pour définir la base de l'URL
const BASE_URL = process.env.REACT_APP_BASE_URL || 'http://localhost:5001';


const Card = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Centre verticalement le contenu */
  text-align: center;
  padding: 12px;
  background-color: ${(props) => props.couleur_de_case || '#FFFFFF'};
  color: ${(props) => props.couleur_ecriture || '#000000'};
  box-shadow: 5px 5px 10px ${(props) => props.couleur_dombre_de_case || 'rgba(0, 0, 0, 0.1)'};
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.2s;
  height: 90%; /* Prend 90% de la hauteur disponible */
  width: calc(90% * 0.9); /* Proportion plus carrée, en gardant une légère différence entre largeur et hauteur */
  margin-top: 2%; /* Ajoute un espace en haut pour centrer verticalement */

  &:hover {
    transform: scale(1.05);
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom right, rgba(255, 255, 255, 0.5), transparent);
    z-index: 0;
  }
`;

const Image = styled('img')`
  width: 80%; /* Ajustement pour que l'image prenne une part importante de la carte */
  height: auto;
  object-fit: contain;
  margin-bottom: 8px;
  z-index: 1;
  border-radius: 10px;
  filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.3));
`;

const Title = styled(Typography)`
  color: ${(props) => props.couleur_bouton_ecriture || '#000000'};
  font-family: ${(props) => props.police_de_caractere || 'sans-serif'};
  font-weight: bold;
  z-index: 1;
  font-size: 0.8em; /* Garde la taille de la police adaptée */
  white-space: normal; /* Permet de passer à la ligne si nécessaire */
  word-wrap: break-word; /* Gère le retour à la ligne des mots longs */
  text-align: center;
  max-width: 100%; /* Garde le texte dans les limites de la carte */
`;

const ServiceCard = ({ service, couleur_de_case, couleur_ecriture, couleur_dombre_de_case, couleur_bouton_ecriture, police_de_caractere, onClick }) => {
  return (
    <Card
      couleur_de_case={couleur_de_case}
      couleur_ecriture={couleur_ecriture}
      couleur_dombre_de_case={couleur_dombre_de_case}
      onClick={onClick}
    >
      <Image
        src={`${BASE_URL}/images/displayimagewithpath/${encodeURIComponent(service.Logo_Requis)}`}
        alt={service.Titre}
      />
      <Title
        variant="body1"
        couleur_bouton_ecriture={couleur_bouton_ecriture}
        police_de_caractere={police_de_caractere}
      >
        {service.Titre}
      </Title>
    </Card>
  );
};

export default ServiceCard;
