import React, { useState, useEffect } from 'react';
import {
  Box, Typography, Button, TextField, Select, MenuItem, Dialog,
  DialogTitle, DialogContent, DialogActions, Grid, List, ListItem,
  ListItemText, IconButton, TablePagination, FormControlLabel, Checkbox
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ImageCarousel from '../ImageCarouselEquipementIT';
import ErrorMessage from '../ErrorMessage';
import ValidationMessage from '../ValidationMessage';
import InfoMessage from '../InfoMessage';
import { useTheme } from '@mui/material/styles';
import {
  useGetAllInfoCategorieSignalementITMutation, useAddEquipementITMutation,
  useGetAllInfoEquipementITMutation, useModifyEquipementITMutation, useDeleteEquipementITMutation,
  useGetAllImageEquipementITMutation, useGetGroupedAdminsMutation, useGetAllTypeDactionITMutation
} from 'state/api';

const RenderEquipementIT = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const buttonColor = theme.palette.primary.main;
  const textColor = theme.palette.mode === 'dark' ? theme.palette.primary.light : theme.palette.secondary.main;
  const userSession = useSelector((state) => state.adminAuth);

  const [openDialog, setOpenDialog] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedEquipement, setSelectedEquipement] = useState(null);
  const [nomEquipement, setNomEquipement] = useState('');
  const [descriptionEquipement, setDescriptionEquipement] = useState('');
  const [nombreParticules, setNombreParticules] = useState(1);
  const [priorite, setPriorite] = useState(1);
  const [severite, setSeverite] = useState(1);
  const [unicite, setUnicite] = useState(false);
  const [potentielUrgenceIntervention, setPotentielUrgenceIntervention] = useState(false);
  const [selectedCategorie, setSelectedCategorie] = useState('');
  const [selectedImage, setSelectedImage] = useState('');
  const [selectedGroupe, setSelectedGroupe] = useState('');
  const [selectedTypeDaction, setSelectedTypeDaction] = useState('');
  const [categories, setCategories] = useState([]);
  const [images, setImages] = useState([]);
  const [groupes, setGroupes] = useState([]);
  const [typesDaction, setTypesDaction] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [equipements, setEquipements] = useState([]);
  const [filteredEquipements, setFilteredEquipements] = useState([]);
  const [nomEquipementRecherche, setNomEquipementRecherche] = useState('');
  const [categorieRecherche, setCategorieRecherche] = useState('');

  const [getAllInfosCategorie] = useGetAllInfoCategorieSignalementITMutation();
  const [addEquipementIT] = useAddEquipementITMutation();
  const [getAllInfoEquipementIT] = useGetAllInfoEquipementITMutation();
  const [modifyEquipementIT] = useModifyEquipementITMutation();
  const [deleteEquipementIT] = useDeleteEquipementITMutation();
  const [getAllImage] = useGetAllImageEquipementITMutation();
  const [getGroupedAdmins] = useGetGroupedAdminsMutation();
  const [getAllTypeDactionIT] = useGetAllTypeDactionITMutation();

  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [validation, setValidation] = useState('');

  const popUpError = (message) => {
    setError(message);
  };

  const popUpInfo = (message) => {
    setMessage(message);
  };

  const popUpValidation = (message) => {
    setValidation(message);
  };

  useEffect(() => {
    if (!userSession.isAuthenticated) {
      alert(t('RenderEquipementIT.session_expired'));
    }
  }, [userSession, t]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        setIsLoading(true);
        const response = await getAllInfosCategorie();
        if (response.data) {
          const categorized = response.data.map((cat) => ({
            ...cat,
            type: cat.ID_Categorie_Precedente === 0 ? 'Primaire' : 'Secondaire'
          }));
          setCategories(categorized);
        }
      } catch (err) {
        popUpError(t('RenderEquipementIT.error_fetching_categories'));
      } finally {
        setIsLoading(false);
      }
    };

    fetchCategories();
  }, [getAllInfosCategorie, t]);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        setIsLoading(true);
        const response = await getAllImage();
        console.log(response.data);
        if (response.data) {
          setImages(response.data.images);
        }
      } catch (err) {
        popUpError(t('RenderEquipementIT.error_fetching_images'));
      } finally {
        setIsLoading(false);
      }
    };

    fetchImages();
  }, [getAllImage, t]);

  useEffect(() => {
    const fetchGroupes = async () => {
      try {
        setIsLoading(true);
        const response = await getGroupedAdmins({ ID_Etablissement: userSession.ID_Etablissement });
        if (response.data) {
          setGroupes(response.data);
        }
      } catch (err) {
        popUpError(t('RenderEquipementIT.error_fetching_groups'));
      } finally {
        setIsLoading(false);
      }
    };

    fetchGroupes();
  }, [getGroupedAdmins, userSession.ID_Etablissement, t]);

  useEffect(() => {
    const fetchTypesDaction = async () => {
      try {
        setIsLoading(true);
        const response = await getAllTypeDactionIT({ ID_Etablissement: userSession.ID_Etablissement });
        if (response.data) {
          setTypesDaction(response.data);
        }
      } catch (err) {
        popUpError(t('RenderEquipementIT.error_fetching_action_types'));
      } finally {
        setIsLoading(false);
      }
    };

    fetchTypesDaction();
  }, [getAllTypeDactionIT, userSession.ID_Etablissement, t]);

  const fetchEquipements = async () => {
    try {
      setIsLoading(true);
      const response = await getAllInfoEquipementIT({ ID_Etablissement: userSession.ID_Etablissement });
      if (response.data) {
        setEquipements(response.data);
        setFilteredEquipements(response.data);
      }
    } catch (error) {
      popUpError(t('RenderEquipementIT.error_fetching_equipments'));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchEquipements();
  }, [getAllInfoEquipementIT, userSession.ID_Etablissement, t]);

  useEffect(() => {
    filterEquipements();
  }, [nomEquipementRecherche, categorieRecherche, equipements]);

  const filterEquipements = () => {
    let filtered = equipements;

    if (nomEquipementRecherche) {
      filtered = filtered.filter(equipement => equipement.Nom_Equipement.toLowerCase().includes(nomEquipementRecherche.toLowerCase()));
    }

    if (categorieRecherche) {
      filtered = filtered.filter(equipement => equipement.ID_Categorie_Signalement === categorieRecherche);
    }

    setFilteredEquipements(filtered);
  };

  const handleOpenDialog = (equipement = null) => {
    if (equipement) {
      setSelectedEquipement(equipement);
      setNomEquipement(equipement.Nom_Equipement);
      setDescriptionEquipement(equipement.Description_Equipement);
      setNombreParticules(equipement.Nombre_particules);
      setPriorite(equipement.Priorite);
      setSeverite(equipement.Severite);
      setUnicite(equipement.Unicite);
      setPotentielUrgenceIntervention(equipement.Potentiel_urgence_intervention);
      setSelectedCategorie(equipement.ID_Categorie_Signalement);
      setSelectedImage(equipement.Lien_Image_Equipement);
      setSelectedGroupe(equipement.ID_Groupe);
      setSelectedTypeDaction(equipement.ID_Type_Daction_IT);
      setEditMode(true);
    } else {
      setSelectedEquipement(null);
      setNomEquipement('');
      setDescriptionEquipement('');
      setNombreParticules(1);
      setPriorite(1);
      setSeverite(1);
      setUnicite(false);
      setPotentielUrgenceIntervention(false);
      setSelectedCategorie('');
      setSelectedImage('');
      setSelectedGroupe('');
      setSelectedTypeDaction('');
      setEditMode(false);
    }
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedEquipement(null);
  };

  const handleSubmit = async () => {
    try {
      if (!selectedCategorie || !selectedImage || !selectedGroupe || !selectedTypeDaction) {
        popUpInfo(t('RenderEquipementIT.select_category_group_action_type'));
        return;
      }

      setIsLoading(true);

      const equipementData = {
        Nom_Equipement: nomEquipement,
        ID_Categorie_Signalement: selectedCategorie,
        Description_Equipement: descriptionEquipement,
        Lien_Image_Equipement: selectedImage,
        ID_Etablissement: userSession.ID_Etablissement,
        Nombre_particules: nombreParticules,
        Priorite: priorite,
        Severite: severite,
        ID_Groupe: selectedGroupe,
        Unicite: unicite,
        Potentiel_urgence_intervention: potentielUrgenceIntervention,
        ID_Type_Daction_IT: selectedTypeDaction
      };

      let response;
      if (editMode && selectedEquipement) {
        equipementData.ID_Equipement_IT = selectedEquipement.ID_Equipement_IT;
        response = await modifyEquipementIT(equipementData);
      } else {
        response = await addEquipementIT(equipementData);
      }

      if (response.error) {
        popUpError(editMode ? t('RenderEquipementIT.error_saving_equipment') : t('RenderEquipementIT.error_saving_equipment'));
      } else {
        handleCloseDialog();
        await fetchEquipements();
        popUpValidation(editMode ? t('RenderEquipementIT.success_saving_equipment') : t('RenderEquipementIT.success_saving_equipment'));
      }
    } catch (err) {
      popUpError(t('RenderEquipementIT.error_saving_equipment'));
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteEquipement = async (equipement) => {
    try {
      const response = await deleteEquipementIT({ ID_Equipement_IT: equipement.ID_Equipement_IT });
      if (response.error) {
        popUpError(t('RenderEquipementIT.error_deleting_equipment'));
      } else {
        await fetchEquipements();
        popUpValidation(t('RenderEquipementIT.success_deleting_equipment'));
      }
    } catch (err) {
      popUpError(t('RenderEquipementIT.error_deleting_equipment'));
    }
  };

  const handleImageSelect = (selectedIndex) => {
    setSelectedImage(selectedIndex);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box m="1.5rem 2.5rem">
      <Typography variant="h3" gutterBottom style={{ marginTop: '60px', marginBottom: '20px' }}>
        {t('RenderEquipementIT.manage_equipments')}
      </Typography>
      <ErrorMessage message={error} setError={setError} />
      <InfoMessage message={message} setInfo={setMessage} />
      <ValidationMessage message={validation} setValidation={setValidation} />
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={5} style={{ textAlign: 'left' }}>
          <Button variant="contained" color="primary" onClick={() => handleOpenDialog()} style={{ height: '55px' }}>
            {t('RenderEquipementIT.create_new_equipment')}
          </Button>
        </Grid>
        <Grid item xs={7} style={{ textAlign: 'right', display: 'flex', alignItems: 'center' }}>
          <TextField
            label={t('RenderEquipementIT.equipment_name')}
            value={nomEquipementRecherche}
            onChange={(e) => setNomEquipementRecherche(e.target.value)}
          />
          <Select
            value={categorieRecherche}
            onChange={(e) => setCategorieRecherche(e.target.value)}
            style={{ width: '30%', marginLeft: '8px' }}
          >
            <MenuItem value="">
              <em>{t('RenderEquipementIT.select_category')}</em>
            </MenuItem>
            {categories.map(cat => (
              <MenuItem key={cat.ID_Categorie_Signalement_IT} value={cat.ID_Categorie_Signalement_IT}>
                {cat.Nom_Categorie} ({cat.type})
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>

      <List style={{ marginTop: '20px' }}>
        {filteredEquipements.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((equipement, index) => {
          const categorie = categories.find(cat => cat.ID_Categorie_Signalement_IT === equipement.ID_Categorie_Signalement);
          const categorieNom = categorie ? categorie.Nom_Categorie : t('RenderEquipementIT.unknown_category');
          const groupe = groupes.find(grp => grp.ID_Groupe === equipement.ID_Groupe);
          const groupeNom = groupe ? groupe.Nom_groupe : t('RenderEquipementIT.unknown_group');
          const typeDaction = typesDaction.find(type => type.ID_Type_Daction_IT === equipement.ID_Type_Daction_IT);
          const typeDactionNom = typeDaction ? typeDaction.Titre : t('RenderEquipementIT.unknown_action_type');
          const couleur = typeDaction ? typeDaction.Couleur.replace(/^0x/, '#') : '#fff';
          return (
            <ListItem key={index} secondaryAction={
              <>
                <IconButton edge="end" aria-label="edit" onClick={() => handleOpenDialog(equipement)}>
                  <EditIcon />
                </IconButton>
                <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteEquipement(equipement)}>
                  <DeleteIcon />
                </IconButton>
              </>
            }>
              <div style={{ width: '20px', height: '20px', backgroundColor: couleur, borderRadius: '4px', marginRight: '10px', filter: 'blur(1px)' }}></div>
              <ListItemText
                primary={equipement.Nom_Equipement}
                secondary={
                  <>
                    <Typography
                      component="span"
                      variant="body2"
                      color="textSecondary"
                    >
                      {t('RenderEquipementIT.equipment_category')}: {categorieNom}, {t('RenderEquipementIT.equipment_group')}: {groupeNom}, {t('RenderEquipementIT.equipment_action_type')}: {typeDactionNom}, {t('RenderEquipementIT.equipment_particles')}: {equipement.Nombre_particules}, {t('RenderEquipementIT.equipment_unicity')}: {equipement.Unicite ? t('RenderEquipementIT.yes') : t('RenderEquipementIT.no')}, {t('RenderEquipementIT.equipment_priority')}: {equipement.Priorite}, {t('RenderEquipementIT.equipment_severity')}: {equipement.Severite}, {t('RenderEquipementIT.equipment_potential_urgency')}: {equipement.Potentiel_urgence_intervention ? t('RenderEquipementIT.yes') : t('RenderEquipementIT.no')}
                    </Typography>
                  </>
                }
              />
            </ListItem>
          );
        })}
      </List>

      <TablePagination
        component="div"
        count={filteredEquipements.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 25, 100]}
      />

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>{editMode ? t('RenderEquipementIT.edit_equipment') : t('RenderEquipementIT.add_new_equipment')}</DialogTitle>
        <DialogContent>
          <form>
            <TextField
              label={t('RenderEquipementIT.equipment_name')}
              value={nomEquipement}
              onChange={(e) => setNomEquipement(e.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              label={t('RenderEquipementIT.equipment_description')}
              value={descriptionEquipement}
              onChange={(e) => setDescriptionEquipement(e.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              label={t('RenderEquipementIT.particles_count')}
              type="number"
              value={nombreParticules}
              onChange={(e) => setNombreParticules(e.target.value)}
              inputProps={{ min: 1, max: 100 }}
              fullWidth
              margin="normal"
            />
            <TextField
              label={t('RenderEquipementIT.priority')}
              type="number"
              value={priorite}
              onChange={(e) => setPriorite(e.target.value)}
              inputProps={{ min: 1, max: 3 }}
              fullWidth
              margin="normal"
            />
            <TextField
              label={t('RenderEquipementIT.severity')}
              type="number"
              value={severite}
              onChange={(e) => setSeverite(e.target.value)}
              inputProps={{ min: 1, max: 3 }}
              fullWidth
              margin="normal"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={unicite}
                  onChange={(e) => setUnicite(e.target.checked)}
                />
              }
              label={t('RenderEquipementIT.unicity')}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={potentielUrgenceIntervention}
                  onChange={(e) => setPotentielUrgenceIntervention(e.target.checked)}
                />
              }
              label={t('RenderEquipementIT.potential_urgency')}
            />
            <Select
              value={selectedCategorie}
              onChange={(e) => setSelectedCategorie(e.target.value)}
              fullWidth
              margin="normal"
              displayEmpty
              style={{ marginBottom: '16px' }}
            >
              <MenuItem value="">
                <em>{t('RenderEquipementIT.select_category')}</em>
              </MenuItem>
              {categories.map(cat => (
                <MenuItem key={cat.ID_Categorie_Signalement_IT} value={cat.ID_Categorie_Signalement_IT}>
                  {cat.Nom_Categorie} ({cat.type})
                </MenuItem>
              ))}
            </Select>
            <Select
              value={selectedGroupe}
              onChange={(e) => setSelectedGroupe(e.target.value)}
              fullWidth
              margin="normal"
              displayEmpty
              style={{ marginBottom: '16px' }}
            >
              <MenuItem value="">
                <em>{t('RenderEquipementIT.select_group')}</em>
              </MenuItem>
              {groupes.map(grp => (
                <MenuItem key={grp.ID_Groupe} value={grp.ID_Groupe}>
                  {grp.Nom_groupe}
                </MenuItem>
              ))}
            </Select>
            <Select
              value={selectedTypeDaction}
              onChange={(e) => setSelectedTypeDaction(e.target.value)}
              fullWidth
              margin="normal"
              displayEmpty
              style={{ marginBottom: '16px' }}
            >
              <MenuItem value="">
                <em>{t('RenderEquipementIT.select_action_type')}</em>
              </MenuItem>
              {typesDaction.map(type => (
                <MenuItem key={type.ID_Type_Daction_IT} value={type.ID_Type_Daction_IT}>
                  {type.Titre}
                </MenuItem>
              ))}
            </Select>
            <ImageCarousel images={images} selectedImage={selectedImage} onImageSelect={handleImageSelect} />
            {message && <div style={{ textAlign: 'center', fontSize: '1em', marginTop: '10px' }}>{message}</div>}
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} style={{ backgroundColor: buttonColor, color: textColor }}>{t('RenderEquipementIT.cancel')}</Button>
          <Button onClick={handleSubmit} style={{ backgroundColor: buttonColor, color: textColor }}>{editMode ? t('RenderEquipementIT.save') : t('RenderEquipementIT.submit')}</Button>
        </DialogActions>
      </Dialog>

      {isLoading && <p>{t('RenderEquipementIT.loading')}</p>}
    </Box>
  );
};

export default RenderEquipementIT;
