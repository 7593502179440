import React, { useState } from "react";
import { Box, useTheme, Button, Typography } from "@mui/material";
import Header from "components/Header";
import RenderCategorieServiceIT from "components/informatique/RenderCategorieServiceIT";
import RenderServiceIT from "components/informatique/RenderServiceIT";
import { useTranslation } from "react-i18next";
import { motion, AnimatePresence } from "framer-motion";

const ServiceIT = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [activeSection, setActiveSection] = useState('categories');

  const renderSection = () => {
    switch (activeSection) {
      case 'categorieservice':
        return <RenderCategorieServiceIT />;
      case 'service':
        return <RenderServiceIT />;
      default:
        return <RenderServiceIT />;
    }
  };

  const buttonStyle = (isActive) => ({
    fontSize: '18px',
    fontWeight: '600',
    margin: '8px',
    padding: '14px 28px',
    boxShadow: isActive 
      ? `0px 6px 12px ${theme.palette.neutral.third}33` 
      : `0px 4px 8px ${theme.palette.neutral.third}26`,
    backgroundColor: isActive ? theme.palette.primary.secondary : theme.palette.neutral.secondary,
    color: isActive ? theme.palette.neutral.secondary : theme.palette.primary.main,
    borderRadius: '6px',
    textTransform: 'none',
    transition: 'all 0.3s ease',
    width: '180px', // Largeur fixe pour tous les boutons
    '&:hover': {
      backgroundColor: isActive ? theme.palette.primary.third : theme.palette.neutral.dark,
      boxShadow: `0px 8px 16px ${theme.palette.neutral.third}33`,
      transform: 'translateY(-2px)',
    },
  });

  const buttonTextStyle = {
    letterSpacing: '1px', // Augmentation de l'espacement entre les lettres
    fontWeight: 'bold',
    textTransform: 'uppercase',
  };

  const containerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
    exit: { opacity: 0, y: -20, transition: { duration: 0.5 } }
  };

  return (
    <Box m="0.5rem 2.5rem">
      <Header title={t('ServiceIT.title')} subtitle={t('ServiceIT.subtitle')} />

      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
        <Button sx={buttonStyle(activeSection === 'categorieservice')} onClick={() => setActiveSection('categorieservice')}>
          <Typography sx={buttonTextStyle}>{t('ServiceIT.buttonCategory').toUpperCase()}</Typography>
        </Button>
        <Button sx={buttonStyle(activeSection === 'service')} onClick={() => setActiveSection('service')}>
          <Typography sx={buttonTextStyle}>{t('ServiceIT.buttonService').toUpperCase()}</Typography>
        </Button>
      </Box>

      <AnimatePresence mode="wait">
        <motion.div
          key={activeSection}
          variants={containerVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
        >
          <Box sx={{
            padding: '20px',
          }}>
            {renderSection()}
          </Box>
        </motion.div>
      </AnimatePresence>
    </Box>
  );
};

export default ServiceIT;
