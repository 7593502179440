import React from 'react';
import { Button, Typography } from '@mui/material';
import { styled } from '@mui/system';

const StyledSuivantButton = styled(Button)(({ theme, style }) => ({
  minWidth: '120px', // Adapté pour couvrir le texte
  marginTop: '20px',
  padding: '10px 20px', // Espacement intérieur pour ajuster autour du texte
  borderRadius: '10px',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',
  boxShadow: `5px 5px 10px rgba(0, 0, 0, 0.2)`,
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 0,
  },
  ...style,
}));

const FormulaireBoutonSuivant = ({ styleProps, setShown }) => {
  const dynamicStyles = {
    backgroundColor: styleProps ? styleProps.couleur_bouton : '#FFF',
    color: styleProps ? styleProps.couleur_bouton_ecriture : '#000',
    boxShadow: styleProps ? `5px 5px 10px ${styleProps.couleur_de_lombre_du_bouton}` : '0px 0px 0px #000',
  };

  const handleClick = () => {
    setShown("E");
  };

  return (
    <StyledSuivantButton style={dynamicStyles} onClick={handleClick}>
      <Typography variant="body2" style={{ color: dynamicStyles.color, fontFamily: styleProps ? styleProps.police_de_caractere : 'sans-serif' }}>
        Suivant
      </Typography>
    </StyledSuivantButton>
  );
};

export default FormulaireBoutonSuivant;
