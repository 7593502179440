import React, { useEffect, useState } from 'react';
import { Typography, TextField, Button, IconButton, MenuItem, Slider, Box, Select, InputLabel, FormControl } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import styled from '@emotion/styled';
import { useSelector } from 'react-redux'; // Importing the useSelector hook
import { useTranslation } from 'react-i18next';

const FormWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-color: ${(props) => props.couleur_de_fond || '#FFFFFF'};
  color: ${(props) => props.couleur_ecriture || '#000000'};
  font-family: ${(props) => props.police_de_caractere || 'Arial, sans-serif'};
`;

const FormContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  max-width: 600px;
  background-color: ${(props) => props.couleur_de_fond || '#FFFFFF'};
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  position: relative;

  @media (max-width: 767px) {
    width: 95%;
    padding: 10px;
  }
`;

const CloseButton = styled(IconButton)`
  position: absolute;
  top: 16px;
  right: 16px;
`;

const LogoContainer = styled('div')`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 32px;

  img {
    max-height: 120px;
    border-radius: 15px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  }

  @media (max-width: 767px) {
    margin-bottom: 16px;
  }
`;

const TitleContainer = styled('div')`
  text-align: center;
  width: 100%;
  margin-bottom: 16px;
`;

const InputField = styled(TextField)`
  width: 100%;
  margin-bottom: 16px;
  color: ${(props) => props.couleur_ecriture || '#000000'};

  & .MuiOutlinedInput-root {
    background-color: ${(props) => props.couleur_de_fond_input || '#FFFFFF'};
    color: ${(props) => props.couleur_ecriture_input || '#000000'};
    border: 1px solid ${(props) => props.couleur_bordure || '#000000'};
  }

  & .MuiInputLabel-outlined {
    color: ${(props) => props.couleur_ecriture_input || '#000000'};
  }

  & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.couleur_bouton || '#3f51b5'};
  }
`;

const StyledSelect = styled(Select)`
  & .MuiSelect-select {
    background-color: ${(props) => props.couleur_de_fond_input || '#FFFFFF'};
    color: ${(props) => props.couleur_ecriture_input || '#000000'};
  }

  & .MuiOutlinedInput-notchedOutline {
    border: 1px solid ${(props) => props.couleur_bordure || '#000000'};
  }

  & .MuiSelect-icon {
    color: ${(props) => props.couleur_ecriture_input || '#000000'};
  }
`;

const StyledMenuItem = styled(MenuItem)`
  background-color: ${(props) => props.couleur_de_fond_input || '#FFFFFF'} !important;
  color: ${(props) => props.couleur_ecriture_input || '#000000'} !important;

  &:hover {
    background-color: ${(props) => props.couleur_bouton || '#3f51b5'} !important;
    color: ${(props) => props.couleur_bouton_ecriture || '#FFFFFF'} !important;
  }
`;

const SubmitButton = styled(Button)`
  margin-top: 16px;
  background-color: ${(props) => props.couleur_bouton || '#3f51b5'};
  color: ${(props) => props.couleur_bouton_ecriture || '#FFFFFF'};
  transition: transform 0.2s;
  &:hover {
    transform: scale(1.05);
    background-color: ${(props) => props.couleur_bouton || '#3f51b5'};
  }
`;

const EquipementForm = ({
  onClose,
  couleur_de_fond,
  couleur_ecriture,
  couleur_bouton,
  couleur_bouton_ecriture,
  couleur_de_fond_input,
  couleur_ecriture_input,
  couleur_bordure,
  police_de_caractere,
  maxParticules,
  logoUrl,
  onFormSubmit
}) => {
  const { t } = useTranslation();
  const userSession = useSelector((state) => state.userAuth); // Getting user session from Redux store
  const [formData, setFormData] = useState({
    Description: '',
    Niveau_urgence_intervention: 4,
    Nombre_de_particule_impacte: 1,
    Attachment: null,
    Email_signaleur: '' // Initial empty state
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (userSession.user && userSession.user.email) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        Email_signaleur: userSession.user.email, // Automatically fill email
      }));
    }
  }, [userSession]);

  useEffect(() => {
    if (maxParticules > 1 && formData.Nombre_de_particule_impacte > maxParticules) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        Nombre_de_particule_impacte: maxParticules,
      }));
    }
  }, [maxParticules]);

  const handleSliderChange = (event, newValue) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      Nombre_de_particule_impacte: newValue,
    }));
  };

  const handleInputChange = (event) => {
    const { name, value, files } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: files ? files[0] : value,
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.Description) newErrors.Description = "Description du problème est obligatoire";
    if (!formData.Email_signaleur) {
      newErrors.Email_signaleur = "Email du demandeur est obligatoire";
    } else if (!formData.Email_signaleur.includes('@')) {
      newErrors.Email_signaleur = "Email invalide";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      onFormSubmit(formData);  // Appel de la fonction onFormSubmit avec les données du formulaire
      onClose();
    }
  };

  return (
    <FormWrapper couleur_de_fond={couleur_de_fond} couleur_ecriture={couleur_ecriture}>
      <FormContainer
        couleur_de_fond={couleur_de_fond}
        couleur_ecriture={couleur_ecriture}
        police_de_caractere={police_de_caractere}
      >
        <CloseButton onClick={onClose}>
          <CloseIcon />
        </CloseButton>
        {logoUrl && (
          <LogoContainer>
            <img src={logoUrl} alt="Logo" />
          </LogoContainer>
        )}
        <TitleContainer>
          <Typography
            variant="h5"
            style={{
              color: couleur_ecriture,
              fontFamily: police_de_caractere,
              fontWeight: 'bold',
            }}
          >
            {t('EquipementForm.title')}
          </Typography>
        </TitleContainer>
        <InputField
          label={t('EquipementForm.descriptionLabel')}
          variant="outlined"
          multiline
          rows={4}
          name="Description"
          value={formData.Description}
          onChange={handleInputChange}
          couleur_de_fond_input={couleur_de_fond_input}
          couleur_ecriture_input={couleur_ecriture_input}
          couleur_bordure={couleur_bordure}
          error={!!errors.Description}
          helperText={errors.Description && t('EquipementForm.errorDescription')}
        />
        <FormControl variant="outlined" fullWidth margin="normal">
          <InputLabel style={{ color: couleur_ecriture || '#FFFFFF' }}>
            {t('EquipementForm.urgenceLabel')}
          </InputLabel>
          <StyledSelect
            value={formData.Niveau_urgence_intervention}
            onChange={handleInputChange}
            label={t('EquipementForm.urgenceLabel')}
            name="Niveau_urgence_intervention"
            couleur_de_fond_input={couleur_de_fond_input}
            couleur_ecriture={couleur_ecriture}
            couleur_ecriture_input={couleur_ecriture_input}
            couleur_bordure={couleur_bordure}
            couleur_bouton={couleur_bouton}
          >
            <StyledMenuItem value={1}>{t('EquipementForm.urgenceOptions.1')}</StyledMenuItem>
            <StyledMenuItem value={2}>{t('EquipementForm.urgenceOptions.2')}</StyledMenuItem>
            <StyledMenuItem value={3}>{t('EquipementForm.urgenceOptions.3')}</StyledMenuItem>
            <StyledMenuItem value={4}>{t('EquipementForm.urgenceOptions.4')}</StyledMenuItem>
          </StyledSelect>
        </FormControl>
        {maxParticules > 1 && (
          <Box width="100%" marginBottom="16px">
            <Typography variant="body1" gutterBottom>
              {t('EquipementForm.particulesLabel')}
            </Typography>
            <Slider
              value={formData.Nombre_de_particule_impacte}
              onChange={handleSliderChange}
              aria-labelledby="Nombre_de_particule_impacte-slider"
              min={1}
              max={maxParticules}
              step={1}
              valueLabelDisplay="auto"
              style={{ color: couleur_bouton }}
            />
          </Box>
        )}
        <InputField
          label={t('EquipementForm.emailLabel')}
          variant="outlined"
          name="Email_signaleur"
          value={formData.Email_signaleur}
          onChange={handleInputChange}
          couleur_de_fond_input={couleur_de_fond_input}
          couleur_ecriture_input={couleur_ecriture_input}
          couleur_bordure={couleur_bordure}
          error={!!errors.Email_signaleur}
          helperText={errors.Email_signaleur && (errors.Email_signaleur === 'Email invalide' ? t('EquipementForm.errorEmail.invalid') : t('EquipementForm.errorEmail.required'))}
        />
        <InputField
          type="file"
          name="Attachment"
          onChange={handleInputChange}
          couleur_de_fond_input={couleur_de_fond_input}
          couleur_ecriture_input={couleur_ecriture_input}
          couleur_bordure={couleur_bordure}
        />
        <SubmitButton
          onClick={handleSubmit}
          couleur_bouton={couleur_bouton}
          couleur_bouton_ecriture={couleur_bouton_ecriture}
        >
          {t('EquipementForm.submitButton')}
        </SubmitButton>
      </FormContainer>
    </FormWrapper>
  );
};

export default EquipementForm;