import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, Typography, Paper, Container } from '@mui/material';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { useCreateStripeCheckoutSessionMutation } from 'state/api';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const SouscriptionEtablissement = () => {
  const { t } = useTranslation();
  const [nomEtablissement, setNomEtablissement] = useState('');
  const [usernameEtablissement, setUsernameEtablissement] = useState('');
  const [motDePasseEtablissement, setMotDePasseEtablissement] = useState('');
  const [mailEtablissement, setMailEtablissement] = useState('');
  const [errors, setErrors] = useState({});
  
  const userSession = useSelector((state) => state.userAuth);
  const { type } = useParams();
  const [subscriptionType, setSubscriptionType] = useState('proprietaire');

  const [createCheckoutSession, { isLoading, isError, isSuccess }] = useCreateStripeCheckoutSessionMutation();

  useEffect(() => {
    if (type) {
      setSubscriptionType(type);
    }
  }, [type]);

  const validateInputs = () => {
    let newErrors = {};

    if (!nomEtablissement.trim()) newErrors.nomEtablissement = t('SouscriptionEtablissement.errorEmptyField');
    if (!usernameEtablissement.trim()) newErrors.usernameEtablissement = t('SouscriptionEtablissement.errorEmptyField');

    if (!motDePasseEtablissement) {
      newErrors.motDePasseEtablissement = t('SouscriptionEtablissement.errorEmptyField');
    } else if (!/^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()]).{8,}$/.test(motDePasseEtablissement)) {
      newErrors.motDePasseEtablissement = t('SouscriptionEtablissement.errorPasswordRequirements');
    }

    if (!mailEtablissement) {
      newErrors.mailEtablissement = t('SouscriptionEtablissement.errorEmptyField');
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(mailEtablissement)) {
      newErrors.mailEtablissement = t('SouscriptionEtablissement.errorInvalidEmail');
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateInputs()) {
      return;
    }

    const stripe = await stripePromise;

    try {
      const session = await createCheckoutSession({
        subscriptionType,
        successUrl: `${window.location.origin}/subscriptionresult?session_id={CHECKOUT_SESSION_ID}`,
        cancelUrl: `${window.location.origin}/subscriptionresult?status=error`,
        ID_Utilisateur: userSession.user.ID_Utilisateur,
        metadata: {
          Nom_Etablissement: nomEtablissement,
          Username_Etablissement: usernameEtablissement,
          Mot_de_Passe_Etablissement: motDePasseEtablissement,
          Mail_Etablissement: mailEtablissement,
        },
      }).unwrap();

      const { error } = await stripe.redirectToCheckout({ sessionId: session.id });

      if (error) {
        console.error('Erreur lors de la redirection vers Stripe:', error);
      }
    } catch (error) {
      console.error('Erreur lors de la souscription:', error);
    }
  };

  return (
    <Box 
      sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        minHeight: '100vh', 
        backgroundColor: '#1a237e'
      }}
    >
      <Container maxWidth="sm">
        <Paper elevation={3} sx={{ padding: 4, backgroundColor: 'white' }}>
          <Typography variant="h3" gutterBottom align="center" sx={{ color: '#000000', letterSpacing: '0.1em', marginBottom: '2rem' }}>
            {t('SouscriptionEtablissement.title')}
          </Typography>
          <form onSubmit={handleSubmit}>
            <Box mb="1.5rem">
              <TextField
                label={t('SouscriptionEtablissement.nomEtablissement')}
                variant="outlined"
                fullWidth
                value={nomEtablissement}
                onChange={(e) => setNomEtablissement(e.target.value)}
                helperText={errors.nomEtablissement || t('SouscriptionEtablissement.nomEtablissementDescription')}
                error={!!errors.nomEtablissement}
                InputLabelProps={{ style: { color: '#000000' } }}
                InputProps={{ 
                  style: { color: '#000000' },
                  sx: { '& fieldset': { borderColor: '#BDBDBD', borderWidth: '1px' } }
                }}
                FormHelperTextProps={{ style: { color: errors.nomEtablissement ? 'red' : '#757575' } }}
              />
            </Box>
            <Box mb="1.5rem">
              <TextField
                label={t('SouscriptionEtablissement.usernameEtablissement')}
                variant="outlined"
                fullWidth
                value={usernameEtablissement}
                onChange={(e) => setUsernameEtablissement(e.target.value)}
                helperText={errors.usernameEtablissement || t('SouscriptionEtablissement.usernameEtablissementDescription')}
                error={!!errors.usernameEtablissement}
                InputLabelProps={{ style: { color: '#000000' } }}
                InputProps={{ 
                  style: { color: '#000000' },
                  sx: { '& fieldset': { borderColor: '#BDBDBD', borderWidth: '1px' } }
                }}
                FormHelperTextProps={{ style: { color: errors.usernameEtablissement ? 'red' : '#757575' } }}
              />
            </Box>
            <Box mb="1.5rem">
              <TextField
                label={t('SouscriptionEtablissement.motDePasseEtablissement')}
                variant="outlined"
                type="password"
                fullWidth
                value={motDePasseEtablissement}
                onChange={(e) => setMotDePasseEtablissement(e.target.value)}
                helperText={errors.motDePasseEtablissement || t('SouscriptionEtablissement.motDePasseEtablissementDescription')}
                error={!!errors.motDePasseEtablissement}
                InputLabelProps={{ style: { color: '#000000' } }}
                InputProps={{ 
                  style: { color: '#000000' },
                  sx: { '& fieldset': { borderColor: '#BDBDBD', borderWidth: '1px' } }
                }}
                FormHelperTextProps={{ style: { color: errors.motDePasseEtablissement ? 'red' : '#757575' } }}
              />
            </Box>
            <Box mb="1.5rem">
              <TextField
                label={t('SouscriptionEtablissement.mailEtablissement')}
                variant="outlined"
                fullWidth
                value={mailEtablissement}
                onChange={(e) => setMailEtablissement(e.target.value)}
                helperText={errors.mailEtablissement || t('SouscriptionEtablissement.mailEtablissementDescription')}
                error={!!errors.mailEtablissement}
                InputLabelProps={{ style: { color: '#000000' } }}
                InputProps={{ 
                  style: { color: '#000000' },
                  sx: { '& fieldset': { borderColor: '#BDBDBD', borderWidth: '1px' } }
                }}
                FormHelperTextProps={{ style: { color: errors.mailEtablissement ? 'red' : '#757575' } }}
              />
            </Box>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={isLoading}
              fullWidth
              sx={{ padding: '0.75rem', fontSize: '1.1rem' }}
            >
              {isLoading ? t('SouscriptionEtablissement.loadingButton') : t('SouscriptionEtablissement.souscrireButton')}
            </Button>
            {isSuccess && (
              <Typography variant="body1" color="green" mt="1rem">
                {t('SouscriptionEtablissement.successMessage')}
              </Typography>
            )}
            {isError && (
              <Typography variant="body1" color="red" mt="1rem">
                {t('SouscriptionEtablissement.errorMessage')}
              </Typography>
            )}
          </form>
        </Paper>
      </Container>
    </Box>
  );
};

export default SouscriptionEtablissement;
