import React from 'react';
import { List, ListItem, Box, Typography, useTheme } from '@mui/material';
import { keyframes } from '@emotion/react';
import { useTranslation } from 'react-i18next';

const growShrink = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
`;

const ListeRetourFeedback = ({ feedbacks, onSelectFeedback, selectedFeedback }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <List>
      {feedbacks.map((feedback) => (
        <ListItem
          key={feedback.ID_Feedback}
          button
          onClick={() => onSelectFeedback(feedback)}
          selected={selectedFeedback && selectedFeedback.ID_Feedback === feedback.ID_Feedback}
          sx={{
            animation: selectedFeedback && selectedFeedback.ID_Feedback === feedback.ID_Feedback ? `${growShrink} 2s infinite` : 'none',
            boxShadow: selectedFeedback && selectedFeedback.ID_Feedback === feedback.ID_Feedback ? '0 0 10px rgba(0, 0, 0, 0.5)' : 'none',
            borderRadius: '8px',
            transition: 'transform 0.3s ease-in-out',
            '&:hover': {
              transform: 'scale(1.05)',
              boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
            },
            marginBottom: '8px',
            padding: '16px',
            border: '1px solid #ddd',
          }}
        >
          <Box sx={{ flex: 1 }}>
            <Typography variant="h6" sx={{ fontWeight: 'bold', color: theme.palette.neutral.main }}>
              {feedback.Nom_Feedback}
            </Typography>

            <Typography variant="body2" sx={{ color: feedback.FeedBack_Actif ? 'green' : 'red' }}>
              {feedback.FeedBack_Actif ? t('ListeRetourFeedback.active') : t('ListeRetourFeedback.inactive')}
            </Typography>
            <Typography variant="body2" sx={{ color: theme.palette.neutral.main, marginTop: '4px' }}>
              {`${t('ListeRetourFeedback.total_responses')}: ${feedback.Total_Reponses}`}
            </Typography>
          </Box>
        </ListItem>
      ))}
    </List>
  );
};

export default ListeRetourFeedback;
