import React from 'react';
import { Box, Container, Typography, Grid, Paper, Button, useMediaQuery, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Navbar from '../../components_public/navbar';
import logoHurflym from '../../assets/logo_hurflym_sans_fond.png';
import logoHurflymViolet from '../../assets/Logo_hurflym_violet_sur_fond_blanc_sans_fond.png';
import logoHurflymGris from '../../assets/Logo_hurflym_gris_sur_fond_blanc_sans_fond.png';
import photoDeProfil from '../../assets/photo_de_profil_2.jpg';
import logoDesign from '../../assets/logo_design.png';
import logoLinkedin from '../../assets/logo_linkedin.png';

const APropos = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation();

  const sections = [
    {
      question: t('About.whatIsHurflym.question'),
      reponse: t('About.whatIsHurflym.answer')
    },
    {
      question: t('About.whoAreWe.question'),
      reponse: t('About.whoAreWe.answer')
    },
    {
      question: t('About.whoIsItFor.question'),
      reponse: t('About.whoIsItFor.answer')
    },
    {
      question: t('About.roadmap.question'),
      reponse: t('About.roadmap.answer')
    },
    {
      question: t('About.whichSubscription.question'),
      reponse: t('About.whichSubscription.answer')
    },
    {
      question: t('About.contactUs.question'),
      reponse: t('About.contactUs.answer'),
    }
  ];

  const logos = [logoHurflym, photoDeProfil, logoHurflymGris, logoDesign, logoHurflymViolet, logoLinkedin];

  return (
    <Box sx={{ backgroundColor: '#ffffff', minHeight: '100vh' }}>
      <Navbar />
      <Container maxWidth="lg" sx={{ pt: isMobile ? '20%' : '8%', pb: 6 }}>
        <Grid container spacing={4}>
          {sections.map((section, index) => (
            <React.Fragment key={index}>
              <Grid item xs={12} md={6} sx={{ my: isMobile ? '4%' : '8%' }}>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', mb: isMobile ? 2 : 0 }}>
                  <img 
                    src={logos[index]} 
                    alt={t('About.logoAlt', { index: index + 1 })} 
                    style={{ 
                      maxWidth: '90%', 
                      maxHeight: '250px', 
                      transform: `rotate(${Math.random() * 6 - 3}deg)`,
                      borderRadius: index === 1 ? '15px' : '0',
                    }} 
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6} sx={{ my: isMobile ? '4%' : '8%' }}>
                <Paper elevation={3} sx={{ p: 4, height: '100%', backgroundColor: '#ffffff', borderRadius: 2, transform: isMobile ? 'none' : `rotate(${Math.random() * 6 - 3}deg)` }}>
                  <Box sx={{ transform: 'rotate(0deg)' }}>
                    <Typography variant="h5" component="h2" gutterBottom sx={{ color: '#77c8d7', fontWeight: 'bold', fontSize: isMobile ? '1.2rem' : '1.5rem' }}>
                      {section.question}
                    </Typography>
                    <Typography variant="body1" sx={{ color: '#333333', fontSize: isMobile ? '0.9rem' : '1rem', overflowWrap: 'break-word', textAlign: 'justify' }}>
                      {section.reponse}
                    </Typography>
                  </Box>
                </Paper>
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 4, mb: 10 }}>
          <Button 
            variant="contained" 
            color="primary" 
            size="large"
            onClick={() => navigate('/abonnements')}
            sx={{ 
              backgroundColor: 'white', 
              color: '#3e577c',
              borderRadius: '25px',
              boxShadow: '0 10px 30px rgba(0, 0, 0, 0.3)',
              transition: 'all 0.3s ease',
              '&:hover': {
                backgroundColor: 'white',
                boxShadow: '0 15px 35px rgba(237, 94, 173, 0.5)',
                transform: 'scale(1.05)',
                marginBottom: '20px',
                '& img': {
                  transform: 'rotate(360deg)',
                  transition: 'transform 0.5s ease-in-out',
                },
              },
              display: 'flex',
              alignItems: 'center',
              gap: 2,
              fontSize: isMobile ? '1.2rem' : '2rem',
              padding: isMobile ? '12px 24px' : '16px 32px',
            }}
          >
            <img src={logoHurflym} alt={t('About.hurflymLogoAlt')} style={{ height: isMobile ? '32px' : '48px', transition: 'transform 0.5s ease-in-out' }} />
            {t('About.joinAdventure')}
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default APropos;
