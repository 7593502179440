import React, { useState, useEffect } from 'react';
import { useLoginPostUserMutation } from "state/api";
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setUserAuthenticated } from './../../state/userAuth';
import { useTranslation } from 'react-i18next';
import './login.css';

function Login() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loginUser, { datapost, errorpost }] = useLoginPostUserMutation();

  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [authError, setAuthError] = useState(null);

  const handleLogin = async (event) => {
    event.preventDefault();
    setAuthError(null); // Reset authentication error
    try {
      const result = await loginUser({ username, password, id }).unwrap();
      localStorage.setItem('isUserAuthenticated', 'true');
      localStorage.setItem('userInfo', JSON.stringify(result));
      dispatch(setUserAuthenticated({ isAuthenticated: true, user: result }));
      setShouldRedirect(true);
      navigate(`/`);
    } catch (err) {
      if (err.status === 401) {
        setAuthError(t('Login.AuthError'));
      } else {
        setAuthError(t('Login.AuthErrorGeneral'));
      }
    }
  };

  useEffect(() => {
    if (shouldRedirect) {
      navigate('/dashboard');
    }
  }, [shouldRedirect, navigate]);

  return (
    <div className="login-container">
      <form onSubmit={handleLogin}>
        {authError && <p className="login-error">{authError}</p>}
        <label htmlFor="username">{t('Login.UsernameLabel')}</label>
        <input
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          placeholder="Username"
          className="login-input"
          id="username"
        />
        <label htmlFor="password">{t('Login.PasswordLabel')}</label>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          className="login-input"
          id="password"
        />
        <button type="submit" className="login-button">{t('Login.LoginButton')}</button>
      </form>
    </div>
  );
}

export default Login;
