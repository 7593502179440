import React, { useState, useEffect } from 'react';
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Container, Snackbar, Card, CardContent, useMediaQuery } from '@mui/material';
import { Check } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import NavbarUtilisateur from 'components_public/navbarUtilisateur';

import LogoProprietaire from '../../assets/logo_hurflym_sans_fond_rose_fushia.png';
import LogoEntreprise from '../../assets/logo_hurflym_sans_fond_bleu_clair.png';
import LogoCompagnie from '../../assets/logo_hurflym_sans_fond_bleu_fonce.png';
import LogoMultinationale from '../../assets/logo_hurflym_noir_sans_fond.png';

const AbonnementsWithoutBackground = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userAuth = useSelector((state) => state.userAuth);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [maxDescriptionHeight, setMaxDescriptionHeight] = useState(0);
  const isMobile = useMediaQuery('(max-width:600px)');

  const abonnementsData = [
    {
      avantage: t('Abonnement.Double domaine informatique et service généraux présent dans l\'application'),
      proprietaire: true,
      entreprise: true,
      company: true,
      multinationale: true,
    },
    {
      avantage: t('Abonnement.Signalement par QrCode disponible pour les collaborateurs ainsi que web'),
      proprietaire: true,
      entreprise: true,
      company: true,
      multinationale: true,
    },
    {
      avantage: t('Abonnement.Signalement pour un équipement ou un équipement particulier disponible'),
      proprietaire: true,
      entreprise: true,
      company: true,
      multinationale: true,
    },
    {
      avantage: t('Abonnement.Des demandes de services peuvent être effectuées auprès des responsables de l\'établissement dans l\'application'),
      proprietaire: true,
      entreprise: true,
      company: true,
      multinationale: true,
    },
    {
      avantage: t('Abonnement.Gestion des lieux et des espaces pour un découpage qui correspond au client'),
      proprietaire: true,
      entreprise: true,
      company: true,
      multinationale: true,
    },
    {
      avantage: t('Abonnement.Personnalisation de la page des signalements à l\'image de marque du client avec son logo'),
      proprietaire: true,
      entreprise: true,
      company: true,
      multinationale: true,
    },
    {
      avantage: t('Abonnement.Gestion des administrateurs et des droits confiés aux personnes responsables'),
      proprietaire: true,
      entreprise: true,
      company: true,
      multinationale: true,
    },
    {
      avantage: t('Abonnement.Feedback et analyse disponible'),
      proprietaire: false,
      entreprise: true,
      company: true,
      multinationale: true,
    },
    {
      avantage: t('Abonnement.Accès aux statistiques'),
      proprietaire: false,
      entreprise: true,
      company: true,
      multinationale: true,
    },
    {
      avantage: t('Abonnement.Création d\'un portail pour travailler avec des entreprises partenaires'),
      proprietaire: false,
      entreprise: true,
      company: true,
      multinationale: true,
    },
    {
      avantage: t('Abonnement.Possibilité d\'intégrer un portail d\'une entreprise partenaire'),
      proprietaire: false,
      entreprise: true,
      company: true,
      multinationale: true,
    },
    {
      avantage: t('Abonnement.API pour les développeurs pour créer des outils avec l\'écosystème'),
      proprietaire: false,
      entreprise: false,
      company: true,
      multinationale: true,
    },
    {
      avantage: t('Abonnement.Intégration de Meakuumi l\'application mobile pour sécuriser les usages'),
      proprietaire: false,
      entreprise: false,
      company: true,
      multinationale: true,
    },
    {
      avantage: t('Abonnement.Intégration d\'une SSO d\'entreprise'),
      proprietaire: false,
      entreprise: false,
      company: false,
      multinationale: true,
    },
  ];

  const renderIcon = (value) => {
    return value ? (
      <Check sx={{ color: '#ed5ead' }} />
    ) : (
      ''
    );
  };

  useEffect(() => {
    const descriptions = [
      t('Abonnement.Accès basique'),
      t('Abonnement.Accès intermédiaire'),
      t('Abonnement.Accès complet et publicitaire'),
      t('Abonnement.Accès sur mesure'),
    ];
    
    const maxHeight = Math.max(...descriptions.map(desc => {
      const el = document.createElement('div');
      el.style.visibility = 'hidden';
      el.style.width = '170px';
      el.innerHTML = desc;
      document.body.appendChild(el);
      const height = el.offsetHeight;
      document.body.removeChild(el);
      return height;
    }));
    
    setMaxDescriptionHeight(maxHeight);
  }, [t]);

  const metallicEffect = (color) => ({
    background: color,
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
    borderBottom: '1px solid rgba(62, 87, 124, 0.1)',
    borderTop: '1px solid rgba(62, 87, 124, 0.1)',
  });

  const buttonHover = {
    '&:hover': {
      transform: 'scale(1.05)',
      transition: 'transform 0.3s',
    },
  };

  const handleSubscription = (type) => {
    if (userAuth.isAuthenticated) {
      navigate(`/souscription-etablissement/${type}`);
    } else {
      setOpenSnackbar(true);
    }
  };

  const handleRDV = () => {
    navigate('/contact');
  };

  const renderMobileCard = (logo, title, description, price, buttonColor, isRDV = false) => (
    <Card sx={{ mb: 2, borderRadius: '15px', boxShadow: 3 }}>
      <CardContent sx={{ textAlign: 'center' }}>
        <Box component="img" src={logo} alt={`Logo ${title}`} sx={{ width: '100px', mb: 2 }} />
        <Typography variant="h5" sx={{ mb: 1, color: '#3e577c', fontWeight: 'bold', textTransform: 'uppercase' }}>{title}</Typography>
        <Typography variant="body2" sx={{ mb: 1, color: '#3e577c' }}>{description}</Typography>
        <Typography variant="h6" sx={{ mb: 1, color: '#3e577c' }}>{price}</Typography>
        <Button
          variant="contained"
          sx={{ ...buttonHover, mt: 1, backgroundColor: buttonColor, color: 'white' }}
          onClick={isRDV ? handleRDV : () => handleSubscription(title.toLowerCase())}
        >
          {isRDV ? t('Abonnement.Prendre RDV') : t('Abonnement.Souscrire')}
        </Button>
      </CardContent>
    </Card>
  );

  return (
    <Box>
      <NavbarUtilisateur />

      <Box
        sx={{
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          pt: '75px',
          pb: '75px',
        }}
      >
        <Container sx={{ zIndex: 1, mt: 6 }}>
          {isMobile ? (
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              {renderMobileCard(LogoProprietaire, t('Abonnement.Propriétaire'), t('Abonnement.Accès basique'), '146€/mois', '#ed5ead')}
              {renderMobileCard(LogoEntreprise, t('Abonnement.Entreprise'), t('Abonnement.Accès intermédiaire'), '243€/mois', '#77c8d7')}
              {renderMobileCard(LogoCompagnie, t('Abonnement.Company'), t('Abonnement.Accès complet et publicitaire'), '297€/mois', '#3e577c')}
              {renderMobileCard(LogoMultinationale, t('Abonnement.Multi-nationale'), t('Abonnement.Accès sur mesure'), 'Contactez-nous', 'black', true)}
            </Box>
          ) : (
            <TableContainer component={Paper} sx={{ backgroundColor: 'white', color: '#3e577c' }}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ width: '20%', color: '#3e577c', borderRight: '1px solid rgba(62, 87, 124, 0.1)', borderTop: '1px solid rgba(62, 87, 124, 0.1)', borderBottom: '1px solid rgba(62, 87, 124, 0.1)' }} />
                    <TableCell
                      align="center"
                      sx={{
                        ...metallicEffect('white'),
                        height: '400px',
                        verticalAlign: 'top',
                        padding: '20px',
                        borderRight: '1px solid rgba(62, 87, 124, 0.1)',
                      }}
                    >
                      <Box component="img" src={LogoProprietaire} alt="Logo Propriétaire" sx={{ width: '150px', mb: 2 }} />
                      <Typography variant="h4" sx={{ mb: 2, height: '50px', color: '#3e577c', fontWeight: 'bold', textTransform: 'uppercase' }}>{t('Abonnement.Propriétaire')}</Typography>
                      <Typography variant="body1" sx={{ mb: 2, height: `${maxDescriptionHeight}px`, textAlign: 'justify', color: '#3e577c' }}>{t('Abonnement.Accès basique')}</Typography>
                      <Typography variant="body2" sx={{ color: '#77c8d7', fontWeight: 'bold', mb: 2, height: '10px' }}>{t('Abonnement.1 mois offert')}</Typography>
                      <Typography variant="h3" sx={{ mb: 2, height: '20px', color: '#3e577c' }}>146€</Typography>
                      <Typography variant="body2" sx={{ color: 'gray', mb: 2, height: '10px' }}>/mois</Typography>
                      <Button
                        variant="contained"
                        sx={{ ...buttonHover, mt: 'auto', backgroundColor: '#ed5ead', color: 'white' }}
                        onClick={() => handleSubscription('proprietaire')}
                      >
                        {t('Abonnement.Souscrire')}
                      </Button>
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        ...metallicEffect('white'),
                        height: '400px',
                        verticalAlign: 'top',
                        padding: '20px',
                        borderRight: '1px solid rgba(62, 87, 124, 0.1)',
                      }}
                    >
                      <Box component="img" src={LogoEntreprise} alt="Logo Entreprise" sx={{ width: '150px', mb: 2 }} />
                      <Typography variant="h4" sx={{ mb: 2, height: '50px', color: '#3e577c', fontWeight: 'bold', textTransform: 'uppercase' }}>{t('Abonnement.Entreprise')}</Typography>
                      <Typography variant="body1" sx={{ mb: 2, height: `${maxDescriptionHeight}px`, textAlign: 'justify', color: '#3e577c' }}>{t('Abonnement.Accès intermédiaire')}</Typography>
                      <Typography variant="body2" sx={{ color: '#77c8d7', fontWeight: 'bold', mb: 2, height: '10px' }}>{t('Abonnement.1 mois offert')}</Typography>
                      <Typography variant="h3" sx={{ mb: 2, height: '20px', color: '#3e577c' }}>243€</Typography>
                      <Typography variant="body2" sx={{ color: 'gray', mb: 2, height: '10px' }}>/mois</Typography>
                      <Button
                        variant="contained"
                        sx={{ ...buttonHover, mt: 'auto', backgroundColor: '#77c8d7', color: 'white' }}
                        onClick={() => handleSubscription('entreprise')}
                      >
                        {t('Abonnement.Souscrire')}
                      </Button>
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        ...metallicEffect('white'),
                        height: '400px',
                        verticalAlign: 'top',
                        padding: '20px',
                        borderRight: '1px solid rgba(62, 87, 124, 0.1)',
                      }}
                    >
                      <Box component="img" src={LogoCompagnie} alt="Logo Compagnie" sx={{ width: '150px', mb: 2 }} />
                      <Typography variant="h4" sx={{ mb: 2, height: '50px', color: '#3e577c', fontWeight: 'bold', textTransform: 'uppercase' }}>{t('Abonnement.Company')}</Typography>
                      <Typography variant="body1" sx={{ mb: 2, height: `${maxDescriptionHeight}px`, textAlign: 'justify', color: '#3e577c' }}>{t('Abonnement.Accès complet et publicitaire')}</Typography>
                      <Typography variant="body2" sx={{ color: '#77c8d7', fontWeight: 'bold', mb: 2, height: '10px' }}>{t('Abonnement.1 mois offert')}</Typography>
                      <Typography variant="h3" sx={{ mb: 2, height: '20px', color: '#3e577c' }}>297€</Typography>
                      <Typography variant="body2" sx={{ color: 'gray', mb: 2, height: '10px' }}>/mois</Typography>
                      <Button
                        variant="contained"
                        sx={{ ...buttonHover, mt: 'auto', backgroundColor: '#3e577c', color: 'white' }}
                        onClick={() => handleSubscription('company')}
                      >
                        {t('Abonnement.Souscrire')}
                      </Button>
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        ...metallicEffect('white'),
                        height: '400px',
                        verticalAlign: 'top',
                        padding: '20px',
                      }}
                    >
                      <Box component="img" src={LogoMultinationale} alt="Logo Multi-nationale" sx={{ width: '150px', mb: 2 }} />
                      <Typography variant="h4" sx={{ mb: 2, height: '50px', color: '#3e577c', fontWeight: 'bold', textTransform: 'uppercase' }}>{t('Abonnement.Multi-nationale')}</Typography>
                      <Typography variant="body1" sx={{ mb: 2, height: `${maxDescriptionHeight}px`, textAlign: 'justify', color: '#3e577c' }}>{t('Abonnement.Accès sur mesure')}</Typography>
                      <Typography variant="body2" sx={{ color: '#77c8d7', fontWeight: 'bold', mb: 2, height: '10px' }}>{t('Abonnement.1 mois offert')}</Typography>
                      <Typography variant="h3" sx={{ mb: 2, height: '20px', color: '#3e577c' }}>Contactez-nous</Typography>
                      <Typography variant="body2" sx={{ color: 'transparent', mb: 2, height: '10px' }}>&nbsp;</Typography>
                      <Button
                        variant="contained"
                        sx={{ ...buttonHover, mt: 'auto', backgroundColor: 'black', color: 'white' }}
                        onClick={handleRDV}
                      >
                        {t('Abonnement.Prendre RDV')}
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {abonnementsData.map((row, index) => (
                    <TableRow key={row.avantage} sx={{ backgroundColor: index % 2 === 0 ? 'rgba(62, 87, 124, 0.05)' : 'white' }}>
                      <TableCell component="th" scope="row" sx={{ color: '#3e577c', fontWeight: 'bold', borderRight: '1px solid rgba(62, 87, 124, 0.1)' }}>
                        {row.avantage}
                      </TableCell>
                      <TableCell align="center" sx={{ color: '#3e577c', width: '20%', borderRight: '1px solid rgba(62, 87, 124, 0.1)' }}>
                        {renderIcon(row.proprietaire)}
                      </TableCell>
                      <TableCell align="center" sx={{ color: '#3e577c', width: '20%', borderRight: '1px solid rgba(62, 87, 124, 0.1)' }}>
                        {renderIcon(row.entreprise)}
                      </TableCell>
                      <TableCell align="center" sx={{ color: '#3e577c', width: '20%', borderRight: '1px solid rgba(62, 87, 124, 0.1)' }}>
                        {renderIcon(row.company)}
                      </TableCell>
                      <TableCell align="center" sx={{ color: '#3e577c', width: '20%' }}>
                        {renderIcon(row.multinationale)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Container>
      </Box>

      <Snackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        message={t('Vous devez être connecté pour souscrire à un abonnement.')}
        autoHideDuration={6000}
      />
    </Box>
  );
};

export default AbonnementsWithoutBackground;
