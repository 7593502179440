import io from 'socket.io-client';
import { api } from '../state/api';

class DiscussionService {
  constructor() {
    this.socket = io(process.env.REACT_APP_API_URL || 'http://localhost:5001');
  }

  async chargerHistorique(incidentId) {
    try {
      const response = await api.endpoints.getDiscussionHistory.initiate(incidentId).unwrap();
      if (!response) {
        throw new Error('Erreur lors du chargement de l\'historique');
      }
      return response.data;
    } catch (error) {
      console.error('Erreur lors du chargement de l\'historique:', error);
      throw error;
    }
  }

  rejoindreDiscussion(incidentId) {
    this.socket.emit('joinIncident', { incidentId });
  }

  quitterDiscussion(incidentId) {
    this.socket.emit('leaveIncident', { incidentId });
  }

  envoyerMessage(incidentId, message, utilisateurId) {
    this.socket.emit('nouveauMessage', {
      incidentId,
      message,
      utilisateurId
    });
  }

  ecouterNouveauxMessages(callback) {
    this.socket.on('messageRecu', callback);
  }

  ecouterStatutLecture(callback) {
    this.socket.on('statutLecture', callback);
  }

  commencerTyping(incidentId, utilisateurId) {
    this.socket.emit('startTyping', { incidentId, utilisateurId });
  }

  arreterTyping(incidentId, utilisateurId) {
    this.socket.emit('stopTyping', { incidentId, utilisateurId });
  }

  ecouterTyping(callback) {
    this.socket.on('isTyping', callback);
  }
}

export default new DiscussionService();