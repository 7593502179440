import React, { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import styled from '@emotion/styled';
import { useParams } from 'react-router-dom';
import { useAllInfoSignalementMutation } from 'state/api';
import FormulaireSignalement from 'components/PageFormulaire';
import WebFont from 'webfontloader';
import { useTranslation } from 'react-i18next';

// Utiliser la variable d'environnement REACT_APP_BASE_URL pour définir la base de l'URL
const BASE_URL = process.env.REACT_APP_BASE_URL || 'http://localhost:5001';

// Styled components for custom styling
const StyledPage = styled.div`
  background-color: ${(props) => props.couleur_de_fond || '#FFFFFF'};
  color: ${(props) => props.couleur_ecriture || '#000000'};
  padding: 16px;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  font-family: ${(props) => props.police_de_caractere}, sans-serif;
  font-size: 18px;
`;

const StyledLogo = styled.img`
  width: 60%;
  height: auto; 
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
`;

const MainPage = () => {
  const { t } = useTranslation();
  const [backgroundColor, setBackgroundColor] = useState('#FFFFFF');
  const [textColor, setTextColor] = useState('#000000');
  const [logoUrl, setLogoUrl] = useState('');
  const [infoFormulaire, setInfoFormulaire] = useState([[], [], []]);

  const { id } = useParams();
  const [allInfo] = useAllInfoSignalementMutation();

  useEffect(() => {
    allInfo({ ID_Signalement: id }).unwrap()
      .then((data) => {
        setInfoFormulaire(data);
        setLogoUrl(data[0].ID_Etablissement);
        setBackgroundColor(data.backgroundColor);
        setTextColor(data.textColor);

        if (data[3] && data[3].URL_police_de_caractere) {
          WebFont.load({
            google: {
              families: [data[3].URL_police_de_caractere],
            },
          });
        }
      })
      .catch((error) => console.error('Error:', error));
  }, [id, allInfo]);

  return (
    <StyledPage couleur_de_fond={infoFormulaire[3] ? infoFormulaire[3].couleur_de_fond : '#FFFFFF'} couleur_ecriture={infoFormulaire[3] ? infoFormulaire[3].couleur_ecriture : '#000000'} police_de_caractere={infoFormulaire[3] ? infoFormulaire[3].police_de_caractere : 'sans-serif'}>
      <StyledLogo src={`${BASE_URL}/public/image/${logoUrl}`} alt="Logo" style={{ maxWidth: '80%', marginTop: '20px' }} />
      <Typography variant="body1" style={{ margin: '60px 0 20px 0', color: textColor, textAlign: 'center' }}>
        {t('MainPageClientS.FindProblem')}
      </Typography>
      <FormulaireSignalement liste_categorie={infoFormulaire[2]} liste_equipement={infoFormulaire[1]} detail_equipement={infoFormulaire[0]} personnalisation={infoFormulaire[3]} mail_de_destination={infoFormulaire[4]} />
    </StyledPage>
  );
};

export default MainPage;
