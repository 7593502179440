import React, { useState, useEffect } from 'react';
import {
  Typography, TextField, Button, Select, FormControl, InputLabel, MenuItem, IconButton, Snackbar, Alert, FormHelperText
} from '@mui/material';
import styled from '@emotion/styled';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const ScrollableContainer = styled('div')`
  position: relative;
  width: 100vw;
  height: 100vh;
  background-color: ${(props) => props.couleur_de_fond || '#FFFFFF'};
  color: ${(props) => props.couleur_ecriture || '#000000'};
  font-family: ${(props) => props.police_de_caractere}, sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  z-index: 10;
`;

const FormContainer = styled('div')`
  width: 100%;
  max-width: 800px;
  max-height: 90vh;
  padding: 20px;
  background-color: ${(props) => props.couleur_de_fond || '#FFFFFF'};
  color: ${(props) => props.couleur_ecriture || '#000000'};
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  margin: 20px;
`;

const LogoContainer = styled('div')`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 32px;

  img {
    max-height: 120px;
    border-radius: 15px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  }

  @media (max-width: 767px) {
    margin-bottom: 16px;
  }
`;

const InputField = styled(TextField)`
  width: 100%;
  margin-bottom: 16px;

  & .MuiOutlinedInput-root {
    background-color: ${(props) => props.couleur_de_fond || '#FFFFFF'};
    color: ${(props) => props.couleur_ecriture || '#000000'};
    border: 1px solid ${(props) => props.couleur_bordure || '#000000'};
  }

  & .MuiInputLabel-outlined {
    color: ${(props) => props.couleur_ecriture || '#000000'};
  }

  & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.couleur_bordure || '#3f51b5'};
  }
`;

const StyledSelect = styled(Select)`
  width: 100%;
  margin-bottom: 16px;

  & .MuiSelect-select {
    background-color: ${(props) => props.couleur_de_fond || 'transparent'};
    color: ${(props) => props.couleur_ecriture || '#000000'};
  }

  & .MuiOutlinedInput-notchedOutline {
    border: 1px solid ${(props) => props.couleur_bordure || '#000000'};
  }

  & .MuiSelect-icon {
    color: ${(props) => props.couleur_ecriture || '#000000'};
  }

  &:hover .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.couleur_bordure || '#3f51b5'};
  }
`;

const StyledMenuItem = styled(MenuItem)`
  background-color: ${(props) => props.couleur_de_fond || '#FFFFFF'} !important;
  color: ${(props) => props.couleur_ecriture || '#000000'} !important;

  &:hover {
    background-color: ${(props) => props.couleur_bouton || '#3f51b5'} !important;
    color: ${(props) => props.couleur_bouton_ecriture || '#FFFFFF'} !important;
  }
`;

const SubmitButton = styled(Button)`
  margin-top: 16px;
  background-color: ${(props) => props.couleur_bouton || '#3f51b5'};
  color: ${(props) => props.couleur_bouton_ecriture || '#FFFFFF'};
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.05);
    background-color: ${(props) => props.couleur_bouton || '#3f51b5'};
  }
`;

const BackButton = styled(IconButton)`
  position: fixed;
  top: 20px;
  left: 20px;
  color: ${(props) => props.couleur_ecriture || '#000000'};
`;

const DemandedeService = ({
  onClose,
  selectedService,
  ID_Etablissement,
  setValidationMessage,
  couleur_de_fond,
  couleur_ecriture,
  couleur_bouton,
  couleur_bouton_ecriture,
  couleur_bordure,
  police_de_caractere,
  logoUrl,
  onFormSubmit
}) => {
  const { t } = useTranslation();
  const userSession = useSelector((state) => state.userAuth); // Getting user session from Redux store
  const [besoin, setBesoin] = useState('');
  const [precisionBesoin, setPrecisionBesoin] = useState('');
  const [messageUtilisateur, setMessageUtilisateur] = useState('');
  const [dateDebut, setDateDebut] = useState('');
  const [dateFin, setDateFin] = useState('');
  const [heureDebut, setHeureDebut] = useState('');
  const [heureFin, setHeureFin] = useState('');
  const [lieu, setLieu] = useState('');
  const [fichier, setFichier] = useState(null);
  const [adresseMailDemandeur, setAdresseMailDemandeur] = useState('');
  const [adresseMailCC, setAdresseMailCC] = useState('');
  const [adresseMailDemandePourAutre, setAdresseMailDemandePourAutre] = useState('');
  const [demandePourAutre, setDemandePourAutre] = useState(false);
  const [selectedAdresseOption, setSelectedAdresseOption] = useState('');
  const [errors, setErrors] = useState({});
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const customStyles = {
    color: couleur_ecriture,
  };

  const menuProps = {
    PaperProps: {
      style: {
        backgroundColor: couleur_de_fond || 'white',
        color: couleur_ecriture,
      },
    },
  };

  useEffect(() => {
    if (userSession.user && userSession.user.email) {
      setAdresseMailDemandeur(userSession.user.email); // Automatically fill email
    }
  }, [userSession]);

  const handleSubmit = () => {
    const newErrors = {};
    if (selectedService.Besoin.length > 0 && !besoin) {
      newErrors.besoin = 'Ce champ est requis';
    }
    if (selectedService.Precision_Besoin.length > 0 && !precisionBesoin) {
      newErrors.precisionBesoin = 'Ce champ est requis';
    }
    if (!messageUtilisateur) {
      newErrors.messageUtilisateur = 'Ce champ est requis';
    }
    if (selectedService.Date_Requise !== 'Non' && !dateDebut) {
      newErrors.dateDebut = 'Ce champ est requis';
    }
    if (
      selectedService.Date_Requise === 'Intervalle_de_date' ||
      selectedService.Date_Requise === 'Intervalle_de_date_avec_heure_unique' ||
      selectedService.Date_Requise === 'Intervalle_de_date_avec_intervalle_dheure'
    ) {
      if (!dateFin) {
        newErrors.dateFin = 'Ce champ est requis';
      }
    }
    if (
      selectedService.Date_Requise === 'Date_unique_avec_heure_unique' ||
      selectedService.Date_Requise === 'Data_unique_avec_intervalle_dheure' ||
      selectedService.Date_Requise === 'Intervalle_de_date_avec_heure_unique' ||
      selectedService.Date_Requise === 'Intervalle_de_date_avec_intervalle_dheure'
    ) {
      if (!heureDebut) {
        newErrors.heureDebut = 'Ce champ est requis';
      }
      if (
        selectedService.Date_Requise === 'Data_unique_avec_intervalle_dheure' ||
        selectedService.Date_Requise === 'Intervalle_de_date_avec_intervalle_dheure'
      ) {
        if (!heureFin) {
          newErrors.heureFin = 'Ce champ est requis';
        }
      }
    }
    if (selectedService.Lieu_Requis && !lieu) {
      newErrors.lieu = 'Ce champ est requis';
    }
    if (!selectedAdresseOption) {
      newErrors.selectedAdresseOption = 'Veuillez sélectionner une adresse mail';
    } else if (selectedAdresseOption === 'Mon adresse' && !adresseMailDemandeur) {
      newErrors.adresseMailDemandeur = 'Veuillez entrer votre adresse mail';
    } else if (selectedAdresseOption === 'Autre adresse' && !adresseMailDemandePourAutre) {
      newErrors.adresseMailDemandePourAutre = 'Veuillez entrer une adresse mail';
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    const formData = {
      Besoin: besoin,
      Precision_Besoin: precisionBesoin,
      Description: messageUtilisateur,
      Date_unique: dateDebut,
      Intervalle_de_date: dateDebut && dateFin ? `${dateDebut} - ${dateFin}` : '',
      Heure_Debut: heureDebut,
      Heure_Fin: heureFin,
      Lieu: lieu,
      Email_signaleur: selectedAdresseOption === 'Autre adresse' ? adresseMailDemandePourAutre : adresseMailDemandeur,
      Email_cc: adresseMailCC.split(','),
      Attachment: fichier,
    };

    onFormSubmit(formData);  // Appel de la fonction onFormSubmit avec les données du formulaire
    onClose();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFichier(file);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const besoinOptions = selectedService?.Besoin?.join(',').split(',').map(option => option.trim()).filter(option => option) || [];
  const precisionBesoinOptions = selectedService?.Precision_Besoin?.join(',').split(',').map(option => option.trim()).filter(option => option) || [];

  return (
    <ScrollableContainer couleur_de_fond={couleur_de_fond} couleur_ecriture={couleur_ecriture} police_de_caractere={police_de_caractere}>
      <BackButton onClick={onClose}>
        <CloseIcon />
      </BackButton>

      <FormContainer couleur_de_fond={couleur_de_fond} couleur_ecriture={couleur_ecriture}>
        {logoUrl && (
          <LogoContainer>
            <img src={logoUrl} alt="Logo" />
          </LogoContainer>
        )}
        <Typography variant="h5" gutterBottom>
          {selectedService?.Titre || t('DemandedeService.title')}
        </Typography>
        <FormControl fullWidth margin="normal">
          <InputLabel style={{ color: couleur_ecriture || '#000000' }}>{t('DemandedeService.besoinLabel')}</InputLabel>
          <StyledSelect
            name="Besoin"
            value={besoin}
            onChange={(e) => setBesoin(e.target.value)}
            couleur_de_fond={couleur_de_fond}
            couleur_ecriture={couleur_ecriture}
            couleur_bordure={couleur_bordure}
            MenuProps={menuProps}
            style={customStyles}
          >
            {besoinOptions.map((option, index) => (
              <StyledMenuItem key={index} value={option}>{option}</StyledMenuItem>
            ))}
          </StyledSelect>
          {errors.besoin && <FormHelperText error>{errors.besoin}</FormHelperText>}
        </FormControl>
        {precisionBesoinOptions.length > 0 && (
          <FormControl fullWidth margin="normal">
            <InputLabel style={{ color: couleur_ecriture || '#000000' }}>{t('DemandedeService.precisionBesoinLabel')}</InputLabel>
            <StyledSelect
              name="Precision_Besoin"
              value={precisionBesoin}
              onChange={(e) => setPrecisionBesoin(e.target.value)}
              couleur_de_fond={couleur_de_fond}
              couleur_ecriture={couleur_ecriture}
              couleur_bordure={couleur_bordure}
              MenuProps={menuProps}
              style={customStyles}
            >
              {precisionBesoinOptions.map((option, index) => (
                <StyledMenuItem key={index} value={option}>{option}</StyledMenuItem>
              ))}
            </StyledSelect>
            {errors.precisionBesoin && <FormHelperText error>{errors.precisionBesoin}</FormHelperText>}
          </FormControl>
        )}
        {selectedService?.Date_Requise !== 'Non' && (
          <>
            <InputField
              label={t('DemandedeService.dateRequiseLabel')}
              type="date"
              fullWidth
              margin="normal"
              value={dateDebut}
              onChange={(e) => setDateDebut(e.target.value)}
              InputLabelProps={{
                shrink: true,
                style: { color: couleur_ecriture },
              }}
              inputProps={{ style: customStyles }}
              error={!!errors.dateDebut}
              helperText={errors.dateDebut}
              couleur_de_fond={couleur_de_fond}
              couleur_ecriture={couleur_ecriture}
              couleur_bordure={couleur_bordure}
            />
            {(selectedService.Date_Requise.includes('intervalle') || selectedService.Date_Requise === 'Intervalle_de_date_avec_heure_unique') && (
              <InputField
                label={t('DemandedeService.dateFinLabel')}
                type="date"
                fullWidth
                margin="normal"
                value={dateFin}
                onChange={(e) => setDateFin(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                  style: { color: couleur_ecriture },
                }}
                inputProps={{ style: customStyles }}
                error={!!errors.dateFin}
                helperText={errors.dateFin}
                couleur_de_fond={couleur_de_fond}
                couleur_ecriture={couleur_ecriture}
                couleur_bordure={couleur_bordure}
              />
            )}
            {selectedService.Date_Requise?.includes('heure') && (
              <>
                <InputField
                  label={t('DemandedeService.heureDebutLabel')}
                  type="time"
                  fullWidth
                  margin="normal"
                  value={heureDebut}
                  onChange={(e) => setHeureDebut(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                    style: { color: couleur_ecriture },
                  }}
                  inputProps={{ style: customStyles }}
                  error={!!errors.heureDebut}
                  helperText={errors.heureDebut}
                  couleur_de_fond={couleur_de_fond}
                  couleur_ecriture={couleur_ecriture}
                  couleur_bordure={couleur_bordure}
                />
                {(selectedService.Date_Requise === 'Data_unique_avec_intervalle_dheure' ||
                  selectedService.Date_Requise === 'Intervalle_de_date_avec_intervalle_dheure') && (
                  <InputField
                    label={t('DemandedeService.heureFinLabel')}
                    type="time"
                    fullWidth
                    margin="normal"
                    value={heureFin}
                    onChange={(e) => setHeureFin(e.target.value)}
                    InputLabelProps={{
                      shrink: true,
                      style: { color: couleur_ecriture },
                    }}
                    inputProps={{ style: customStyles }}
                    error={!!errors.heureFin}
                    helperText={errors.heureFin}
                    couleur_de_fond={couleur_de_fond}
                    couleur_ecriture={couleur_ecriture}
                    couleur_bordure={couleur_bordure}
                  />
                )}
              </>
            )}
          </>
        )}
        {selectedService?.Lieu_Requis && (
          <FormControl fullWidth margin="normal">
            <InputLabel style={{ color: couleur_ecriture || '#000000' }}>{t('DemandedeService.lieuLabel')}</InputLabel>
            <StyledSelect
              name="Lieu"
              value={lieu}
              onChange={(e) => setLieu(e.target.value)}
              couleur_de_fond={couleur_de_fond}
              couleur_ecriture={couleur_ecriture}
              couleur_bordure={couleur_bordure}
              MenuProps={menuProps}
              style={customStyles}
            >
              {/* Map through lieux options */}
            </StyledSelect>
            {errors.lieu && <FormHelperText error>{errors.lieu}</FormHelperText>}
          </FormControl>
        )}
        {selectedService?.Fichier_Requis && (
          <FormControl fullWidth margin="normal">
            <InputField
              type="file"
              onChange={handleFileChange}
              InputLabelProps={{ style: { display: 'none' } }}
              inputProps={{
                accept: '.doc,.docx,.pdf,.ppt,.pptx',
                style: {
                  backgroundColor: couleur_de_fond,
                  color: couleur_ecriture,
                  border: `1px solid ${couleur_bordure}`
                }
              }}
              error={!!errors.fichier}
              helperText={errors.fichier}
            />
          </FormControl>
        )}
        <InputField
          label={t('DemandedeService.messageUtilisateurLabel')}
          fullWidth
          margin="normal"
          multiline
          rows={4}
          value={messageUtilisateur}
          onChange={(e) => setMessageUtilisateur(e.target.value)}
          InputLabelProps={{
            style: {
              color: couleur_ecriture,
              border: `1px solid ${couleur_bordure}`
            },
          }}
          inputProps={{ style: customStyles }}
          error={!!errors.messageUtilisateur}
          helperText={errors.messageUtilisateur || 'Ce champ est requis'}
          couleur_de_fond={couleur_de_fond}
          couleur_ecriture={couleur_ecriture}
          couleur_bordure={couleur_bordure}
        />
        <FormControl fullWidth margin="normal">
          <InputLabel style={{ color: couleur_ecriture || '#000000' }}>{t('DemandedeService.adresseMailLabel')}</InputLabel>
          <StyledSelect
            value={selectedAdresseOption}
            onChange={(e) => {
              const selectedOption = e.target.value;
              setSelectedAdresseOption(selectedOption);
              setDemandePourAutre(selectedOption === 'Autre adresse');
            }}
            label={t('DemandedeService.adresseMailLabel')}
            couleur_de_fond={couleur_de_fond}
            couleur_ecriture={couleur_ecriture}
            couleur_bordure={couleur_bordure}
            MenuProps={menuProps}
            style={customStyles}
          >
            <MenuItem value="Mon adresse">{t('DemandedeService.adresseMailDemandeurLabel')}</MenuItem>
            <MenuItem value="Autre adresse">{t('DemandedeService.adresseMailAutreLabel')}</MenuItem>
          </StyledSelect>
          {errors.selectedAdresseOption && <FormHelperText error>{errors.selectedAdresseOption}</FormHelperText>}
        </FormControl>
        {selectedAdresseOption === 'Mon adresse' && (
          <InputField
            label={t('DemandedeService.adresseMailDemandeurLabel')}
            fullWidth
            margin="normal"
            value={adresseMailDemandeur}
            onChange={(e) => setAdresseMailDemandeur(e.target.value)}
            InputLabelProps={{
              style: { color: couleur_ecriture },
            }}
            inputProps={{ style: customStyles }}
            error={!!errors.adresseMailDemandeur}
            helperText={errors.adresseMailDemandeur}
            couleur_de_fond={couleur_de_fond}
            couleur_ecriture={couleur_ecriture}
            couleur_bordure={couleur_bordure}
          />
        )}
        {selectedAdresseOption === 'Autre adresse' && (
          <InputField
            label={t('DemandedeService.adresseMailAutreLabel')}
            fullWidth
            margin="normal"
            value={adresseMailDemandePourAutre}
            onChange={(e) => setAdresseMailDemandePourAutre(e.target.value)}
            InputLabelProps={{
              style: { color: couleur_ecriture },
            }}
            inputProps={{ style: customStyles }}
            error={!!errors.adresseMailDemandePourAutre}
            helperText={errors.adresseMailDemandePourAutre}
            couleur_de_fond={couleur_de_fond}
            couleur_ecriture={couleur_ecriture}
            couleur_bordure={couleur_bordure}
          />
        )}
        <InputField
          label={t('DemandedeService.adresseMailCCLabel')}
          fullWidth
          margin="normal"
          value={adresseMailCC}
          onChange={(e) => setAdresseMailCC(e.target.value)}
          helperText={errors.adresseMailCC || t('DemandedeService.ccHelperText')}
          InputLabelProps={{
            style: { color: couleur_ecriture },
          }}
          inputProps={{ style: customStyles }}
          error={!!errors.adresseMailCC}
          couleur_de_fond={couleur_de_fond}
          couleur_ecriture={couleur_ecriture}
          couleur_bordure={couleur_bordure}
        />

        <SubmitButton
          onClick={handleSubmit}
          couleur_bouton={couleur_bouton}
          couleur_bouton_ecriture={couleur_bouton_ecriture}
        >
          {t('DemandedeService.submitButton')}
        </SubmitButton>
      </FormContainer>

      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </ScrollableContainer>
  );
};

export default DemandedeService;