// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.subscription-result {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    padding: 20px;
    text-align: center;
  }
  
  .subscription-result h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  .subscription-result p {
    font-size: 1.25rem;
    margin-bottom: 40px;
  }
  
  .subscription-result.success {
    background-color: #4caf50;
    color: white;
  }
  
  .subscription-result.error {
    background-color: #f44336;
    color: white;
  }
  
  .subscription-result button {
    background-color: white;
    color: #000;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    border: none;
    border-radius: 5px;
  }
  
  .subscription-result button:hover {
    background-color: #ddd;
  }
  
  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    font-size: 2rem;
  }
  `, "",{"version":3,"sources":["webpack://./src/scenes/subscriptionresult/SubscriptionResult.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,iBAAiB;IACjB,aAAa;IACb,kBAAkB;EACpB;;EAEA;IACE,iBAAiB;IACjB,mBAAmB;EACrB;;EAEA;IACE,kBAAkB;IAClB,mBAAmB;EACrB;;EAEA;IACE,yBAAyB;IACzB,YAAY;EACd;;EAEA;IACE,yBAAyB;IACzB,YAAY;EACd;;EAEA;IACE,uBAAuB;IACvB,WAAW;IACX,kBAAkB;IAClB,eAAe;IACf,eAAe;IACf,YAAY;IACZ,kBAAkB;EACpB;;EAEA;IACE,sBAAsB;EACxB;;EAEA;IACE,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,iBAAiB;IACjB,eAAe;EACjB","sourcesContent":[".subscription-result {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    min-height: 100vh;\n    padding: 20px;\n    text-align: center;\n  }\n  \n  .subscription-result h1 {\n    font-size: 2.5rem;\n    margin-bottom: 20px;\n  }\n  \n  .subscription-result p {\n    font-size: 1.25rem;\n    margin-bottom: 40px;\n  }\n  \n  .subscription-result.success {\n    background-color: #4caf50;\n    color: white;\n  }\n  \n  .subscription-result.error {\n    background-color: #f44336;\n    color: white;\n  }\n  \n  .subscription-result button {\n    background-color: white;\n    color: #000;\n    padding: 10px 20px;\n    font-size: 1rem;\n    cursor: pointer;\n    border: none;\n    border-radius: 5px;\n  }\n  \n  .subscription-result button:hover {\n    background-color: #ddd;\n  }\n  \n  .loading {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    min-height: 100vh;\n    font-size: 2rem;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
