import React from "react";
import { Box, TextField, MenuItem, Select, InputLabel, FormControl } from "@mui/material";
import { useTranslation } from 'react-i18next';

const NavbarIncidentSG = ({ filters, onFiltersChange, typeDactions, groupes }) => {
  const { t } = useTranslation();

  const handleChange = (event) => {
    const { name, value } = event.target;
    onFiltersChange({ [name]: value });
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", marginBottom: "20px" }}>
      <Box sx={{ display: "flex", justifyContent: "center", flexWrap: "wrap" }}>
        <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }}>
          <InputLabel>{t('NavbarIncidentSG.equipment_type')}</InputLabel>
          <Select
            name="equipementType"
            value={filters.equipementType}
            onChange={handleChange}
            label={t('NavbarIncidentSG.equipment_type')}
          >
            <MenuItem value="all">{t('NavbarIncidentSG.all')}</MenuItem>
            <MenuItem value="equipement">{t('NavbarIncidentSG.equipment_type')}</MenuItem>
            <MenuItem value="equipement_particulier">Équipement Particulier</MenuItem>
            <MenuItem value="service">Service</MenuItem>
          </Select>
        </FormControl>

        <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }}>
          <InputLabel>{t('NavbarIncidentSG.severity')}</InputLabel>
          <Select
            name="severite"
            value={filters.severite}
            onChange={handleChange}
            label={t('NavbarIncidentSG.severity')}
          >
            <MenuItem value="all">{t('NavbarIncidentSG.all')}</MenuItem>
            <MenuItem value="1">1</MenuItem>
            <MenuItem value="2">2</MenuItem>
            <MenuItem value="3">3</MenuItem>
          </Select>
        </FormControl>

        <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }}>
          <InputLabel>{t('NavbarIncidentSG.priority')}</InputLabel>
          <Select
            name="priorite"
            value={filters.priorite}
            onChange={handleChange}
            label={t('NavbarIncidentSG.priority')}
          >
            <MenuItem value="all">{t('NavbarIncidentSG.all')}</MenuItem>
            <MenuItem value="1">1</MenuItem>
            <MenuItem value="2">2</MenuItem>
            <MenuItem value="3">3</MenuItem>
          </Select>
        </FormControl>

        <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }}>
          <InputLabel>{t('NavbarIncidentSG.action_type')}</InputLabel>
          <Select
            name="typeDaction"
            value={filters.typeDaction}
            onChange={handleChange}
            label={t('NavbarIncidentSG.action_type')}
          >
            <MenuItem value="all">{t('NavbarIncidentSG.all')}</MenuItem>
            {typeDactions.map((type) => (
              <MenuItem key={type.ID_Type_Daction_SG} value={type.ID_Type_Daction_SG.toString()}>
                {type.Titre}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Box sx={{ display: "flex", justifyContent: "center", flexWrap: "wrap" }}>
        <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }}>
          <InputLabel>{t('NavbarIncidentSG.urgency')}</InputLabel>
          <Select
            name="urgence"
            value={filters.urgence}
            onChange={handleChange}
            label={t('NavbarIncidentSG.urgency')}
          >
            <MenuItem value="all">{t('NavbarIncidentSG.all')}</MenuItem>
            <MenuItem value="0">0</MenuItem>
            <MenuItem value="1">1</MenuItem>
            <MenuItem value="2">2</MenuItem>
            <MenuItem value="3">3</MenuItem>
            <MenuItem value="4">4</MenuItem>
          </Select>
        </FormControl>

        <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }}>
          <InputLabel>{t('NavbarIncidentSG.responsible_group')}</InputLabel>
          <Select
            name="groupResponsable"
            value={filters.groupResponsable}
            onChange={handleChange}
            label={t('NavbarIncidentSG.responsible_group')}
          >
            <MenuItem value="all">{t('NavbarIncidentSG.all')}</MenuItem>
            {groupes.map((groupe) => (
              <MenuItem key={groupe.ID_Groupe} value={groupe.ID_Groupe.toString()}>
                {groupe.Nom_groupe}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }}>
          <InputLabel>{t('NavbarIncidentSG.for')}</InputLabel>
          <Select
            name="pour"
            value={filters.pour}
            onChange={handleChange}
            label={t('NavbarIncidentSG.for')}
          >
            <MenuItem value="all">{t('NavbarIncidentSG.all')}</MenuItem>
            <MenuItem value="groupe">{t('NavbarIncidentSG.my_group')}</MenuItem>
            <MenuItem value="moi">{t('NavbarIncidentSG.me')}</MenuItem>
          </Select>
        </FormControl>

        <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }}>
          <InputLabel>{t('NavbarIncidentSG.date_order')}</InputLabel>
          <Select
            name="dateOrder"
            value={filters.dateOrder}
            onChange={handleChange}
            label={t('NavbarIncidentSG.date_order')}
          >
            <MenuItem value="none">{t('NavbarIncidentSG.none')}</MenuItem>
            <MenuItem value="asc">{t('NavbarIncidentSG.date_order_asc')}</MenuItem>
            <MenuItem value="desc">{t('NavbarIncidentSG.date_order_desc')}</MenuItem>
          </Select>
        </FormControl>

        <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }}>
          <InputLabel>{t('NavbarIncidentSG.ticket_state')}</InputLabel>
          <Select
            name="etatTicket"
            value={filters.etatTicket}
            onChange={handleChange}
            label={t('NavbarIncidentSG.ticket_state')}
          >
            <MenuItem value="default">{t('NavbarIncidentSG.default')}</MenuItem>
            <MenuItem value="creation">{t('NavbarIncidentSG.creation')}</MenuItem>
            <MenuItem value="assigne">{t('NavbarIncidentSG.assigned')}</MenuItem>
            <MenuItem value="en cours">{t('NavbarIncidentSG.in_progress')}</MenuItem>
            <MenuItem value="termine">{t('NavbarIncidentSG.completed')}</MenuItem>
            <MenuItem value="cloture">{t('NavbarIncidentSG.closed')}</MenuItem>
            <MenuItem value="abus">{t('NavbarIncidentSG.abuse')}</MenuItem>
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
};

export default NavbarIncidentSG;
