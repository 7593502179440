import React, { useState, useEffect, useRef } from 'react';
import { Typography, TextField, Box, IconButton, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import HomeIcon from '@mui/icons-material/Home';
import styled from '@emotion/styled';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useAllInfoNoeudMutation, useGetAllInfoSectorMutation, usePublicGetInfoRegleSpecialMutation } from 'state/api';
import WebFont from 'webfontloader';
import { useTranslation } from 'react-i18next';
import NoeudSelector from './NoeudSelector';
import ValidationMessage from '../ValidationMessage';
import ErrorMessage from '../ErrorMessage';

// Utilisation des variables d'environnement pour l'URL de base
const BASE_URL = process.env.REACT_APP_BASE_URL || 'http://localhost:5001';

const StyledPage = styled('div')`
  background-color: ${(props) => props.couleur_de_fond || '#FFFFFF'} !important;
  color: ${(props) => props.couleur_ecriture || '#000000'} !important;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-family: ${(props) => props.police_de_caractere}, sans-serif;
  font-size: 18px;
  padding: 16px;
  box-sizing: border-box;
  overflow-x: hidden !important;
  @media (max-width: 767px) {
    padding: 8px;
  }

  &::-webkit-scrollbar {
    width: 12px !important;
    background-color: ${(props) => props.couleur_de_fond || '#FFFFFF'} !important;
  }

  &::-webkit-scrollbar-track {
    background-color: ${(props) => props.couleur_de_fond || '#FFFFFF'} !important;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.couleur_ecriture || '#000000'} !important;
    border-radius: 10px !important;
  }
`;

const StyledButton = styled(Button)(({ style }) => ({
  width: '250px',
  height: 'auto',
  margin: '20px',
  borderRadius: '10px',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '20px',
  overflow: 'hidden',
  boxShadow: `5px 5px 10px ${style.boxShadow || 'rgba(0, 0, 0, 0.2)'}`,
  '& > img': {
    maxWidth: '80%',
    maxHeight: '80%',
    marginBottom: '10px',
    zIndex: 1,
  },
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'linear-gradient(to bottom right, rgba(255, 255, 255, 0.5), transparent)',
    zIndex: 0,
  },
  ...style,
}));

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  margin: auto;
  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

const CategorieButton = ({ styleProps, categorie, onClick, srcImage }) => {
  const urlImage = `${BASE_URL}/images/displayimagewithpath/${encodeURIComponent(srcImage)}`;

  const dynamicStyles = {
    backgroundColor: styleProps.couleur_de_case,
    color: styleProps.couleur_ecriture,
    boxShadow: `5px 5px 10px ${styleProps.couleur_dombre_de_case}`,
  };

  return (
    <StyledButton style={dynamicStyles} onClick={onClick}>
      <img src={urlImage} alt={categorie} />
      <Typography variant="body1" style={{ color: styleProps.couleur_bouton_ecriture, fontFamily: styleProps.police_de_caractere }}>
        {categorie}
      </Typography>
    </StyledButton>
  );
};

const TextContainer = styled.div`
  text-align: center;
  margin-bottom: 20px;
  @media (min-width: 768px) {
    margin-top: 80px;
    margin-bottom: 40px;
    font-size: 1.5em;
  }
`;

const Title = styled(Typography)`
  color: ${(props) => props.couleur_ecriture} !important;
  font-family: ${(props) => props.police_de_caractere} !important;
  font-size: 1.5vw !important;
  margin-bottom: 10px !important;
  @media (max-width: 767px) {
    font-size: 2vw !important;
  }
`;

const Explanation = styled(Typography)`
  color: ${(props) => props.couleur_ecriture} !important;
  font-family: ${(props) => props.police_de_caractere} !important;
  font-size: 0.75vw !important;
  @media (max-width: 767px) {
    font-size: 1.25vw !important;
  }
`;

const AccessDeniedMessage = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: #ffffff;

  .access-denied-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ff0000;
    padding: 20px;
    border-radius: 10px;
    color: #ffffff;
    font-size: 2rem;
    position: relative;

    .icon {
      font-size: 4rem;
      margin-bottom: 16px;
    }

    .home-button {
      position: absolute;
      top: 10px;
      left: 10px;
    }
  }
`;

const FormulaireFrontAcceuil = () => {
  const { ID_Noeud, ID_Etablissement } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const userSession = useSelector((state) => state.userAuth);
  const [getInfoWithNoeudCheck] = useAllInfoNoeudMutation();
  const [getInfoSector] = useGetAllInfoSectorMutation();
  const [getInfoRegleSpecial] = usePublicGetInfoRegleSpecialMutation();
  const [personnalisation, setPersonnalisation] = useState({});
  const [noeuds, setNoeuds] = useState([]);
  const [isNoeudOrigine, setIsNoeudOrigine] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [accessDenied, setAccessDenied] = useState(false);
  const redirecting = useRef(false);

  useEffect(() => {
    const checkRegleSpecial = async () => {
      try {
        const regleSpecial = await getInfoRegleSpecial({ ID_Etablissement }).unwrap();
        
        if (regleSpecial.ConnexionPrive) {
          if (!userSession.isAuthenticated || !userSession.user || !userSession.user.email) {
            const redirectPath = encodeURIComponent(window.location.pathname);
            if (!redirecting.current) {
              redirecting.current = true;
              navigate(`/loginclient?redirectTo=${redirectPath}`);
            }
            return;
          }

          const userDomain = userSession.user.email.split('@').pop();
          const isDomainAllowed = regleSpecial.DomainePrive.some(domain => userDomain.endsWith(domain.trim()));

          if (!isDomainAllowed) {
            setAccessDenied(true);
            return;
          }
        }
        
        fetchInfo();
      } catch (error) {
        console.error('Erreur lors de la vérification des règles spéciales:', error);
        setErrorMessage(t('FormulaireFrontAcceuil.error_rule_check'));
      }
    };

    const fetchInfo = async () => {
      if (ID_Noeud && ID_Etablissement) {
        getInfoWithNoeudCheck({ ID_Noeud, ID_Etablissement })
          .unwrap()
          .then((data) => {
            setPersonnalisation(data.personnalisation || {});
            setIsNoeudOrigine(data.isNoeudOrigine);
            if (data.personnalisation && data.personnalisation.URL_police_de_caractere) {
              WebFont.load({
                google: {
                  families: [data.personnalisation.URL_police_de_caractere]
                }
              });
            }
          })
          .catch((error) => {
            console.error('Erreur lors de la récupération des données de personnalisation:', error);
          });

        getInfoSector({ ID_Etablissement })
          .unwrap()
          .then((data) => {
            setNoeuds(data[0].children || []);
          })
          .catch((error) => {
            console.error('Erreur lors de la récupération des données de secteur:', error);
          });
      } else {
      }
    };

    checkRegleSpecial();
  }, [ID_Noeud, ID_Etablissement, getInfoWithNoeudCheck, getInfoSector, getInfoRegleSpecial, userSession, navigate, t]);

  const handleCategoryClick = (category) => {
    if (category === 'signalement') {
      navigate(`/client/signalement/${ID_Noeud}/${ID_Etablissement}`);
    } else if (category === 'services') {
      navigate(`/client/services/${ID_Noeud}/${ID_Etablissement}`);
    } else if (category === 'feedback') {
      navigate(`/client/feedback/${ID_Noeud}/${ID_Etablissement}`);
    }
  };

  const handleHomeClick = () => {
    navigate('/infoutilisateur');
  };

  const categories = [
    { id: 'signalement', name: t('FormulaireFrontAcceuil.signalement'), image: 'client\\signalement.png' },
    { id: 'services', name: t('FormulaireFrontAcceuil.services'), image: 'client\\services.png' },
    { id: 'feedback', name: t('FormulaireFrontAcceuil.feedback'), image: 'client\\feedback.png' },
  ];

  if (accessDenied) {
    return (
      <AccessDeniedMessage>
        <Button
          variant="contained"
          color="primary"
          startIcon={<HomeIcon />}
          onClick={handleHomeClick}
          className="home-button"
          sx={{ position: 'fixed', top: 10, left: 10 }}
        >
          {t('FormulaireFrontAcceuil.home')}
        </Button>
        <div className="access-denied-box">
          <div className="icon">⚠️</div>
          {t('FormulaireFrontAcceuil.access_denied')}
        </div>
      </AccessDeniedMessage>
    );
  }

  return (
    <StyledPage
      couleur_de_fond={personnalisation.couleur_de_fond}
      couleur_ecriture={personnalisation.couleur_ecriture}
      police_de_caractere={personnalisation.police_de_caractere}
    >
      <TextContainer>
        <Title couleur_ecriture={personnalisation.couleur_ecriture} police_de_caractere={personnalisation.police_de_caractere}>
          {t('FormulaireFrontAcceuil.title')}
        </Title>
        <Explanation couleur_ecriture={personnalisation.couleur_ecriture} police_de_caractere={personnalisation.police_de_caractere}>
          {t('FormulaireFrontAcceuil.explanation')}
        </Explanation>
      </TextContainer>
      <ButtonContainer>
        {categories.map((category) => (
          <CategorieButton
            key={category.id}
            styleProps={personnalisation}
            categorie={category.name}
            onClick={() => handleCategoryClick(category.id)}
            srcImage={category.image}
          />
        ))}
      </ButtonContainer>
      <NoeudSelector styleProps={personnalisation} noeuds={noeuds} />
      {errorMessage && (
        <ErrorMessage message={errorMessage} setError={setErrorMessage} />
      )}
    </StyledPage>
  );
};

export default FormulaireFrontAcceuil;
