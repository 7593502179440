import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const SubscriptionRoute = ({ children }) => {
  const isAdminAuthenticated = useSelector((state) => state.adminAuth.isAuthenticated);
  const abonnementType = useSelector((state) => state.adminAuth.abonnementType);
  console.log("abonnementType", abonnementType);
  // Vérifier si l'utilisateur est authentifié et si l'abonnement est "entreprise" ou "company"
  if (isAdminAuthenticated && (abonnementType === "entreprise" || abonnementType === "company")) {
    return children;
  } else {
    return <Navigate to="/accueil" />;
  }
};

export default SubscriptionRoute;
