import React from "react";
import { Box } from "@mui/material";
import Header from "components/Header";
import TreeIT from "../../components/informatique/TreeIT";
import { useTranslation } from "react-i18next";

const SecteurIT = () => {
  const { t } = useTranslation();

  return (
    <Box m="1.5rem 2.5rem">
      <Header title={t('SecteurIT.title')} subtitle={t('SecteurIT.subtitle')} />
      <div className="app-container">
        <h1>{t('SecteurIT.header')}</h1>
        <TreeIT />
      </div>
    </Box>
  );
};

export default SecteurIT;
