import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, List, ListItem, ListItemText, Container, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/system';
import Navbar from '../../components_public/navbar.jsx';
import logoBleuFonce from '../../assets/logo_hurflym_sans_fond_bleu_fonce.png';
import logoBleuClair from '../../assets/logo_hurflym_sans_fond_bleu_clair.png';

const sections = [
  'Introduction',
  'Secteur',
  'Signalement',
  'Equipement',
  'Equipement Particulier',
  'Personnalisation',
  'Service',
  'Feedback',
  'Portails',
  'Administration',
  'Statistique',
  'API',
];

const Logo = styled('img')({
  width: '20px',
  height: '20px',
  position: 'absolute',
  left: '10px',
  marginLeft: '-10px',
});

const DocDeveloper = () => {
  const { t } = useTranslation();
  const [selectedSection, setSelectedSection] = useState('Introduction');
  const sectionRefs = useRef({});
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setSelectedSection(entry.target.id);
          }
        });
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.6,
      }
    );

    sections.forEach((section) => {
      const element = sectionRefs.current[section];
      if (element) {
        observer.observe(element);
      }
    });

    return () => {
      sections.forEach((section) => {
        const element = sectionRefs.current[section];
        if (element) {
          observer.unobserve(element);
        }
      });
    };
  }, []);

  const handleSectionClick = (section) => {
    setSelectedSection(section);
    const element = sectionRefs.current[section];
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const renderContent = (section) => {
    return (
      <Typography sx={{ color: 'black', textAlign: 'justify' }}>
        {t(`Documentation.${section}Text`)}
      </Typography>
    );
  };

  return (
    <Box sx={{ width: '100%', minHeight: '100vh', backgroundColor: 'white' }}>
      <Navbar />
      <Container maxWidth="xl" sx={{ pt: 4, display: 'flex', flexDirection: isMobile ? 'column' : 'row' }}>
        {!isMobile && (
          <Box sx={{ width: '25%', position: 'sticky', top: 'calc(15% + 80px)', alignSelf: 'flex-start', display: 'flex', justifyContent: 'flex-start', pl: 4 }}>
            <List>
              {sections.map((section) => (
                <ListItem
                  button
                  key={section}
                  onClick={() => handleSectionClick(section)}
                  selected={selectedSection === section}
                  sx={{
                    padding: '5px 0',
                    color: selectedSection === section ?  '#77c8d7' :'#3e577c',
                    cursor: 'pointer',
                    position: 'relative',
                    pl: 4,
                    mb: '-2px',
                    borderRadius: '8px',
                    border: selectedSection === section ? '2px solid #77c8d7' : 'none',
                    transition: 'border 0.3s ease',
                  }}
                >
                  <Logo src={selectedSection === section ? logoBleuClair : logoBleuFonce} alt="Logo" />
                  <ListItemText primary={t(`Documentation.${section}`)} />
                </ListItem>
              ))}
            </List>
          </Box>
        )}
        <Box sx={{ width: isMobile ? '100%' : '70%', pl: isMobile ? 0 : 8 }}>
          <Typography variant="h3" sx={{ color: '#3e577c', fontWeight: 'bold', textAlign: 'center', mb: 4, mt: isMobile ? '20%' : '15%' }}>
            TOUT SAVOIR SUR HURFLYM
          </Typography>
          {sections.map((section) => (
            <Box
              id={section}
              key={section}
              ref={(el) => (sectionRefs.current[section] = el)}
              sx={{ mb: 6 }}
            >
              <Typography variant="h5" sx={{ mb: 2, color: '#3e577c', fontWeight: 'bold' }}>
                {t(`Documentation.${section}`)}
              </Typography>
              {renderContent(section)}
            </Box>
          ))}
        </Box>
      </Container>
    </Box>
  );
};

export default DocDeveloper;
