import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, TextField, Dialog, DialogTitle, DialogContent, DialogActions, Grid, List,
  ListItem,
  ListItemText,
  IconButton,
  TablePagination, Select, MenuItem, FormControlLabel, Checkbox, Tooltip } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useGetAllInfoRoleMutation, useGetAllInfoListeUtilisateurEtablissementMutation, useAddAdministrateurMutation, useDeleteListeUtilisateurEtablissementMutation, useModifyListeUtilisateurEtablissementMutation, useSearchListeUtilisateurEtablissementMutation, useGetGroupedAdminsMutation, useCreateGroupKeysMutation } from 'state/api';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import InfoIcon from '@mui/icons-material/Info';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import ErrorMessage from './ErrorMessage';
import ValidationMessage from './ValidationMessage';
import InfoMessage from './InfoMessage';
import RoleSelector from './RoleSelector';
import { useTranslation } from 'react-i18next';


const RenderAdministrateurs = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [openInviteKey, setOpenInviteKey] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState('');
  const [expirationDate, setExpirationDate] = useState(() => {
    const date = new Date();
    date.setDate(date.getDate() + 7);
    return date.toISOString().split('T')[0];
  });
  const [generatedKey, setGeneratedKey] = useState('');
  const [groups, setGroups] = useState([]);
  const [inputsDisabled, setInputsDisabled] = useState(false);

  const [isLoading, setIsLoading] = useState(false); // Ajout d'un état de chargement
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [administrateurs, setAdministrateurs] = useState([]);
  const [openEditDialog, setOpenEditDialog] = useState(false); // État pour ouvrir/fermer la pop-up d'édition

  const [mailAdministrateurRecherche, setMailAdministrateurRecherche] = useState('');
  const [mailAdministrateurAjout, setMailAdministrateurAjout] = useState('');


  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [administrateurToDelete, setAdministrateurToDelete] = useState(null);

  const [editAdministrateur, setEditAdministrateur] = useState(false);

  const [nouvelAdministrateurRole, setNouvelAdministrateurRole] = useState('');

  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState('');
  const [getAllInfoRole, { data: rolesData }] = useGetAllInfoRoleMutation();
  const [getGroupedAdmins] = useGetGroupedAdminsMutation();

  const userSession = useSelector((state) => state.adminAuth);

  const fetchGroups = async () => {
    try {
      const response = await getGroupedAdmins({
        ID_Etablissement: userSession.ID_Etablissement
      });
      if (response.data) {
        setGroups(response.data);
      }
    } catch (err) {
      popUpError("Erreur lors de la récupération des groupes");
    }
  };

  useEffect(() => {
    fetchGroups();
  }, []);

  const handleOpenInviteKey = () => {
    setOpenInviteKey(true);
    setInputsDisabled(false);
    setGeneratedKey('');
  };

  const handleCloseInviteKey = () => {
    setOpenInviteKey(false);
    setSelectedGroup('');
    setExpirationDate(null);
    setGeneratedKey('');
    setInputsDisabled(false);
  };

  const [createGroupKeys] = useCreateGroupKeysMutation();

  const handleCreateInviteKey = async () => {
    try {
      setIsLoading(true);
      const response = await createGroupKeys({
        ID_Etablissement: userSession.ID_Etablissement,
        ID_Groupe: selectedGroup,
        expirationDate: expirationDate,
        nombreCles: 1
      });
      console.log(response);
      if (response.data?.success) {
        setGeneratedKey(response.data.data.activationKey);
        setInputsDisabled(true);
      } else {
        popUpError("Erreur lors de la création de la clé");
      }
    } catch (err) {
      popUpError("Erreur lors de la création de la clé");
    } finally {
      setIsLoading(false);
    }
  };

  const handleCopyKey = () => {
    navigator.clipboard.writeText(generatedKey);
    popUpValidation('Clé copiée dans le presse-papier');
  };

  const fetchRole = async () => {
    try {
      if(roles!== ''){
        setIsLoading(true);
      const response = await getAllInfoRole({
        ID_Etablissement: userSession.ID_Etablissement,
      });
      const data = response.data;
      if (data) {
        setRoles(data);
      }
      }
      
    } catch (err) {
      if(err.originalStatus === 401){
        alert('Votre session a expiré. Veuillez vous reconnecter.');
        window.location = '/loginuser';
      }
      popUpError('Erreur lors de la récuparation des roles.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchRole();
  }, [getAllInfoRole, userSession.ID_Etablissement]);

  const handleRoleChange = (newRoleId) => {
    setSelectedRole(newRoleId);
  };

  const theme = useTheme();
  const buttonColor =  theme.palette.primary.main;
  const textColor = theme.palette.mode === 'dark' ? theme.palette.primary.light : theme.palette.secondary.main;
  
  // État pour les permissions sélectionnées
  const [selectedPermissions, setSelectedPermissions] = useState([]);




  const [deleteAdministrateur] = useDeleteListeUtilisateurEtablissementMutation();

  const [getAllInfoAdministrateur] = useGetAllInfoListeUtilisateurEtablissementMutation();


  const [addAdministrateur] = useAddAdministrateurMutation();


  const handleCloseEdit = () => {
    setEditAdministrateur(null);
    setSelectedPermissions([]);
    setOpenEditDialog(false);
  };

  const [modifyAdministrateur] = useModifyListeUtilisateurEtablissementMutation();

  const handleEdit = (administrateur) => {
    setEditAdministrateur(administrateur);
    setSelectedRole(administrateur.ID_Role); // Pré-sélectionner le rôle de l'utilisateur actuel
    setOpenEditDialog(true);
  };

  const handleSaveEdit = async () => {
    try {
      setIsLoading(true);
      if (editAdministrateur) {
        const response = await modifyAdministrateur({
          ID_Role: selectedRole,
          ID_Etablissement: editAdministrateur.ID_Etablissement,
          ID_Utilisateur: editAdministrateur.ID_Utilisateur,
        });
        fetchAdministrateur();
        popUpValidation('Les modifications ont bien été appliquées.');
        handleCloseEdit();
      }
    } catch (err) {
      console.error('Erreur lors de la modification de l\'administrateur:', err);
      if(err.originalStatus === 401){
        alert('Votre session a expiré. Veuillez vous reconnecter.');
        window.location = '/loginuser';
      } else {
        popUpError('Erreur lors de l\'envoi des modifications au serveur.');
      }
    } finally {
      setIsLoading(false);
    }
  };
  
  const handledeleteAdministrateur = async () => {
    try {
      if (administrateurToDelete) {
        const response = await deleteAdministrateur({
          ID_Utilisateur: administrateurToDelete.ID_Utilisateur,
          ID_Etablissement: administrateurToDelete.ID_Etablissement,
        });
        setAdministrateurs(prevAdministrateur => prevAdministrateur.filter(administrateur => administrateur.ID_Utilisateur !== administrateurToDelete.ID_Utilisateur));
        fetchAdministrateur();
        popUpValidation("L'administrateur à bien été supprimé.");
        setOpenDeleteDialog(false);
      }
    } catch (err) {
      if(err.originalStatus === 401){
        alert('Votre session a expiré. Veuillez vous reconnecter.');
        window.location = '/loginuser';
      }
      popUpError("Erreur lors de la suppression de l'administrateur.");
    }
  };

  const handleDeleteDialogOpen = (administrateur) => {
    setAdministrateurToDelete(administrateur);
    setOpenDeleteDialog(true);
  };

  const fetchAdministrateur = async () => {
    try {
      if(administrateurs!== ''){
        setIsLoading(true);
      const response = await getAllInfoAdministrateur({
        ID_Etablissement: userSession.ID_Etablissement,
      });
      const data = response.data;
      if (data) {
        setAdministrateurs(data);
      }
      }
      
    } catch (err) {
      if(err.originalStatus === 401){
        alert('Votre session a expiré. Veuillez vous reconnecter.');
        window.location = '/loginuser';
      }
      popUpError('Erreur lors de la récuparation des administrateurs.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAdministrateur();
  }, [getAllInfoAdministrateur, userSession.ID_Etablissement]);


  const handleOpen = () => {setOpen(true)};
  const handleClose = () => {setSelectedPermissions([]); setMailAdministrateurAjout(''); setOpen(false)};
  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const response = await addAdministrateur({
        ID_Etablissement: userSession.ID_Etablissement,
        email: mailAdministrateurAjout,
        ID_Role: nouvelAdministrateurRole,
      });
      fetchAdministrateur();
      handleClose();
      popUpValidation('L\'invitation au nouvel adminsitrateur à bien été envoyé');
    } catch (err) {
      if(err.originalStatus === 401){
        alert('Votre session a expiré. Veuillez vous reconnecter.');
        window.location = '/loginuser';
      }
      popUpError('Erreur lors de l\'ajout du administrateur au serveur.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
};

const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
};

const [searchAdministrateur] = useSearchListeUtilisateurEtablissementMutation();

  const handlesearchAdministrateur = async () => {
    try {
      setIsLoading(true);
      const response = await searchAdministrateur({
        ID_Etablissement: userSession.ID_Etablissement,
        mailAdministrateurRecherche: mailAdministrateurRecherche,
      });
      
      const data = response.data;
      if (data) {
        setAdministrateurs(data);
      } else {
        popUpInfo('Aucun administrateur trouvé.');
        setAdministrateurs([]);
      }
    } catch (err) {
      if(err.originalStatus === 401){
        alert('Votre session a expiré. Veuillez vous reconnecter.');
        window.location = '/loginuser';
      }
      popUpError('Erreur lors de la recherche des administrateurs.');
    } finally {
      setIsLoading(false);
    }
  };



  const [error, setError] = useState('');

  const popUpError = (message) => {
    setError(message);
  };

  const [message, setMessage] = useState('');

  const popUpInfo = (message) => {
    setMessage(message);
  };

  const [validation, setValidation] = useState('');

  const popUpValidation = (message) => {
    setValidation(message);
  };



  return (
    <Box m="1.5rem 2.5rem">
      <Typography variant="h3" gutterBottom style={{ marginTop: '60px', marginBottom: '20px' }}>
        {t('RenderAdministrateurs.GestionAdministrateurs')}
      </Typography>
      <ErrorMessage message={error} setError={setError}/>
      <InfoMessage message={message} setInfo={setMessage}/>
      <ValidationMessage message={validation} setValidation={setValidation}/>
      <Grid container spacing={2} alignItems="center" style={{ width: '95%', margin: '0 auto' }}>
        <Grid item xs={3}>
          <Button variant="contained" color="primary" onClick={() => setOpen(true)} style={{ height: '55px' }}>
            {t('RenderAdministrateurs.AjouterAdministrateur')}
          </Button>
        </Grid>
        <Grid item xs={3} style={{ display: 'flex', alignItems: 'center' }}>
          <Button variant="contained" color="primary" onClick={handleOpenInviteKey} style={{ height: '55px', marginRight: '10px' }}>
            Créer une clé d'invitation
          </Button>
          <Tooltip title="Inviter toute une équipe avec une même clé à envoyer à chacun">
            <InfoIcon color="primary" />
          </Tooltip>
        </Grid>
        <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
          <TextField 
            label={t('RenderAdministrateurs.MailAdministrateur')} 
            value={mailAdministrateurRecherche} 
            onChange={(e) => setMailAdministrateurRecherche(e.target.value)}
          />
          <Button variant="contained" color="primary" onClick={handlesearchAdministrateur} style={{ marginLeft: '8px' }}>
            <SearchIcon style={{ fontSize: 40 }} />
          </Button>
        </Grid>
      </Grid>
      
      <List style={{ marginTop: '32px' }}>
        {administrateurs.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((administrateur, index) => (
          <ListItem key={index} secondaryAction={
            <>
              <IconButton edge="end" aria-label="edit" onClick={() => handleEdit(administrateur)}>
                <EditIcon />
              </IconButton>
              <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteDialogOpen(administrateur)}>
                <DeleteIcon />
              </IconButton>
            </>
          }>
            <ListItemText
              primary={<Typography variant="body1">{administrateur.utilisateur.pseudo}</Typography>}
              secondary={<Typography variant="body2" color="textSecondary">({administrateur.utilisateur.email})</Typography>}
            />
          </ListItem>
        ))}
      </List>

      <TablePagination
        component="div"
        count={administrateurs.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 25, 100]}
      />

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{t('RenderAdministrateurs.AjouterAdministrateur')}</DialogTitle>
        <DialogContent>
          <TextField
            label={t('RenderAdministrateurs.MailAdministrateur')}
            value={mailAdministrateurAjout}
            onChange={(e) => setMailAdministrateurAjout(e.target.value)}
            fullWidth
            margin="normal"
          />
          <RoleSelector 
            roles={roles} 
            onRoleChange={(roleId) => setNouvelAdministrateurRole(roleId)} 
            selectedRole={nouvelAdministrateurRole}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} style={{ backgroundColor: buttonColor, color: textColor }}>
            {t('RenderAdministrateurs.Annuler')}
          </Button>
          <Button onClick={handleSubmit} style={{ backgroundColor: buttonColor, color: textColor }}>
            {t('RenderAdministrateurs.Ajouter')}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)}>
        <DialogTitle>{t('RenderAdministrateurs.SupprimerAdministrateur')}</DialogTitle>
        <DialogContent>
          <Typography>{t('RenderAdministrateurs.ConfirmerSuppression')}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)} style={{ backgroundColor: buttonColor, color: textColor }}>
            {t('RenderAdministrateurs.Annuler')}
          </Button>
          <Button onClick={handledeleteAdministrateur} style={{ backgroundColor: buttonColor, color: textColor }}>
            {t('RenderAdministrateurs.Supprimer')}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openEditDialog} onClose={handleCloseEdit}>
        <DialogTitle>{t('RenderAdministrateurs.ChoisirRole')}</DialogTitle>
        <DialogContent>
          <RoleSelector roles={roles} onRoleChange={handleRoleChange} selectedRole={selectedRole} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEdit} color="primary">{t('RenderAdministrateurs.Annuler')}</Button>
          <Button onClick={handleSaveEdit} color="primary">{t('RenderAdministrateurs.Sauvegarder')}</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openInviteKey} onClose={handleCloseInviteKey} PaperProps={{ style: { width: '500px' } }}>
        <DialogTitle>Créer une clé d'invitation</DialogTitle>
        <DialogContent>
          <Select
            value={selectedGroup}
            onChange={(e) => setSelectedGroup(e.target.value)}
            fullWidth
            margin="normal"
            style={{ marginTop: '20px' }}
            disabled={inputsDisabled}
          >
            {groups.map((group) => (
              <MenuItem key={group._id} value={group.ID_Groupe}>
                {group.Nom_groupe}
              </MenuItem>
            ))}
          </Select>
          <TextField
            type="date"
            label="Date d'expiration"
            value={expirationDate}
            onChange={(e) => setExpirationDate(e.target.value)}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            style={{ marginTop: '20px' }}
            disabled={inputsDisabled}
          />
          {generatedKey && (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, marginTop: '20px' }}>
              <TextField
                label="Clé générée"
                value={generatedKey}
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
              />
              <IconButton onClick={handleCopyKey} color="primary">
                <ContentCopyIcon />
              </IconButton>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseInviteKey} color="primary">Fermer</Button>
          {!generatedKey && (
            <Button onClick={handleCreateInviteKey} color="primary">Générer la clé</Button>
          )}
        </DialogActions>
      </Dialog>
    </Box>
  );
};


export default RenderAdministrateurs;