import React from 'react';
import styled from '@emotion/styled';
import ServiceCard from './ServiceCard';

const Container = styled('div')`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 40vh; /* Limite la hauteur à 40% de la hauteur de la page */
  width: 100%;
  height: 100%;
`;

const ServiceList = styled('div')`
  display: flex;
  flex-direction: row;
  gap: 12px;
  width: 100%;
  height: 100%;
  overflow-x: auto; /* Scroll horizontal si nécessaire */
  padding-bottom: 8px;
  padding-left: 16px; /* Ajoute un espace à gauche pour éloigner la première carte du bord */

  /* Force le défilement si les éléments dépassent la largeur disponible */
  & > * {
    min-width: 180px; /* Largeur minimale d'une carte pour forcer le défilement */
  }

  &::-webkit-scrollbar {
    height: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.couleur_dombre_de_case || 'rgba(0, 0, 0, 0.1)'};
    border-radius: 10px;
  }
`;

const ContainerServicePortail = ({ services, couleur_de_fond, couleur_ecriture, couleur_dombre_de_case, police_de_caractere, onServiceClick }) => {
  return (
    <Container>
      <ServiceList couleur_dombre_de_case={couleur_dombre_de_case}>
        {services.map((service, index) => (
          <ServiceCard
            key={service.id}
            service={service}
            couleur_de_case={couleur_de_fond}
            couleur_ecriture={couleur_ecriture}
            couleur_dombre_de_case={couleur_dombre_de_case}
            couleur_bouton_ecriture={couleur_ecriture}
            police_de_caractere={police_de_caractere}
            onClick={() => onServiceClick(service)} /* Appel du callback lors du clic */
            style={{ marginLeft: index === 0 ? '16px' : '0' }} /* Ajoute un margin-left uniquement à la première carte */
          />
        ))}
      </ServiceList>
    </Container>
  );
};

export default ContainerServicePortail;
