import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { setupListeners } from "@reduxjs/toolkit/query";
import { api } from "./state/api"; // votre api existant
import { stripeApi } from "./state/apiStripe"; // votre nouvel api pour Stripe
import globalReducer from "./state/index";
import adminAuthReducer, { adminLogout } from "./state/adminAuth";
import userAuthReducer, { userLogout } from "./state/userAuth";
import languageReducer from "./state/languageSlice"; // Import du reducer pour la langue
import logger from 'redux-logger';

const sessionDuration = 5 * 60 * 60 * 1000; // 5 heures en millisecondes

const checkSessionExpiration = (store) => {
  const { adminAuth, userAuth } = store.getState();

  const checkAndLogout = async (auth, logoutAction) => {
    if (auth.isAuthenticated && auth.loginTime && (Date.now() - auth.loginTime > sessionDuration)) {
      store.dispatch(logoutAction());
      try {
        await api.endpoints.logout.initiate()(store.dispatch, store.getState);
      } catch (error) {
        console.error("Erreur lors de la déconnexion côté serveur:", error);
      }
    }
  };

  checkAndLogout(userAuth, userLogout);
  checkAndLogout(adminAuth, adminLogout);
};

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["adminAuth", "userAuth", "global", "language"], // Ajoutez "language" ici pour la persistance
};

const rootReducer = combineReducers({
  global: globalReducer,
  adminAuth: adminAuthReducer,
  userAuth: userAuthReducer,
  language: languageReducer, // Ajoutez le reducer pour la langue
  [api.reducerPath]: api.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(api.middleware), // Ajoutez le middleware de stripeApi
});

const persistor = persistStore(store);

setupListeners(store.dispatch);

// Vérification périodique de l'expiration de la session
setInterval(() => checkSessionExpiration(store), 60000); // Vérifier toutes les minutes

export { store, persistor };
