import React from 'react';
import { Box, Typography, TextField, IconButton, FormControlLabel, Checkbox, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import { useTranslation } from 'react-i18next';

const Input = ({ input, onChange, onRemove, error }) => {
  const { t } = useTranslation();

  const options = input.Options || {};
  if (!options.choices && ['checkbox', 'radio', 'dropdown'].includes(input.Type_Dinput)) {
    options.choices = [];
  }

  const handleChoiceChange = (index, value) => {
    const updatedChoices = [...options.choices];
    updatedChoices[index] = value;
    onChange('Options', { ...options, choices: updatedChoices });
  };

  const handleAddChoice = () => {
    const updatedChoices = [...options.choices, ''];
    onChange('Options', { ...options, choices: updatedChoices });
  };

  const handleRemoveChoice = (index) => {
    const updatedChoices = options.choices.filter((_, i) => i !== index);
    onChange('Options', { ...options, choices: updatedChoices });
  };

  const renderIcon = () => {
    switch (input.Type_Dinput) {
      case 'radio':
        return <RadioButtonCheckedIcon />;
      case 'checkbox':
        return <CheckBoxIcon />;
      case 'dropdown':
        return <ArrowDropDownCircleIcon />;
      default:
        return null;
    }
  };

  const renderOptions = () => {
    switch (input.Type_Dinput) {
      case 'boolean':
      case 'number':
        return null;
      case 'slider':
        return (
          <>
            <TextField
              label={t('Input.min')}
              type="number"
              value={options.min || ''}
              onChange={(e) => onChange('Options', { ...options, min: parseFloat(e.target.value) })}
              fullWidth
              margin="normal"
              error={!!error?.min}
              helperText={error?.min}
            />
            <TextField
              label={t('Input.max')}
              type="number"
              value={options.max || ''}
              onChange={(e) => onChange('Options', { ...options, max: parseFloat(e.target.value) })}
              fullWidth
              margin="normal"
              error={!!error?.max}
              helperText={error?.max}
            />
          </>
        );
      case 'varchar':
        return (
          <TextField
            label={t('Input.max_length')}
            type="number"
            value={options.maxlength || ''}
            onChange={(e) => onChange('Options', { ...options, maxlength: parseInt(e.target.value, 10) })}
            fullWidth
            margin="normal"
            error={!!error?.maxlength}
            helperText={error?.maxlength}
          />
        );
      case 'checkbox':
      case 'radio':
      case 'dropdown':
        return (
          <Box>
            <Typography variant="body1" gutterBottom align="center" style={{ marginTop: '16px', marginBottom: '16px' }}>
              {t('Input.choices')}
            </Typography>
            {options.choices.map((choice, index) => (
              <Box key={index} display="flex" alignItems="center" mb={2}>
                <Box display="flex" alignItems="center" justifyContent="center" style={{ marginRight: '8px', marginBottom: '-40px' }}>
                  {renderIcon()}
                </Box>
                <TextField
                  value={choice}
                  onChange={(e) => handleChoiceChange(index, e.target.value)}
                  fullWidth
                  margin="normal"
                  style={{ marginRight: 8, flexBasis: '80%', height: '25px' }}
                  error={!!error?.choices}
                />
                <IconButton onClick={() => handleRemoveChoice(index)} style={{ height: '35px', marginBottom: '-40px' }}>
                  <DeleteIcon />
                </IconButton>
              </Box>
            ))}
            {error?.choices && (
              <Typography color="error" variant="caption">
                {error.choices}
              </Typography>
            )}
            <Box mt={3} display="flex" justifyContent="center">
              <Button onClick={handleAddChoice} variant="contained" color="primary" style={{ marginTop: '10px' }}>
                {t('Input.add_choice')}
              </Button>
            </Box>
          </Box>
        );
      case 'file':
        return (
          <TextField
            label={t('Input.accept')}
            value={options.accept || ''}
            onChange={(e) => onChange('Options', { ...options, accept: e.target.value })}
            fullWidth
            margin="normal"
            error={!!error?.accept}
            helperText={error?.accept}
          />
        );
      case 'date':
      case 'time':
        return null;
      default:
        return null;
    }
  };

  return (
    <Box mb={2} p={2} border={1} borderRadius={8} borderColor="grey.300">
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h6" fontSize="1.5rem" fontWeight="bold">
          {input.Type_Dinput.charAt(0).toUpperCase() + input.Type_Dinput.slice(1)}
        </Typography>
        <IconButton edge="end" aria-label="delete" onClick={onRemove}>
          <DeleteIcon />
        </IconButton>
      </Box>
      <TextField
        label={t('Input.input_name')}
        value={input.Nom_Input}
        onChange={(e) => onChange('Nom_Input', e.target.value)}
        fullWidth
        margin="normal"
        style={{ marginBottom: '-8px' }}
        error={!!error?.Nom_Input}
        helperText={error?.Nom_Input}
      />
      <TextField
        label={t('Input.input_description')}
        value={input.Description_Input}
        onChange={(e) => onChange('Description_Input', e.target.value)}
        fullWidth
        margin="normal"
        style={{ marginBottom: '-8px' }}
        error={!!error?.Description_Input}
        helperText={error?.Description_Input}
      />
      <Box mt={3} mb={3} />
      <TextField
        label={t('Input.input_instruction')}
        value={input.Instruction_Input}
        onChange={(e) => onChange('Instruction_Input', e.target.value)}
        fullWidth
        margin="normal"
        error={!!error?.Instruction_Input}
        helperText={error?.Instruction_Input}
      />
      <Box mt={3} />
      {renderOptions()}
      <Box display="flex" justifyContent="flex-end" mt={2}>
        <FormControlLabel
          control={
            <Checkbox
              checked={input.Obligatoire}
              onChange={(e) => onChange('Obligatoire', e.target.checked)}
            />
          }
          label={t('Input.mandatory')}
        />
      </Box>
    </Box>
  );
};

export default Input;
