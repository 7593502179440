import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import NavbarTicketPartenaires from "./NavbarTicketPartenaire";
import ListeTicketPartenaires from "./ListeTicketPartenaire";
import DetailTicketPartenaires from "./DetailsTicketPartenaire";
import { useSelector } from "react-redux";
import { useGetAllTicketPartenaireMutation } from "state/api";

const RenderTicketPartenaires = ({ isExpanded }) => {
  const theme = useTheme();
  const [tickets, setTickets] = useState([]);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [filters, setFilters] = useState({
    equipementType: "all",
    severite: "all",
    priorite: "all",
    typeDaction: "all",
    urgence: "all",
    groupResponsable: "all",
    pour: "all",
    dateOrder: "none",
    etatTicket: "default",
  });

  const userSession = useSelector((state) => state.adminAuth);
  const [getAllTickets] = useGetAllTicketPartenaireMutation();

  const fetchTickets = async (updateSelectedTicket = false) => {
    try {
      const response = await getAllTickets({
        ID_Etablissement: userSession.ID_Etablissement,
      }).unwrap();

      // Combine incidents IT and SG with specific details
      const ticketsWithDetails = response.flatMap((portail) => {
        const incidentsIT = portail.incidentsIT.map((incident) => ({
          ...incident,
          details: {
            specificDetails: incident.specificDetails,
            locationDetails: incident.locationDetails || null,
            etablissementDetails: portail.etablissementSource,
            typeDactionDetails: null,
            groupDetails: null,
          },
        }));

        const incidentsSG = portail.incidentsSG.map((incident) => ({
          ...incident,
          details: {
            specificDetails: incident.specificDetails,
            locationDetails: incident.locationDetails || null,
            etablissementDetails: portail.etablissementSource,
            typeDactionDetails: null,
            groupDetails: null,
          },
        }));

        return [...incidentsIT, ...incidentsSG];
      });

      setTickets(ticketsWithDetails);

      // Update the selectedTicket if necessary
      if (updateSelectedTicket && selectedTicket) {
        const updatedTicket = ticketsWithDetails.find(
          (ticket) =>
            ticket.ID_Incident_IT === selectedTicket.ID_Incident_IT ||
            ticket.ID_Incident_SG === selectedTicket.ID_Incident_SG
        );
        if (updatedTicket) {
          setSelectedTicket(updatedTicket);
        }
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des tickets:", error);
    }
  };

  useEffect(() => {
    fetchTickets();
  }, [getAllTickets]);

  const handleSelectTicket = (ticket) => {
    setSelectedTicket(ticket);
  };

  const handleFiltersChange = (newFilters) => {
    setFilters((prevFilters) => ({ ...prevFilters, ...newFilters }));
  };

  const sortedTickets = tickets
    .filter((ticket) => {
      if (filters.etatTicket === "default") {
        if (ticket.Etat_du_ticket === "cloture" || ticket.Etat_du_ticket === "abus") {
          return false;
        }
      } else if (filters.etatTicket !== "all" && filters.etatTicket !== ticket.Etat_du_ticket) {
        return false;
      }

      return (
        (filters.equipementType === "all" || filters.equipementType === ticket.Type_de_ticket) &&
        (filters.severite === "all" || (ticket.Severite && filters.severite === ticket.Severite.toString())) &&
        (filters.priorite === "all" || (ticket.Priorite && filters.priorite === ticket.Priorite.toString())) &&
        (filters.typeDaction === "all" || (ticket.ID_Type_Daction && filters.typeDaction === ticket.ID_Type_Daction.toString())) &&
        (filters.urgence === "all" || (ticket.Niveau_urgence_intervention !== undefined && filters.urgence === ticket.Niveau_urgence_intervention.toString())) &&
        (filters.groupResponsable === "all" || (ticket.ID_Groupe && filters.groupResponsable === ticket.ID_Groupe.toString())) &&
        (filters.pour === "all" || filters.pour === ticket.Pour)
      );
    })
    .sort((a, b) => {
      if (filters.dateOrder !== "none") {
        const dateA = new Date(a.Updated[a.Updated.length - 1]);
        const dateB = new Date(b.Updated[b.Updated.length - 1]);
        return filters.dateOrder === "asc" ? dateA - dateB : dateB - dateA;
      }

      if (a.Niveau_urgence_intervention !== b.Niveau_urgence_intervention) {
        return a.Niveau_urgence_intervention - b.Niveau_urgence_intervention;
      }
      return b.Severite * b.Priorite - a.Severite * a.Priorite;
    });

  return (
    <Box sx={{ marginLeft: "10px", marginRight: "10px" }}>
      {isExpanded && (
        <>
          <NavbarTicketPartenaires
            filters={filters}
            onFiltersChange={handleFiltersChange}
          />
        </>
      )}
      <Box sx={{ display: "flex", marginTop: "20px" }}>
        <Box
          sx={{
            width: {
              xs: "100%",
              sm: "100%",
              md: "40%",
              lg: "40%",
            },
            maxWidth: "350px",
            paddingRight: "10px",
          }}
        >
          <ListeTicketPartenaires
            tickets={sortedTickets}
            onSelectTicket={handleSelectTicket}
            selectedTicket={selectedTicket}
          />
        </Box>
        <Box sx={{ width: "60%", paddingLeft: "10px" }}>
          <DetailTicketPartenaires
            ticket={selectedTicket}
            refreshTicket={() => fetchTickets(true)}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default RenderTicketPartenaires;
