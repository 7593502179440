import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setAdminAuthenticated } from 'state/adminAuth';
import { useCreationEntrepriseMutation } from 'state/api';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function AdminDashboard() {
  const { t } = useTranslation();
  const [nomEtablissement, setNomEtablissement] = useState('');
  const [usernameEtablissement, setUsernameEtablissement] = useState('');
  const [motDePasseEtablissement, setMotDePasseEtablissement] = useState('');
  const [typeAbonnement, setTypeAbonnement] = useState('');
  const [localisation, setLocalisation] = useState('');

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [createEntreprise, { error, isLoading }] = useCreationEntrepriseMutation();

  const handleCreateEtablissement = async (event) => {
    event.preventDefault();

    const data = {
      Nom_Etablissement: nomEtablissement,
      Username_Etablissement: usernameEtablissement,
      Mot_de_Passe_Etablissement: motDePasseEtablissement,
      subscriptionType: typeAbonnement,
      Localisation: localisation,
    };

    try {
      const response = await createEntreprise(data);
      const responseData = response.data;
    } catch (err) {
      if (err.originalStatus === 401) {
        alert('Votre session a expiré. Veuillez vous reconnecter.');
        window.location = '/loginuser';
      }
    }
  };

  return (
    <div>
      <h1>{t('AdminDashboard.Title')}</h1>
      <form onSubmit={handleCreateEtablissement}>
        <div>
          <label>{t('AdminDashboard.NomEtablissement')}:</label>
          <input
            type="text"
            value={nomEtablissement}
            onChange={(e) => setNomEtablissement(e.target.value)}
            required
          />
        </div>
        <div>
          <label>{t('AdminDashboard.UsernameEtablissement')}:</label>
          <input
            type="text"
            value={usernameEtablissement}
            onChange={(e) => setUsernameEtablissement(e.target.value)}
            required
          />
        </div>
        <div>
          <label>{t('AdminDashboard.MotDePasseEtablissement')}:</label>
          <input
            type="password"
            value={motDePasseEtablissement}
            onChange={(e) => setMotDePasseEtablissement(e.target.value)}
            required
          />
        </div>
        <div>
          <label>{t('AdminDashboard.TypeAbonnement')}:</label>
          <input
            type="text"
            value={typeAbonnement}
            onChange={(e) => setTypeAbonnement(e.target.value)}
            required
          />
        </div>
        <div>
          <label>{t('AdminDashboard.Localisation')}:</label>
          <input
            type="text"
            value={localisation}
            onChange={(e) => setLocalisation(e.target.value)}
            required
          />
        </div>
        <button type="submit">{t('AdminDashboard.SubmitButton')}</button>
      </form>
    </div>
  );
}

export default AdminDashboard;
