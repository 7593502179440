import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, TextField, Dialog, DialogTitle, DialogContent, DialogActions, Grid, List, ListItem, ListItemText, IconButton, TablePagination, FormControlLabel, Checkbox, Radio, RadioGroup, FormControl, FormLabel } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { useAddGroupedAdminMutation, useDeleteGroupedAdminMutation, useGetGroupedAdminsMutation, useUpdateGroupedAdminMutation, useGetAllInfoRoleMutation } from 'state/api';
import ErrorMessage from './ErrorMessage';
import ValidationMessage from './ValidationMessage';
import InfoMessage from './InfoMessage';

const RenderGroupe = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [groupes, setGroupes] = useState([]);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [nomGroupeRecherche, setNomGroupeRecherche] = useState('');
  const [nomGroupeAjout, setNomGroupeAjout] = useState('');
  const [descriptionGroupeAjout, setDescriptionGroupeAjout] = useState('');
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [groupeToDelete, setGroupeToDelete] = useState(null);
  const [editGroupe, setEditGroupe] = useState(null);
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState(null);
  const userSession = useSelector((state) => state.adminAuth);
  const theme = useTheme();
  const buttonColor = theme.palette.primary.main;
  const textColor = theme.palette.mode === 'dark' ? theme.palette.primary.light : theme.palette.secondary.main;

  const [addGroupedAdmin] = useAddGroupedAdminMutation();
  const [deleteGroupedAdmin] = useDeleteGroupedAdminMutation();
  const [getGroupedAdmins] = useGetGroupedAdminsMutation();
  const [updateGroupedAdmin] = useUpdateGroupedAdminMutation();
  const [getAllInfoRole] = useGetAllInfoRoleMutation();

  const handleOpen = () => setOpen(true);
  const handleClose = () => { setSelectedRole(null); setOpen(false); };
  const handleCloseEdit = () => { setEditGroupe(null); setSelectedRole(null); setOpenEditDialog(false); };

  const handleRoleChange = (event) => {
    setSelectedRole(parseInt(event.target.value));
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      await addGroupedAdmin({
        ID_Etablissement: userSession.ID_Etablissement,
        Nom_groupe: nomGroupeAjout,
        Description_groupe: descriptionGroupeAjout,
        ID_Role: selectedRole,
      });
      fetchGroupes();
      handleClose();
      popUpValidation(t('RenderGroupe.validation.create_success'));
    } catch (err) {
      if (err.originalStatus === 401) {
        alert(t('RenderGroupe.error.session_expired'));
        window.location = '/loginuser';
      }
      popUpError(t('RenderGroupe.error.create_error'));
    } finally {
      setIsLoading(false);
    }
  };

  
  const handleEdit = (groupe) => {
    setEditGroupe(groupe);
    setSelectedRole(groupe.ID_Role);
    setOpenEditDialog(true);
  };

  const handleSaveEdit = async () => {
    try {
      setIsLoading(true);
      if (editGroupe) {
        await updateGroupedAdmin({
          id: editGroupe._id,
          ID_Etablissement: editGroupe.ID_Etablissement,
          Nom_groupe: editGroupe.Nom_groupe,
          Description_groupe: editGroupe.Description_groupe,
          ID_Role: selectedRole,
        });
        fetchGroupes();
        popUpValidation(t('RenderGroupe.validation.edit_success'));
        handleCloseEdit();
      }
    } catch (err) {
      if (err.originalStatus === 401) {
        alert(t('RenderGroupe.error.session_expired'));
        window.location = '/loginuser';
      }
      popUpError(t('RenderGroupe.error.edit_error'));
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditChange = (field, value) => {
    setEditGroupe((prevEditGroupe) => ({
      ...prevEditGroupe,
      [field]: value,
    }));
  };

  const handleDeleteGroupe = async () => {
    try {
      if (groupeToDelete) {
        await deleteGroupedAdmin(groupeToDelete._id);
        setGroupes((prevGroupes) => prevGroupes.filter((groupe) => groupe._id !== groupeToDelete._id));
        fetchGroupes();
        popUpValidation(t('RenderGroupe.validation.delete_success'));
        setOpenDeleteDialog(false);
      }
    } catch (err) {
      if (err.originalStatus === 401) {
        alert(t('RenderGroupe.error.session_expired'));
        window.location = '/loginuser';
      }
      popUpError(t('RenderGroupe.error.delete_error'));
    }
  };

  const handleDeleteDialogOpen = (groupe) => {
    setGroupeToDelete(groupe);
    setOpenDeleteDialog(true);
  };

  const fetchGroupes = async () => {
    try {
      setIsLoading(true);
      const response = await getGroupedAdmins({ ID_Etablissement: userSession.ID_Etablissement });
      const data = response.data;
      if (data) {
        setGroupes(data);
      }
    } catch (err) {
      if (err.originalStatus === 401) {
        alert(t('RenderGroupe.error.session_expired'));
        window.location = '/loginuser';
      }
      popUpError(t('RenderGroupe.error.fetch_error'));
    } finally {
      setIsLoading(false);
    }
  };

  const fetchRoles = async () => {
    try {
      const response = await getAllInfoRole({ ID_Etablissement: userSession.ID_Etablissement });
      const data = response.data;
      if (data) {
        setRoles(data);
      }
    } catch (err) {
      if (err.originalStatus === 401) {
        alert(t('RenderGroupe.error.session_expired'));
        window.location = '/loginuser';
      }
      popUpError(t('RenderGroupe.error.fetch_roles_error'));
    }
  };

  useEffect(() => {
    fetchGroupes();
    fetchRoles();
  }, [getGroupedAdmins, getAllInfoRole, userSession.ID_Etablissement]);

  const handleChangePage = (event, newPage) => setPage(newPage);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [error, setError] = useState('');
  const popUpError = (message) => setError(message);

  const [message, setMessage] = useState('');
  const popUpInfo = (message) => setMessage(message);

  const [validation, setValidation] = useState('');
  const popUpValidation = (message) => setValidation(message);

  return (
    <Box m="1.5rem 2.5rem">
      <Typography variant="h3" gutterBottom style={{ marginTop: '60px', marginBottom: '20px' }}>
        {t('RenderGroupe.title')}
      </Typography>
      <ErrorMessage message={error} setError={setError} />
      <InfoMessage message={message} setInfo={setMessage} />
      <ValidationMessage message={validation} setValidation={setValidation} />
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={5} style={{ textAlign: 'left' }}>
          <Button variant="contained" color="primary" onClick={handleOpen} style={{ height: '55px' }}>
            {t('RenderGroupe.create_button')}
          </Button>
        </Grid>
        <Grid item xs={7} style={{ textAlign: 'right', display: 'flex', alignItems: 'center' }}>
          <TextField label={t('RenderGroupe.search_placeholder')} value={nomGroupeRecherche} onChange={(e) => setNomGroupeRecherche(e.target.value)} />
          <Button variant="contained" color="primary" onClick={fetchGroupes} style={{ marginLeft: '8px' }}>
            <SearchIcon style={{ fontSize: 40 }} />
          </Button>
        </Grid>
      </Grid>

      <List style={{ marginTop: '32px' }}>
        {groupes.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((groupe, index) => (
          <ListItem key={index} secondaryAction={
            <>
              <IconButton edge="end" aria-label="edit" onClick={() => handleEdit(groupe)}>
                <EditIcon />
              </IconButton>
              <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteDialogOpen(groupe)}>
                <DeleteIcon />
              </IconButton>
            </>
          }>
            <ListItemText primary={groupe.Nom_groupe} />
          </ListItem>
        ))}
      </List>

      <TablePagination
        component="div"
        count={groupes.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 25, 100]}
      />

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{t('RenderGroupe.add_dialog_title')}</DialogTitle>
        <DialogContent>
          <form>
            <TextField label={t('RenderGroupe.form_nom_groupe')} value={nomGroupeAjout} onChange={(e) => setNomGroupeAjout(e.target.value)} fullWidth margin="normal" />
            <TextField label={t('RenderGroupe.form_description_groupe')} value={descriptionGroupeAjout} onChange={(e) => setDescriptionGroupeAjout(e.target.value)} fullWidth margin="normal" />
            <hr style={{ border: '1px solid #ddd', marginBottom: '20px' }} />
            <Typography variant="h6" style={{ marginTop: '0px', marginBottom: '10px' }}>{t('RenderGroupe.role_label')}</Typography>
            <FormControl component="fieldset">
              <FormLabel component="legend">{t('RenderGroupe.role_legend')}</FormLabel>
              <RadioGroup value={selectedRole ? selectedRole.toString() : ''} onChange={handleRoleChange}>
                {roles.map((role) => (
                  <FormControlLabel
                    key={role.ID_Role}
                    value={role.ID_Role.toString()}
                    control={<Radio />}
                    label={role.Nom_Role}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} style={{ backgroundColor: buttonColor, color: textColor }}>{t('RenderGroupe.cancel_button')}</Button>
          <Button onClick={handleSubmit} style={{ backgroundColor: buttonColor, color: textColor }}>{t('RenderGroupe.submit_button')}</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)}>
        <DialogTitle>{t('RenderGroupe.delete_dialog_title')}</DialogTitle>
        <DialogContent>
          <Typography>{t('RenderGroupe.delete_dialog_confirmation')}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)} style={{ backgroundColor: buttonColor, color: textColor }}>{t('RenderGroupe.cancel_button')}</Button>
          <Button onClick={handleDeleteGroupe} style={{ backgroundColor: buttonColor, color: textColor }}>{t('RenderGroupe.submit_button')}</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openEditDialog} onClose={handleCloseEdit}>
        <DialogTitle>{t('RenderGroupe.edit_dialog_title')}</DialogTitle>
        <DialogContent>
          <form>
            <TextField label={t('RenderGroupe.form_nom_groupe')} value={editGroupe ? editGroupe.Nom_groupe : ''} onChange={(e) => handleEditChange('Nom_groupe', e.target.value)} fullWidth margin="normal" />
            <TextField label={t('RenderGroupe.form_description_groupe')} value={editGroupe ? editGroupe.Description_groupe : ''} onChange={(e) => handleEditChange('Description_groupe', e.target.value)} fullWidth margin="normal" />
            <hr style={{ border: '1px solid #ddd', marginBottom: '20px' }} />
            <Typography variant="h6" style={{ marginTop: '0px', marginBottom: '10px' }}>{t('RenderGroupe.role_label')}</Typography>
            <FormControl component="fieldset">
              <FormLabel component="legend">{t('RenderGroupe.role_legend')}</FormLabel>
              <RadioGroup value={selectedRole ? selectedRole.toString() : ''} onChange={handleRoleChange}>
                {roles.map((role) => (
                  <FormControlLabel
                    key={role.ID_Role}
                    value={role.ID_Role.toString()}
                    control={<Radio />}
                    label={role.Nom_Role}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEdit} style={{ backgroundColor: buttonColor, color: textColor }}>{t('RenderGroupe.cancel_button')}</Button>
          <Button onClick={handleSaveEdit} style={{ backgroundColor: buttonColor, color: textColor }}>{t('RenderGroupe.save_button')}</Button>
        </DialogActions>
      </Dialog>

      {isLoading && <p>{t('RenderGroupe.loading_message')}</p>}
    </Box>
  );
};

export default RenderGroupe;
