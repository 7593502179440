import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, TextField, Dialog, DialogTitle, DialogContent, DialogActions, Grid, List,
  ListItem,
  ListItemText,
  IconButton,
  TablePagination, Select, MenuItem, FormControlLabel, Checkbox } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useGetAllInfoRoleMutation, useAddRoleMutation, useDeleteRoleMutation, useModifyRoleMutation, useSearchRoleMutation } from 'state/api';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import ErrorMessage from './ErrorMessage';
import ValidationMessage from './ValidationMessage';
import InfoMessage from './InfoMessage';

const RenderRole = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false); 
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [roles, setRole] = useState([]);
  const [openEditDialog, setOpenEditDialog] = useState(false); 

  const [nomRoleRecherche, setNomRoleRecherche] = useState('');
  const [nomRoleAjout, setNomRoleAjout] = useState('');
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [roleToDelete, setRoleToDelete] = useState(null);
  const [editRole, setEditRole] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [messageText, setMessageText] = useState('');
  const theme = useTheme();
  const buttonColor =  theme.palette.primary.main;
  const textColor = theme.palette.mode === 'dark' ? theme.palette.primary.light : theme.palette.secondary.main;
  const permissions = Object.keys(t('RenderRole.permissions', { returnObjects: true }));
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [deleteRole] = useDeleteRoleMutation();
  const [getAllInfoRole, { data: apiData, error: apiError }] = useGetAllInfoRoleMutation();
  const userSession = useSelector((state) => state.adminAuth);
  const [addRole] = useAddRoleMutation();
  const [modifySignalement] = useModifyRoleMutation();

  const handlePermissionChange = (event) => {
    const permId = event.target.name;
    setSelectedPermissions((prev) =>
      prev.includes(permId)
        ? prev.filter((id) => id !== permId)
        : [...prev, permId]
    );
  };

  const handlePermissionEdit = (event) => {
    const permissionName = event.target.name.replace(/\s+/g, '_');
    setSelectedPermissions((prev) =>
      prev.includes(permissionName)
        ? prev.filter((perm) => perm !== permissionName)
        : [...prev, permissionName]
    );
  };

  const handleCloseEdit = () => {
    setEditRole(null);
    setSelectedPermissions([]);
    setOpenEditDialog(false);
  };

  const handleEdit = (role) => {
    setEditRole(role);
    const permissionsActives = [];
    for (const [key, value] of Object.entries(role)) {
      if (value === true && (key.startsWith("Ecriture_") || key.startsWith("Lecture_"))) {
        permissionsActives.push(key);
      }
    }
    setSelectedPermissions(permissionsActives);
    setOpenEditDialog(true);
  };

  const handleSaveEdit = async () => {
    try {
      setIsLoading(true);
      if (editRole) {
        const response = await modifySignalement({
          ID_Role: editRole.ID_Role,
          ID_Etablissement: editRole.ID_Etablissement,
          Nom_Role: editRole.Nom_Role,
          Permissions: selectedPermissions,
        });
        fetchRole();
        popUpValidation(t('RenderRole.success_edit_message'));
        handleCloseEdit();
      }
    } catch (err) {
      if(err.originalStatus === 401){
        alert('Votre session a expiré. Veuillez vous reconnecter.');
        window.location = '/loginuser';
      }
      popUpError(t('RenderRole.error_edit_message'));
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditChange = (field, value) => {
    setEditRole((prevEditEquipement) => ({
      ...prevEditEquipement,
      [field]: value,
    }));
  };

  const handleDeleteRole = async () => {
    try {
      if (roleToDelete) {
        const response = await deleteRole({ ID_Role: roleToDelete.ID_Role });
        setRole((prevRoles) => prevRoles.filter((r) => r.ID_Role !== roleToDelete.ID_Role));
        fetchRole();
        popUpValidation(t('RenderRole.success_delete_message'));
        setOpenDeleteDialog(false);
      }
    } catch (err) {
      if(err.originalStatus === 401){
        alert('Votre session a expiré. Veuillez vous reconnecter.');
        window.location = '/loginuser';
      }
      popUpError(t('RenderRole.error_delete_message'));
    }
  };

  const handleDeleteDialogOpen = (role) => {
    setRoleToDelete(role);
    setOpenDeleteDialog(true);
  };

  const fetchRole = async () => {
    try {
      setIsLoading(true);
      const response = await getAllInfoRole({ ID_Etablissement: userSession.ID_Etablissement });
      const data = response.data;
      if (data) setRole(data);
    } catch (err) {
      if(err.originalStatus === 401){
        alert('Votre session a expiré. Veuillez vous reconnecter.');
        window.location = '/loginuser';
      }
      popUpError(t('RenderRole.error_fetch_message'));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchRole();
  }, [getAllInfoRole, userSession.ID_Etablissement]);

  const handleOpen = () => { setOpen(true); };
  const handleClose = () => { setSelectedPermissions([]); setOpen(false); };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const response = await addRole({
        ID_Etablissement: userSession.ID_Etablissement,
        Nom_Role: nomRoleAjout,
        Permissions: selectedPermissions,
      });
      fetchRole();
      handleClose();
      popUpValidation(t('RenderRole.success_add_message'));
    } catch (err) {
      if(err.originalStatus === 401){
        alert('Votre session a expiré. Veuillez vous reconnecter.');
        window.location = '/loginuser';
      }
      popUpError(t('RenderRole.error_add_message'));
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => setPage(newPage);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [searchRole] = useSearchRoleMutation();

  const handleSearchRole = async () => {
    try {
      setIsLoading(true);
      const response = await searchRole({
        ID_Etablissement: userSession.ID_Etablissement,
        recherche: nomRoleRecherche,
      });
      const data = response.data;
      if (data) {
        setRole(data);
      } else {
        popUpInfo(t('RenderRole.no_role_found_message'));
        setRole([]);
      }
    } catch (err) {
      if(err.originalStatus === 401){
        alert('Votre session a expiré. Veuillez vous reconnecter.');
        window.location = '/loginuser';
      }
      popUpError(t('RenderRole.error_search_message'));
    } finally {
      setIsLoading(false);
    }
  };

  const [error, setError] = useState('');
  const popUpError = (message) => setError(message);

  const [message, setMessage] = useState('');
  const popUpInfo = (message) => setMessage(message);

  const [validation, setValidation] = useState('');
  const popUpValidation = (message) => setValidation(message);

  return (
    <Box m="1.5rem 2.5rem">
      <Typography variant="h3" gutterBottom style={{ marginTop: '60px', marginBottom: '20px' }}>
        {t('RenderRole.title')}
      </Typography>
      <ErrorMessage message={error} setError={setError} />
      <InfoMessage message={message} setInfo={setMessage} />
      <ValidationMessage message={validation} setValidation={setValidation} />
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={5} style={{ textAlign: 'left' }}>
          <Button variant="contained" color="primary" onClick={handleOpen} style={{ height: '55px' }}>
            {t('RenderRole.create_role_button')}
          </Button>
        </Grid>
        <Grid item xs={7} style={{ textAlign: 'right', display: 'flex', alignItems: 'center' }}>
          <TextField
            label={t('RenderRole.search_role_placeholder')}
            value={nomRoleRecherche}
            onChange={(e) => setNomRoleRecherche(e.target.value)}
          />
          <Button variant="contained" color="primary" onClick={handleSearchRole} style={{ marginLeft: '8px' }}>
            <SearchIcon style={{ fontSize: 40 }} />
          </Button>
        </Grid>
      </Grid>

      <List style={{ marginTop: '32px' }}>
        {roles.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((role, index) => (
          <ListItem key={index} secondaryAction={
            <>
              <IconButton edge="end" aria-label="edit" onClick={() => { handleEdit(role) }}>
                <EditIcon />
              </IconButton>
              <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteDialogOpen(role)}>
                <DeleteIcon />
              </IconButton>
            </>
          }>
            <ListItemText primary={role.Nom_Role} />
          </ListItem>
        ))}
      </List>

      <TablePagination
        component="div"
        count={roles.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 25, 100]}
      />

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{t('RenderRole.add_role_dialog_title')}</DialogTitle>
        <DialogContent>
          <form>
            <TextField
              label={t('RenderRole.role_name_label')}
              value={nomRoleAjout}
              onChange={(e) => setNomRoleAjout(e.target.value)}
              fullWidth
              margin="normal"
            />
            <hr style={{ border: '1px solid #ddd', marginBottom: '20px' }} />
            <Typography variant="h3" style={{ marginTop: '0px', marginBottom: '10px' }}>
              {t('RenderRole.choose_permissions_label')}
            </Typography>
            <Grid container direction="column" spacing={2}>
              {permissions.map((perm) => (
                <Grid item key={perm}>
                  <FormControlLabel
                    control={<Checkbox checked={selectedPermissions.includes(perm)} onChange={handlePermissionChange} name={perm} />}
                    label={t(`RenderRole.permissions.${perm}`)}
                  />
                </Grid>
              ))}
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} style={{ backgroundColor: buttonColor, color: textColor }}>
            {t('RenderRole.delete_dialog_cancel')}
          </Button>
          <Button onClick={() => handleSubmit(selectedPermissions)} style={{ backgroundColor: buttonColor, color: textColor }}>
            {t('RenderRole.submit_button')}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)}>
        <DialogTitle>{t('RenderRole.delete_dialog_title')}</DialogTitle>
        <DialogContent>
          <Typography>{t('RenderRole.delete_dialog_content')}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)} style={{ backgroundColor: buttonColor, color: textColor }}>
            {t('RenderRole.delete_dialog_cancel')}
          </Button>
          <Button onClick={handleDeleteRole} style={{ backgroundColor: buttonColor, color: textColor }}>
            {t('RenderRole.delete_dialog_confirm')}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openEditDialog} onClose={handleCloseEdit}>
        <DialogTitle>{t('RenderRole.edit_role_dialog_title')}</DialogTitle>
        <DialogContent>
          <form>
            <TextField
              label={t('RenderRole.role_name_label')}
              value={editRole ? editRole.Nom_Role : ''}
              onChange={(e) => handleEditChange('Nom_Role', e.target.value)}
              fullWidth
              margin="normal"
            />
            <hr style={{ border: '1px solid #ddd', marginBottom: '20px' }} />
            <Typography variant="h6" style={{ marginTop: '0px', marginBottom: '10px' }}>
              {t('RenderRole.choose_permissions_label')}
            </Typography>
            <Grid container direction="column" spacing={2}>
              {permissions.map((perm) => (
                <Grid item key={perm}>
                  <FormControlLabel
                    control={<Checkbox checked={selectedPermissions.includes(perm)} onChange={handlePermissionEdit} name={perm} />}
                    label={t(`RenderRole.permissions.${perm}`)}
                  />
                </Grid>
              ))}
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEdit} style={{ backgroundColor: buttonColor, color: textColor }}>
            {t('RenderRole.delete_dialog_cancel')}
          </Button>
          <Button onClick={handleSaveEdit} style={{ backgroundColor: buttonColor, color: textColor }}>
            {t('RenderRole.save_button')}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default RenderRole;
