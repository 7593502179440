import React from 'react';
import { Box, Button, Typography } from '@mui/material';

const NombreParticulesComponent = ({ equipement, nombreParticules, onNombreParticulesChange, maxParticules }) => {
  const handleIncrement = () => {
    if (nombreParticules < maxParticules) {
      onNombreParticulesChange(nombreParticules + 1);
    }
  };

  const handleDecrement = () => {
    if (nombreParticules > 1) {
      onNombreParticulesChange(nombreParticules - 1);
    }
  };

  return (
    <Box display="flex" alignItems="center" justifyContent="center" mt={2}>
      <Button variant="contained" onClick={handleDecrement} disabled={nombreParticules <= 1}>
        -
      </Button>
      <Typography variant="body1" mx={2}>
        {nombreParticules}
      </Typography>
      <Button variant="contained" onClick={handleIncrement} disabled={nombreParticules >= maxParticules}>
        +
      </Button>
    </Box>
  );
};

export default NombreParticulesComponent;
