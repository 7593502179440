import React, { useState, useEffect } from 'react';
import { Typography, Button, TextField } from '@mui/material';
import styled from '@emotion/styled';
import { useParams } from 'react-router-dom';
import { useAllInfoEpMutation, useMailEpMutation } from 'state/api';
import WebFont from 'webfontloader';
import { useTranslation } from 'react-i18next';
import CategorieDisplay from 'components/FormulaireEquipementParticulierCase';

// Utilisation des variables d'environnement pour l'URL de base
const BASE_URL = process.env.REACT_APP_BASE_URL || 'http://localhost:5001';

// Styled components for custom styling
const StyledPage = styled.div`
  background-color: ${(props) => props.couleur_de_fond || '#FFFFFF'};
  color: ${(props) => props.couleur_ecriture || '#000000'};
  padding: 16px;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  font-family: ${(props) => props.police_de_caractere}, sans-serif;
  font-size: 18px;
`;

const StyledLogo = styled.img`
  width: 60%;
  height: auto; 
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
`;

const StyledInputContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  marginTop: '20px',
});

const StyledFileInput = styled('input')({
  margin: '10px 0',
});

const StyledSoumettreButton = styled(Button)(({ theme, style }) => ({
  minWidth: '120px',
  marginTop: '30px',
  padding: '10px 20px',
  borderRadius: '10px',
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  boxShadow: `5px 5px 10px rgba(0, 0, 0, 0.2)`,
  ...style,
}));

const MainPage = () => {
  const { t } = useTranslation();
  const [backgroundColor, setBackgroundColor] = useState('#FFFFFF');
  const [textColor, setTextColor] = useState('#000000');
  const [logoUrl, setLogoUrl] = useState('');
  const [infoFormulaire, setInfoFormulaire] = useState([[], [], []]);
  const [categoriePrincipaleObjet, setCategoriePrincipaleObjet] = useState([]);

  const [comment, setComment] = useState('');
  const [image, setImage] = useState(null);
  const { id } = useParams();
  const [allInfo] = useAllInfoEpMutation();
  const [sendMail] = useMailEpMutation();

  const handleFileChange = (event) => {
    setImage(event.target.files[0]);
  };

  const handleSubmit = () => {
    const formData = new FormData();
    formData.append('comment', comment);
    formData.append('to', infoFormulaire[4]);
    formData.append('subject', `Un problème est survenu pour ${infoFormulaire[0].Nom_Ep}`);
    if (image) formData.append('image', image);

    sendMail(formData).unwrap();
  };

  const dynamicStyles = {
    backgroundColor: infoFormulaire[3] ? infoFormulaire[3].couleur_bouton : '#FFF',
    color: infoFormulaire[3] ? infoFormulaire[3].couleur_bouton_ecriture : '#000',
    boxShadow: infoFormulaire[3] ? `5px 5px 10px ${infoFormulaire[3].couleur_de_lombre_du_bouton}` : '#000',
  };

  useEffect(() => {
    allInfo({ ID_Ep: id }).unwrap()
      .then((data) => {
        setInfoFormulaire(data);
        setLogoUrl(data[0].ID_Etablissement);
        setBackgroundColor(data.backgroundColor);
        setTextColor(data.textColor);

        if (data[3] && data[3].URL_police_de_caractere) {
          WebFont.load({
            google: {
              families: [data[3].URL_police_de_caractere],
            },
          });
        }

        const categoriePrincipaleObjet_ = data[1].find(categorie => categorie.ID_Categorie === data[0].ID_Categorie);
        if (categoriePrincipaleObjet_) {
          setCategoriePrincipaleObjet(categoriePrincipaleObjet_);
        }
      })
      .catch((error) => console.error('Error:', error));
  }, [id, allInfo]);

  return (
    <StyledPage couleur_de_fond={infoFormulaire[3] ? infoFormulaire[3].couleur_de_fond : '#FFFFFF'} couleur_ecriture={infoFormulaire[3] ? infoFormulaire[3].couleur_ecriture : '#000000'} police_de_caractere={infoFormulaire[3] ? infoFormulaire[3].police_de_caractere : 'sans-serif'}>
      <StyledLogo src={`${BASE_URL}/public/image/${logoUrl}`} alt="Logo" />
      <Typography variant="body1" style={{ margin: '60px 0 20px 0', color: textColor, textAlign: 'center' }}>
        {t('MainPage.DescribeProblem')}
      </Typography>
      <CategorieDisplay styleProps={infoFormulaire[3]} categorie={categoriePrincipaleObjet.Nom_Categorie} ID_Image={infoFormulaire[0].ID_Image} srcImage={infoFormulaire[0].ID_Categorie} />
      <StyledInputContainer>
        <TextField
          label={t('MainPage.CommentLabel')}
          variant="outlined"
          multiline
          rows={4}
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          style={{ width: '80%' }}
        />
        <StyledFileInput
          type="file"
          onChange={handleFileChange}
        />
        <StyledSoumettreButton
          variant="contained"
          onClick={handleSubmit}
          style={dynamicStyles}
        >
          {t('MainPage.SubmitButton')}
        </StyledSoumettreButton>
      </StyledInputContainer>
    </StyledPage>
  );
};

export default MainPage;
