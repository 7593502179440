import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  useTheme,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Snackbar,
  Alert
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import {
  useAddCommentIncidentSGMutation,
  useUpdateCommentIncidentSGMutation,
  useDeleteCommentIncidentSGMutation,
  useAddAttachmentIncidentSGMutation,
  useDeleteAttachmentIncidentSGMutation,
  useGetUsersInfoIncidentSGMutation,
  useExportAttachmentIncidentSGMutation
} from "state/api";
import ErrorMessage from '../../components/ErrorMessage';
import ValidationMessage from '../../components/ValidationMessage';

const DetailTicketPartenaire = ({ ticket, refreshTicket }) => {
  const { t } = useTranslation();
  const [editableTicket, setEditableTicket] = useState(null);
  const [isAddingComment, setIsAddingComment] = useState(false);
  const [commentText, setCommentText] = useState("");
  const [editCommentId, setEditCommentId] = useState(null);
  const [editCommentText, setEditCommentText] = useState("");
  const [userInfoMap, setUserInfoMap] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);
  const [isAddingAttachment, setIsAddingAttachment] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [attachmentToDelete, setAttachmentToDelete] = useState(null);
  const [validationMessage, setValidationMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const userSession = useSelector((state) => state.userAuth);

  const theme = useTheme();

  const [addComment] = useAddCommentIncidentSGMutation();
  const [updateComment] = useUpdateCommentIncidentSGMutation();
  const [deleteComment] = useDeleteCommentIncidentSGMutation();
  const [addAttachment] = useAddAttachmentIncidentSGMutation();
  const [deleteAttachment] = useDeleteAttachmentIncidentSGMutation();
  const [getUsersInfo] = useGetUsersInfoIncidentSGMutation();
  const [exportAttachmentIncidentSG] = useExportAttachmentIncidentSGMutation();

  useEffect(() => {
    setEditableTicket(ticket);
    fetchUserInfos();
  }, [ticket]);

  const fetchUserInfos = async () => {
    if (ticket && ticket.Commentaires && ticket.Commentaires.length > 0) {
      const userIds = ticket.Commentaires.map((comment) => comment.ID_Utilisateur);
      try {
        const response = await getUsersInfo({ userIds }).unwrap();
        const userInfoMap = {};
        response.forEach(user => {
          userInfoMap[user.ID_Utilisateur] = user;
        });
        setUserInfoMap(userInfoMap);
      } catch (error) {
        console.error(t('DetailTicketPartenaire.UserInfoError'), error);
      }
    }
  };

  const handleAddCommentClick = () => {
    setIsAddingComment(true);
  };

  const handleSaveComment = async () => {
    if (!editableTicket) return;
    await addComment({ ID_Incident_SG: editableTicket.ID_Incident_SG, ID_Utilisateur: userSession.user.ID_Utilisateur, Commentaire: commentText });
    setCommentText("");
    setIsAddingComment(false);
    refreshTicket();
  };

  const handleCancelComment = () => {
    setIsAddingComment(false);
    setCommentText("");
  };

  const handleEditCommentClick = (comment) => {
    setEditCommentId(comment._id);
    setEditCommentText(comment.Commentaire);
  };

  const handleSaveEditComment = async () => {
    if (!editableTicket || !editCommentId) return;
    await updateComment({ ID_Incident_SG: editableTicket.ID_Incident_SG, CommentID: editCommentId, ID_Utilisateur: userSession.user.ID_Utilisateur, Commentaire: editCommentText });
    setEditCommentId(null);
    setEditCommentText("");
    refreshTicket();
  };

  const handleCancelEditComment = () => {
    setEditCommentId(null);
    setEditCommentText("");
  };

  const handleDeleteComment = async (comment) => {
    if (!editableTicket) return;
    await deleteComment({ ID_Incident_SG: editableTicket.ID_Incident_SG, CommentID: comment._id });
    setConfirmDelete(false);
    refreshTicket();
  };

  const handleAddAttachment = async () => {
    if (!editableTicket || !selectedFile) return;
    const formData = new FormData();
    formData.append('docs', selectedFile);
    formData.append('ID_Incident_SG', editableTicket.ID_Incident_SG);
    formData.append('ID_Utilisateur', userSession.user.ID_Utilisateur);
    await addAttachment(formData);
    setSelectedFile(null);
    setIsAddingAttachment(false);
    refreshTicket();
  };

  const handleDownloadAttachment = async (attachment) => {
    if (!editableTicket) return;
    try {
      const response = await exportAttachmentIncidentSG({ ID_Incident_SG: editableTicket.ID_Incident_SG, filename: attachment }).unwrap();
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', attachment.split('\\').pop().split('--')[0]);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setValidationMessage(t('DetailTicketPartenaire.FileDownloadSuccess'));
    } catch (error) {
      console.error(t('DetailTicketPartenaire.FileDownloadError'), error);
      setErrorMessage(t('DetailTicketPartenaire.FileDownloadError'));
    }
  };

  const handleDeleteAttachment = async (attachment) => {
    if (!editableTicket) return;
    await deleteAttachment({ ID_Incident_SG: editableTicket.ID_Incident_SG, filename: attachment, ID_Utilisateur: userSession.user.ID_Utilisateur });
    setAttachmentToDelete(null);
    refreshTicket();
  };

  const getFileName = (attachment) => {
    const parts = attachment.split('\\');
    const fileNameWithSalt = parts[parts.length - 1];
    return fileNameWithSalt.split('--')[0];
  };

  const getTicketTitle = () => {
    if (!editableTicket) return t('DetailTicketPartenaire.TitleUnavailable');

    if (editableTicket.Type_de_ticket === "service") {
      return editableTicket.details?.specificDetails?.Titre || t('DetailTicketPartenaire.ServiceNameUnavailable');
    } else if (editableTicket.Type_de_ticket === "equipement") {
      return editableTicket.details?.specificDetails?.Nom_Equipement || t('DetailTicketPartenaire.EquipmentNameUnavailable');
    } else if (editableTicket.Type_de_ticket === "equipement_particulier") {
      return editableTicket.details?.specificDetails?.Nom_Ep || t('DetailTicketPartenaire.ParticularEquipmentNameUnavailable');
    } else {
      return t('DetailTicketPartenaire.TitleUnavailable');
    }
  };

  const getUrgencyText = (niveauUrgence) => {
    return t(`DetailTicketPartenaire.UrgencyLevels.${niveauUrgence}`, t('DetailTicketPartenaire.UrgencyLevels.Unknown'));
  };

  if (!editableTicket) {
    return <Typography variant="h6">{t('DetailTicketPartenaire.SelectTicket')}</Typography>;
  }

  return (
    <Box sx={{ margin: "20px", padding: "20px", borderRadius: "12px", backgroundColor: theme.palette.background.alt }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '16px' }}>
        <Box sx={{ width: '60%' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h4" sx={{ fontSize: '1rem', flexGrow: 1 }}>
              {getTicketTitle()}
            </Typography>
          </Box>
          <Box sx={{ marginTop: '16px' }}>
            <Typography variant="h6" sx={{ fontStyle: 'italic', color: 'grey' }}>{t('DetailTicketPartenaire.Essentials')}</Typography>
            <Table sx={{ marginTop: '8px' }}>
              <TableBody>
                <TableRow>
                  <TableCell><span role="img" aria-label="urgency">⏰</span> {t('DetailTicketPartenaire.Urgency')}:</TableCell>
                  <TableCell>{getUrgencyText(editableTicket.Niveau_urgence_intervention)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell><span role="img" aria-label="location">📍</span> {editableTicket.ID_Noeud ? t('DetailTicketPartenaire.Location') : t('DetailTicketPartenaire.Portal')}:</TableCell>
                  <TableCell>{editableTicket.details?.locationDetails?.Nom_Element || editableTicket.details?.etablissementDetails?.Nom_Etablissement || t('DetailTicketPartenaire.LocationUnavailable')}</TableCell>
                </TableRow>
                {editableTicket.Type_de_ticket !== "service" && (
                  <TableRow>
                    <TableCell><span role="img" aria-label="reporters">👥</span> {t('DetailTicketPartenaire.Reporters')}:</TableCell>
                    <TableCell>{editableTicket.Signaleurs.length}</TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell><span role="img" aria-label="last-updated">🕒</span> {t('DetailTicketPartenaire.LastUpdated')}:</TableCell>
                  <TableCell>{new Date(editableTicket.Updated[editableTicket.Updated.length - 1]).toLocaleString()}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        </Box>

        <Box sx={{ width: '40%', textAlign: 'right', paddingRight: '16px' }}>
          <Typography variant="h6" sx={{ fontStyle: 'italic', color: 'grey' }}>{t('DetailTicketPartenaire.IncidentStatus')}</Typography>
          <Typography variant="body1">{editableTicket.Etat_du_ticket || t('DetailTicketPartenaire.StatusUnknown')}</Typography>
        </Box>
      </Box>

      {editableTicket.Type_de_ticket === "service" && (
        <Box sx={{ marginTop: '32px' }}>
          <Typography variant="h6" sx={{ fontStyle: 'italic', color: 'grey' }}>{t('DetailTicketPartenaire.ServiceDetails')}</Typography>
          <Table sx={{ marginTop: '8px' }}>
            <TableBody>
              <TableRow>
                <TableCell><span role="img" aria-label="type">📋</span> {t('DetailTicketPartenaire.ServiceType')}:</TableCell>
                <TableCell>{editableTicket.details?.specificDetails?.Titre || t('DetailTicketPartenaire.ServiceNameUnavailable')}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
      )}

      <Box sx={{ marginTop: '32px' }}>
        <Paper elevation={2} sx={{ padding: '8px', borderRadius: '8px', backgroundColor: theme.palette.background.card }}>
          <Typography variant="h6" sx={{ fontStyle: 'italic', color: 'white', marginBottom: '8px' }}>{t('DetailTicketPartenaire.Comments')}</Typography>
          {editableTicket.Commentaires.length === 0 ? (
            <Typography variant="body2" sx={{ color: 'grey', textAlign: 'center', marginBottom: '8px' }}>
              {t('DetailTicketPartenaire.NoComments')}
            </Typography>
          ) : (
            editableTicket.Commentaires.map((comment, index) => (
              <Box key={index} sx={{ marginBottom: "8px", padding: '8px', borderRadius: '8px', backgroundColor: '#f5f5f5' }}>
                {editCommentId === comment._id ? (
                  <>
                    <TextField
                      value={editCommentText}
                      onChange={(e) => setEditCommentText(e.target.value)}
                      fullWidth
                      multiline
                      rows={2}
                      placeholder={t('DetailTicketPartenaire.EditCommentPlaceholder')}
                      inputProps={{ style: { color: 'black' } }}
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '8px' }}>
                      <Button variant="contained" color="primary" onClick={handleSaveEditComment}>
                        {t('DetailTicketPartenaire.Submit')}
                      </Button>
                      <Button variant="contained" onClick={handleCancelEditComment}>
                        {t('DetailTicketPartenaire.Cancel')}
                      </Button>
                    </Box>
                  </>
                ) : (
                  <>
                    <Typography variant="body2" sx={{ color: 'grey', marginBottom: '4px' }}>
                      <strong>{userInfoMap[comment.ID_Utilisateur]?.name} {userInfoMap[comment.ID_Utilisateur]?.prenom}</strong> - {new Date(comment.Date_commentaire).toLocaleString()}
                      <Typography variant="caption" sx={{ fontStyle: 'italic', color: 'grey', display: 'block' }}>
                        {userInfoMap[comment.ID_Utilisateur]?.email}
                      </Typography>
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'black' }}>{comment.Commentaire}</Typography>
                    {comment.ID_Utilisateur === userSession.user.ID_Utilisateur && (
                      <>
                        <Button size="small" onClick={() => handleEditCommentClick(comment)}>
                          {t('DetailTicketPartenaire.EditComment')}
                        </Button>
                        <Button size="small" onClick={() => setConfirmDelete(true)}>
                          {t('DetailTicketPartenaire.DeleteComment')}
                        </Button>
                        <Dialog
                          open={confirmDelete}
                          onClose={() => setConfirmDelete(false)}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                        >
                          <DialogTitle id="alert-dialog-title">{t('DetailTicketPartenaire.ConfirmDelete')}</DialogTitle>
                          <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                              {t('DetailTicketPartenaire.DeleteCommentConfirm')}
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={() => setConfirmDelete(false)} color="primary">
                              {t('DetailTicketPartenaire.Cancel')}
                            </Button>
                            <Button onClick={() => handleDeleteComment(comment)} color="primary" autoFocus>
                              {t('DetailTicketPartenaire.DeleteComment')}
                            </Button>
                          </DialogActions>
                        </Dialog>
                      </>
                    )}
                  </>
                )}
              </Box>
            ))
          )}
          {isAddingComment ? (
            <Box sx={{ marginTop: '8px' }}>
              <TextField
                value={commentText}
                onChange={(e) => setCommentText(e.target.value)}
                fullWidth
                multiline
                rows={2}
                placeholder={t('DetailTicketPartenaire.AddCommentPlaceholder')}
                inputProps={{ style: { color: 'black' } }}
              />
              <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '8px' }}>
                <Button variant="contained" color="primary" onClick={handleSaveComment}>
                  {t('DetailTicketPartenaire.Submit')}
                </Button>
                <Button variant="contained" onClick={handleCancelComment}>
                  {t('DetailTicketPartenaire.Cancel')}
                </Button>
              </Box>
            </Box>
          ) : (
            <Button variant="outlined" startIcon={<AddIcon />} onClick={handleAddCommentClick}>
              {t('DetailTicketPartenaire.AddComment')}
            </Button>
          )}
        </Paper>
      </Box>

      <Box sx={{ marginTop: '32px' }}>
        <Paper elevation={2} sx={{ padding: '8px', borderRadius: '8px', backgroundColor: theme.palette.background.card }}>
          <Typography variant="h6" sx={{ fontStyle: 'italic', color: 'white' }}>
            {t('DetailTicketPartenaire.FilesAvailable')}
          </Typography>
          <List>
            {editableTicket.Attachements.map((attachment, index) => (
              <ListItem key={index}>
                <ListItemText primary={getFileName(attachment)} />
                <ListItemSecondaryAction>
                  <IconButton edge="end" onClick={() => handleDownloadAttachment(attachment)}>
                    <DownloadIcon />
                  </IconButton>
                  <IconButton edge="end" onClick={() => setAttachmentToDelete(attachment)}>
                    <DeleteIcon />
                  </IconButton>
                  <Dialog
                    open={!!attachmentToDelete}
                    onClose={() => setAttachmentToDelete(null)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">{t('DetailTicketPartenaire.ConfirmDelete')}</DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        {t('DetailTicketPartenaire.DeleteFileConfirm')}
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={() => setAttachmentToDelete(null)} color="primary">
                        {t('DetailTicketPartenaire.Cancel')}
                      </Button>
                      <Button onClick={() => handleDeleteAttachment(attachmentToDelete)} color="primary" autoFocus>
                        {t('DetailTicketPartenaire.DeleteComment')}
                      </Button>
                    </DialogActions>
                  </Dialog>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
          {isAddingAttachment ? (
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '8px' }}>
              <input
                type="file"
                onChange={(e) => setSelectedFile(e.target.files[0])}
                style={{ marginBottom: '8px' }}
              />
              <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <Button variant="contained" color="primary" onClick={handleAddAttachment}>
                  {t('DetailTicketPartenaire.Submit')}
                </Button>
                <Button variant="contained" onClick={() => setIsAddingAttachment(false)}>
                  {t('DetailTicketPartenaire.Cancel')}
                </Button>
              </Box>
            </Box>
          ) : (
            <Button variant="outlined" startIcon={<AddIcon />} onClick={() => setIsAddingAttachment(true)}>
              {t('DetailTicketPartenaire.AddFile')}
            </Button>
          )}
        </Paper>
      </Box>

      <Snackbar open={!!errorMessage} autoHideDuration={6000} onClose={() => setErrorMessage('')}>
        <Alert onClose={() => setErrorMessage('')} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
      {validationMessage && <ValidationMessage message={validationMessage} setValidation={setValidationMessage} />}
    </Box>
  );
};

export default DetailTicketPartenaire;
