import React from 'react';
import { Button, Typography } from '@mui/material';
import { styled } from '@mui/system';

// Utiliser la variable d'environnement REACT_APP_BASE_URL pour définir la base de l'URL
const BASE_URL = process.env.REACT_APP_BASE_URL || 'http://localhost:5001';

const StyledButton = styled(Button)(({ theme, style }) => ({
  width: '250px',
  height: 'auto', 
  marginBottom: '20px',
  borderRadius: '10px',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '20px', 
  overflow: 'hidden',
  boxShadow: `5px 5px 10px rgba(0, 0, 0, 0.2)`,
  '& > img': {
    maxWidth: '80%', 
    maxHeight: '80%', 
    marginBottom: '10px', 
    zIndex: 1,
  },
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'linear-gradient(to bottom right, rgba(255, 255, 255, 0.5), transparent)',
    zIndex: 0,
  },
  ...style,
}));

const CategorieButton = ({ styleProps, categorie, onClick, srcImage, ID_Image, shown, ID_Equipement, dataSignalement }) => {
  // Utilisation de REACT_APP_BASE_URL pour l'URL de l'image
  const urlImage = shown === "S" 
    ? `${BASE_URL}/public/imageequipement/${srcImage}-${ID_Image}.png`
    : `${BASE_URL}/public/imageequipement/${srcImage}-0.png`;
    
  var isEquipementSelected = false;
  if (shown === "S") {
    isEquipementSelected = dataSignalement.some(equipement => equipement === ID_Equipement);
  }

  var dynamicStyles = {
    backgroundColor: shown === "S" && isEquipementSelected ? styleProps.couleur_de_case_active : styleProps.couleur_de_case,
    color: styleProps.couleur_ecriture,
    boxShadow: `5px 5px 10px ${styleProps.couleur_dombre_de_case}`,
  };

  return (
    <StyledButton style={dynamicStyles} onClick={onClick}>
      <img src={urlImage} alt="Catégorie" />
      <Typography variant="body1" style={{ color: styleProps.couleur_ecriture, fontFamily: styleProps ? styleProps.police_de_caractere : 'sans-serif' }}>
        {categorie}
      </Typography>
    </StyledButton>
  );
};

export default CategorieButton;
