import React, {useState} from 'react';
import { Button, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { useMailSignalementMutation } from 'state/api';

// Utilisez le même style que StyledPrecedentButton, ou ajustez selon vos besoins
const StyledSubmitButton = styled(Button)(({ theme, style, styleProps }) => ({
  minWidth: '120px',
  marginTop: '20px',
  padding: '10px 20px',
  borderRadius: '10px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',
  boxShadow: `5px 5px 10px rgba(0, 0, 0, 0.2)`,
  ...style,
}));

const FormulaireBoutonSoumettre = ({ styleProps, commentaire,dataSignalement, setShown, mail_de_destination, detail_equipement, liste_equipement }) => {
  const [error, setError] = useState("");
    const [mailSignalement, { isLoading, isSuccess, isError }] = useMailSignalementMutation();
  const dynamicStyles = {
    backgroundColor: styleProps ? styleProps.couleur_bouton : '#FFF',
    color: styleProps ? styleProps.couleur_bouton_ecriture : '#000',
    boxShadow: styleProps ? `5px 5px 10px ${styleProps.couleur_de_lombre_du_bouton}` : '0px 0px 0px #000',
  };

  const handleSubmitClick = async () => {
    try {
      const equipementsConcernes = liste_equipement.filter(equipement =>
        dataSignalement.includes(equipement.ID_Equipement)
      );

      const listeEquipementsHTML = equipementsConcernes.map(equipement => 
        `<li>${equipement.Nom_Equipement} (Description: ${equipement.Description_Equipement})</li>`
      ).join("");
  
      const htmlContent = `
      <!DOCTYPE html>
      <html>
      <head>
          <style>
              .header {
                  background-color: #800080; /* Violet */
                  color: white;
                  padding: 10px;
                  text-align: center;
              }
              .footer {
                  background-color: #800080; /* Bleu */
                  color: white;
                  padding: 10px;
                  text-align: center;
              }
              .content {
                  margin: 20px;
                  text-align: center;
                  font-size: 16px;
                  color: black !important;
              }
              ul {
                list-style-type: none;
                padding: 0;
            }
          </style>
      </head>
      <body>
          <div class="header">
              <h1>Assistant Hurflym</h1>
          </div>
      
          <div class="content">
              <p>Votre assistant est là pour vous aider.</p>
              <p>Il a été signalé que certains équipements pourraient rencontrer des problèmes. Voici les détails :</p>
              <ul>${listeEquipementsHTML}</ul>
              <p>Commentaire de l'utilisateur: ${commentaire}</p>
          </div>
      
          <div class="footer">
              <p>Merci d'utiliser nos services.</p>
          </div>
      </body>
      </html>
      `;
      const to = mail_de_destination;
      const subject = 'Problème signalé à ' + detail_equipement.Nom_signalement;
      await mailSignalement({ to, subject, htmlContent }).unwrap();
      setShown("T");
    } catch (error) {
      setShown("R");
    }
  };

  return (
    
    <StyledSubmitButton style={dynamicStyles} onClick={handleSubmitClick}>
      <Typography variant="body2" style={{ color: dynamicStyles.color, fontFamily: styleProps ? styleProps.police_de_caractere : 'sans-serif' }}>
        Soumettre
      </Typography>
    </StyledSubmitButton>
  );
};

export default FormulaireBoutonSoumettre;
