import React from 'react';
import { Typography, Container } from '@mui/material';
import NavbarUtilisateur from 'components_public/navbarUtilisateur';

const Services = () => {
  return (
    <Container>
    <NavbarUtilisateur />
      <Typography variant="h3" gutterBottom component="div">
        Services
      </Typography>
      <Typography variant="body1">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam nunc justo, sagittis nec libero non, interdum dapibus nunc. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Praesent sit amet eros nisi. Curabitur scelerisque felis turpis, auctor consequat quam elementum quis.
      </Typography>
    </Container>
  );
};

export default Services;
