import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  TextField,
  Checkbox,
  Button,
  FormControlLabel,
  Typography,
  Box,
  IconButton,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  FormGroup,
  Divider,
} from '@mui/material';
import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useGetInfoRegleSpecialMutation, useModifyRegleSpecialMutation } from '../state/api';
import ErrorMessage from './ErrorMessage';
import ValidationMessage from './ValidationMessage';
import InfoMessage from './InfoMessage';

const RenderRegle = () => {
  const { t } = useTranslation();
  const ID_Etablissement = useSelector((state) => state.adminAuth.ID_Etablissement);
  const [regle, setRegle] = useState({
    ConnexionObligatoire: false,
    ConnexionPrive: false,
    DomainePrive: [''],
    IsConnexionUnique: true,
    ConnexionUnique: 'Hurflym',
    ConnexionMultiple: [],
  });

  const [getInfoRegleSpecial, { data, isLoading }] = useGetInfoRegleSpecialMutation();
  const [modifyRegleSpecial] = useModifyRegleSpecialMutation();

  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [validation, setValidation] = useState('');

  useEffect(() => {
    getInfoRegleSpecial({ ID_Etablissement: ID_Etablissement });
  }, [getInfoRegleSpecial, ID_Etablissement]);

  useEffect(() => {
    if (data) {
      setRegle({
        ConnexionObligatoire: data.ConnexionObligatoire,
        ConnexionPrive: data.ConnexionPrive,
        DomainePrive: data.DomainePrive.length > 0 ? data.DomainePrive : [''],
        IsConnexionUnique: data.IsConnexionUnique,
        ConnexionUnique: data.ConnexionUnique || 'Hurflym',
        ConnexionMultiple: data.ConnexionMultiple || [],
      });
    }
  }, [data]);

  const handleInputChange = (index, value) => {
    const newDomainePrive = [...regle.DomainePrive];
    newDomainePrive[index] = value;
    setRegle((prev) => ({ ...prev, DomainePrive: newDomainePrive }));
  };

  const handleAddDomain = () => {
    setRegle((prev) => ({ ...prev, DomainePrive: [...prev.DomainePrive, ''] }));
  };

  const handleRemoveDomain = (index) => {
    const newDomainePrive = regle.DomainePrive.filter((_, i) => i !== index);
    setRegle((prev) => ({ ...prev, DomainePrive: newDomainePrive }));
  };

  const handleRadioChange = (event) => {
    const isUnique = event.target.value === 'unique';
    setRegle((prev) => ({
      ...prev,
      IsConnexionUnique: isUnique,
      ConnexionUnique: isUnique ? 'Hurflym' : '',
      ConnexionMultiple: !isUnique ? [] : prev.ConnexionMultiple,
    }));
  };

  const handleConnexionUniqueChange = (event) => {
    setRegle((prev) => ({ ...prev, ConnexionUnique: event.target.value }));
  };

  const handleConnexionMultipleChange = (event) => {
    const value = event.target.value;
    const checked = event.target.checked;
    setRegle((prev) => {
      const newConnexionMultiple = checked
        ? [...prev.ConnexionMultiple, value]
        : prev.ConnexionMultiple.filter((item) => item !== value);
      return { ...prev, ConnexionMultiple: newConnexionMultiple };
    });
  };

  const handleSaveChanges = async () => {
    try {
      const response = await modifyRegleSpecial({ ...regle, ID_Etablissement }).unwrap();
      setValidation(t('RenderRegle.success_message'));
    } catch (err) {
      setError(t('RenderRegle.error_message'));
    }
  };

  const connexionOptions = ['Hurflym', 'Google', 'Microsoft', 'Facebook', 'Github'];

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: 4 }}>
      <Typography variant="h5" sx={{ mb: 2, textAlign: 'center' }}>
        {t('RenderRegle.title')}
      </Typography>
      <FormControlLabel
        control={
          <Checkbox
            checked={regle.ConnexionObligatoire}
            onChange={(e) => setRegle((prev) => ({ ...prev, ConnexionObligatoire: e.target.checked }))}
          />
        }
        label={t('RenderRegle.connexion_obligatoire_label')}
        sx={{ mb: 2 }}
      />
      {regle.ConnexionObligatoire && (
        <>
          <FormControlLabel
            control={
              <Checkbox
                checked={regle.ConnexionPrive}
                onChange={(e) => setRegle((prev) => ({ ...prev, ConnexionPrive: e.target.checked }))}
              />
            }
            label={t('RenderRegle.domaine_prive_label')}
            sx={{ mb: 2 }}
          />
          {regle.ConnexionPrive && (
            <>
              {regle.DomainePrive.map((domain, index) => (
                <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <TextField
                    fullWidth
                    label={`${t('RenderRegle.domaine_label')} ${index + 1}`}
                    variant="outlined"
                    value={domain}
                    onChange={(e) => handleInputChange(index, e.target.value)}
                    sx={{ mr: 2 }}
                  />
                  <IconButton onClick={() => handleRemoveDomain(index)}>
                    <DeleteIcon />
                  </IconButton>
                </Box>
              ))}
              <Button onClick={handleAddDomain} variant="outlined" color="primary" startIcon={<AddIcon />} sx={{ mb: 2 }}>
                {t('RenderRegle.add_domain_button')}
              </Button>
            </>
          )}
          <Divider sx={{ width: '100%', marginY: 3 }} />
          <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4 }}>
            <FormControl component="fieldset" sx={{ marginRight: 8 }}>
              <FormLabel component="legend">{t('RenderRegle.connexion_type_label')}</FormLabel>
              <RadioGroup
                value={regle.IsConnexionUnique ? 'unique' : 'multiple'}
                onChange={handleRadioChange}
                sx={{ display: 'flex', flexDirection: 'row' }}
              >
                <FormControlLabel
                  value="unique"
                  control={<Radio sx={{ color: regle.IsConnexionUnique ? 'black' : 'lightgray' }} />}
                  label={t('RenderRegle.connexion_unique_label')}
                />
                <FormControlLabel
                  value="multiple"
                  control={<Radio sx={{ color: !regle.IsConnexionUnique ? 'black' : 'lightgray' }} />}
                  label={t('RenderRegle.connexion_multiple_label')}
                />
              </RadioGroup>
            </FormControl>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
            <FormControl
              fullWidth
              sx={{
                mb: 2,
                marginRight: 8,
                opacity: regle.IsConnexionUnique ? 1 : 0.3,
                pointerEvents: regle.IsConnexionUnique ? 'auto' : 'none',
              }}
            >
              <FormLabel>{t('RenderRegle.connexion_unique_select_label')}</FormLabel>
              <RadioGroup value={regle.ConnexionUnique} onChange={handleConnexionUniqueChange}>
                {connexionOptions.map((option) => (
                  <FormControlLabel
                    key={option}
                    value={option}
                    control={<Radio />}
                    label={t(`RenderRegle.connexion_option_${option.toLowerCase()}`)}
                  />
                ))}
              </RadioGroup>
            </FormControl>
            <FormControl
              component="fieldset"
              sx={{
                mb: 2,
                opacity: !regle.IsConnexionUnique ? 1 : 0.3,
                pointerEvents: !regle.IsConnexionUnique ? 'auto' : 'none',
              }}
            >
              <FormLabel component="legend">{t('RenderRegle.connexion_multiple_select_label')}</FormLabel>
              <FormGroup>
                {connexionOptions.map((option) => (
                  <FormControlLabel
                    key={option}
                    control={
                      <Checkbox
                        checked={regle.ConnexionMultiple.includes(option)}
                        onChange={handleConnexionMultipleChange}
                        value={option}
                      />
                    }
                    label={t(`RenderRegle.connexion_option_${option.toLowerCase()}`)}
                  />
                ))}
              </FormGroup>
            </FormControl>
          </Box>
        </>
      )}
      <Button onClick={handleSaveChanges} variant="contained" color="primary">
        {t('RenderRegle.save_changes_button')}
      </Button>
      <ErrorMessage message={error} setError={setError} />
      <ValidationMessage message={validation} setValidation={setValidation} />
      <InfoMessage message={message} setInfo={setMessage} />
    </Box>
  );
};

export default RenderRegle;
