import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  TextField,
  Card,
  CardContent,
  CardActions,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  useTheme,
  CircularProgress,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import Header from 'components/Header';
import { useSelector } from 'react-redux';
import {
  useAddPortailMutation,
  useDeletePortailMutation,
  useGetAllInfoEtablissementPortailQuery,
  useUpdateServicePortailMutation,
  useRemoveServicePortailMutation,
  useGetAllInfoServiceITMutation,
  useUpdateEquipementPortailMutation,
  useDeleteEquipementChainITPortailMutation,
  useGetAllInfoEquipementITMutation,
  useUpdateEquipementParticulierPortailMutation,
  useDeleteEquipementParticulierChainITPortailMutation,
  useGetAllInfoEquipementParticulierITMutation,
  useGetAllInfoPortailMutation,
} from 'state/api';
import { useTranslation } from 'react-i18next';
import ErrorMessage from 'components/ErrorMessage';
import ValidationMessage from 'components/ValidationMessage';

const PortailIT = () => {
  const { t } = useTranslation();
  const [portails, setPortails] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [addPortail] = useAddPortailMutation();
  const [deletePortail] = useDeletePortailMutation();
  const [getAllInfoPortail] = useGetAllInfoPortailMutation();
  const { data: etablissementsData } = useGetAllInfoEtablissementPortailQuery();
  const [getAllInfoServiceIT] = useGetAllInfoServiceITMutation();
  const [getAllInfoEquipementIT] = useGetAllInfoEquipementITMutation();
  const [getAllInfoEquipementParticulierIT] = useGetAllInfoEquipementParticulierITMutation();
  const [updateEquipement] = useUpdateEquipementPortailMutation();
  const [updateEquipementParticulier] = useUpdateEquipementParticulierPortailMutation();
  const [updateServicePortail] = useUpdateServicePortailMutation();
  const [removeServicePortail] = useRemoveServicePortailMutation();
  const [deleteEquipementChainIT] = useDeleteEquipementChainITPortailMutation();
  const [deleteEquipementParticulierChainIT] = useDeleteEquipementParticulierChainITPortailMutation();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [filteredEtablissements, setFilteredEtablissements] = useState([]);
  const [selectedEtablissement, setSelectedEtablissement] = useState(null);
  const [dialogSearchTerm, setDialogSearchTerm] = useState('');
  const [dialogType, setDialogType] = useState(null);
  const [selectedPortail, setSelectedPortail] = useState(null);
  const [selectionList, setSelectionList] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [loadingItems, setLoadingItems] = useState(false);
  const [filterTerm, setFilterTerm] = useState('');
  const [loadingPortails, setLoadingPortails] = useState(true);
  const [loadingAction, setLoadingAction] = useState(false);
  const [itemConfirmationDialogOpen, setItemConfirmationDialogOpen] = useState(false);
  const [portailConfirmationDialogOpen, setPortailConfirmationDialogOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [portailToDelete, setPortailToDelete] = useState(null);
  const [creatingPortail, setCreatingPortail] = useState(false);
  const [error, setError] = useState(null);
  const [validation, setValidation] = useState(null);

  const theme = useTheme();
  const userSession = useSelector((state) => state.adminAuth);

  const fetchPortails = async () => {
    try {
      const response = await getAllInfoPortail({ ID_Etablissement_Source: userSession.ID_Etablissement, ID_Etablissement: userSession.ID_Etablissement }).unwrap();

      const fetchedPortails = await Promise.all(
        response.map(async (portail) => {
          let servicesIT = [];
          let equipementsIT = [];
          let equipementsParticuliersIT = [];

          try {
            const serviceCategories = (await getAllInfoServiceIT({ ID_Etablissement: userSession.ID_Etablissement }).unwrap()) || [];
            servicesIT = serviceCategories.flatMap(category => category.services);
          } catch (error) {
            if (error.status !== 404) console.error('Erreur lors de la récupération des services IT :', error);
          }

          try {
            equipementsIT = (await getAllInfoEquipementIT({ ID_Etablissement: userSession.ID_Etablissement }).unwrap()) || [];
          } catch (error) {
            if (error.status !== 404) console.error('Erreur lors de la récupération des équipements IT :', error);
          }

          try {
            equipementsParticuliersIT = (await getAllInfoEquipementParticulierIT({ ID_Etablissement: userSession.ID_Etablissement }).unwrap()) || [];
          } catch (error) {
            if (error.status !== 404) console.error('Erreur lors de la récupération des équipements particuliers IT :', error);
          }

          return {
            ...portail,
            services: servicesIT.filter(service => service.ID_Portail === portail.ID_Portail),
            equipements: equipementsIT.filter(equipement => equipement.ID_Portail_Destination === portail.ID_Etablissement_Destination),
            equipementsParticuliers: equipementsParticuliersIT.filter(equipement => equipement.ID_Portail_Destination === portail.ID_Etablissement_Destination),
            servicesLibres: servicesIT.filter(service => !service.ID_Portail || service.ID_Portail === null || service.ID_Portail === 0),
            equipementsLibres: equipementsIT.filter(equipement => !equipement.ID_Portail_Destination || equipement.ID_Portail_Destination === null || equipement.ID_Portail_Destination === 0),
            equipementsParticuliersLibres: equipementsParticuliersIT.filter(equipement => !equipement.ID_Portail_Destination || equipement.ID_Portail_Destination === null || equipement.ID_Portail_Destination === 0),
          };
        })
      );
      setPortails(fetchedPortails);
    } catch (error) {
      console.error('Erreur lors de la récupération des portails :', error);
      setError(t('PortailIT.errorFetchingPortails'));
    } finally {
      setLoadingPortails(false);
    }
  };

  useEffect(() => {
    fetchPortails();
  }, [
    userSession.ID_Etablissement,
    getAllInfoPortail,
    getAllInfoServiceIT,
    getAllInfoEquipementIT,
    getAllInfoEquipementParticulierIT,
  ]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleCreatePortail = async () => {
    const newPortailData = {
      ID_Etablissement_Source: userSession.ID_Etablissement,
      ID_Etablissement_Destination: selectedEtablissement.ID_Etablissement,
      ID_Etablissement: userSession.ID_Etablissement,
    };
    setCreatingPortail(true);
    try {
      const newPortail = await addPortail(newPortailData).unwrap();
      setPortails([...portails, newPortail.portail]);
      setDialogOpen(false);
      setValidation(t('PortailIT.portailCreated'));
    } catch (error) {
      console.error('Erreur lors de la création du portail :', error);
      setError(t('PortailIT.errorCreatingPortail'));
    } finally {
      setCreatingPortail(false);
    }
  };

  const handleOpenDeletePortailDialog = (id) => {
    setPortailToDelete(id);
    setPortailConfirmationDialogOpen(true);
  };

  const handleDeletePortail = async () => {
    setLoadingAction(true);
    try {
      await deletePortail({ ID_Portail: portailToDelete, ID_Etablissement: userSession.ID_Etablissement }).unwrap();
      setPortails(portails.filter((portail) => portail.ID_Portail !== portailToDelete));
      setPortailConfirmationDialogOpen(false);
      setValidation(t('PortailIT.portailDeleted'));
    } catch (error) {
      console.error('Erreur lors de la suppression du portail :', error);
      setError(t('PortailIT.errorDeletingPortail'));
    } finally {
      setLoadingAction(false);
    }
  };

  const handleRemoveItem = async () => {
    if (!itemToDelete) {
      console.error("Aucun élément à supprimer n'a été sélectionné.");
      return;
    }
  
    const { ID_Portail, category, item } = itemToDelete;
    setLoadingAction(true);
    try {
      if (category === 'equipements') {
        setPortails((prevPortails) =>
          prevPortails.map((portail) =>
            portail.ID_Portail === ID_Portail
              ? {
                  ...portail,
                  equipements: portail.equipements.filter((e) => e.ID_Equipement_IT !== item.ID_Equipement_IT),
                  equipementsLibres: [...portail.equipementsLibres, item],
                }
              : portail
          )
        );
        await deleteEquipementChainIT({ ID_Equipement_IT: item.ID_Equipement_IT, ID_Etablissement: userSession.ID_Etablissement }).unwrap();
      } else if (category === 'equipementsParticuliers') {
        setPortails((prevPortails) =>
          prevPortails.map((portail) =>
            portail.ID_Portail === ID_Portail
              ? {
                  ...portail,
                  equipementsParticuliers: portail.equipementsParticuliers.filter((e) => e.ID_Ep_IT !== item.ID_Ep_IT),
                  equipementsParticuliersLibres: [...portail.equipementsParticuliersLibres, item],
                }
              : portail
          )
        );
        await deleteEquipementParticulierChainIT({ ID_Ep_IT: item.ID_Ep_IT, ID_Etablissement: userSession.ID_Etablissement }).unwrap();
      } else if (category === 'services') {
        setPortails((prevPortails) =>
          prevPortails.map((portail) =>
            portail.ID_Portail === ID_Portail
              ? {
                  ...portail,
                  services: portail.services.filter((s) => s.ID_Service_IT !== item.ID_Service_IT),
                  servicesLibres: [...portail.servicesLibres, item],
                }
              : portail
          )
        );
        await removeServicePortail({ ID_Service_IT: item.ID_Service_IT, ID_Etablissement: userSession.ID_Etablissement }).unwrap();
      }
      setItemConfirmationDialogOpen(false);
      setValidation(t('PortailIT.itemRemoved'));
    } catch (error) {
      console.error(`Erreur lors de la suppression de l'élément de ${category} :`, error);
      setError(t('PortailIT.errorRemovingItem'));
    } finally {
      setLoadingAction(false);
      fetchPortails(); // Fetch the latest data from the server
    }
  };

  const handleDialogSearchChange = (e) => {
    const term = e.target.value;
    setDialogSearchTerm(term);
    setFilteredEtablissements(etablissementsData.filter((etablissement) =>
      etablissement.Nom_Etablissement.toLowerCase().includes(term.toLowerCase())
    ));
  };

  const handleSelectEtablissement = (etablissement) => {
    setSelectedEtablissement(etablissement);
  };

  const handleOpenDialog = () => {
    setDialogOpen(true);
    setFilteredEtablissements(etablissementsData);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedEtablissement(null);
    setDialogType(null);
    setSelectedPortail(null);
  };

  const handleOpenItemDialog = async (portail, type) => {
    setSelectedPortail(portail);
    setDialogType(type);

    setLoadingItems(true);
    let items = [];
    try {
      if (type === 'services') {
        items = portail.servicesLibres;
      } else if (type === 'equipements') {
        items = portail.equipementsLibres;
      } else if (type === 'equipementsParticuliers') {
        items = portail.equipementsParticuliersLibres;
      }
    } catch (error) {
      console.error(`Erreur lors de la récupération des ${type} :`, error);
      setError(t('PortailIT.errorFetchingItems'));
    }
    setSelectionList(items);
    setSelectedItems([]);
    setLoadingItems(false);
    setDialogOpen(true);
  };

  const handleItemSelect = (item) => {
    setSelectedItems((prevSelected) => {
      if (prevSelected.includes(item)) {
        return prevSelected.filter((i) => i !== item);
      } else {
        return [...prevSelected, item];
      }
    });
  };

  const handleValidateSelection = async () => {
    setLoadingAction(true);
    try {
      for (const item of selectedItems) {
        if (dialogType === 'services') {
          setPortails((prevPortails) =>
            prevPortails.map((portail) =>
              portail.ID_Portail === selectedPortail.ID_Portail
                ? {
                    ...portail,
                    services: [...portail.services, item],
                    servicesLibres: portail.servicesLibres.filter((s) => s.ID_Service_IT !== item.ID_Service_IT),
                  }
                : portail
            )
          );
          await updateServicePortail({ ID_Portail: selectedPortail.ID_Portail, ID_Service_IT: item.ID_Service_IT, ID_Etablissement: userSession.ID_Etablissement }).unwrap();
        } else if (dialogType === 'equipements') {
          setPortails((prevPortails) =>
            prevPortails.map((portail) =>
              portail.ID_Portail === selectedPortail.ID_Portail
                ? {
                    ...portail,
                    equipements: [...portail.equipements, item],
                    equipementsLibres: portail.equipementsLibres.filter((e) => e.ID_Equipement_IT !== item.ID_Equipement_IT),
                  }
                : portail
            )
          );
          await updateEquipement({ ID_Portail: selectedPortail.ID_Portail, ID_Equipement_IT: item.ID_Equipement_IT, ID_Etablissement: userSession.ID_Etablissement }).unwrap();
        } else if (dialogType === 'equipementsParticuliers') {
          setPortails((prevPortails) =>
            prevPortails.map((portail) =>
              portail.ID_Portail === selectedPortail.ID_Portail
                ? {
                    ...portail,
                    equipementsParticuliers: [...portail.equipementsParticuliers, item],
                    equipementsParticuliersLibres: portail.equipementsParticuliersLibres.filter((e) => e.ID_Ep_IT !== item.ID_Ep_IT),
                  }
                : portail
            )
          );
          await updateEquipementParticulier({ ID_Portail: selectedPortail.ID_Portail, ID_Ep_IT: item.ID_Ep_IT, ID_Etablissement: userSession.ID_Etablissement }).unwrap();
        }
      }
      fetchPortails(); // Fetch the latest data from the server
      setDialogOpen(false);
      setValidation(t('PortailIT.selectionValidated'));
    } catch (error) {
      console.error(`Erreur lors de la mise à jour des ${dialogType} :`, error);
      setError(t('PortailIT.errorUpdatingItems'));
    } finally {
      setLoadingAction(false);
    }
  };

  const handleOpenItemConfirmationDialog = (ID_Portail, category, item) => {
    setItemToDelete({ ID_Portail, category, item });
    setItemConfirmationDialogOpen(true);
  };

  const handleCloseItemConfirmationDialog = () => {
    setItemConfirmationDialogOpen(false);
    setItemToDelete(null);
  };

  const filteredPortails = portails.filter((portail) =>
    portail.Nom_Etablissement_Destination && portail.Nom_Etablissement_Destination.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const filteredSelectionList = selectionList.filter(item =>
    (item.Nom_Ep || item.Nom_Equipement || item.Titre).toLowerCase().includes(filterTerm.toLowerCase())
  );

  const handleFilterChange = (e) => {
    setFilterTerm(e.target.value);
  };

  const cardStyle = {
    margin: '10px',
    padding: '10px',
    backgroundColor: theme.palette.background.alt,
    borderColor: theme.palette.primary.main,
    borderRadius: '8px',
    borderWidth: '1px',
    borderStyle: 'solid',
  };

  return (
    <Box m="1.5rem 2.5rem">
      <Header title={t('PortailIT.headerTitle')} subtitle={t('PortailIT.headerSubtitle')} />
      <div className="app-container">
        <Box display="flex" justifyContent="space-between" alignItems="center" mb="1rem" mt="20px">
          <TextField
            label={t('PortailIT.searchPlaceholder')}
            variant="outlined"
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <Button variant="contained" color="primary" onClick={handleOpenDialog}>
            {t('PortailIT.createButton')}
          </Button>
        </Box>
        {loadingPortails ? (
          <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
            <CircularProgress />
            <Typography variant="body1" ml={2}>{t('PortailIT.loadingItems')}</Typography>
          </Box>
        ) : (
          <Box display="flex" flexDirection="column" gap="1rem">
            {filteredPortails.map((portail) => (
              <Card key={portail.ID_Portail} style={cardStyle}>
                <CardContent>
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="h6" fontWeight="bold" fontSize="2rem">
                      {portail.Nom_Etablissement_Destination}
                    </Typography>
                    <CardActions>
                      <Button variant="contained" color="primary" onClick={() => handleOpenItemDialog(portail, 'equipements')}>{t('PortailIT.equipementButton')}</Button>
                      <Button variant="contained" color="primary" onClick={() => handleOpenItemDialog(portail, 'equipementsParticuliers')}>{t('PortailIT.particulierEquipementButton')}</Button>
                      <Button variant="contained" color="primary" onClick={() => handleOpenItemDialog(portail, 'services')}>{t('PortailIT.serviceButton')}</Button>
                      <IconButton color="secondary" onClick={() => handleOpenDeletePortailDialog(portail.ID_Portail)} style={{ marginLeft: '1rem' }}>
                        <DeleteIcon />
                      </IconButton>
                    </CardActions>
                  </Box>
                  <Box mt="1rem">
                    <Typography variant="h6" component="div" fontStyle="italic" style={{ marginTop: `30px` }}>
                      {t('PortailIT.equipementButton')}
                    </Typography>
                    <Box mb="0.5rem" style={{ borderBottom: `2px solid ${theme.palette.primary.main}`, marginTop: `00px` }}></Box>
                    <ul style={{ listStyleType: 'none', padding: 0 }}>
                      {portail.equipements.map((equipement) => (
                        <li key={equipement.ID_Equipement_IT} style={{ fontSize: '1.1rem', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <span>{equipement.Nom_Equipement}</span>
                          <Button
                            size="small"
                            onClick={() => handleOpenItemConfirmationDialog(portail.ID_Portail, 'equipements', equipement)}
                            style={{ color: theme.palette.error.main }}
                          >
                            {t('PortailIT.removeButton')}
                          </Button>
                        </li>
                      ))}
                    </ul>
                  </Box>
                  <Box mt="1rem">
                    <Typography variant="h6" component="div" fontStyle="italic" style={{ marginTop: `30px` }}>
                      {t('PortailIT.particulierEquipementButton')}
                    </Typography>
                    <Box mb="0.5rem" style={{ borderBottom: `2px solid ${theme.palette.primary.main}` }}></Box>
                    <ul style={{ listStyleType: 'none', padding: 0 }}>
                      {portail.equipementsParticuliers.map((equipementParticulier) => (
                        <li key={equipementParticulier.ID_Ep_IT} style={{ fontSize: '1.1rem', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <span>{equipementParticulier.Nom_Ep}</span>
                          <Button
                            size="small"
                            onClick={() => handleOpenItemConfirmationDialog(portail.ID_Portail, 'equipementsParticuliers', equipementParticulier)}
                            style={{ color: theme.palette.error.main }}
                          >
                            {t('PortailIT.removeButton')}
                          </Button>
                        </li>
                      ))}
                    </ul>
                  </Box>
                  <Box mt="1rem">
                    <Typography variant="h6" component="div" fontStyle="italic" style={{ marginTop: `30px` }}>
                      {t('PortailIT.serviceButton')}
                    </Typography>
                    <Box mb="0.5rem" style={{ borderBottom: `2px solid ${theme.palette.primary.main}` }}></Box>
                    <ul style={{ listStyleType: 'none', padding: 0 }}>
                      {portail.services.map((service) => (
                        <li key={service.ID_Service_IT} style={{ fontSize: '1.1rem', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <span>{service.Titre}</span>
                          <Button
                            size="small"
                            onClick={() => handleOpenItemConfirmationDialog(portail.ID_Portail, 'services', service)}
                            style={{ color: theme.palette.error.main }}
                          >
                            {t('PortailIT.removeButton')}
                          </Button>
                        </li>
                      ))}
                    </ul>
                  </Box>
                </CardContent>
              </Card>
            ))}
          </Box>
        )}
      </div>

      <Dialog open={dialogOpen} onClose={handleCloseDialog} PaperProps={{ style: { minHeight: '60vh', minWidth: '50vw' } }}>
        <DialogTitle>
          {dialogType
            ? t(`PortailIT.dialogTitle.${dialogType}`)
            : t('PortailIT.dialogTitle.default')}
          <IconButton
            aria-label="close"
            onClick={handleCloseDialog}
            style={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {dialogType ? (
            <>
              <TextField
                label={t('PortailIT.filterLabel')}
                variant="outlined"
                value={filterTerm}
                onChange={handleFilterChange}
                fullWidth
                margin="dense"
              />
              {loadingItems ? (
                <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
                  <CircularProgress />
                  <Typography variant="body1" ml={2}>{t('PortailIT.loadingItems')}</Typography>
                </Box>
              ) : filteredSelectionList.length ? (
                <List>
                  {filteredSelectionList.map((item) => (
                    <ListItem
                      button
                      key={item.ID_Ep_IT || item.ID_Equipement_IT || item.ID_Service_IT}
                      selected={selectedItems.includes(item)}
                      onClick={() => handleItemSelect(item)}
                    >
                      <ListItemText
                        primary={item.Nom_Ep || item.Nom_Equipement || item.Titre}
                      />
                    </ListItem>
                  ))}
                </List>
              ) : (
                <Typography variant="body2">{t(`PortailIT.noItemsFound.${dialogType}`)}</Typography>
              )}
            </>
          ) : (
            <>
              <TextField
                autoFocus
                margin="dense"
                label={t('PortailIT.searchPlaceholder')}
                type="text"
                fullWidth
                value={dialogSearchTerm}
                onChange={handleDialogSearchChange}
              />
              <List>
                {filteredEtablissements.map((etablissement) => (
                  <ListItem
                    button
                    key={etablissement.ID_Etablissement}
                    selected={selectedEtablissement?.ID_Etablissement === etablissement.ID_Etablissement}
                    onClick={() => handleSelectEtablissement(etablissement)}
                  >
                    <ListItemText primary={etablissement.Nom_Etablissement} />
                  </ListItem>
                ))}
              </List>
            </>
          )}
        </DialogContent>
        <DialogActions>
          {dialogType ? (
            <Button onClick={handleValidateSelection} color="primary" variant="contained" disabled={loadingAction}>
              {loadingAction ? <CircularProgress size={24} /> : t('PortailIT.confirmButton')}
            </Button>
          ) : (
            <Button onClick={handleCreatePortail} color="primary" variant="contained" disabled={!selectedEtablissement || creatingPortail}>
              {creatingPortail ? <CircularProgress size={24} /> : t('PortailIT.confirmButton')}
            </Button>
          )}
        </DialogActions>
      </Dialog>

      <Dialog
        open={portailConfirmationDialogOpen}
        onClose={() => setPortailConfirmationDialogOpen(false)}
      >
        <DialogTitle>{t('PortailIT.confirmDeleteTitle')}</DialogTitle>
        <DialogContent>
          <Typography>{t('PortailIT.confirmDeletePortail')}</Typography>
          {loadingAction && (
            <Box display="flex" justifyContent="center" alignItems="center" mt="1rem">
              <CircularProgress />
              <Typography variant="body1" ml={2}>{t('PortailIT.deletingPortail')}</Typography>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPortailConfirmationDialogOpen(false)} color="primary">
            {t('PortailIT.cancelButton')}
          </Button>
          <Button
            onClick={handleDeletePortail}
            color="secondary"
            variant="contained"
            disabled={loadingAction}
          >
            {t('PortailIT.deleteButton')}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={itemConfirmationDialogOpen}
        onClose={handleCloseItemConfirmationDialog}
      >
        <DialogTitle>{t('PortailIT.confirmDeleteTitle')}</DialogTitle>
        <DialogContent>
          <Typography>{t('PortailIT.confirmDeleteItem')}</Typography>
          {loadingAction && (
            <Box display="flex" justifyContent="center" alignItems="center" mt="1rem">
              <CircularProgress />
              <Typography variant="body1" ml={2}>{t('PortailIT.deletingItem')}</Typography>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseItemConfirmationDialog} color="primary">
            {t('PortailIT.cancelButton')}
          </Button>
          <Button
            onClick={handleRemoveItem}
            color="secondary"
            variant="contained"
            disabled={loadingAction}
          >
            {t('PortailIT.deleteButton')}
          </Button>
        </DialogActions>
      </Dialog>

      <ErrorMessage message={error} setError={setError} />
      <ValidationMessage message={validation} setValidation={setValidation} />
    </Box>
  );
};

export default PortailIT;