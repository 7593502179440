import React, { useState, useEffect } from 'react';
import { useLoginUserMutation } from "state/api";
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setUserAuthenticated } from './../../state/userAuth';
import './login.css';
import googleLogo from '../../assets/logo_google.png';

const BASE_URL = process.env.REACT_APP_BASE_URL || 'http://localhost:5001';

function LoginClient() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [login, { datapost, errorpost }] = useLoginUserMutation();

  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [authError, setAuthError] = useState(null);

  const searchParams = new URLSearchParams(location.search);
  const redirectTo = searchParams.get('redirectTo');

  const handleLogin = async (event) => {
    event.preventDefault();
    setAuthError(null);
    try {
      const result = await login({ username, password }).unwrap();
      dispatch(setUserAuthenticated({
        isAuthenticated: true,
        user: result.utilisateur.utilisateurInfo,
        etablissements: result.utilisateur.etablissements,
      }));
      setShouldRedirect(true);
    } catch (err) {
      if (err.status === 401) {
        setAuthError(t('LoginClient.auth_error_invalid_credentials'));
      } else {
        setAuthError(t('LoginClient.auth_error_generic'));
      }
    }
  };

  const handleGoogleLogin = () => {
    window.location.href = `${BASE_URL}/auth/google`;
  };

  useEffect(() => {
    if (shouldRedirect) {
      const redirectPath = redirectTo && redirectTo !== '/loginclient' ? decodeURIComponent(redirectTo) : '/dashboard';
      navigate(redirectPath);
    }
  }, [shouldRedirect, navigate, redirectTo]);

  return (
    <div className="login-page">
      <div className="login-container">
        <div className="login-box">
          <div className="login-section">
            <form onSubmit={handleLogin} className="login-form">
              {authError && <p className="login-error">{authError}</p>}
              <input
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                placeholder={t('LoginClient.username_placeholder')}
                className="login-input"
                id="username"
              />
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder={t('LoginClient.password_placeholder')}
                className="login-input"
                id="password"
              />
              <button type="submit" className="login-button">{t('LoginClient.login_button')}</button>
            </form>
            
            <div className="google-login-wrapper" style={{ marginTop: '20px' }}>
              <button 
                onClick={handleGoogleLogin} 
                className="google-login-button"
                style={{ 
                  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                  border: 'none'
                }}
              >
                <img src={googleLogo} alt="Google logo" width="20" height="20" />
                {t('LoginClient.google_login_button')}
              </button>
            </div>
          </div>

          <hr className="separator" />

          <div className="signup-section">
            <button 
              className="signup-button"
              onClick={() => navigate('/inscriptionclient')}
            >
              {t('LoginClient.signup_button')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginClient;
