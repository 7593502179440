import React, { useState, useEffect } from 'react';
import TreeNode from './TreeNode';
import { useGetAllInfoSectorMutation } from "state/api";
import { useSelector } from 'react-redux';

const Tree = () => {
  const [treeData, setTreeData] = useState([]);
  const [flatTreeData, setFlatTreeData] = useState([]);

  const userSession = useSelector((state) => state.adminAuth);

  const [getAllInfoSector] = useGetAllInfoSectorMutation();

  useEffect(() => {
    const fetchTreeData = async () => {
      try {
        const idEtablissement = userSession.ID_Etablissement;
        const data = {
          ID_Etablissement : idEtablissement,
        };
        const response = await getAllInfoSector(data).unwrap();
        setTreeData(response);
        setFlatTreeData(flattenTree(response));
      } catch (err) {
        if(err.originalStatus === 401){
          alert('Votre session a expiré. Veuillez vous reconnecter.');
          window.location = '/loginuser';
        }
      }
    };

    if (userSession.isAuthenticated) {
      fetchTreeData();
    }
  }, [userSession, getAllInfoSector]);

  const flattenTree = (node, parentId = null) => {
    let flatList = [];
    flatList.push({ ...node, parentId });
  
    if (node.children) {
      node.children.forEach(child => {
        flatList = [...flatList, ...flattenTree(child, node.ID_Noeud)];
      });
    }
  
    return flatList;
  };

  const refreshTree = async () => {
    try {
      const idEtablissement = userSession.ID_Etablissement;
      const response = await getAllInfoSector({ ID_Etablissement: idEtablissement }).unwrap();
      setTreeData(response);
      setFlatTreeData(flattenTree(response));
    } catch (err) {
      if(err.originalStatus === 401){
        alert('Votre session a expiré. Veuillez vous reconnecter.');
        window.location = '/loginuser';
      }
    }
  };

  return (
    <div className="tree-container">
      {treeData.map(node => <TreeNode key={node.ID_Noeud} data={node} refreshTree={refreshTree} flatTreeData={flatTreeData} />)}
    </div>
  );
};

export default Tree;
