import React, { useState } from 'react';
import { Box, Button, Typography, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DownloadIcon from '@mui/icons-material/Download';
import { useTranslation } from 'react-i18next';

// Utilisation de la variable d'environnement REACT_APP_BASE_URL
const BASE_URL = process.env.REACT_APP_BASE_URL || 'http://localhost:5001';

const ImageCarrouselRetourFeedback = ({ imageFiles, otherFiles, onDownload }) => {
  const { t } = useTranslation();
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const theme = useTheme();
  const buttonColor = theme.palette.primary.main;
  const activeColor = theme.palette.secondary.main;
  const inactiveColor = theme.palette.action.disabled;
  const textColor = theme.palette.mode === 'dark' ? theme.palette.primary.light : theme.palette.secondary.main;

  if (!imageFiles || imageFiles.length === 0) {
    return <div>{t('ImageCarrouselRetourFeedback.no_images')}</div>;
  }

  const handlePrevClick = () => {
    setSelectedImageIndex((prevIndex) =>
      prevIndex === 0 ? imageFiles.length - 1 : prevIndex - 1
    );
  };

  const handleNextClick = () => {
    setSelectedImageIndex((prevIndex) =>
      prevIndex === imageFiles.length - 1 ? 0 : prevIndex + 1
    );
  };

  const getFileName = (filePath) => filePath.replace('fichiers\\RetourFeedback\\', ''); // Supprime "fichiers/RetourFeedback/"

  // Limiter l'affichage des ronds à 3 avant et 3 après l'image sélectionnée
  const renderIndicators = () => {
    const maxIndicators = 7;
    const halfRange = Math.floor(maxIndicators / 2);

    let startIndex = Math.max(0, selectedImageIndex - halfRange);
    let endIndex = Math.min(imageFiles.length, selectedImageIndex + halfRange + 1);

    if (selectedImageIndex <= halfRange) {
      endIndex = Math.min(maxIndicators, imageFiles.length);
    } else if (selectedImageIndex + halfRange >= imageFiles.length) {
      startIndex = Math.max(0, imageFiles.length - maxIndicators);
    }

    const visibleImages = imageFiles.slice(startIndex, endIndex);

    return visibleImages.map((_, index) => {
      const actualIndex = startIndex + index;
      return (
        <Box
          key={actualIndex}
          onClick={() => setSelectedImageIndex(actualIndex)}
          sx={{
            width: '30px',
            height: '30px',
            borderRadius: '50%',
            backgroundColor: actualIndex === selectedImageIndex ? activeColor : inactiveColor,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            margin: '0 5px',
            color: '#fff',
            fontWeight: 'bold',
            transition: 'background-color 0.3s ease',
          }}
        >
          {actualIndex + 1}
        </Box>
      );
    });
  };

  return (
    <Box style={{ marginTop: '15px' }}>
      <Typography variant="subtitle1">{t('ImageCarrouselRetourFeedback.available_images')} :</Typography>
      <Box display="flex" alignItems="center" style={{ marginTop: '15px' }}>
        <Button
          variant="outlined"
          onClick={handlePrevClick}
          style={{ backgroundColor: buttonColor, color: textColor, marginRight: '20px' }}
        >
          <ArrowBackIosIcon />
        </Button>
        <Box flexGrow={1} textAlign="center">
          <div
            style={{
              cursor: 'pointer',
              borderRadius: '10%',
              height: '400px',
            }}
          >
            <img
              src={`${BASE_URL}/retour-feedback/image/${encodeURIComponent(imageFiles[selectedImageIndex])}`}
              alt={imageFiles[selectedImageIndex]}
              style={{ maxWidth: '80%', maxHeight: '80%', marginTop: '10%' }}
            />
          </div>

          {/* Ajouter le bouton pour télécharger l'image */}
          <Box mt={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => onDownload(imageFiles[selectedImageIndex])}
            >
              {t('ImageCarrouselRetourFeedback.download_image')}
            </Button>
          </Box>
        </Box>
        <Button
          variant="outlined"
          onClick={handleNextClick}
          style={{ backgroundColor: buttonColor, color: textColor, marginLeft: '20px' }}
        >
          <ArrowForwardIosIcon />
        </Button>
      </Box>

      {/* Ajout des indicateurs de position des images avec limitation à 7 ronds */}
      <Box display="flex" justifyContent="center" alignItems="center" style={{ marginTop: '10px' }}>
        {renderIndicators()}
      </Box>

      {otherFiles.length > 0 && (
        <Box style={{ marginTop: '20px' }}>
          <Typography variant="subtitle1">{t('ImageCarrouselRetourFeedback.other_files')} :</Typography>
          <ul style={{ padding: 0, listStyleType: 'none', marginLeft: '20px', marginRight: '20px' }}>
            {otherFiles.map((file, index) => (
              <li key={index} style={{ marginBottom: '10px' }}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{
                    padding: '10px',
                    backgroundColor: theme.palette.background.paper,
                    borderRadius: '10px',
                    transition: 'background-color 0.3s ease, box-shadow 0.3s ease',
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: theme.palette.action.hover,
                      boxShadow: theme.shadows[3],
                    },
                    marginLeft: '5px',
                    marginRight: '5px',
                  }}
                >
                  <span>{getFileName(file)}</span>
                  <IconButton edge="end" aria-label="download" onClick={() => onDownload(file)}>
                    <DownloadIcon />
                  </IconButton>
                </Box>
              </li>
            ))}
          </ul>
        </Box>
      )}
    </Box>
  );
};

export default ImageCarrouselRetourFeedback;
