import React from "react";
import { List, ListItem, ListItemText, Box, Typography } from "@mui/material";
import { keyframes } from '@emotion/react';
import { useTranslation } from 'react-i18next';

const growShrink = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
`;

const ListeIncidentSG = ({ incidents, onSelectIncident, selectedIncident }) => {
  const { t } = useTranslation();

  const formatColor = (color) => {
    if (color && color.startsWith("0x")) {
      return `#${color.slice(2)}`;
    }
    return color;
  };

  const renderLieuOuPortail = (incident) => {
    if (incident.ID_Noeud && incident.details.locationDetails) {
      return `${t('ListeIncidentSG.place')}: ${incident.details.locationDetails.Nom_Element}`;
    } else if (incident.ID_Portail && incident.details.etablissementDetails) {
      return `${t('ListeIncidentSG.portal')}: ${incident.details.etablissementDetails.Nom_Etablissement} (${t('ListeIncidentSG.external')})`;
    } else {
      return `${t('ListeIncidentSG.location')}: ${t('ListeIncidentSG.not_available')}`;
    }
  };

  const renderNom = (incident) => {
    if (incident.Type_de_ticket === "equipement_particulier") {
      return incident.details.specificDetails?.Nom_Ep || t('ListeIncidentSG.not_available');
    } else if (incident.Type_de_ticket === "equipement") {
      return incident.details.specificDetails?.Nom_Equipement || t('ListeIncidentSG.not_available');
    } else if (incident.Type_de_ticket === "service") {
      return incident.details.specificDetails?.Titre || t('ListeIncidentSG.not_available');
    } else {
      return t('ListeIncidentSG.not_available');
    }
  };

  return (
    <List>
      {incidents.map((incident) => (
        <ListItem
          key={incident._id}
          button
          onClick={() => onSelectIncident(incident)}
          sx={{
            animation: selectedIncident && selectedIncident._id === incident._id ? `${growShrink} 2s infinite` : 'none',
            boxShadow: selectedIncident && selectedIncident._id === incident._id ? '0 0 10px rgba(0, 0, 0, 0.5)' : 'none',
            borderRadius: '8px',
            overflow: 'hidden',
            transition: 'transform 0.3s ease-in-out',
            '&:hover': {
              transform: 'scale(1.05)',
              boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
            },
            marginBottom: '8px',
            display: 'flex',
            alignItems: 'center',
            padding: '16px',
            border: '1px solid #ddd',
          }}
        >
          <Box
            sx={{
              width: '20px',
              height: '20px',
              backgroundColor: incident.details.typeDactionDetails ? formatColor(incident.details.typeDactionDetails.Couleur) : 'gray',
              marginRight: '10px',
              borderRadius: '50%',
            }}
          />
          <Box sx={{ flex: 1 }}>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              {`${incident.Type_de_ticket} - ${renderNom(incident)}`}
            </Typography>
            <Typography variant="body2" sx={{ fontStyle: 'italic' }}>
              {renderLieuOuPortail(incident)}
            </Typography>
            <Typography variant="body2" sx={{ fontStyle: 'italic' }}>
              {incident.Assignee ? `${t('ListeIncidentSG.handled_by')}: ${incident.Assignee}` : t('ListeIncidentSG.not_handled')}
            </Typography>
            <Typography variant="body2" sx={{ fontStyle: 'italic' }}>
              {`${t('ListeIncidentSG.responsible_group')}: ${incident.details.groupDetails ? incident.details.groupDetails.Nom_groupe : t('ListeIncidentSG.not_available')}`}
            </Typography>
          </Box>
        </ListItem>
      ))}
    </List>
  );
};

export default ListeIncidentSG;
