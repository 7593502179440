// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html,
body,
#root,
.app {
  height: 100%;
  width: 100%;
  font-family: "Inter", sans-serif;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: transparent;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #33a9bf;
  background: linear-gradient(45deg, #33a9bf, #c21573);
  border-radius: 20px;
  border: 2px solid transparent;
  background-clip: padding-box;
}

/* Handle on Hover */
::-webkit-scrollbar-thumb:hover {
  background: #1a9eb7;
  background: linear-gradient(45deg, #1a9eb7, #a0115f);
  border: 2px solid transparent;
  background-clip: padding-box;
}

* ::-moz-scrollbar-thumb {
  background: linear-gradient(45deg, #33a9bf, #c21573);
  border-radius: 20px;
  border: 2px solid transparent;
  background-clip: padding-box;
}

* ::-moz-scrollbar-thumb:hover {
  background: linear-gradient(45deg, #1a9eb7, #a0115f);
  border: 2px solid transparent;
  background-clip: padding-box;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAEA;;;;EAIE,YAAY;EACZ,WAAW;EACX,gCAAgC;AAClC;;AAEA;EACE,UAAU;EACV,WAAW;EACX,6BAA6B;AAC/B;;AAEA,UAAU;AACV;EACE,6BAA6B;EAC7B,mBAAmB;AACrB;;AAEA,WAAW;AACX;EACE,mBAAmB;EAGnB,oDAAoD;EACpD,mBAAmB;EACnB,6BAA6B;EAC7B,4BAA4B;AAC9B;;AAEA,oBAAoB;AACpB;EACE,mBAAmB;EAGnB,oDAAoD;EACpD,6BAA6B;EAC7B,4BAA4B;AAC9B;;AAEA;EACE,oDAAoD;EACpD,mBAAmB;EACnB,6BAA6B;EAC7B,4BAA4B;AAC9B;;AAEA;EACE,oDAAoD;EACpD,6BAA6B;EAC7B,4BAA4B;AAC9B","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap\");\n\nhtml,\nbody,\n#root,\n.app {\n  height: 100%;\n  width: 100%;\n  font-family: \"Inter\", sans-serif;\n}\n\n::-webkit-scrollbar {\n  width: 8px;\n  height: 8px;\n  background-color: transparent;\n}\n\n/* Track */\n::-webkit-scrollbar-track {\n  background-color: transparent;\n  border-radius: 10px;\n}\n\n/* Handle */\n::-webkit-scrollbar-thumb {\n  background: #33a9bf;\n  background: -webkit-linear-gradient(45deg, #33a9bf, #c21573);\n  background: -moz-linear-gradient(45deg, #33a9bf, #c21573);\n  background: linear-gradient(45deg, #33a9bf, #c21573);\n  border-radius: 20px;\n  border: 2px solid transparent;\n  background-clip: padding-box;\n}\n\n/* Handle on Hover */\n::-webkit-scrollbar-thumb:hover {\n  background: #1a9eb7;\n  background: -webkit-linear-gradient(45deg, #1a9eb7, #a0115f);\n  background: -moz-linear-gradient(45deg, #1a9eb7, #a0115f);\n  background: linear-gradient(45deg, #1a9eb7, #a0115f);\n  border: 2px solid transparent;\n  background-clip: padding-box;\n}\n\n* ::-moz-scrollbar-thumb {\n  background: linear-gradient(45deg, #33a9bf, #c21573);\n  border-radius: 20px;\n  border: 2px solid transparent;\n  background-clip: padding-box;\n}\n\n* ::-moz-scrollbar-thumb:hover {\n  background: linear-gradient(45deg, #1a9eb7, #a0115f);\n  border: 2px solid transparent;\n  background-clip: padding-box;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
