import React, { useState, useEffect } from 'react';
import { Box, Slide, Paper, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const ErrorMessage = ({ message, setError }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (message) {
      setOpen(true);

      const timeoutId = setTimeout(() => {
        setOpen(false);
        setError(''); // Réinitialiser le message d'erreur après la fermeture automatique
      }, 5000);

      return () => clearTimeout(timeoutId);
    }
  }, [message, setError]);

  const handleClose = () => {
    setOpen(false);
    setError(''); // Réinitialiser le message d'erreur lors de la fermeture manuelle
  };

  return (
    <Slide direction="right" in={open} mountOnEnter unmountOnExit>
      <Paper elevation={3} sx={{ position: 'fixed', top: 0, left: '50%', transform: 'translateX(-50%)', width: '300px', p: 2, mt: 2, bgcolor: 'error.main', color: 'error.contrastText' }}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="body1">{message}</Typography>
          <IconButton size="small" onClick={handleClose} sx={{ color: 'inherit' }}>
            <CloseIcon />
          </IconButton>
        </Box>
      </Paper>
    </Slide>
  );
};

export default ErrorMessage;
