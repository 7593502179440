import React from 'react';
import { TextField, Checkbox, FormControlLabel, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

const FeedbackDataInput = ({ filters, onFilterChange }) => {
  const { t } = useTranslation(); // Initialisation de la traduction

  const handleNameChange = (event) => {
    onFilterChange({ ...filters, Nom_Feedback: event.target.value });
  };

  const handleActiveChange = (event) => {
    onFilterChange({ ...filters, Feedback_Actif: event.target.checked ? true : null });
  };

  return (
    <Box
      sx={{
        width: '60%',
        margin: '0 auto', // Centrage horizontal
        marginTop: '40px', // Marge en haut
        marginBottom: '40px', // Marge en bas
        color: 'white',
      }}
    >
      <TextField
        label={t('FeedbackDataInput.feedback_name')} // Traduction
        variant="outlined"
        fullWidth
        value={filters.Nom_Feedback}
        onChange={handleNameChange}
        sx={{
          marginBottom: '16px',
          input: { color: 'white' }, // Texte en blanc dans l'input
          label: { color: 'white' }, // Texte en blanc pour le label
          fieldset: { borderColor: 'white' }, // Bordure blanche
          '& .MuiOutlinedInput-root:hover fieldset': {
            borderColor: 'white',
          },
        }}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={filters.Feedback_Actif === true}
            onChange={handleActiveChange}
            sx={{ color: 'white' }} // Checkbox en blanc
          />
        }
        label={t('FeedbackDataInput.active_feedback')} // Traduction
        sx={{ color: 'white' }} // Texte en blanc
      />
    </Box>
  );
};

export default FeedbackDataInput;
