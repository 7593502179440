import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import NavbarIncidentSG from "./NavbarIncidentSG";
import ListeIncidentSG from "./ListeIncidentSG";
import DetailIncidentSG from "./DetailIncidentSG";
import { useSelector } from "react-redux";
import {
  useGetAllIncidentsSGMutation,
  useGetAllTypeDactionSGMutation,
  useGetGroupedAdminsMutation,
  useGetInfoServiceSGMutation,
  useSearchEquipementParticulierSGMutation,
  useSearchEquipementSGMutation,
  useGetInfoSectorMutation,
  useGetIncidentSGByIdMutation,
  useGetInfoEtablissementMutation
} from "state/api";

const RenderIncidentSG = ({ isExpanded }) => {
  const theme = useTheme();
  const [incidents, setIncidents] = useState([]);
  const [selectedIncident, setSelectedIncident] = useState(null);
  const [typeDactions, setTypeDactions] = useState([]);
  const [groupes, setGroupes] = useState([]);
  const [filters, setFilters] = useState({
    equipementType: "all",
    severite: "all",
    priorite: "all",
    typeDaction: "all",
    urgence: "all",
    groupResponsable: "all",
    pour: "all",
    dateOrder: "none",
    etatTicket: "default",
  });

  const userSession = useSelector((state) => state.adminAuth);
  const [getAllIncidentsSG] = useGetAllIncidentsSGMutation();
  const [getAllTypeDactionSG] = useGetAllTypeDactionSGMutation();
  const [getGroupedAdmins] = useGetGroupedAdminsMutation();
  const [getInfoServiceSG] = useGetInfoServiceSGMutation();
  const [getEquipementParticulierSG] = useSearchEquipementParticulierSGMutation();
  const [getEquipementSG] = useSearchEquipementSGMutation();
  const [getInfoSector] = useGetInfoSectorMutation();
  const [getIncidentSGById] = useGetIncidentSGByIdMutation();
  const [getInfoEtablissement] = useGetInfoEtablissementMutation();

  const fetchIncidents = async () => {
    try {
      const response = await getAllIncidentsSG({
        ID_Etablissement: userSession.ID_Etablissement
      }).unwrap();

      const incidentsWithDetails = await Promise.all(response.map(async (incident) => {
        let specificDetails;
        if (incident.Type_de_ticket === "service") {
          specificDetails = await getInfoServiceSG({ ID_Service_SG: incident.ID_Service }).unwrap();
        } else if (incident.Type_de_ticket === "equipement") {
          specificDetails = await getEquipementSG({ ID_Equipement_SG: incident.ID_Equipement }).unwrap();
        } else if (incident.Type_de_ticket === "equipement_particulier") {
          specificDetails = await getEquipementParticulierSG({ ID_Ep_SG: incident.ID_Ep }).unwrap();
        }

        if (Array.isArray(specificDetails) && specificDetails.length > 0) {
          specificDetails = specificDetails[0];
        }

        let locationDetails = null;
        if (incident.ID_Noeud) {
          try {
            locationDetails = await getInfoSector({ ID_Noeud: incident.ID_Noeud }).unwrap();
          } catch (error) {
            console.error("Error fetching location details:", error);
          }
        }

        let etablissementDetails = null;
        if (incident.ID_Portail) {
          try {
            etablissementDetails = await getInfoEtablissement({ ID_Portail: incident.ID_Portail }).unwrap();
          } catch (error) {
            console.error("Error fetching etablissement details:", error);
          }
        }

        const typeDactionDetails = typeDactions.find(type => type.ID_Type_Daction_SG === incident.ID_Type_Daction_SG);
        const groupDetails = groupes.find(group => group.ID_Groupe === incident.ID_Groupe);

        return { ...incident, details: { specificDetails, locationDetails, etablissementDetails, typeDactionDetails, groupDetails } };
      }));

      setIncidents(incidentsWithDetails);
    } catch (error) {
      console.error("Error fetching incidents:", error);
    }
  };

  useEffect(() => {
    fetchIncidents();
  }, [getAllIncidentsSG, getInfoServiceSG, getEquipementSG, getEquipementParticulierSG, getInfoSector, getInfoEtablissement, typeDactions, groupes, userSession.ID_Etablissement]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const typeDactionsResponse = await getAllTypeDactionSG({ ID_Etablissement: userSession.ID_Etablissement }).unwrap();
        setTypeDactions(typeDactionsResponse);
        const groupesResponse = await getGroupedAdmins({ ID_Etablissement: userSession.ID_Etablissement });
        setGroupes(groupesResponse.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [getAllTypeDactionSG, getGroupedAdmins, userSession.ID_Etablissement]);

  const handleSelectIncident = (incident) => {
    setSelectedIncident(incident);
  };

  const handleFiltersChange = (newFilters) => {
    setFilters((prevFilters) => ({ ...prevFilters, ...newFilters }));
  };

  const refreshIncidentData = async () => {
    if (!selectedIncident) return;

    try {
      const incidentResponse = await getIncidentSGById({ ID_Incident_SG: selectedIncident.ID_Incident_SG }).unwrap();

      let specificDetails;
      if (incidentResponse.Type_de_ticket === "service") {
        specificDetails = await getInfoServiceSG({ ID_Service_SG: incidentResponse.ID_Service }).unwrap();
      } else if (incidentResponse.Type_de_ticket === "equipement") {
        specificDetails = await getEquipementSG({ ID_Equipement_SG: incidentResponse.ID_Equipement }).unwrap();
      } else if (incidentResponse.Type_de_ticket === "equipement_particulier") {
        specificDetails = await getEquipementParticulierSG({ ID_Ep_SG: incidentResponse.ID_Ep }).unwrap();
      }

      if (Array.isArray(specificDetails) && specificDetails.length > 0) {
        specificDetails = specificDetails[0];
      }

      let locationDetails = null;
      if (incidentResponse.ID_Noeud) {
        try {
          locationDetails = await getInfoSector({ ID_Noeud: incidentResponse.ID_Noeud }).unwrap();
        } catch (error) {
          console.error("Error fetching location details:", error);
        }
      }

      let etablissementDetails = null;
      if (incidentResponse.ID_Portail) {
        try {
          etablissementDetails = await getInfoEtablissement({ ID_Etablissement: incidentResponse.ID_Portail }).unwrap();
        } catch (error) {
          console.error("Error fetching etablissement details:", error);
        }
      }

      const typeDactionDetails = typeDactions.find(type => type.ID_Type_Daction_SG === incidentResponse.ID_Type_Daction_SG);
      const groupDetails = groupes.find(group => group.ID_Groupe === incidentResponse.ID_Groupe);

      const updatedIncident = { ...incidentResponse, details: { specificDetails, locationDetails, etablissementDetails, typeDactionDetails, groupDetails } };
      setSelectedIncident(updatedIncident);

      // Refresh the entire incidents list as well
      await fetchIncidents();
    } catch (error) {
      console.error("Error refreshing incident data:", error);
    }
  };

  const sortedIncidents = incidents
    .filter(incident => {
      if (filters.etatTicket === "default") {
        if (incident.Etat_du_ticket === "cloture" || incident.Etat_du_ticket === "abus") {
          return false;
        }
      } else if (filters.etatTicket !== "all" && filters.etatTicket !== incident.Etat_du_ticket) {
        return false;
      }

      return (
        (filters.equipementType === "all" || filters.equipementType === incident.Type_de_ticket) &&
        (filters.severite === "all" || (incident.Severite && filters.severite === incident.Severite.toString())) &&
        (filters.priorite === "all" || (incident.Priorite && filters.priorite === incident.Priorite.toString())) &&
        (filters.typeDaction === "all" || (incident.ID_Type_Daction_SG && filters.typeDaction === incident.ID_Type_Daction_SG.toString())) &&
        (filters.urgence === "all" || (incident.Niveau_urgence_intervention !== undefined && filters.urgence === incident.Niveau_urgence_intervention.toString())) &&
        (filters.groupResponsable === "all" || (incident.ID_Groupe && filters.groupResponsable === incident.ID_Groupe.toString())) &&
        (filters.pour === "all" || filters.pour === incident.Pour)
      );
    })
    .sort((a, b) => {
      if (filters.dateOrder !== "none") {
        const dateA = new Date(a.Updated[a.Updated.length - 1]);
        const dateB = new Date(b.Updated[a.Updated.length - 1]);
        return filters.dateOrder === "asc" ? dateA - dateB : dateB - dateA;
      }

      if (a.Niveau_urgence_intervention !== b.Niveau_urgence_intervention) {
        return a.Niveau_urgence_intervention - b.Niveau_urgence_intervention;
      }
      return (b.Severite * b.Priorite) - (a.Severite * a.Priorite);
    });

  return (
    <Box sx={{ marginLeft: "10px", marginRight: "10px" }}>
      {isExpanded && (
        <>
          <NavbarIncidentSG
            filters={filters}
            onFiltersChange={handleFiltersChange}
            typeDactions={typeDactions}
            groupes={groupes}
          />
        </>
      )}
      <Box sx={{ display: "flex", marginTop: "20px" }}>
        <Box
          sx={{
            width: {
              xs: '100%',
              sm: '100%',
              md: '40%',
              lg: '40%',
            },
            maxWidth: '350px',
            paddingRight: "10px"
          }}
        >
          <ListeIncidentSG incidents={sortedIncidents} onSelectIncident={handleSelectIncident} selectedIncident={selectedIncident} />
        </Box>
        <Box sx={{ width: "60%", paddingLeft: "10px" }}>
          <DetailIncidentSG incident={selectedIncident} refreshIncident={refreshIncidentData} />
        </Box>
      </Box>
    </Box>
  );
};

export default RenderIncidentSG;
