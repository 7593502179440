import React from 'react';
import { Box, Typography, Divider, Tabs, Tab, Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Discussion from '../../../components/Discussion';

const DetailsTicket = ({ ticket, userSession }) => {
  const { t } = useTranslation();

  if (!ticket) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100%">
        <Typography variant="body1" color="textSecondary">
          {t('Ticket.Details.SelectTicket')}
        </Typography>
      </Box>
    );
  }

  const isIT = ticket.ID_Incident_IT !== undefined;
  const ticketId = isIT ? ticket.ID_Incident_IT : ticket.ID_Incident_SG;

  const getReadableType = (type) => {
    switch(type) {
      case 'equipement':
        return t('Ticket.Details.Equipment');
      case 'equipement_particulier':
        return t('Ticket.Details.SpecialEquipment');
      case 'service':
        return t('Ticket.Details.Service');
      default:
        return type;
    }
  };

  const getReadableState = (state) => {
    switch(state) {
      case 'creation':
        return t('Ticket.Details.Creation');
      case 'assigne':
        return t('Ticket.Details.Assigned');
      case 'en cours':
        return t('Ticket.Details.InProgress');
      case 'termine':
        return t('Ticket.Details.Completed');
      case 'cloture':
        return t('Ticket.Details.Closed');
      case 'abus':
        return t('Ticket.Details.Abuse');
      default:
        return state;
    }
  };

  return (
    <Paper elevation={6} sx={{ p: 3, borderRadius: '16px', boxShadow: '0 8px 32px rgba(0,0,0,0.2)' }}>
      <Typography variant="h5" gutterBottom sx={{ fontWeight: 600, color: 'primary.main' }}>
        {ticket.Titre_ticket}
      </Typography>

      <Box sx={{ 
        mb: 3,
        display: 'flex',
        gap: 4,
        bgcolor: 'background.default',
        p: 2,
        borderRadius: '12px'
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="subtitle2" color="textSecondary" sx={{ mr: 1 }}>
            {t('Ticket.Details.Type')}:
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 700 }}>
            {getReadableType(ticket.Type_de_ticket)}
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="subtitle2" color="textSecondary" sx={{ mr: 1 }}>
            {t('Ticket.Details.Status')}:
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 700 }}>
            {getReadableState(ticket.Etat_du_ticket)}
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="subtitle2" color="textSecondary" sx={{ mr: 1 }}>
            {t('Ticket.Details.CreatedOn')}:
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 700 }}>
            {moment(ticket.Creation).format('DD/MM/YYYY HH:mm')}
          </Typography>
        </Box>
      </Box>

      <Divider sx={{ my: 3 }} />
      
      <Box sx={{ display: 'flex', gap: 3, mb: 3 }}>
        <Box sx={{ flex: 1, bgcolor: 'background.default', p: 2, borderRadius: '12px' }}>
          <Typography variant="h6" gutterBottom sx={{ color: 'primary.main', fontWeight: 600 }}>
            {ticket.Type_de_ticket === 'service' ? 'Description du Besoin' : 'Description de l\'incident'}
          </Typography>
          {Array.isArray(ticket.Description) ? (
            ticket.Description.map((desc, index) => (
              <Typography key={index} variant="body1" paragraph>
                {desc}
              </Typography>
            ))
          ) : (
            <Typography variant="body1" paragraph>
              {ticket.Description}
            </Typography>
          )}
        </Box>

        {ticket.Besoin && ticket.Besoin.length > 0 && (
          <Box sx={{ flex: 1, bgcolor: 'background.default', p: 2, borderRadius: '12px' }}>
            <Typography variant="h6" gutterBottom sx={{ color: 'primary.main', fontWeight: 600 }}>
              {t('Ticket.Details.Needs')}
            </Typography>
            {ticket.Besoin.map((besoin, index) => (
              <Typography key={index} variant="body1" sx={{ mb: 1 }}>
                {besoin}
              </Typography>
            ))}
          </Box>
        )}
      </Box>

      <Box sx={{ mt: 3 }}>
        <Typography variant="h6" gutterBottom sx={{ color: 'primary.main', fontWeight: 600 }}>
          {t('Ticket.Details.Discussion')}
        </Typography>
        <Discussion 
          incidentId={ticketId}
          utilisateurId={userSession?.ID_Utilisateur}
          type={ticket?.Type_de_ticket === 'service' ? 'IT' : 'SG'}
          userSession={userSession}
        />
      </Box>
    </Paper>
  );
};

export default DetailsTicket;