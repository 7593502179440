import React from 'react';
import { Button, Typography } from '@mui/material';
import { styled } from '@mui/system';

const StyledPrecedentButton = styled(Button)(({ theme, style }) => ({
  minWidth: '120px', // Adapté pour couvrir le texte
  marginTop: '50px',
  padding: '10px 20px', // Espacement intérieur pour ajuster autour du texte
  borderRadius: '10px',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',
  boxShadow: `5px 5px 10px rgba(0, 0, 0, 0.2)`,
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    //background: 'linear-gradient(to bottom right, rgba(255, 255, 255, 0.5), transparent)',
    zIndex: 0,
  },
  ...style,
}));

const PrecedentButton = ({ styleProps,categorie_principale, categorie_secondaire, shown, setCategoriePrincipale, setCategorieSecondaire, setShown }) => {
    const dynamicStyles = {
        backgroundColor: styleProps ? styleProps.couleur_bouton : '#FFF',
        color: styleProps ? styleProps.couleur_bouton_ecriture : '#000',
        boxShadow: styleProps ? `5px 5px 10px ${styleProps.couleur_de_lombre_du_bouton}` : '#000',
      };
      
    const handleClick = () => {
        switch (shown) {
          case "P":
            setCategoriePrincipale(null);
            setShown("A");
            break;
          case "S":
            setCategorieSecondaire(null);
            setShown("P");
            break;
          case "E":
            setShown("S");
            break;
          default:
        }
      };
      

  return (
    <StyledPrecedentButton style={dynamicStyles} onClick={handleClick}>
      <Typography variant="body2" style={{ color: styleProps?.couleur_bouton_ecriture || '#000', fontFamily: styleProps ? styleProps.police_de_caractere : 'sans-serif' }}>
        Précédent
      </Typography>
    </StyledPrecedentButton>
  );
};

export default PrecedentButton;
