import React from 'react';
import { List, ListItem, ListItemText, Divider, Paper, Typography, Box } from '@mui/material';
import moment from 'moment';

const ListeTickets = ({ tickets, selectedTicket, onTicketSelect }) => {
  // Combine and sort all tickets
  const allTickets = [
    ...(tickets.incidentsIT || []).map(ticket => ({
      ...ticket,
      id: ticket.ID_Incident_IT,
      type: 'IT'
    })),
    ...(tickets.incidentsSG || []).map(ticket => ({
      ...ticket, 
      id: ticket.ID_Incident_SG,
      type: 'SG'
    }))
  ].sort((a, b) => new Date(b.Creation) - new Date(a.Creation));

  // Calculer la hauteur en fonction du nombre de tickets
  const itemHeight = 100; // Hauteur approximative d'un item en pixels
  const calculatedHeight = Math.min(allTickets.length * itemHeight, window.innerHeight * 1.5); // Augmenté à 1.5 au lieu de 1

  return (
    <Paper 
      elevation={6}
      sx={{ 
        height: calculatedHeight,
        minHeight: '75vh',
        overflow: 'auto',
        overflowX: 'hidden',
        borderRadius: '16px',
        backgroundColor: 'background.paper',
        boxShadow: '0 12px 40px rgba(0,0,0,0.15)',
      }}
    >
      <List sx={{ p: 0 }}>
        {allTickets.map((ticket, index) => (
          <React.Fragment key={`${ticket.type}-${ticket.id}`}>
            <ListItem
              button
              selected={selectedTicket?.id === ticket.id}
              onClick={() => onTicketSelect(ticket)}
              sx={{
                transition: 'all 0.2s ease-in-out',
                '&:hover': {
                  backgroundColor: 'action.hover',
                  transform: 'translateX(8px)'
                },
                '&.Mui-selected': {
                  backgroundColor: 'primary.light',
                  '&:hover': {
                    backgroundColor: 'primary.light',
                  }
                }
              }}
            >
              <Box sx={{ width: '100%', p: 1 }}>
                <ListItemText
                  primary={
                    <Typography 
                      variant="body2" 
                      color="text.secondary"
                      sx={{ fontWeight: 500 }}
                    >
                      {moment(ticket.Creation).format('DD/MM/YYYY HH:mm')}
                    </Typography>
                  }
                  secondary={
                    <Typography 
                      variant="subtitle1"
                      sx={{ 
                        mt: 0.5,
                        fontWeight: selectedTicket?.id === ticket.id ? 600 : 400
                      }}
                    >
                      {ticket.Titre_ticket}
                    </Typography>
                  }
                />
              </Box>
            </ListItem>
            {index < allTickets.length - 1 && (
              <Divider 
                sx={{ 
                  opacity: 0.6,
                  mx: 2 
                }} 
              />
            )}
          </React.Fragment>
        ))}
      </List>
    </Paper>
  );
};

export default ListeTickets;