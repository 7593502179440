import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Typography,
  TextField,
  Select,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import {
  useGetAllInfoEquipementITIncompletMutation,
  useGetAllInfoEquipementSGIncompletMutation,
  useGetAllInfoEquipementParticulierITIncompletMutation,
  useGetAllInfoEquipementParticulierSGIncompletMutation,
  useGetAllInfoCategorieSignalementITMutation,
  useGetAllInfoCategorieSignalementSGMutation,
  useGetAllTypeDactionITMutation,
  useGetAllTypeDactionSGMutation,
  useModifyEquipementITMutation,
  useModifyEquipementSGMutation,
  useModifyEquipementParticulierITMutation,
  useModifyEquipementParticulierSGMutation
} from "state/api";
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';
import { themeSettings } from "theme";
import { useTheme } from "@mui/material/styles";

const ActionNecessaire = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [openDialog, setOpenDialog] = useState(false);
  const [currentSection, setCurrentSection] = useState(0);
  const [equipementsIT, setEquipementsIT] = useState([]);
  const [equipementsSG, setEquipementsSG] = useState([]);
  const [equipementsParticuliersIT, setEquipementsParticuliersIT] = useState([]);
  const [equipementsParticuliersSG, setEquipementsParticuliersSG] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [sections, setSections] = useState([]);
  const [categoriesIT, setCategoriesIT] = useState([]);
  const [categoriesSG, setCategoriesSG] = useState([]);
  const [typesDactionIT, setTypesDactionIT] = useState([]);
  const [typesDactionSG, setTypesDactionSG] = useState([]);
  const [selectedCategorie, setSelectedCategorie] = useState('');
  const [selectedTypeDaction, setSelectedTypeDaction] = useState('');
  const [loading, setLoading] = useState(true);

  const [getEquipementsITIncomplet] = useGetAllInfoEquipementITIncompletMutation();
  const [getEquipementsSGIncomplet] = useGetAllInfoEquipementSGIncompletMutation();
  const [getEquipementsParticuliersITIncomplet] = useGetAllInfoEquipementParticulierITIncompletMutation();
  const [getEquipementsParticuliersSGIncomplet] = useGetAllInfoEquipementParticulierSGIncompletMutation();
  const [getAllInfoCategorieSignalementIT] = useGetAllInfoCategorieSignalementITMutation();
  const [getAllInfoCategorieSignalementSG] = useGetAllInfoCategorieSignalementSGMutation();
  const [getAllTypeDactionIT] = useGetAllTypeDactionITMutation();
  const [getAllTypeDactionSG] = useGetAllTypeDactionSGMutation();
  const [modifyEquipementIT] = useModifyEquipementITMutation();
  const [modifyEquipementSG] = useModifyEquipementSGMutation();
  const [modifyEquipementParticulierIT] = useModifyEquipementParticulierITMutation();
  const [modifyEquipementParticulierSG] = useModifyEquipementParticulierSGMutation();

  const userSession = useSelector((state) => state.adminAuth);

  const fetchData = async () => {
    setLoading(true);
    let equipementsITResponse = [];
    let equipementsSGResponse = [];
    let equipementsParticuliersITResponse = [];
    let equipementsParticuliersSGResponse = [];

    try {
      equipementsITResponse = await getEquipementsITIncomplet({ ID_Etablissement: userSession.ID_Etablissement }).unwrap();
      setEquipementsIT(equipementsITResponse);
    } catch (error) {
      if (error.status !== 405) {
        console.error(t('fetch_error_equipements_it'), error);
      }
    }
    try {
      equipementsSGResponse = await getEquipementsSGIncomplet({ ID_Etablissement: userSession.ID_Etablissement }).unwrap();
      setEquipementsSG(equipementsSGResponse);
    } catch (error) {
      if (error.status !== 405) {
        console.error(t('fetch_error_equipements_sg'), error);
      }
    }
    try {
      equipementsParticuliersITResponse = await getEquipementsParticuliersITIncomplet({ ID_Etablissement: userSession.ID_Etablissement }).unwrap();
      setEquipementsParticuliersIT(equipementsParticuliersITResponse);
    } catch (error) {
      if (error.status !== 405) {
        console.error(t('fetch_error_equipements_particuliers_it'), error);
      }
    }
    try {
      equipementsParticuliersSGResponse = await getEquipementsParticuliersSGIncomplet({ ID_Etablissement: userSession.ID_Etablissement }).unwrap();
      setEquipementsParticuliersSG(equipementsParticuliersSGResponse);
    } catch (error) {
      if (error.status !== 405) {
        console.error(t('fetch_error_equipements_particuliers_sg'), error);
      }
    }

    const newSections = [
      { title: t('incomplete_it_equipments'), items: equipementsITResponse },
      { title: t('incomplete_sg_equipments'), items: equipementsSGResponse },
      { title: t('incomplete_it_particular_equipments'), items: equipementsParticuliersITResponse },
      { title: t('incomplete_sg_particular_equipments'), items: equipementsParticuliersSGResponse },
    ].filter(section => section.items.length > 0);

    setSections(newSections);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [getEquipementsITIncomplet, getEquipementsSGIncomplet, getEquipementsParticuliersITIncomplet, getEquipementsParticuliersSGIncomplet, userSession.ID_Etablissement]);

  useEffect(() => {
    const fetchCategoriesAndTypes = async () => {
      try {
        const categorieITResponse = await getAllInfoCategorieSignalementIT({ ID_Etablissement: userSession.ID_Etablissement }).unwrap();
        const categorieSGResponse = await getAllInfoCategorieSignalementSG({ ID_Etablissement: userSession.ID_Etablissement }).unwrap();
        const typesDactionITResponse = await getAllTypeDactionIT({ ID_Etablissement: userSession.ID_Etablissement }).unwrap();
        const typesDactionSGResponse = await getAllTypeDactionSG({ ID_Etablissement: userSession.ID_Etablissement }).unwrap();

        setCategoriesIT(categorieITResponse);
        setCategoriesSG(categorieSGResponse);
        setTypesDactionIT(typesDactionITResponse);
        setTypesDactionSG(typesDactionSGResponse);
      } catch (error) {
        console.error(t('fetch_error_categories_types'), error);
      }
    };

    fetchCategoriesAndTypes();
  }, [getAllInfoCategorieSignalementIT, getAllInfoCategorieSignalementSG, getAllTypeDactionIT, getAllTypeDactionSG, userSession.ID_Etablissement]);

  useEffect(() => {
    if (sections.length > 1) {
      const interval = setInterval(() => {
        setCurrentSection((prev) => (prev + 1) % sections.length);
      }, 5000);

      return () => clearInterval(interval);
    }
  }, [sections]);

  const handleOpenDialog = (item) => {
    setSelectedItem(item);
    setSelectedCategorie(item.ID_Categorie_Signalement || item.ID_Categorie_Signalement_SG || item.ID_Categorie_Signalement_IT || '');
    setSelectedTypeDaction(item.ID_Type_Daction_IT || item.ID_Type_Daction_SG || '');
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedItem(null);
    setSelectedCategorie('');
    setSelectedTypeDaction('');
  };

  const handleNextSection = () => {
    setCurrentSection((prev) => (prev + 1) % sections.length);
  };

  const handlePreviousSection = () => {
    setCurrentSection((prev) => (prev - 1 + sections.length) % sections.length);
  };

  const handleSubmit = async () => {
    try {
      if (!selectedCategorie || !selectedTypeDaction) {
        alert(t('select_category_and_action_type'));
        return;
      }

      const updatedItem = {
        ...selectedItem,
        ID_Categorie_Signalement: selectedCategorie,
        ID_Categorie_Signalement_SG: selectedCategorie,
        ID_Categorie_Signalement_IT: selectedCategorie,
        ID_Type_Daction_IT: selectedTypeDaction,
        ID_Type_Daction_SG: selectedTypeDaction
      };

      const response = selectedItem.ID_Equipement_IT
        ? await modifyEquipementIT(updatedItem)
        : selectedItem.ID_Equipement_SG
          ? await modifyEquipementSG(updatedItem)
          : selectedItem.ID_Ep_IT
            ? await modifyEquipementParticulierIT(updatedItem)
            : await modifyEquipementParticulierSG(updatedItem);

      if (response.error) {
        alert(t('update_error'));
      } else {
        handleCloseDialog();
        fetchData();
      }
    } catch (error) {
      alert(t('server_error'));
    }
  };

  if (loading) {
    return (
      <Box
        bgcolor="neutral.main"
        p={2}
        borderRadius={2}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        position="relative"
        height="150px"
      >
        <CircularProgress color="secondary" />
      </Box>
    );
  }

  if (sections.length === 0) {
    return (
      <Box
        bgcolor="neutral.main"
        p={2}
        borderRadius={2}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        position="relative"
        height="150px"
      >
        <Typography variant="h6" sx={{ color: '#FFFFFF' }}>{t('inventory_up_to_date')}</Typography>
      </Box>
    );
  }

  return (
    <Box
      bgcolor="neutral.main"
      p={2}
      borderRadius={2}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      position="relative"
    >
      <Typography variant="h6" gutterBottom sx={{ color: '#FFFFFF' }}>
        {t('necessary_actions')}
      </Typography>
      <Box display="flex" alignItems="center">
        {sections.length > 1 && (
          <IconButton onClick={handlePreviousSection}>
            <ArrowBack sx={{ color: '#FFFFFF' }} />
          </IconButton>
        )}
        <Box 
          mx={2} 
          textAlign="center" 
          width="250px" 
          height="50px" 
          display="flex" 
          alignItems="center" 
          justifyContent="center" 
          overflow="hidden"
        >
          <Typography 
            variant="body1"
            sx={{
              textAlign: 'center',
              lineHeight: '1.2em',
              whiteSpace: 'normal',
              height: '2.4em',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              color: '#FFFFFF'
            }}
          >
            {sections[currentSection].title}
          </Typography>
        </Box>
        {sections.length > 1 && (
          <IconButton onClick={handleNextSection}>
            <ArrowForward sx={{ color: '#FFFFFF' }} />
          </IconButton>
        )}
      </Box>
      <Box mt={2}>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => handleOpenDialog(sections[currentSection].items[0])}
          sx={{ width: '150px' }}
        >
          {t('view_details')}
        </Button>
      </Box>

      {openDialog && selectedItem && (
        <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="md" fullWidth>
          <DialogTitle>
            {t('complete_information')}
            <IconButton
              aria-label="close"
              onClick={handleCloseDialog}
              style={{ position: "absolute", right: 8, top: 8 }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <List>
              <ListItem>
                <ListItemText
                  primary={`${t('choose_values_for')} ${
                    selectedItem.ID_Equipement_IT || selectedItem.ID_Equipement_SG || selectedItem.ID_Ep_IT || selectedItem.ID_Ep_SG ? t('particular_equipment') : t('equipment')
                  } ${t('named')}: ${selectedItem.Nom_Equipement || selectedItem.Nom_Ep}`}
                  primaryTypographyProps={{ color: '#FFFFFF' }}
                />
              </ListItem>
              <ListItem>
                <TextField
                  label={t('report_category')}
                  value={selectedCategorie}
                  onChange={(e) => {
                    setSelectedCategorie(e.target.value);
                  }}
                  select
                  fullWidth
                  InputProps={{
                    style: { color: '#FFFFFF' }
                  }}
                  InputLabelProps={{
                    style: { color: '#FFFFFF' }
                  }}
                >
                  {(currentSection % 2 === 0 ? categoriesIT : categoriesSG).map((cat) => (
                    <MenuItem key={cat.ID_Categorie_Signalement_IT || cat.ID_Categorie_Signalement_SG} value={cat.ID_Categorie_Signalement_IT || cat.ID_Categorie_Signalement_SG}>
                      {cat.Nom_Categorie}
                    </MenuItem>
                  ))}
                </TextField>
              </ListItem>
              <ListItem>
                <TextField
                  label={t('action_type')}
                  value={selectedTypeDaction}
                  onChange={(e) => {
                    setSelectedTypeDaction(e.target.value);
                  }}
                  select
                  fullWidth
                  InputProps={{
                    style: { color: '#FFFFFF' }
                  }}
                  InputLabelProps={{
                    style: { color: '#FFFFFF' }
                  }}
                >
                  {(currentSection % 2 === 0 ? typesDactionIT : typesDactionSG).map((type) => (
                    <MenuItem key={type.ID_Type_Daction_IT || type.ID_Type_Daction_SG} value={type.ID_Type_Daction_IT || type.ID_Type_Daction_SG}>
                      {type.Titre}
                    </MenuItem>
                  ))}
                </TextField>
              </ListItem>
            </List>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="primary">
              {t('cancel')}
            </Button>
            <Button onClick={handleSubmit} color="primary">
              {t('apply')}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
};

export default ActionNecessaire;
