import React, { useState } from 'react';
import { Card, IconButton, Tooltip, Modal, Box, TextField, Button, Typography, List, ListItem, ListItemText, ListItemSecondaryAction } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import FeedbackIcon from '@mui/icons-material/Feedback';
import RemoveIcon from '@mui/icons-material/Remove';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { useAddSectorMutation, useDeleteSectorMutation, useModifySectorMutation, useSearchSectorMutation, useMoveSectorMutation } from "state/api";
import { useSelector } from 'react-redux';
import FeedbackModal from './FeedBackModal';

const TreeNode = ({ data, refreshTree }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openFeedbackModal, setOpenFeedbackModal] = useState(false);
  const [nomNoeud, setNomNoeud] = useState('');
  const [localisation, setLocalisation] = useState('');
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [openModalModify, setOpenModalModify] = useState(false);
  const [openModalMove, setOpenModalMove] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [selectedNode, setSelectedNode] = useState(null);

  const [addSector] = useAddSectorMutation();
  const [modifySector] = useModifySectorMutation();
  const [deleteSector] = useDeleteSectorMutation();
  const [searchSector] = useSearchSectorMutation();
  const [moveSector] = useMoveSectorMutation();

  const userSession = useSelector((state) => state.adminAuth);

  const hasChildren = data.children && data.children.length > 0;
  const isRootNode = data.Profondeur === 0;

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const handleOpenModalDelete = () => setOpenModalDelete(true);
  const handleCloseModalDelete = () => setOpenModalDelete(false);

  const handleOpenModalModify = () => {
    setOpenModalModify(true);
    setNomNoeud(data.Nom_Element);
    setLocalisation(data.Localisation);
  };
  const handleCloseModalModify = () => setOpenModalModify(false);

  const handleOpenModalMove = () => setOpenModalMove(true);
  const handleCloseModalMove = () => {
    setOpenModalMove(false);
    setSearchResults([]);
    setSelectedNode(null);
  };

  const handleOpenFeedbackModal = () => setOpenFeedbackModal(true);
  const handleCloseFeedbackModal = () => setOpenFeedbackModal(false);

  const handleAddSector = async () => {
    const dataForm = {
      ID_Noeud_parent: data.ID_Noeud,
      Nom_Noed: nomNoeud,
      Localisation: localisation,
      Profondeur_parent: data.Profondeur,
      ID_Etablissement: userSession.ID_Etablissement,
    };

    try {
      await addSector(dataForm).unwrap();
      handleCloseModal();
      refreshTree();
    } catch (err) {
      if (err.originalStatus === 401) {
        alert('Votre session a expiré. Veuillez vous reconnecter.');
        window.location = '/loginuser';
      }
    }
  };

  const handleDeleteSector = async (recursive) => {
    const dataForm = {
      ID_Noeud: data.ID_Noeud,
      recursif: recursive,
    };

    try {
      await deleteSector(dataForm).unwrap();
      handleCloseModalDelete();
      refreshTree();
    } catch (err) {
      if (err.originalStatus === 401) {
        alert('Votre session a expiré. Veuillez vous reconnecter.');
        window.location = '/loginuser';
      }
    }
  };

  const handleModifySector = async () => {
    const dataForm = {
      ID_Noeud: data.ID_Noeud,
      Nom_Noeud: nomNoeud,
      Localisation: localisation,
    };

    try {
      await modifySector(dataForm).unwrap();
      handleCloseModalModify();
      refreshTree();
    } catch (err) {
      if (err.originalStatus === 401) {
        alert('Votre session a expiré. Veuillez vous reconnecter.');
        window.location = '/loginuser';
      }
    }
  };

  const handleSearch = async () => {
    try {
      const response = await searchSector({ recherche: searchTerm }).unwrap();
      setSearchResults(response);
    } catch (err) {
      if (err.originalStatus === 401) {
        alert('Votre session a expiré. Veuillez vous reconnecter.');
        window.location = '/loginuser';
      }
    }
  };

  const handleSelectNode = (node) => {
    setSelectedNode(node);
  };

  const handleSubmitChange = async () => {
    if (!selectedNode) return;

    try {
      await moveSector({
        ID_Noeud: data.ID_Noeud,
        ID_Destination: selectedNode.ID_Noeud,
      }).unwrap();
      handleCloseModalMove();
      refreshTree();
    } catch (err) {
      if (err.originalStatus === 401) {
        alert('Votre session a expiré. Veuillez vous reconnecter.');
        window.location = '/loginuser';
      }
    }
  };

  const cardStyle = {
    margin: '10px',
    padding: '10px',
    backgroundColor: theme.palette.background.alt,
    borderColor: theme.palette.primary.third,
  };

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };

  return (
    <Card variant="outlined" style={cardStyle}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {hasChildren && (
          <IconButton onClick={() => setIsOpen(!isOpen)}>
            {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        )}
        <Typography variant="h6" style={{ color: theme.palette.neutral.white }}>
          {data.Nom_Element}
        </Typography>
        <div style={{ marginLeft: 'auto' }}>
          <Tooltip title={t('TreeNodeSector.Add')}>
            <IconButton onClick={handleOpenModal}><AddCircleOutlineIcon /></IconButton>
          </Tooltip>
          <Tooltip title={t('TreeNodeSector.Edit')}>
            <IconButton onClick={handleOpenModalModify}><EditIcon /></IconButton>
          </Tooltip>
          <Tooltip title={t('TreeNodeSector.AddFeedback')}>
            <IconButton onClick={handleOpenFeedbackModal}><FeedbackIcon /></IconButton>
          </Tooltip>
          {!isRootNode && (
            <>
              <Tooltip title={t('TreeNodeSector.Delete')}>
                <IconButton onClick={handleOpenModalDelete}><DeleteIcon /></IconButton>
              </Tooltip>
              <Tooltip title={t('TreeNodeSector.Move')}>
                <IconButton onClick={handleOpenModalMove}><OpenWithIcon /></IconButton>
              </Tooltip>
            </>
          )}
        </div>
      </div>

      {isOpen && (
        <div style={{ paddingLeft: '20px', color: theme.palette.neutral.white }}>
          <List>
            {data.feedbacks && data.feedbacks.map((feedback) => (
              <ListItem key={feedback.ID_Feedback}>
                <ListItemText primary={`${feedback.Nom_Feedback} (Feedback)`} />
                <ListItemSecondaryAction>
                  <IconButton edge="end" onClick={() => { }}>
                    <RemoveIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
          {hasChildren && (
            <div>
              {data.children.map((child) => (
                <TreeNode key={child.id} data={child} refreshTree={refreshTree} />
              ))}
            </div>
          )}
        </div>
      )}

      <Modal open={openModal} onClose={handleCloseModal}>
        <Box sx={modalStyle}>
          <Typography variant="h6">{t('TreeNodeSector.AddSector')}</Typography>
          <TextField label={t('TreeNodeSector.SectorName')} variant="outlined" fullWidth margin="normal" value={nomNoeud} onChange={(e) => setNomNoeud(e.target.value)} />
          <TextField label={t('TreeNodeSector.Location')} variant="outlined" fullWidth margin="normal" value={localisation} onChange={(e) => setLocalisation(e.target.value)} />
          <Button variant="contained" color="primary" onClick={handleAddSector}>
            {t('TreeNodeSector.Validate')}
          </Button>
        </Box>
      </Modal>

      <Modal open={openModalDelete} onClose={handleCloseModalDelete}>
        <Box sx={modalStyle}>
          <Typography variant="h6">{t('TreeNodeSector.DeleteSector')}</Typography>
          <div style={{ display: 'flex', justifyContent: 'center', gap: '20px', marginTop: '20px' }}>
            <Button variant="contained" color="primary" onClick={() => handleDeleteSector(false)}>
              {t('TreeNodeSector.Unique')}
            </Button>
            <Button variant="contained" color="primary" onClick={() => handleDeleteSector(true)}>
              {t('TreeNodeSector.Recursive')}
            </Button>
          </div>
        </Box>
      </Modal>

      <Modal open={openModalModify} onClose={handleCloseModalModify}>
        <Box sx={modalStyle}>
          <Typography variant="h6">{t('TreeNodeSector.ModifySector')}</Typography>
          <TextField label={t('TreeNodeSector.SectorName')} variant="outlined" fullWidth margin="normal" value={nomNoeud} onChange={(e) => setNomNoeud(e.target.value)} />
          <TextField label={t('TreeNodeSector.Location')} variant="outlined" fullWidth margin="normal" value={localisation} onChange={(e) => setLocalisation(e.target.value)} />
          <Button variant="contained" color="primary" onClick={handleModifySector}>
            {t('TreeNodeSector.Validate')}
          </Button>
        </Box>
      </Modal>

      <Modal open={openModalMove} onClose={handleCloseModalMove}>
        <Box sx={modalStyle}>
          <Typography variant="h6">{t('TreeNodeSector.MoveSector')}</Typography>
          <TextField
            label={t('TreeNodeSector.SectorName')}
            variant="outlined"
            fullWidth
            margin="normal"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <Button variant="contained" color="primary" onClick={handleSearch}>
            {t('TreeNodeSector.Search')}
          </Button>
          <List component="nav" aria-label="search results">
            {searchResults.map((node) => (
              <ListItem
                button
                key={node.ID_Noeud}
                onClick={() => handleSelectNode(node)}
                selected={selectedNode?.ID_Noeud === node.ID_Noeud}
              >
                <ListItemText primary={node.Nom_Element} />
              </ListItem>
            ))}
          </List>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleSubmitChange}
            disabled={!selectedNode}
          >
            {t('TreeNodeSector.MoveHere')}
          </Button>
        </Box>
      </Modal>

      <FeedbackModal
        open={openFeedbackModal}
        onClose={handleCloseFeedbackModal}
        ID_Noeud={data.ID_Noeud}
        currentFeedbacks={data.feedbacks || []}
        refreshTree={refreshTree}
      />
    </Card>
  );
};

export default TreeNode;
