import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, TextField, Dialog, DialogTitle, DialogContent, DialogActions, Grid, List, ListItem, ListItemText, IconButton, TablePagination, InputAdornment, FormControl, FormHelperText } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSelector } from 'react-redux';

import {
  useAddTypeDactionITMutation,
  useDeleteTypeDactionITMutation,
  useGetAllTypeDactionITMutation,
  useModifyTypeDactionITMutation,
} from 'state/api';
import ErrorMessage from '../ErrorMessage';
import ValidationMessage from '../ValidationMessage';
import InfoMessage from '../InfoMessage';

const RenderTypeDactionIT = () => {
  const { t } = useTranslation(); // Initialize the useTranslation hook
  const [typeDactions, setTypeDactions] = useState([]);
  const [open, setOpen] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [typeDactionToDelete, setTypeDactionToDelete] = useState(null);
  const [editingTypeDaction, setEditingTypeDaction] = useState(null);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [color, setColor] = useState('#000000');
  const [nameError, setNameError] = useState(false);
  const [colorError, setColorError] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchTerm, setSearchTerm] = useState('');
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [validation, setValidation] = useState('');

  const [getAllTypeDaction] = useGetAllTypeDactionITMutation();
  const [addTypeDaction] = useAddTypeDactionITMutation();
  const [modifyTypeDaction] = useModifyTypeDactionITMutation();
  const [deleteTypeDaction] = useDeleteTypeDactionITMutation();

  const userSession = useSelector((state) => state.adminAuth);

  useEffect(() => {
    const fetchTypeDactions = async () => {
      const response = await getAllTypeDaction({ ID_Etablissement: userSession.ID_Etablissement });
      setTypeDactions(response.data || []);
    };
    fetchTypeDactions();
  }, [getAllTypeDaction, userSession.ID_Etablissement]);

  const handleOpenNewTypeDactionDialog = () => {
    setEditingTypeDaction(null);
    setName('');
    setDescription('');
    setColor('#000000');
    setNameError(false);
    setColorError(false);
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
    setEditingTypeDaction(null);
    setNameError(false);
    setColorError(false);
  };

  const handleDeleteConfirmation = (typeDaction) => {
    setTypeDactionToDelete(typeDaction);
    setConfirmationOpen(true);
  };

  const handleAddOrEditTypeDaction = async () => {
    let hasError = false;

    if (!name) {
      setNameError(true);
      hasError = true;
    } else {
      setNameError(false);
    }

    if (!color) {
      setColorError(true);
      hasError = true;
    } else {
      setColorError(false);
    }

    if (hasError) {
      setError(t('RenderTypeDactionIT.server_error'));
      return;
    }

    const hexColor = `0x${color.slice(1).toUpperCase()}`;
    const formData = {
      Titre: name,
      Description: description,
      ID_Etablissement: userSession.ID_Etablissement,
      Couleur: hexColor
    };

    try {
      if (editingTypeDaction) {
        formData.ID_Type_Daction_IT = editingTypeDaction.ID_Type_Daction_IT;
        await modifyTypeDaction(formData);
        setValidation(t('RenderTypeDactionIT.edit_success'));
      } else {
        await addTypeDaction(formData);
        setValidation(t('RenderTypeDactionIT.add_success'));
      }

      setOpen(false);
      setName('');
      setDescription('');
      setColor('#000000');

      const updatedTypeDactions = await getAllTypeDaction({ ID_Etablissement: userSession.ID_Etablissement });
      setTypeDactions(updatedTypeDactions.data || []);
    } catch (err) {
      setError(t('RenderTypeDactionIT.server_error'));
    }
  };

  const handleEdit = async (typeDaction) => {
    setEditingTypeDaction(typeDaction);
    setName(typeDaction.Titre || '');
    setDescription(typeDaction.Description || '');
    setColor(typeDaction.Couleur ? `#${typeDaction.Couleur.slice(2)}` : '#000000');
    setOpen(true);
  };

  const handleDelete = async () => {
    try {
      await deleteTypeDaction({ ID_Type_Daction_IT: typeDactionToDelete.ID_Type_Daction_IT });
      setConfirmationOpen(false);
      setTypeDactionToDelete(null);

      const updatedTypeDactions = await getAllTypeDaction({ ID_Etablissement: userSession.ID_Etablissement });
      setTypeDactions(updatedTypeDactions.data || []);
      setValidation(t('RenderTypeDactionIT.delete_success'));
    } catch (err) {
      setError(t('RenderTypeDactionIT.server_error'));
    }
  };

  const handleCloseConfirmationDialog = () => {
    setConfirmationOpen(false);
    setTypeDactionToDelete(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredTypeDactions = typeDactions.filter((type) =>
    type.Titre.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Box m="1.5rem 2.5rem">
      <Typography variant="h3" gutterBottom style={{ marginTop: '60px', marginBottom: '20px' }}>
        {t('RenderTypeDactionIT.manage_action_types')}
      </Typography>
      <ErrorMessage message={error} setError={setError} />
      <InfoMessage message={message} setInfo={setMessage} />
      <ValidationMessage message={validation} setValidation={setValidation} />
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={9}>
          <TextField
            label={t('RenderTypeDactionIT.search_action_type')}
            value={searchTerm}
            onChange={handleSearch}
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item xs={3} style={{ textAlign: 'right' }}>
          <Button variant="contained" color="primary" onClick={handleOpenNewTypeDactionDialog}>
            {t('RenderTypeDactionIT.add_action_type')}
          </Button>
        </Grid>
      </Grid>
      <List style={{ marginTop: '20px' }}>
        {filteredTypeDactions.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((typeDaction) => (
          <ListItem key={typeDaction.ID_Type_Daction_IT} secondaryAction={
            <>
              <IconButton edge="end" aria-label="edit" onClick={() => handleEdit(typeDaction)}>
                <EditIcon />
              </IconButton>
              <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteConfirmation(typeDaction)}>
                <DeleteIcon />
              </IconButton>
            </>
          }>
            <ListItemText
              primary={
                <Box display="flex" alignItems="center">
                  <Box
                    sx={{
                      width: 16,
                      height: 16,
                      bgcolor: `#${typeDaction.Couleur.slice(2)}`,
                      borderRadius: '50%',
                      marginRight: 2,
                    }}
                  />
                  {typeDaction.Titre}
                </Box>
              }
              secondary={typeDaction.Description}
            />
          </ListItem>
        ))}
      </List>
      <TablePagination
        component="div"
        count={filteredTypeDactions.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      />

      <Dialog open={open} onClose={handleCloseDialog}>
        <DialogTitle>{editingTypeDaction ? t('RenderTypeDactionIT.edit_action_type') : t('RenderTypeDactionIT.add_new_action_type')}</DialogTitle>
        <DialogContent>
          <FormControl fullWidth margin="dense" error={nameError}>
            <TextField
              autoFocus
              margin="dense"
              label={t('RenderTypeDactionIT.action_type_title')}
              type="text"
              fullWidth
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
            {nameError && <FormHelperText>{t('RenderTypeDactionIT.title_required')}</FormHelperText>}
          </FormControl>
          <TextField
            margin="dense"
            label={t('RenderTypeDactionIT.action_type_description')}
            type="text"
            fullWidth
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          <FormControl fullWidth margin="dense" error={colorError}>
            <TextField
              label={t('RenderTypeDactionIT.color_picker')}
              type="color"
              fullWidth
              value={color}
              onChange={(e) => setColor(e.target.value)}
              required
            />
            {colorError && <FormHelperText>{t('RenderTypeDactionIT.color_required')}</FormHelperText>}
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>{t('RenderTypeDactionIT.cancel')}</Button>
          <Button onClick={handleAddOrEditTypeDaction}>{editingTypeDaction ? t('RenderTypeDactionIT.save') : t('RenderTypeDactionIT.submit')}</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={confirmationOpen} onClose={handleCloseConfirmationDialog}>
        <DialogTitle>{t('RenderTypeDactionIT.confirm_deletion')}</DialogTitle>
        <DialogContent>
          <Typography>{t('RenderTypeDactionIT.confirm_delete_message')}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmationDialog}>{t('RenderTypeDactionIT.cancel')}</Button>
          <Button onClick={handleDelete} color="error">{t('RenderTypeDactionIT.delete')}</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default RenderTypeDactionIT;
