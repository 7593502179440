import React from "react";
import { Box, Typography, Divider } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

const RenderKeyMetrics = ({ data }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: "40px",  
        marginBottom: "40px", 
        width: "100%",
        flexWrap: "nowrap",
      }}
    >
      {/* Nombre total de signalements ouverts */}
      <Box sx={{ textAlign: "center", minWidth: "150px" }}>
        <Typography variant="h4" sx={{ color: theme.palette.text.primary }}>
          {data.totalOpenIncidents}
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: "bold", color: theme.palette.primary.main }}>
          {t('RenderKeyMetrics.openIncidents')}
        </Typography>
      </Box>

      <Divider orientation="vertical" flexItem sx={{ margin: "0 20px" }} />

      {/* Temps moyen entre mises à jour */}
      <Box sx={{ textAlign: "center", minWidth: "150px" }}>
        <Typography variant="h4" sx={{ color: theme.palette.text.primary }}>
          {data.avgUpdateTime ? `${(data.avgUpdateTime / (1000 * 60 * 60)).toFixed(2)} ${t('RenderKeyMetrics.hours')}` : t('RenderKeyMetrics.na')}
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: "bold", color: theme.palette.primary.main }}>
          {t('RenderKeyMetrics.avgUpdateTime')}
        </Typography>
      </Box>

      <Divider orientation="vertical" flexItem sx={{ margin: "0 20px" }} />

      {/* Groupe avec le plus de tickets ouverts */}
      <Box sx={{ textAlign: "center", minWidth: "150px" }}>
        <Typography variant="h4" sx={{ color: theme.palette.text.primary }}>
          {data.groupDetails ? data.groupDetails.Nom_groupe : t('RenderKeyMetrics.na')}
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: "bold", color: theme.palette.primary.main }}>
          {t('RenderKeyMetrics.mostLoadedGroup')}
        </Typography>
      </Box>

      <Divider orientation="vertical" flexItem sx={{ margin: "0 20px" }} />

      {/* Lieu avec le plus d'incidents ouverts */}
      <Box sx={{ textAlign: "center", minWidth: "150px" }}>
        <Typography variant="h4" sx={{ color: theme.palette.text.primary }}>
          {data.locationDetails ? data.locationDetails.Nom_Element || data.locationDetails.Nom_Portail : t('RenderKeyMetrics.na')}
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: "bold", color: theme.palette.primary.main }}>
          {t('RenderKeyMetrics.mostIncidentLocation')}
        </Typography>
      </Box>

      <Divider orientation="vertical" flexItem sx={{ margin: "0 20px" }} />

      {/* Équipement/service avec le plus de tickets ouverts */}
      <Box sx={{ textAlign: "center", minWidth: "150px" }}>
        <Typography variant="h4" sx={{ color: theme.palette.text.primary }}>
          {data.itemDetails ? data.itemDetails.Nom_Equipement || data.itemDetails.Nom_Service || data.itemDetails.Nom_Ep : t('RenderKeyMetrics.na')}
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: "bold", color: theme.palette.primary.main }}>
          {t('RenderKeyMetrics.mostImpactedItem')}
        </Typography>
      </Box>
    </Box>
  );
};

export default RenderKeyMetrics;
