import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, IconButton, Card, CardContent, Tooltip } from "@mui/material";
import { Add, Edit, Delete, Visibility, VisibilityOff, ContentCopy } from "@mui/icons-material";
import { useCreateMachineAccountMutation, useUpdateMachineAccountMutation, useDeleteMachineAccountMutation, useGetMachineAccountsMutation } from 'state/api';
import { useSelector } from 'react-redux';
import { useTheme } from "@mui/material/styles";
import { useTranslation } from 'react-i18next'; // Importation pour la traduction

const CompteMachines = () => {
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedMachineId, setSelectedMachineId] = useState(null);
  const [machineName, setMachineName] = useState("");
  const [editMode, setEditMode] = useState(null); // ID du compte machine à éditer
  const [machineAccounts, setMachineAccounts] = useState([]);
  const [apiKeyVisible, setApiKeyVisible] = useState({});
  const [etablissementIdVisible, setEtablissementIdVisible] = useState({});

  const ID_Etablissement = useSelector((state) => state.adminAuth.ID_Etablissement);
  const [createMachineAccount] = useCreateMachineAccountMutation();
  const [updateMachineAccount] = useUpdateMachineAccountMutation();
  const [deleteMachineAccount] = useDeleteMachineAccountMutation();
  const [getMachineAccounts] = useGetMachineAccountsMutation();
  
  const theme = useTheme(); 
  const { t } = useTranslation(); // Hook de traduction

  // Fonction pour récupérer les comptes machines
  const fetchMachineAccounts = async () => {
    try {
      const response = await getMachineAccounts(ID_Etablissement);
      setMachineAccounts(response.data);
    } catch (error) {
      console.error(t('Machine.ErrorFetching'), error);
    }
  };

  useEffect(() => {
    if (ID_Etablissement) {
      fetchMachineAccounts();
    }
  }, [ID_Etablissement]);

  const handleOpenCreateDialog = () => setOpenCreateDialog(true);
  const handleCloseCreateDialog = () => setOpenCreateDialog(false);
  const handleOpenDeleteDialog = (id) => {
    setSelectedMachineId(id);
    setOpenDeleteDialog(true);
  };
  const handleCloseDeleteDialog = () => {
    setSelectedMachineId(null);
    setOpenDeleteDialog(false);
  };

  const handleCreateMachine = async () => {
    try {
      await createMachineAccount({ machineName, ID_Etablissement }).unwrap();
      setMachineName("");
      setOpenCreateDialog(false);
      fetchMachineAccounts(); 
    } catch (error) {
      console.error(t('Machine.ErrorCreating'), error);
    }
  };

  const handleUpdateMachine = async (id) => {
    try {
      await updateMachineAccount({ id, machineName }).unwrap();
      setEditMode(null);
      fetchMachineAccounts(); 
    } catch (error) {
      console.error(t('Machine.ErrorUpdating'), error);
    }
  };

  const handleDeleteMachine = async () => {
    try {
      await deleteMachineAccount(selectedMachineId).unwrap();
      setOpenDeleteDialog(false);
      fetchMachineAccounts(); 
    } catch (error) {
      console.error(t('Machine.ErrorDeleting'), error);
    }
  };

  const toggleApiKeyVisibility = (id) => {
    setApiKeyVisible((prevState) => ({ ...prevState, [id]: !prevState[id] }));
  };

  const toggleEtablissementIdVisibility = (id) => {
    setEtablissementIdVisible((prevState) => ({ ...prevState, [id]: !prevState[id] }));
  };

  return (
    <Box>
      <Typography variant="h4" sx={{ marginBottom: 2 }}>{t('Machine.ManageMachineAccounts')}</Typography>
      <Button variant="contained" startIcon={<Add />} onClick={handleOpenCreateDialog}>
        {t('Machine.AddMachineAccount')}
      </Button>

      <Box sx={{ marginTop: 3, display: 'flex', flexDirection: 'column', gap: 2 }}>
        {Array.isArray(machineAccounts) && machineAccounts.length > 0 ? (
          machineAccounts.map((machine) => (
            <Card key={machine._id} sx={{ borderRadius: '8px', boxShadow: 2, padding: 2, backgroundColor: theme.palette.primary.main }}>
              <CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  {editMode === machine._id ? (
                    <Box sx={{ flexGrow: 1 }}>
                      <TextField
                        fullWidth
                        value={machineName}
                        onChange={(e) => setMachineName(e.target.value)}
                        label={t('Machine.MachineName')}
                      />
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
                        <Button onClick={() => setEditMode(null)}>{t('Machine.Cancel')}</Button>
                        <Button variant="contained" color="primary" onClick={() => handleUpdateMachine(machine._id)}>{t('Machine.Validate')}</Button>
                      </Box>
                    </Box>
                  ) : (
                    <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
                      <Typography variant="h5" sx={{ flexGrow: 1 }}>{machine.machineName}</Typography>
                      <IconButton onClick={() => { setEditMode(machine._id); setMachineName(machine.machineName); }}>
                        <Edit />
                      </IconButton>
                    </Box>
                  )}

                  <IconButton onClick={() => handleOpenDeleteDialog(machine._id)} color="error">
                    <Delete />
                  </IconButton>
                </Box>

                <Typography variant="subtitle2" sx={{ marginTop: 1 }}>{t('Machine.ApiKey')}</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 1 }}>
                  <TextField
                    fullWidth
                    value={apiKeyVisible[machine._id] ? machine.apiKey : '••••••••••••••••••••••••••'}
                    InputProps={{ readOnly: true }}
                  />
                  <IconButton onClick={() => toggleApiKeyVisibility(machine._id)}>
                    {apiKeyVisible[machine._id] ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                  <Tooltip title={t('Machine.CopyApiKey')}>
                    <IconButton onClick={() => navigator.clipboard.writeText(machine.apiKey)}>
                      <ContentCopy />
                    </IconButton>
                  </Tooltip>
                </Box>

                <Typography variant="subtitle2" sx={{ marginTop: 2 }}>{t('Machine.EtablissementId')}</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 1 }}>
                  <TextField
                    fullWidth
                    value={etablissementIdVisible[machine._id] ? machine.etablissementId : '••••••••••••••••••••••••••'}
                    InputProps={{ readOnly: true }}
                  />
                  <IconButton onClick={() => toggleEtablissementIdVisibility(machine._id)}>
                    {etablissementIdVisible[machine._id] ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                  <Tooltip title={t('Machine.CopyEtablissementId')}>
                    <IconButton onClick={() => navigator.clipboard.writeText(machine.etablissementId)}>
                      <ContentCopy />
                    </IconButton>
                  </Tooltip>
                </Box>

                <Typography variant="body2" sx={{ marginTop: 1, color: theme.palette.text.secondary }}>
                  {t('Machine.CreationDate')}: {new Date(machine.createdAt).toLocaleDateString()}
                </Typography>
              </CardContent>
            </Card>
          ))
        ) : (
          <Typography variant="body2">{t('Machine.NoMachineAccounts')}</Typography>
        )}
      </Box>

      <Dialog open={openCreateDialog} onClose={handleCloseCreateDialog}>
        <DialogTitle>{t('Machine.CreateMachineAccount')}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label={t('Machine.MachineName')}
            type="text"
            fullWidth
            value={machineName}
            onChange={(e) => setMachineName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCreateDialog}>{t('Machine.Cancel')}</Button>
          <Button onClick={handleCreateMachine} variant="contained">{t('Machine.Create')}</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
        <DialogTitle>{t('Machine.ConfirmDeleteTitle')}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t('Machine.ConfirmDeleteText')}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog}>{t('Machine.Cancel')}</Button>
          <Button onClick={handleDeleteMachine} variant="contained" color="error">{t('Machine.Delete')}</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CompteMachines;
