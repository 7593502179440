import React, { useState, useEffect, useRef } from 'react';
import { Typography, TextField, Dialog, DialogTitle, DialogContent, IconButton, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import HomeIcon from '@mui/icons-material/Home';
import styled from '@emotion/styled';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  usePublicgetAllInfoServiceSGMutation,
  usePublicgetAllInfoServiceITMutation,
  useAllInfoNoeudMutation,
  usePublicGetInfoRegleSpecialMutation
} from 'state/api';
import WebFont from 'webfontloader';
import SidebarServices from './SidebarServices';
import DemandedeService from './DemandedeService';
import ValidationMessage from '../ValidationMessage';
import ErrorMessage from '../ErrorMessage';

// Utilisation des variables d'environnement pour l'URL de base
const BASE_URL = process.env.REACT_APP_BASE_URL || 'http://localhost:5001';

const StyledPage = styled('div')`
  display: flex;
  background-color: ${(props) => props.couleur_de_fond || '#FFFFFF'} !important;
  color: ${(props) => props.couleur_ecriture || '#000000'} !important;
  font-family: ${(props) => props.police_de_caractere}, sans-serif;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden !important;
`;

const HeaderContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  margin-top: 5%;
`;

const MainContainer = styled('div')`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center horizontally */
  padding: 16px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 12px;
    background-color: ${(props) => props.couleur_de_fond || '#FFFFFF'};
  }

  &::-webkit-scrollbar-track {
    background-color: ${(props) => props.couleur_de_fond || '#FFFFFF'};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.couleur_ecriture || '#000000'};
    border-radius: 10px;
  }
`;

const ServiceGrid = styled('div')`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(190px, 1fr));
  gap: 16px;
  width: 100%;
  max-width: 1200px;
`;

const ServiceCard = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 16px;
  background-color: ${(props) => props.couleur_de_case || '#FFFFFF'};
  color: ${(props) => props.couleur_ecriture || '#000000'};
  box-shadow: 5px 5px 10px ${(props) => props.couleur_dombre_de_case || 'rgba(0, 0, 0, 0.1)'};
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.2s;
  height: 237.5px;

  &:hover {
    transform: scale(1.05);
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom right, rgba(255, 255, 255, 0.5), transparent);
    z-index: 0;
  }
`;

const ServiceImage = styled('img')`
  width: 120px;
  height: 120px;
  object-fit: contain;
  margin-bottom: 8px;
  z-index: 1;
  border-radius: 10px;
  filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.3));
`;

const ServiceTitle = styled(Typography)`
  color: ${(props) => props.couleur_bouton_ecriture || '#000000'};
  font-family: ${(props) => props.police_de_caractere || 'sans-serif'};
  font-weight: bold;
  z-index: 1;
  font-size: 0.9em;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

const ContentContainer = styled('div')`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 12px;
    background-color: ${(props) => props.couleur_de_fond || '#FFFFFF'};
  }

  &::-webkit-scrollbar-track {
    background-color: ${(props) => props.couleur_de_fond || '#FFFFFF'};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.couleur_ecriture || '#000000'};
    border-radius: 10px;
  }
`;

const StyledTextField = styled(TextField)`
  width: 25%;
  @media (max-width: 767px) {
    width: 80%;
  }
  background-color: ${(props) => props.couleur_de_fond};
  border-radius: 5px;
  margin-bottom: 16px;
`;

const AccessDeniedMessage = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: #ffffff;

  .access-denied-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ff0000;
    padding: 20px;
    border-radius: 10px;
    color: #ffffff;
    font-size: 2rem;
    position: relative;

    .icon {
      font-size: 4rem;
      margin-bottom: 16px;
    }

    .home-button {
      position: absolute;
      top: 10px;
      left: 10px;
    }
  }
`;

const PagePrincipalServices = () => {
  const { ID_Etablissement } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const userSession = useSelector((state) => state.userAuth);
  const [getAllInfoServiceSG] = usePublicgetAllInfoServiceSGMutation();
  const [getAllInfoServiceIT] = usePublicgetAllInfoServiceITMutation();
  const [getInfoWithNoeudCheck] = useAllInfoNoeudMutation();
  const [getInfoRegleSpecial] = usePublicGetInfoRegleSpecialMutation();
  const [personnalisation, setPersonnalisation] = useState({});
  const [categories, setCategories] = useState([]);
  const [services, setServices] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('Tous');
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedService, setSelectedService] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [validationMessage, setValidationMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [accessDenied, setAccessDenied] = useState(false);
  const redirecting = useRef(false);

  useEffect(() => {
    const checkRegleSpecial = async () => {
      try {
        const regleSpecial = await getInfoRegleSpecial({ ID_Etablissement }).unwrap();
        
        if (regleSpecial.ConnexionPrive) {
          if (!userSession.isAuthenticated || !userSession.user || !userSession.user.email) {
            const redirectPath = encodeURIComponent(window.location.pathname);
            if (!redirecting.current) {
              redirecting.current = true;
              navigate(`/loginclient?redirectTo=${redirectPath}`);
            }
            return;
          }

          const userDomain = userSession.user.email.split('@').pop();
          const isDomainAllowed = regleSpecial.DomainePrive.some(domain => userDomain.endsWith(domain.trim()));

          if (!isDomainAllowed) {
            setAccessDenied(true);
            return;
          }
        }

        fetchServices();
        fetchPersonnalisation();
      } catch (error) {
        console.error(t('PagePrincipalServices.error_checking_special_rules'), error);
        setErrorMessage(t('PagePrincipalServices.error_checking_special_rules'));
      }
    };

    const fetchServices = async () => {
      try {
        const [servicesSG, servicesIT] = await Promise.all([
          getAllInfoServiceSG({ ID_Etablissement }).unwrap(),
          getAllInfoServiceIT({ ID_Etablissement }).unwrap(),
        ]);

        const allServices = [...servicesSG, ...servicesIT];
        const allCategories = [{ id: 'Tous', Titre: t('PagePrincipalServices.all') }, ...allServices];

        setServices(allServices);
        setCategories(allCategories);
      } catch (error) {
        console.error(t('PagePrincipalServices.error_fetching_services'), error);
        setErrorMessage(t('PagePrincipalServices.error_fetching_services'));
      }
    };

    const fetchPersonnalisation = async () => {
      try {
        const data = await getInfoWithNoeudCheck({ ID_Etablissement }).unwrap();
        setPersonnalisation(data.personnalisation || {});
        if (data.personnalisation && data.personnalisation.URL_police_de_caractere) {
          WebFont.load({
            google: {
              families: [data.personnalisation.URL_police_de_caractere],
            },
          });
        }
      } catch (error) {
        console.error(t('PagePrincipalServices.error_fetching_personalization'), error);
        setErrorMessage(t('PagePrincipalServices.error_fetching_personalization'));
      }
    };

    checkRegleSpecial();
  }, [ID_Etablissement, getAllInfoServiceSG, getAllInfoServiceIT, getInfoWithNoeudCheck, getInfoRegleSpecial, userSession, navigate, t]);

  const handleCategorySelect = (categoryId) => {
    setSelectedCategory(categoryId);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleServiceClick = (service) => {
    setSelectedService(service);
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setSelectedService(null);
  };

  const handleHomeClick = () => {
    navigate('/infoutilisateur');
  };

  const filteredServices = selectedCategory === 'Tous'
    ? services.flatMap(serviceCategory => serviceCategory.services || [])
    : services.find(serviceCategory => serviceCategory.id === selectedCategory)?.services || [];

  const searchedServices = filteredServices.filter(service =>
    service.Titre.toLowerCase().includes(searchQuery.toLowerCase())
  );

  if (accessDenied) {
    return (
      <AccessDeniedMessage>
        <Button
          variant="contained"
          color="primary"
          startIcon={<HomeIcon />}
          onClick={handleHomeClick}
          className="home-button"
          sx={{ position: 'fixed', top: 10, left: 10 }}
        >
          {t('PagePrincipalServices.home')}
        </Button>
        <div className="access-denied-box">
          <div className="icon">⚠️</div>
          {t('PagePrincipalServices.access_denied')}
        </div>
      </AccessDeniedMessage>
    );
  }

  return (
    <StyledPage
      couleur_de_fond={personnalisation.couleur_de_fond}
      couleur_ecriture={personnalisation.couleur_ecriture}
      police_de_caractere={personnalisation.police_de_caractere}
    >
      <SidebarServices categories={categories} onCategorySelect={handleCategorySelect} styleProps={personnalisation} />
      <ContentContainer
        couleur_de_fond={personnalisation.couleur_de_fond}
        couleur_ecriture={personnalisation.couleur_ecriture}
      >
        <HeaderContainer>
          <Typography
            variant="h4"
            style={{
              color: personnalisation.couleur_ecriture,
              fontFamily: personnalisation.police_de_caractere,
              fontWeight: 'bold',
              marginBottom: '16px',
            }}
          >
            {t('PagePrincipalServices.select_service')}
          </Typography>
          <StyledTextField
            placeholder={t('PagePrincipalServices.search_service_placeholder')}
            value={searchQuery}
            onChange={handleSearchChange}
            couleur_de_fond={personnalisation.couleur_de_fond}
            couleur_ecriture={personnalisation.couleur_ecriture}
            InputProps={{
              style: { color: personnalisation.couleur_ecriture },
            }}
          />
        </HeaderContainer>
        <MainContainer
          couleur_de_fond={personnalisation.couleur_de_fond}
          couleur_ecriture={personnalisation.couleur_ecriture}
        >
          <ServiceGrid>
            {searchedServices.map((service) => (
              <ServiceCard
                key={service.id}
                couleur_de_case={personnalisation.couleur_de_case}
                couleur_ecriture={personnalisation.couleur_ecriture}
                couleur_dombre_de_case={personnalisation.couleur_dombre_de_case}
                onClick={() => handleServiceClick(service)}
              >
                <ServiceImage
                  src={`${BASE_URL}/public/displayimagewithpath/${encodeURIComponent(service.Logo_Requis)}`}
                  alt={service.Titre}
                />
                <ServiceTitle
                  variant="body1"
                  couleur_bouton_ecriture={personnalisation.couleur_bouton_ecriture}
                  police_de_caractere={personnalisation.police_de_caractere}
                >
                  {service.Titre}
                </ServiceTitle>
              </ServiceCard>
            ))}
          </ServiceGrid>
        </MainContainer>
      </ContentContainer>
      {selectedService && (
        <Dialog open={isDialogOpen} onClose={handleCloseDialog} maxWidth="md" fullWidth>
          <DialogTitle>
            <IconButton onClick={handleCloseDialog} style={{ position: 'absolute', right: 8, top: 8 }}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <DemandedeService
              open={isDialogOpen}
              onClose={handleCloseDialog}
              selectedService={selectedService}
              ID_Etablissement={ID_Etablissement}
              setValidationMessage={setValidationMessage}
            />
          </DialogContent>
        </Dialog>
      )}
      {validationMessage && (
        <ValidationMessage message={validationMessage} setValidation={setValidationMessage} />
      )}
      {errorMessage && (
        <ErrorMessage message={errorMessage} setError={setErrorMessage} />
      )}
    </StyledPage>
  );
};

export default PagePrincipalServices;
