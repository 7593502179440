import React from 'react';
import { Radio, RadioGroup, FormControlLabel } from '@mui/material';

const RoleSelector = ({ roles, onRoleChange, selectedRole }) => {
  return (
    <RadioGroup value={selectedRole} onChange={(event) => onRoleChange(event.target.value)}>
      {roles.map((role) => (
        <FormControlLabel
          key={role.ID_Role}
          value={role.ID_Role}  // Utilisez l'ID du rôle comme valeur
          control={<Radio />}
          label={role.Nom_Role}  // Le label reste le nom du rôle pour l'utilisateur
        />
      ))}
    </RadioGroup>
  );
};

export default RoleSelector;
