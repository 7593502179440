import React, { useEffect, useState } from 'react';
import { Box, Typography, CircularProgress, Button } from '@mui/material';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useVerifyPortailQuery } from 'state/api';
import { useTranslation } from 'react-i18next';

const VerifyPortail = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const token = searchParams.get('token');
  const [status, setStatus] = useState('loading');
  const { data, error, isLoading } = useVerifyPortailQuery(token);

  useEffect(() => {
    if (!isLoading) {
      if (error) {
        setStatus('error');
      } else if (data?.message === 'Portail accepté') {
        setStatus('accepted');
      } else if (data?.message === 'Portail refusé et supprimé') {
        setStatus('refused');
      }
    }
  }, [isLoading, data, error]);

  const handleRedirect = () => {
    navigate('/acceuilpublic');
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" minHeight="100vh">
      {status === 'loading' && <CircularProgress />}
      {status === 'accepted' && (
        <>
          <Typography variant="h4" gutterBottom>
            {t('VerifyPortail.accepted')}
          </Typography>
          <Button variant="contained" color="primary" onClick={handleRedirect}>
            {t('VerifyPortail.backToHome')}
          </Button>
        </>
      )}
      {status === 'refused' && (
        <>
          <Typography variant="h4" gutterBottom>
            {t('VerifyPortail.refused')}
          </Typography>
          <Button variant="contained" color="primary" onClick={handleRedirect}>
            {t('VerifyPortail.backToHome')}
          </Button>
        </>
      )}
      {status === 'error' && (
        <>
          <Typography variant="h4" gutterBottom>
            {t('VerifyPortail.error')}
          </Typography>
          <Button variant="contained" color="primary" onClick={handleRedirect}>
            {t('VerifyPortail.backToHome')}
          </Button>
        </>
      )}
    </Box>
  );
};

export default VerifyPortail;
