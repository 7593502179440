import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CategoriePrincipaleCard from './CategoriePrincipaleCard';
import EquipementCard from './EquipementCard';
import EquipementForm from './EquipementForm';
import { useTranslation } from 'react-i18next';
import ValidationMessage from '../ValidationMessage';

// Styled components for custom styling
const SignalementGrid = styled('div')`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(190px, 1fr));
  gap: 16px;
  width: 100%;
`;

const BackCard = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.couleur_de_case || '#FFFFFF'};
  color: ${(props) => props.couleur_ecriture || '#000000'};
  box-shadow: 5px 5px 10px ${(props) => props.couleur_dombre_de_case || 'rgba(0, 0, 0, 0.1)'};
  border-radius: 10px;
  cursor: pointer;
  transition: transform 0.2s;
  height: 237.5px;
  width: 100%;
  text-align: center;

  &:hover {
    transform: scale(1.05);
  }
`;

const FullPageContainer = styled('div')`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${(props) => props.couleur_de_fond || '#FFFFFF'};
  color: ${(props) => props.couleur_ecriture || '#000000'};
  font-family: ${(props) => props.police_de_caractere}, sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CategorieSecondaireView = ({ 
  categorie, 
  categoriesSecondaires, 
  equipements, 
  onBack, 
  onCategorieClick, 
  personnalisation, 
  logoUrl, 
  userSession,
  ID_Etablissement,
  ID_Noeud,
  publicAddIncident
}) => {
  const { t } = useTranslation();
  const [selectedEquipement, setSelectedEquipement] = useState(null);
  const [validationMessage, setValidationMessage] = useState('');

  const handleEquipementSelect = (equipement) => {
    setSelectedEquipement(equipement);
  };

  const handleFormClose = () => {
    setSelectedEquipement(null);
  };

  const handleIncidentFormSubmit = async (formData) => {
    const isIT = selectedEquipement?.ID_Equipement_IT !== undefined;
    const type = isIT ? 'IT' : 'SG';
  
    try {
      const formDataToSend = new FormData();
      formDataToSend.append('type', type);
      formDataToSend.append('ID_Etablissement', ID_Etablissement);
      formDataToSend.append('ID_Noeud', ID_Noeud);
      formDataToSend.append('ID_Equipement_IT', isIT ? selectedEquipement.ID_Equipement_IT : '');
      formDataToSend.append('ID_Type_Daction_IT', isIT ? selectedEquipement.ID_Type_Daction_IT : '');
      formDataToSend.append('ID_Equipement_SG', !isIT ? selectedEquipement.ID_Equipement_SG : '');
      formDataToSend.append('ID_Type_Daction_SG', !isIT ? selectedEquipement.ID_Type_Daction_SG : '');
      formDataToSend.append('Priorite', selectedEquipement?.Priorite);
      formDataToSend.append('Severite', selectedEquipement?.Severite);
      formDataToSend.append('ID_Utilisateur', userSession.ID_Utilisateur ? userSession.ID_Utilisateur : null);

      // Append other form data
      Object.keys(formData).forEach(key => {
        if (key === 'Attachment' && formData[key]) {
          formDataToSend.append(key, formData[key], formData[key].name);
        } else {
          formDataToSend.append(key, formData[key]);
        }
      });

      await publicAddIncident(formDataToSend);
      setValidationMessage(t('incident_submit_success'));
      handleFormClose();
    } catch (error) {
      console.error("Error submitting incident:", error);
      setValidationMessage(t('incident_submit_error'));
    }
  };

  const filteredCategories = categoriesSecondaires.filter(cat => 
    cat.ID_Categorie_Precedente === categorie.ID_Categorie_Signalement_IT || 
    cat.ID_Categorie_Precedente === categorie.ID_Categorie_Signalement_SG
  );

  const filteredEquipements = equipements.filter(equipement => 
    equipement.ID_Categorie_Signalement === categorie.ID_Categorie_Signalement_IT || 
    equipement.ID_Categorie_Signalement === categorie.ID_Categorie_Signalement_SG
  );

  return (
    <>
      {selectedEquipement ? (
        <FullPageContainer
          couleur_de_fond={personnalisation.couleur_de_fond}
          couleur_ecriture={personnalisation.couleur_ecriture}
          police_de_caractere={personnalisation.police_de_caractere}
        >
          <EquipementForm
            onClose={handleFormClose}
            couleur_de_fond={personnalisation.couleur_de_fond}
            couleur_ecriture={personnalisation.couleur_ecriture}
            couleur_bouton={personnalisation.couleur_bouton}
            couleur_bouton_ecriture={personnalisation.couleur_bouton_ecriture}
            police_de_caractere={personnalisation.police_de_caractere}
            maxParticules={selectedEquipement.Nombre_particules}
            logoUrl={logoUrl}
            onFormSubmit={handleIncidentFormSubmit}
          />
        </FullPageContainer>
      ) : (
        <SignalementGrid>
          <BackCard
            couleur_de_case={personnalisation.couleur_de_fond}
            couleur_ecriture={personnalisation.couleur_ecriture}
            couleur_dombre_de_case={personnalisation.couleur_dombre_de_case}
            onClick={onBack}
          >
            <ArrowBackIcon />
            <Typography variant="body1">{t('back')}</Typography>
          </BackCard>
          {filteredCategories.map(cat => (
            <CategoriePrincipaleCard
              key={`categorie-${cat.ID_Categorie_Signalement_IT || cat.ID_Categorie_Signalement_SG}`}
              categorie={cat}
              type={cat.ID_Categorie_Signalement_IT ? 'IT' : 'SG'}
              onClick={() => onCategorieClick(cat)}
              couleur_de_case={personnalisation.couleur_de_case}
              couleur_ecriture={personnalisation.couleur_ecriture}
              couleur_dombre_de_case={personnalisation.couleur_dombre_de_case}
            />
          ))}
          {filteredEquipements.map(equipement => (
            <EquipementCard
              key={`equipement-${equipement.ID_Equipement_IT || equipement.ID_Equipement_SG}`}
              equipement={equipement}
              type={equipement.ID_Equipement_IT ? 'IT' : 'SG'}
              onClick={() => handleEquipementSelect(equipement)}
              couleur_de_case={personnalisation.couleur_de_case_active}
              couleur_ecriture={personnalisation.couleur_ecriture}
              couleur_dombre_de_case={personnalisation.couleur_dombre_de_case}
              couleur_bouton_ecriture={personnalisation.couleur_ecriture}
              police_de_caractere={personnalisation.police_de_caractere}
            />
          ))}
        </SignalementGrid>
      )}
      {validationMessage && (
        <ValidationMessage message={validationMessage} setValidation={setValidationMessage} />
      )}
    </>
  );
};

export default CategorieSecondaireView;
