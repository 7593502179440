import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, Paper, List, ListItem, ListItemText } from '@mui/material';
import { Bar } from 'react-chartjs-2';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import ImageCarrouselRetourFeedback from './ImageCarrouselRetourFeedback';
import { useDownloadFileRetourFeedbackQuery } from 'state/api';

// Enregistrement des composants nécessaires pour Chart.js
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const DetailRetourFeedback = ({ feedback }) => {
  const [imageFiles, setImageFiles] = useState([]);
  const [otherFiles, setOtherFiles] = useState([]);
  const theme = useTheme();
  const { t } = useTranslation();

  // Extraire les fichiers du feedback (images et autres)
  useEffect(() => {
    const images = [];
    const others = [];

    feedback.Sections.forEach((section) => {
      section.Inputs.forEach((input) => {
        if (input.Type_Dinput === 'file') {
          const files = Object.keys(input.Valeurs_Reponses);
          files.forEach((file) => {
            if (/\.(jpg|jpeg|png|gif)$/i.test(file)) {
              images.push(file);
            } else {
              others.push(file);
            }
          });
        }
      });
    });

    setImageFiles(images);
    setOtherFiles(others);
  }, [feedback]);

  // Hook pour télécharger un fichier
  const { data: fileData } = useDownloadFileRetourFeedbackQuery();

  // Fonction pour télécharger un fichier
  const handleFileDownload = (fileName) => {
    if (fileData) {
      const url = URL.createObjectURL(fileData);
      const link = document.createElement('a');
      link.href = url;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  // Fonction pour afficher les types d'inputs avec graphique
  const renderChartForInput = (input, isHorizontal = false) => {
    const labels = Object.keys(input.Valeurs_Reponses);
    const data = Object.values(input.Valeurs_Reponses);

    const chartData = {
      labels,
      datasets: [
        {
          label: t('DetailRetourFeedback.response_percentage'),
          data,
          backgroundColor: 'rgba(75,192,192,0.4)',
          borderColor: 'rgba(75,192,192,1)',
          borderWidth: 1,
        },
      ],
    };

    const chartOptions = {
      responsive: true,
      indexAxis: isHorizontal ? 'y' : 'x', // Affichage horizontal pour time/date
      plugins: {
        legend: { position: 'top' },
        title: { display: true, text: input.Nom_Input },
      },
      scales: {
        x: { beginAtZero: true, max: 100 },
        y: { beginAtZero: true },
      },
    };

    return <Bar data={chartData} options={chartOptions} />;
  };

  // Fonction pour afficher les fichiers
  const renderInput = (input) => {
    const inputTypeDescription = (
      <Typography variant="body2" sx={{ color: theme.palette.text.secondary, marginLeft: '8px' }}>
        {`(${t('DetailRetourFeedback.type')}: ${input.Type_Dinput})`}
      </Typography>
    );

    const inputTitleWithDescription = (
      <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
        <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'white' }}>
          {input.Nom_Input}
        </Typography>
        {inputTypeDescription}
      </Box>
    );

    switch (input.Type_Dinput) {
      case 'boolean':
      case 'radio':
      case 'checkbox':
      case 'dropdown':
      case 'slider':
        return (
          <Box>
            {inputTitleWithDescription}
            {renderChartForInput(input)}
          </Box>
        );

      case 'file':
        return (
          <Box>
            {inputTitleWithDescription}
            <ImageCarrouselRetourFeedback
              imageFiles={imageFiles}
              otherFiles={otherFiles}
              onDownload={handleFileDownload}
            />
          </Box>
        );

      case 'varchar':
      case 'text':
        return (
          <Box>
            {inputTitleWithDescription}
            <Typography variant="body1" sx={{ color: 'white' }}>{t('DetailRetourFeedback.text_responses')}:</Typography>
            <List>
              {Object.entries(input.Valeurs_Reponses).map(([key, value], index) => (
                <ListItem key={index}>
                  <ListItemText primary={`${key}: ${value}`} sx={{ color: 'white' }} />
                </ListItem>
              ))}
            </List>
          </Box>
        );

      case 'number':
        return (
          <Box>
            {inputTitleWithDescription}
            {renderChartForInput(input)}
          </Box>
        );

      case 'date':
      case 'time':
        return (
          <Box>
            {inputTitleWithDescription}
            {renderChartForInput(input, true)} {/* Graphique en barres horizontales */}
          </Box>
        );

      default:
        return (
          <Box>
            {inputTitleWithDescription}
            <Typography variant="body1" sx={{ color: 'white' }}>{t('DetailRetourFeedback.unsupported_input_type')}</Typography>
          </Box>
        );
    }
  };

  // Fonction pour télécharger les données JSON
  const handleDownloadJson = () => {
    const jsonData = JSON.stringify(feedback, null, 2);
    const blob = new Blob([jsonData], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `${feedback.Nom_Feedback}_data.json`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Box sx={{ padding: '20px', backgroundColor: theme.palette.background.paper, borderRadius: '10px' }}>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '16px' }}>
        <Button variant="contained" color="primary" onClick={handleDownloadJson}>
          {t('DetailRetourFeedback.download_json')}
        </Button>
      </Box>

      <Typography variant="h5" sx={{ marginBottom: '16px', color: 'white' }}>
        {feedback.Nom_Feedback}
      </Typography>

      <Typography variant="body2" sx={{ fontWeight: 'bold', marginBottom: '8px', color: 'gray' }}>
        {`${t('DetailRetourFeedback.total_responses')}: ${feedback.Total_Reponses}`}
      </Typography>

      {feedback.Sections.map((section, index) => (
        <Paper key={index} sx={{ padding: '16px', marginBottom: '24px', backgroundColor: theme.palette.background.default }} elevation={3}>
          <Typography variant="h6" sx={{ marginBottom: '16px', color: theme.palette.primary.main }}>
            {`${t('DetailRetourFeedback.section_title')} ${index + 1}: ${section.Nom_Section}`}
          </Typography>

          {section.Inputs.map((input) => (
            <Box key={input.ID_Input} sx={{ marginBottom: '16px' }}>
              {renderInput(input)}
            </Box>
          ))}
        </Paper>
      ))}
    </Box>
  );
};

export default DetailRetourFeedback;
