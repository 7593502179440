import React, { useState, useEffect } from 'react';
import {
  Typography,
  TextField,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Divider,
  Button
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useGetAllInfoCategorieSignalementSGMutation, useGetAllInfoEquipementSGMutation } from 'state/api';
import { useSelector } from 'react-redux';
import RemoveIcon from '@mui/icons-material/Remove';
import InfoIcon from '@mui/icons-material/Info';
import AddIcon from '@mui/icons-material/Add';

// Utilisation des variables d'environnement pour l'URL de base
const BASE_URL = process.env.REACT_APP_BASE_URL || 'http://localhost:5001';

const ListeEquipementSignalementSG = ({ liste_equipement, setListeEquipement, isFirstLoad, setIsFirstLoad }) => {
  const { t } = useTranslation();

  const [searchResults, setSearchResults] = useState([]);
  const [selectedEquipments, setSelectedEquipments] = useState([]);
  const [equipmentDetailsOpen, setEquipmentDetailsOpen] = useState(false);
  const [selectedEquipmentDetails, setSelectedEquipmentDetails] = useState(null);
  const [equipements, setEquipements] = useState([]);
  const [nomEquipementRecherche, setNomEquipementRecherche] = useState('');
  const [categorieRecherche, setCategorieRecherche] = useState('');
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const userSession = useSelector((state) => state.adminAuth);

  const [getAllInfosCategorie] = useGetAllInfoCategorieSignalementSGMutation();
  const [getAllInfosEquipement] = useGetAllInfoEquipementSGMutation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const responseCategories = await getAllInfosCategorie({ ID_Etablissement: userSession.ID_Etablissement });
        const responseEquipements = await getAllInfosEquipement({ ID_Etablissement: userSession.ID_Etablissement });
        if (responseCategories.data && responseEquipements.data) {
          setCategories(responseCategories.data);
          setEquipements(responseEquipements.data);
          setSearchResults(responseEquipements.data);
        }
      } catch (err) {
        if (err.originalStatus === 401) {
          alert('Votre session a expiré. Veuillez vous reconnecter.');
          window.location = '/loginuser';
        }
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [getAllInfosCategorie, getAllInfosEquipement, userSession.ID_Etablissement]);

  useEffect(() => {
    const fetchEquipementDetails = async () => {
      if (isFirstLoad) {
        try {
          setIsLoading(true);
          if (liste_equipement && equipements.length > 0) {
            const equipementDetails = liste_equipement.map(id => {
              return equipements.find(equip => equip.ID_Equipement_SG === id);
            }).filter(equip => equip !== undefined);
            setSelectedEquipments(equipementDetails);
          }
          setIsFirstLoad(false);
        } catch (error) {
          console.error(error);
        } finally {
          setIsLoading(false);
        }
      } else {
        if (liste_equipement && selectedEquipments && liste_equipement.length !== selectedEquipments.length) {
          try {
            setIsLoading(true);
            const selectedSignalementsIds = selectedEquipments.map(equipement => equipement.ID_Equipement_SG);
            setListeEquipement(selectedSignalementsIds);
          } catch (error) {
            console.error(error);
          } finally {
            setIsLoading(false);
          }
        }
      }
    };

    if (equipements.length > 0) {
      fetchEquipementDetails();
    }
  }, [liste_equipement, selectedEquipments, isFirstLoad, setIsFirstLoad, equipements]);

  useEffect(() => {
    const filteredResults = equipements.filter(equip => {
      const matchName = equip.Nom_Equipement.toLowerCase().includes(nomEquipementRecherche.toLowerCase());
      const matchCategory = categorieRecherche ? equip.ID_Categorie_Signalement === categorieRecherche : true;
      const notSelected = !selectedEquipments.some(selected => selected.ID_Equipement_SG === equip.ID_Equipement_SG);
      return matchName && matchCategory && notSelected;
    });
    setSearchResults(filteredResults);
  }, [nomEquipementRecherche, categorieRecherche, equipements, selectedEquipments]);

  const addEquipment = (equipment) => {
    if (!selectedEquipments.some((item) => item.ID_Equipement_SG === equipment.ID_Equipement_SG)) {
      setSelectedEquipments((prevSelectedEquipments) => [...prevSelectedEquipments, equipment]);
      setListeEquipement((prevListe) => [...prevListe, equipment.ID_Equipement_SG]);
    }
    setSearchResults((prevSearchResults) => prevSearchResults.filter((item) => item.ID_Equipement_SG !== equipment.ID_Equipement_SG));
  };

  const removeEquipment = (equipment) => {
    setSelectedEquipments((prevSelectedEquipments) =>
      prevSelectedEquipments.filter((item) => item.ID_Equipement_SG !== equipment.ID_Equipement_SG)
    );
    setListeEquipement((prevListe) => prevListe.filter((id) => id !== equipment.ID_Equipement_SG));
    setSearchResults((prevSearchResults) => [...prevSearchResults, equipment]);
  };

  const viewEquipmentDetails = (equipment) => {
    setSelectedEquipmentDetails(equipment);
    setEquipmentDetailsOpen(true);
  };

  const closeEquipmentDetails = () => {
    setEquipmentDetailsOpen(false);
  };

  return (
    <div>
      <Typography variant="h6" style={{ marginTop: '20px' }}>
        {t('ListeEquipementSignalementSG.search_equipment')}
      </Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <TextField
            label={t('ListeEquipementSignalementSG.equipment_name')}
            value={nomEquipementRecherche}
            onChange={(e) => setNomEquipementRecherche(e.target.value)}
          />
        </Grid>
        <Grid item>
          <Select
            value={categorieRecherche}
            onChange={(e) => setCategorieRecherche(e.target.value)}
            displayEmpty
            style={{ minWidth: '150px' }}
          >
            <MenuItem value="">
              <em>{t('ListeEquipementSignalementSG.select_category')}</em>
            </MenuItem>
            {categories.map((cat) => (
              <MenuItem key={cat.ID_Categorie_Signalement_SG} value={cat.ID_Categorie_Signalement_SG}>
                {cat.Nom_Categorie} {cat.ID_Categorie_Precedente === 0 ? t('ListeEquipementSignalementSG.primary') : t('ListeEquipementSignalementSG.secondary')}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>

      <Grid container spacing={2} style={{ marginTop: '20px' }}>
        <Grid item xs={6}>
          <Typography variant="h6">{t('ListeEquipementSignalementSG.search_results')}</Typography>
          <Divider />
          <List>
            {searchResults.map((equipment) => (
              <ListItem key={equipment.ID_Equipement_SG}>
                <ListItemText primary={equipment.Nom_Equipement} />
                <IconButton onClick={() => addEquipment(equipment)}>
                  <AddIcon />
                </IconButton>
                <IconButton onClick={() => viewEquipmentDetails(equipment)}>
                  <InfoIcon />
                </IconButton>
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h6">{t('ListeEquipementSignalementSG.selected_equipments')}</Typography>
          <Divider />
          <List>
            {selectedEquipments.map((equipment) => (
              <ListItem key={equipment.ID_Equipement_SG}>
                <ListItemText primary={equipment.Nom_Equipement} />
                <IconButton onClick={() => removeEquipment(equipment)}>
                  <RemoveIcon />
                </IconButton>
                <IconButton onClick={() => viewEquipmentDetails(equipment)}>
                  <InfoIcon />
                </IconButton>
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>

      <Dialog open={equipmentDetailsOpen} onClose={closeEquipmentDetails}>
        {selectedEquipmentDetails && (
          <>
            <DialogTitle>{t('ListeEquipementSignalementSG.equipment_details')}</DialogTitle>
            <DialogContent>
              <Typography variant="h6" style={{ fontWeight: 'bold', marginBottom: '10px' }}>
                {selectedEquipmentDetails.Nom_Equipement}
              </Typography>
              <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10%' }}>
                <img
                  src={`${BASE_URL}/images/displayimagecategoriesignalementSG/${selectedEquipmentDetails.Lien_Image_Equipement}`}
                  alt={`Equipement-${selectedEquipmentDetails.Nom_Equipement}`}
                  style={{ maxWidth: '60%', maxHeight: '60%' }}
                />
              </div>
              <Typography variant="body1" style={{ marginTop: '10px' }}>
                {selectedEquipmentDetails.Description_Equipement}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeEquipmentDetails} color="primary">
                {t('ListeEquipementSignalementSG.close')}
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </div>
  );
};

export default ListeEquipementSignalementSG;
