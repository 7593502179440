import React from "react";
import { Box } from "@mui/material";
import Header from "components/Header";
import Tree from "../../components/Tree";
import { useTranslation } from "react-i18next";

const Secteur = () => {
  const { t } = useTranslation();

  return (
    <Box m="1.5rem 2.5rem">
      <Header title={t('Secteur.title')} subtitle={t('Secteur.subtitle')} />
      <div className="app-container">
        <h1>{t('Secteur.header')}</h1>
        <Tree />
      </div>
    </Box>
  );
};

export default Secteur;
