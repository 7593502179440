import React from "react";
import { Box, Typography, CircularProgress } from "@mui/material";
import FlexBetween from "components/FlexBetween";
import Header from "components/Header";

const Overview = () => {
  return (
    <Box m="1.5rem 2.5rem">
      <FlexBetween>
        <Header title="STATISTIQUE" subtitle="Visualisez ici toute votre data !" />
      </FlexBetween>

      <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="75vh"
    >
      <CircularProgress
        size={100} 
        thickness={4} 
        sx={{ color: "primary.main" }}
      />
      <Typography variant="h6" mt={2}>
        Arrive prochainement...
      </Typography>
      <Typography variant="body1" mt={2}>
        Cette page vous permettra de visualiser toutes les statistiques de vos utilisateurs !
      </Typography>
    </Box>
    </Box>
    
    
  );
};

export default Overview;
