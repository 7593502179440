import React from 'react';
import { Box } from '@mui/material';
import Navbar from '../../components_public/navbar.jsx';
import HeroSection from './HeroSection';
import Footer from './Footer'; // Seules la section héroïque et le footer sont présents

const AcceuilPublic = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Navbar />
      <HeroSection />
    </Box>
  );
};

export default AcceuilPublic;
