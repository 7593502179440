import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, Container, List, ListItem, ListItemIcon, ListItemText, useMediaQuery, useTheme } from '@mui/material';
import { motion, useAnimation } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import hurflymLogo from '../../assets/logo_hurflym_sans_fond.png';
import hurflymLogoBleuClair from '../../assets/logo_hurflym_sans_fond_blanc.png';
import CheckIcon from '@mui/icons-material/Check';

const HeroSection = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);
  const controls = useAnimation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    
    return () => {
      document.body.style.overflow = '';
    };
  }, []);

  const handleSignUpClick = () => {
    navigate('/abonnements');
  };

  const handleLearnMoreClick = () => {
    navigate('/apropos');
  };

  const fadeInUp = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
  };

  const rayAnimation = {
    hidden: { opacity: 0.5, height: '100%' },
    visible: () => ({
      opacity: [0.5, 1, 0.5],
      height: isMobile ? ['50%', '75%', '50%'] : ['100%', '150%', '100%'],
      transition: {
        duration: Math.random() * 2 + 3,
        delay: Math.random() * 2,
        repeat: Infinity,
        ease: 'easeInOut',
      },
    }),
  };

  const buttonHover = {
    scale: 1.05,
    boxShadow: '0 5px 15px rgba(0, 0, 0, 0.3)',
  };

  const logoAnimation = {
    initial: { rotate: 0, scale: 1 },
    hover: { 
      rotate: [0, 360, 345, 360], 
      scale: [1, 1.1, 1.05, 1.1], 
      transition: { 
        duration: 0.8,
        times: [0, 0.6, 0.8, 1],
        ease: "easeInOut"
      } 
    },
  };

  useEffect(() => {
    if (isHovered) {
      controls.start({
        rotate: [0, 360, 345, 360],
        scale: [1, 1.1, 1.05, 1.1],
        transition: {
          duration: 0.8,
          times: [0, 0.6, 0.8, 1],
          ease: "easeInOut"
        }
      });
    } else {
      controls.start({
        rotate: 0,
        scale: 1,
        transition: {
          duration: 0.4,
          ease: "easeInOut"
        }
      });
    }
  }, [isHovered, controls]);

  return (
    <Box
      sx={{
        minHeight: '100vh',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.palette.primary.main,
        overflow: 'hidden',
        width: '100%',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          background: `radial-gradient(circle at center bottom, ${theme.palette.neutral.third}, transparent 70%)`,
          zIndex: 1,
        }}
      />

      {[...Array(3)].map((_, layerIndex) => (
        <Box
          key={layerIndex}
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-end',
            zIndex: 2,
          }}
        >
          {[...Array(12)].map((_, rayIndex) => (
            <motion.div
              key={rayIndex}
              initial="hidden"
              animate="visible"
              variants={rayAnimation}
              style={{
                position: 'absolute',
                width: isMobile ? '3px' : '5px',
                background: `${theme.palette.neutral.third}`,
                transform: `rotate(${rayIndex * 30 + layerIndex * 10}deg) translateX(-50%)`,
                transformOrigin: 'bottom center',
                bottom: '-20px',
                filter: 'blur(4px)',
              }}
            />
          ))}
        </Box>
      ))}

      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          left: '50%',
          transform: 'translateX(-50%)',
          width: isMobile ? '200px' : '350px',
          height: isMobile ? '100px' : '175px',
          backgroundColor: theme.palette.neutral.third,
          borderRadius: isMobile ? '100px 100px 0 0' : '175px 175px 0 0',
          filter: 'blur(8px)',
          zIndex: 3,
        }}
      />

      <Container
        maxWidth="lg"
        sx={{
          textAlign: 'center',
          zIndex: 5,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          py: 4,
        }}
      >
        <motion.div initial="hidden" animate="visible" variants={fadeInUp} style={{ width: '100%' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: 2,
              marginTop: isMobile ? '20%' : '5%',
            }}
          >
            <motion.div
              animate={controls}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              style={{ 
                height: isMobile ? '100px' : '200px', 
                width: isMobile ? '100px' : '200px', 
                marginRight: '20px',
                position: 'relative' 
              }}
            >
              <motion.img
                src={hurflymLogoBleuClair}
                alt="Logo Hurflym"
                style={{ height: '100%', width: '100%', objectFit: 'contain' }}
              />
            </motion.div>
            <Typography
              variant="h1"
              component="h1"
              sx={{
                fontFamily: 'Montserrat, sans-serif',
                fontWeight: 'bold',
                letterSpacing: isMobile ? '0.5rem' : isTablet ? '1rem' : '1.4rem',
                lineHeight: 1.2,
                color: '#FFFFFF',
                fontSize: isMobile ? '2.5rem' : isTablet ? '5rem' : '7rem',
                marginTop: 0,
                paddingLeft: 0,
                paddingRight: 0,
              }}
            >
              {t('HeroSection.Title')}
            </Typography>
          </Box>
          <Typography
            variant="h6"
            sx={{ mb: 2, color: theme.palette.mode === 'dark' ? theme.palette.neutral.main : '#FFFFFF', fontSize: isMobile ? '1.2rem' : '1.5rem', fontWeight: 'bold', fontFamily: 'Montserrat, sans-serif' }}
          >
            {t('HeroSection.Subtitle')}
          </Typography>
          
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <List sx={{ width: 'auto', mb: 2 }}>
              {[1, 2, 3, 4, 5].map((index) => (
                <ListItem key={index} sx={{ padding: '4px 0' }}>
                  <ListItemIcon sx={{ minWidth: '40px' }}>
                    <CheckIcon sx={{ color: theme.palette.secondary.main, fontSize: isMobile ? '1.5rem' : '2rem', strokeWidth: 3 }} />
                  </ListItemIcon>
                  <ListItemText 
                    primary={t(`HeroSection.Feature${index}`)} 
                    sx={{ 
                      '& .MuiListItemText-primary': { 
                        color: theme.palette.mode === 'dark' ? theme.palette.neutral.main : '#FFFFFF', 
                        fontSize: isMobile ? '0.8rem' : '0.9rem',
                        textAlign: 'justify',
                        fontWeight: 'bold',
                        fontFamily: 'Montserrat, sans-serif'
                      } 
                    }} 
                  />
                </ListItem>
              ))}
            </List>
          </Box>
          
          <Box
            sx={{
              display: 'flex',
              flexDirection: isMobile ? 'column' : 'row',
              justifyContent: 'center',
              gap: '20px',
            }}
          >
            <motion.div
              whileHover={buttonHover}
              style={{ display: 'inline-block', borderRadius: '50px', width: isMobile ? '100%' : '250px' }}
            >
              <Button
                variant="contained"
                size="large"
                fullWidth
                sx={{
                  backgroundColor: theme.palette.secondary.main,
                  color: '#FFFFFF',
                  padding: '12px 24px',
                  letterSpacing: '0.2rem',
                  fontSize: isMobile ? '0.9rem' : '1rem',
                  borderRadius: '50px',
                  border: `2px solid ${theme.palette.secondary.main}`,
                  transition: 'transform 0.2s ease-in-out, background-color 0.2s ease-in-out',
                  '&:hover': {
                    backgroundColor: theme.palette.secondary.dark,
                  },
                  fontFamily: 'Montserrat, sans-serif'
                }}
                onClick={handleLearnMoreClick}
              >
                {t('HeroSection.LearnMoreButton')}
              </Button>
            </motion.div>
            <motion.div
              whileHover={buttonHover}
              style={{ display: 'inline-block', borderRadius: '50px', width: isMobile ? '100%' : '250px' }}
            >
              <Button
                variant="contained"
                size="large"
                fullWidth
                sx={{
                  backgroundColor: '#FFFFFF',
                  color: theme.palette.secondary.main,
                  padding: '12px 24px',
                  letterSpacing: '0.2rem',
                  fontSize: isMobile ? '0.9rem' : '1rem',
                  borderRadius: '50px',
                  border: `2px solid ${theme.palette.secondary.main}`,
                  transition: 'transform 0.2s ease-in-out, background-color 0.2s ease-in-out',
                  '&:hover': {
                    backgroundColor: '#f0f0f0',
                  },
                  fontFamily: 'Montserrat, sans-serif'
                }}
                onClick={handleSignUpClick}
              >
                {t('HeroSection.SignUpButton')}
              </Button>
            </motion.div>
          </Box>
        </motion.div>
      </Container>
    </Box>
  );
};

export default HeroSection;
