// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.column {
  width: 50%;
}

#simulation {
  padding-top: 100px;
}

.slide-in {
  animation: slideIn 0.5s forwards;
}

.slide-out {
  animation: slideOut 0.5s forwards;
}


@keyframes slideIn {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-40%);
  }
}

@keyframes slideOut {
  from {
    transform: translateX(-50%);
  }
  to {
    transform: translateX(-500%);
  }
}


@media (max-width: 1000px) {
    .column-container {
      display: block !important;
    }
    .column {
      width: 100% !important;
      max-width: 100% !important;
      padding-bottom: 50px !important;
      padding-top: 50px !important;
    }
  }
`, "",{"version":3,"sources":["webpack://./src/scenes/personnalisation/style.css"],"names":[],"mappings":";;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE,iCAAiC;AACnC;;;AAGA;EACE;IACE,2BAA2B;EAC7B;EACA;IACE,2BAA2B;EAC7B;AACF;;AAEA;EACE;IACE,2BAA2B;EAC7B;EACA;IACE,4BAA4B;EAC9B;AACF;;;AAGA;IACI;MACE,yBAAyB;IAC3B;IACA;MACE,sBAAsB;MACtB,0BAA0B;MAC1B,+BAA+B;MAC/B,4BAA4B;IAC9B;EACF","sourcesContent":["\n\n.column {\n  width: 50%;\n}\n\n#simulation {\n  padding-top: 100px;\n}\n\n.slide-in {\n  animation: slideIn 0.5s forwards;\n}\n\n.slide-out {\n  animation: slideOut 0.5s forwards;\n}\n\n\n@keyframes slideIn {\n  from {\n    transform: translateX(100%);\n  }\n  to {\n    transform: translateX(-40%);\n  }\n}\n\n@keyframes slideOut {\n  from {\n    transform: translateX(-50%);\n  }\n  to {\n    transform: translateX(-500%);\n  }\n}\n\n\n@media (max-width: 1000px) {\n    .column-container {\n      display: block !important;\n    }\n    .column {\n      width: 100% !important;\n      max-width: 100% !important;\n      padding-bottom: 50px !important;\n      padding-top: 50px !important;\n    }\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
