import React, { useState } from 'react';
import { Container, Typography, TextField, Button } from '@mui/material';
import PrecedentButton from './ForumulaireBoutonPrecedent';
import FormulaireBoutonSoumettre from './FormulaireBoutonSoumettre';


const FormulaireEnvoi = ({ style, categorie_principale, categorie_secondaire, shown, setShown, setCategoriePrincipale, setCategorieSecondaire,dataSignalement, mail_de_destination, detail_equipement, liste_equipement }) => {
  const [commentaire, setCommentaire] = useState('');

  return (
    <Container style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '20px' }}>
      {shown === "E" && (
        <>
          <Typography variant="h5" style={{ marginBottom: '20px', fontFamily: style ? style.police_de_caractere : 'sans-serif', textAlign: 'center' }}>Laissez un commentaire pour plus de détails</Typography>
          <TextField label="Commentaire" multiline rows={4} variant="outlined" value={commentaire} onChange={(e) => setCommentaire(e.target.value)} style={{ marginBottom: '20px', width: '100%', fontFamily: style ? style.police_de_caractere : 'sans-serif' }} />
          <PrecedentButton styleProps={style} setShown={setShown} shown={shown} setCategoriePrincipale={setCategoriePrincipale} setCategorieSecondaire={setCategorieSecondaire} />
          <FormulaireBoutonSoumettre styleProps={style} commentaire={commentaire} dataSignalement={dataSignalement} setShown={setShown} mail_de_destination={mail_de_destination} detail_equipement={detail_equipement} liste_equipement={liste_equipement} />
        </>
      )}
      {shown === "T" && (
        <Typography variant="h4" style={{ marginTop: '20px', textAlign: 'center', fontFamily: style ? style.police_de_caractere : 'sans-serif' }}>
          Signalement envoyé à l'administration.<br></br><br></br> Merci de votre participation !
        </Typography>
      )}
      {shown === "R" && (
        <Typography variant="h4" style={{ marginTop: '20px', textAlign: 'center', fontFamily: style ? style.police_de_caractere : 'sans-serif' }}>
          Problème Lors de l'envoi du signalement à l'administration.
        </Typography>
      )}
    </Container>
  );
};

export default FormulaireEnvoi;

