import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, TextField, List, ListItem, ListItemText, IconButton, TablePagination, Alert, Switch, FormControlLabel, Dialog, DialogTitle, DialogContent, DialogActions, Tooltip } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import InfoIcon from '@mui/icons-material/Info';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useGetAllInfoPortailPartenaireMutation, useModifyIsVisiblePartenaireMutation, useCreatePartnerKeyMutation, useUsePartnerKeyMutation, useGetIsVisiblePartenaireQuery } from 'state/api';
import { useTranslation } from 'react-i18next';

const RenderPortailPartenaires = () => {
  const { t } = useTranslation();
  const [portails, setPortails] = useState([]);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [isVisible, setIsVisible] = useState(false);
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [openResultDialog, setOpenResultDialog] = useState(false);
  const [openUseDialog, setOpenUseDialog] = useState(false);
  const [openUseResultDialog, setOpenUseResultDialog] = useState(false);
  const [expirationDate, setExpirationDate] = useState('');
  const [partnerKeyToUse, setPartnerKeyToUse] = useState('');
  const [createdPartnerKey, setCreatedPartnerKey] = useState('');
  const [useKeyResult, setUseKeyResult] = useState({ success: false, message: '' });

  const [getAllPortails] = useGetAllInfoPortailPartenaireMutation();
  const [modifyIsVisiblePartenaire] = useModifyIsVisiblePartenaireMutation();
  const [createPartnerKey] = useCreatePartnerKeyMutation();
  const [PartnerKey] = useUsePartnerKeyMutation();
  const userSession = useSelector((state) => state.adminAuth);
  const navigate = useNavigate();

  const { data: regleSpecial, isLoading } = useGetIsVisiblePartenaireQuery(userSession.ID_Etablissement);

  const fetchPortails = async () => {
    try {
      const response = await getAllPortails({ ID_Etablissement: userSession.ID_Etablissement });
      setPortails(response.data?.filter(portail => portail.Is_Valid) || []);
    } catch (error) {
      console.error("Erreur lors de la récupération des portails:", error);
    }
  };

  useEffect(() => {
    fetchPortails();
  }, [getAllPortails]);

  useEffect(() => {
    if (!isLoading && regleSpecial) {
      setIsVisible(regleSpecial.Is_Visible_Partenaire);
    }
  }, [regleSpecial, isLoading]);

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleVisibilityToggle = async () => {
    try {
      await modifyIsVisiblePartenaire({ 
        ID_Etablissement: userSession.ID_Etablissement, 
        Is_Visible_Partenaire: !isVisible 
      });
      setIsVisible(!isVisible);
    } catch (error) {
      console.error("Erreur lors de la modification de la visibilité:", error);
    }
  };

  const handleCreatePartnerKey = async () => {
    try {
      const result = await createPartnerKey({
        ID_Etablissement_Source: userSession.ID_Etablissement,
        ExpiresAt: expirationDate
      });
      setCreatedPartnerKey(result.data.partnerKey.Token);
      setOpenCreateDialog(false);
      setOpenResultDialog(true);
      // Rafraîchir la liste des portails
      fetchPortails();
    } catch (error) {
      console.error("Erreur lors de la création de la clé partenaire:", error);
    }
  };

  const handleUsePartnerKey = async () => {
    try {
      const result = await PartnerKey({
        Token: partnerKeyToUse,
        ID_Etablissement_Target: userSession.ID_Etablissement
      });
      if (result.error) {
        throw new Error(result.error.data.message);
      }
      setUseKeyResult({ success: true, message: t('RenderPortailPartenaires.keyUsedSuccessfully') });
      // Rafraîchir la liste des portails
      fetchPortails();
    } catch (error) {
      console.error("Erreur lors de l'utilisation de la clé partenaire:", error);
      setUseKeyResult({ success: false, message: error.message });
    }
    setOpenUseDialog(false);
    setOpenUseResultDialog(true);
  };

  const filteredPortails = portails.filter(portail => 
    portail.etablissementSource.Nom_Etablissement.toLowerCase().includes(search.toLowerCase()) || 
    portail.etablissementSource.Mail_Etablissement.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <Box m="1.5rem 2.5rem">
      <Typography variant="h3" gutterBottom style={{ marginTop: '60px', marginBottom: '20px' }}>
        {t('RenderPortailPartenaires.title')}
      </Typography>
      <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
        <FormControlLabel
          control={
            <Switch
              checked={isVisible}
              onChange={handleVisibilityToggle}
              color="primary"
            />
          }
          label={t('RenderPortailPartenaires.visibilityToggle')}
        />
        <Box>
          <Button variant="contained" color="primary" onClick={() => setOpenCreateDialog(true)} style={{ marginRight: '10px' }}>
            {t('RenderPortailPartenaires.createKey')}
          </Button>
          <Button variant="contained" color="secondary" onClick={() => setOpenUseDialog(true)} style={{ marginRight: '10px' }}>
            {t('RenderPortailPartenaires.useKey')}
          </Button>
          <Tooltip title={t('RenderPortailPartenaires.keyInfoTooltip')} arrow>
            <IconButton color="default">
              <InfoIcon style={{ fontSize: '2rem' }} />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <TextField
        label={t('RenderPortailPartenaires.searchLabel')}
        value={search}
        onChange={handleSearchChange}
        fullWidth
        style={{ marginBottom: '20px' }}
      />
      {filteredPortails.length > 0 ? (
        <List style={{ marginTop: '20px' }}>
          {filteredPortails.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((portail) => (
            <ListItem key={portail.ID_Portail} secondaryAction={
              <IconButton
                edge="end"
                aria-label={t('RenderPortailPartenaires.openLabel')}
                onClick={() => navigate(`/portail_client/${portail.ID_Portail}`)}
              >
                <OpenInNewIcon />
              </IconButton>
            }>
              <ListItemText
                primary={`Portail vers ${portail.etablissementSource.Nom_Etablissement}`}
                secondary={`${t('RenderPortailPartenaires.emailLabel')}: ${portail.etablissementSource.Mail_Etablissement}`}
              />
            </ListItem>
          ))}
        </List>
      ) : (
        <Alert severity="info">{t('RenderPortailPartenaires.noResults')}</Alert>
      )}
      <TablePagination
        component="div"
        count={filteredPortails.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      />

      <Dialog open={openCreateDialog} onClose={() => setOpenCreateDialog(false)}>
        <DialogTitle>{t('RenderPortailPartenaires.createKeyDialog')}</DialogTitle>
        <DialogContent>
          <TextField
            label={t('RenderPortailPartenaires.expirationDate')}
            type="date"
            value={expirationDate}
            onChange={(e) => setExpirationDate(e.target.value)}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            style={{ marginTop: '20px' }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenCreateDialog(false)}>{t('common.cancel')}</Button>
          <Button onClick={handleCreatePartnerKey} color="primary">{t('common.create')}</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openResultDialog} onClose={() => setOpenResultDialog(false)}>
        <DialogTitle>{t('RenderPortailPartenaires.createdKeyDialog')}</DialogTitle>
        <DialogContent>
          <Box display="flex" alignItems="center" mt={2}>
            <IconButton onClick={() => navigator.clipboard.writeText(createdPartnerKey)}>
              <ContentCopyIcon />
            </IconButton>
            <TextField
              label={t('RenderPortailPartenaires.createdKey')}
              value={createdPartnerKey}
              fullWidth
              InputProps={{
                readOnly: true,
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenResultDialog(false)} color="primary">{t('common.close')}</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openUseDialog} onClose={() => setOpenUseDialog(false)}>
        <DialogTitle>{t('RenderPortailPartenaires.useKeyDialog')}</DialogTitle>
        <DialogContent>
          <TextField
            label={t('RenderPortailPartenaires.partnerKey')}
            value={partnerKeyToUse}
            onChange={(e) => setPartnerKeyToUse(e.target.value)}
            fullWidth
            style={{ marginTop: '20px' }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenUseDialog(false)}>{t('common.cancel')}</Button>
          <Button onClick={handleUsePartnerKey} color="primary">{t('common.use')}</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openUseResultDialog} onClose={() => setOpenUseResultDialog(false)}>
        <DialogTitle>{useKeyResult.success ? t('RenderPortailPartenaires.keyUsedSuccessTitle') : t('RenderPortailPartenaires.keyUsedErrorTitle')}</DialogTitle>
        <DialogContent>
          <Typography>{useKeyResult.message}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenUseResultDialog(false)} color="primary">{t('common.close')}</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default RenderPortailPartenaires;
