import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useVerifyemailMutation } from 'state/api'; // Assurez-vous que le chemin est correct
import { useTranslation } from 'react-i18next';

function VerifyEmail() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [verifyEmail, { isSuccess, isError, error }] = useVerifyemailMutation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get('token');
    if (token) {
      verifyEmail({ token });
    }
  }, [location, verifyEmail]);

  useEffect(() => {
    if (isSuccess) {
      navigate('/loginuser'); // Redirigez vers la page d'accueil ou de connexion après la vérification
    }
  }, [isSuccess, navigate]);

  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1>{t('VerifyEmail.title')}</h1>
      {isError && (
        <p>
          {t('VerifyEmail.verificationFailed', {
            error: error?.data?.message || t('unknownError', { ns: 'common' }),
          })}
        </p>
      )}
      {!isError && <p>{t('VerifyEmail.verificationInProgress')}</p>}
    </div>
  );
}

export default VerifyEmail;
