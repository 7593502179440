import React, { useState, useEffect } from 'react';
import { useLoginUserMutation, useGetInfoRegleSpecialMutation } from "state/api";
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setUserAuthenticated } from './../../state/userAuth';
import { useTranslation } from 'react-i18next';
import './login.css';
import googleLogo from './logo_google.png';

// Utiliser la variable d'environnement REACT_APP_BASE_URL pour définir la base de l'URL
const BASE_URL = process.env.REACT_APP_BASE_URL || 'http://localhost:5001';


function LoginUserPerEtablissement() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { ID_Etablissement } = useParams(); // Retrieve ID_Etablissement from URL params
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [login] = useLoginUserMutation();
  const [getInfoRegleSpecial] = useGetInfoRegleSpecialMutation();
  const [authError, setAuthError] = useState(null);
  const [regleSpecial, setRegleSpecial] = useState(null);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const message = query.get('message');

    if (message) {
      setAuthError(decodeURIComponent(message));
    }
  }, [location]);

  useEffect(() => {
    // Fetch special rules for the establishment
    const fetchRegleSpecial = async () => {
      try {
        const response = await getInfoRegleSpecial({ ID_Etablissement }).unwrap();
        setRegleSpecial(response);
        if (response.IsConnexionUnique) {
          if (response.ConnexionUnique === 'Google') {
            window.location.href = `${BASE_URL}/auth/google`;
          } else if (response.ConnexionUnique === 'Hurflym') {
            // Display the form as usual
          }
        }
      } catch (error) {
        setAuthError(t('Login.LoadRulesError'));
      }
    };
    fetchRegleSpecial();
  }, [ID_Etablissement, getInfoRegleSpecial, t]);

  const handleLogin = async (event) => {
    event.preventDefault();
    setAuthError(null);
    try {
      const result = await login({ username, password }).unwrap();
      dispatch(setUserAuthenticated({
        isAuthenticated: true,
        user: result.utilisateur.utilisateurInfo,
        etablissements: result.utilisateur.etablissements,
      }));
      navigate(`/infoutilisateur`);
    } catch (err) {
      if (err.status === 401) {
        setAuthError(t('Login.AuthError'));
      } else {
        setAuthError(t('Login.AuthErrorGeneral'));
      }
    }
  };

  const handleGoogleLogin = () => {
    window.location.href = `${BASE_URL}/auth/google`;
  };

  // Handle Google callback response
  const handleGoogleCallbackResponse = () => {
    const query = new URLSearchParams(location.search);
    const data = query.get('utilisateur');

    if (data) {
      try {
        const userData = JSON.parse(decodeURIComponent(data));
        dispatch(setUserAuthenticated({
          isAuthenticated: true,
          user: userData.utilisateurInfo,
          etablissements: userData.etablissements,
        }));
        navigate('/listeetablissement');
      } catch (error) {
        console.error('Erreur lors du traitement des données de la réponse Google:', error);
        setAuthError(t('Login.GoogleAuthError'));
      }
    }
  };

  useEffect(() => {
    handleGoogleCallbackResponse();
  }, [location]);

  // Render the appropriate form based on the fetched rules
  if (regleSpecial && regleSpecial.IsConnexionUnique && regleSpecial.ConnexionUnique !== 'Hurflym') {
    return null; // Page will redirect, so no need to render anything
  }

  return (
    <div className="login-container">
      <div className="login-box">
        {regleSpecial?.IsConnexionUnique && regleSpecial.ConnexionUnique === 'Hurflym' && (
          <form onSubmit={handleLogin}>
            {authError && <p className="login-error">{authError}</p>}
            <label htmlFor="username">{t('LoginUser.UsernameLabel')}</label>
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder={t('LoginUser.UsernameLabel')}
              className="login-input"
              id="username"
            />
            <label htmlFor="password">{t('LoginUser.PasswordLabel')}</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder={t('LoginUser.PasswordLabel')}
              className="login-input"
              id="password"
            />
            <button type="submit" className="login-button">{t('LoginUser.LoginButton')}</button>
          </form>
        )}

        {regleSpecial && !regleSpecial.IsConnexionUnique && (
          <>
            <form onSubmit={handleLogin}>
              {authError && <p className="login-error">{authError}</p>}
              <label htmlFor="username">{t('LoginUser.UsernameLabel')}</label>
              <input
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                placeholder={t('LoginUser.UsernameLabel')}
                className="login-input"
                id="username"
              />
              <label htmlFor="password">{t('LoginUser.PasswordLabel')}</label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder={t('LoginUser.PasswordLabel')}
                className="login-input"
                id="password"
              />
              <button type="submit" className="login-button">{t('LoginUser.LoginButton')}</button>
            </form>

            <div className="separator">
              <hr />
              <p>{t('LoginUser.OrLoginWith')}</p>
            </div>

            <button onClick={handleGoogleLogin} className="google-login-button">
              <img src={googleLogo} alt="Google logo" width="20" height="20" />
              {t('LoginUser.GoogleLoginButton')}
            </button>
          </>
        )}
      </div>
    </div>
  );
}

export default LoginUserPerEtablissement;
