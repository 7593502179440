import React, {useState, useEffect} from 'react';
import { Container, Typography } from '@mui/material';
import CategorieButton from './FormulaireBouton'; // Assurez-vous que le chemin d'import est correct
import PrecedentButton from './ForumulaireBoutonPrecedent';

const FormulaireCategoriePrincipale = ({ style, categorie_principale, categorie_secondaire, shown, setShown,setCategoriePrincipale, setCategorieSecondaire, liste_categorie_secondaire, liste_complete_categorie }) => {
  const [categoriesSecondaires, setCategoriesSecondaires] = useState([]);

  useEffect(() => {

    // Trouver la catégorie principale dans liste_complete_categorie
    const categoriePrincipaleObjet = liste_complete_categorie.find(cat => cat.ID_Categorie === categorie_principale);
  
    if (categoriePrincipaleObjet && categoriePrincipaleObjet.enfants) {
      // Extraire les catégories secondaires correspondant à la catégorie principale actuelle
      const categoriesSecondairesFiltrees = categoriePrincipaleObjet.enfants.filter(enfant => 
        liste_categorie_secondaire.find(sec => sec.ID_Categorie_Principale === categorie_principale)?.Categories_Secondaires.includes(enfant.ID_Categorie)
      ).map(categorieSecondaire => ({
        ID_Categorie: categorieSecondaire.ID_Categorie,
        Nom_Categorie: categorieSecondaire.Nom_Categorie
      }));
  
      setCategoriesSecondaires(categoriesSecondairesFiltrees);
    } else {
      setCategoriesSecondaires([]);
    }
  }, [liste_complete_categorie, liste_categorie_secondaire, categorie_principale]);

  const handleCategoryPrincipaleClick = (category) => {
    setShown("S"); // Supposons que "S" indique que vous êtes passé à la catégorie secondaire
    setCategorieSecondaire(category.ID_Categorie);
  };

  return (
    <Container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
      {shown === "P" && (
        <>
          <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
            {categoriesSecondaires.map((category, index) => (
              <CategorieButton key={index} styleProps={style}  categorie={category.Nom_Categorie} onClick={() => handleCategoryPrincipaleClick(category)} srcImage={category.ID_Categorie} shown={shown} />
            ))}
            <PrecedentButton styleProps={style} shown={shown} setShown={setShown} setCategoriePrincipale={setCategoriePrincipale} />
          </div>
        </>
      )}
      
    </Container>
  );
};

export default FormulaireCategoriePrincipale;

