import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography, Button, TextField, Dialog, DialogTitle, DialogContent, DialogActions, Grid, List, ListItem, ListItemText, IconButton, TablePagination, Select, MenuItem, Chip } from '@mui/material';
import { useAddFeedbackMutation, useDeleteFeedbackMutation, useModifyFeedbackMutation, useGetAllFeedbackMutation, useGetAllSectionMutation, useGetAllImageFeedbackMutation } from 'state/api';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import ErrorMessage from './ErrorMessage';
import ValidationMessage from './ValidationMessage';
import InfoMessage from './InfoMessage';
import ImageFeedbackCarousel from './ImageCarouselFeedback';

const RenderFeedback = () => {
  const { t } = useTranslation(); // Initialize the translation hook
  const [open, setOpen] = useState(false);
  const [nomFeedback, setNomFeedback] = useState('');
  const [descriptionFeedback, setDescriptionFeedback] = useState('');
  const [sections, setSections] = useState([]);
  const [selectedSections, setSelectedSections] = useState([]);
  const [feedbacks, setFeedbacks] = useState([]);
  const [editFeedback, setEditFeedback] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [feedbackToDelete, setFeedbackToDelete] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [isLoading, setIsLoading] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [messageText, setMessageText] = useState('');
  const [nomError, setNomError] = useState('');
  const [sectionError, setSectionError] = useState('');
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);

  const theme = useTheme();
  const buttonColor = theme.palette.primary.main;
  const textColor = theme.palette.mode === 'dark' ? theme.palette.primary.light : theme.palette.secondary.main;

  const userSession = useSelector((state) => state.adminAuth);

  const [getAllFeedback] = useGetAllFeedbackMutation();
  const [addFeedback] = useAddFeedbackMutation();
  const [modifyFeedback] = useModifyFeedbackMutation();
  const [deleteFeedback] = useDeleteFeedbackMutation();
  const [getAllSection] = useGetAllSectionMutation();
  const [getAllImageFeedback] = useGetAllImageFeedbackMutation();

  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [validation, setValidation] = useState('');

  const popUpError = (message) => {
    setError(message);
  };

  const popUpInfo = (message) => {
    setMessage(message);
  };

  const popUpValidation = (message) => {
    setValidation(message);
  };

  const showTempMessage = (text) => {
    setMessageText(text);
    setShowMessage(true);
    setTimeout(() => {
      setShowMessage(false);
    }, 5000);
  };

  const fetchFeedbacks = async () => {
    try {
      setIsLoading(true);
      const response = await getAllFeedback({ ID_Etablissement: userSession.ID_Etablissement });
      if (response.data) {
        setFeedbacks(response.data);
      }
    } catch (error) {
      if (error.originalStatus === 401) {
        alert('Votre session a expiré. Veuillez vous reconnecter.');
        window.location = '/loginuser';
      }
      popUpError(t('RenderFeedback.error_fetch_feedbacks'));
    } finally {
      setIsLoading(false);
    }
  };

  const fetchSections = async () => {
    try {
      const response = await getAllSection({ ID_Etablissement: userSession.ID_Etablissement });
      if (response.data) {
        setSections(response.data);
      }
    } catch (error) {
      popUpError(t('RenderFeedback.error_fetch_sections'));
    }
  };

  const fetchImages = async () => {
    try {
      const response = await getAllImageFeedback({ ID_Etablissement: userSession.ID_Etablissement });
      if (response.data) {
        const processedImages = response.data.images.map(image => image.replace(/^images\/feedback\//, ''));
        setImages(processedImages);
      }
    } catch (error) {
      popUpError(t('RenderFeedback.error_fetch_images'));
    }
  };

  useEffect(() => {
    fetchFeedbacks();
    fetchSections();
    fetchImages();
  }, [getAllFeedback, getAllSection, getAllImageFeedback, userSession.ID_Etablissement]);

  const handleOpen = (feedback = null) => {
    if (feedback) {
      setEditFeedback(feedback);
      setNomFeedback(feedback.Nom_Feedback);
      setDescriptionFeedback(feedback.Description_Feedback);
      setSelectedSections(feedback.Composition_de_section.map(id => sections.find(section => section.ID_Section === id)));
      setSelectedImage(feedback.Lien_Image);
    } else {
      setEditFeedback(null);
      setNomFeedback('');
      setDescriptionFeedback('');
      setSelectedSections([]);
      setSelectedImage(null);
    }
    setNomError('');
    setSectionError('');
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEditFeedback(null);
  };

  const handleSectionChange = (event) => {
    const value = event.target.value;
    const section = sections.find((section) => section.ID_Section === value);
    if (section && !selectedSections.some((s) => s.ID_Section === value)) {
      setSelectedSections([...selectedSections, section]);
    }
  };

  const handleRemoveSection = (sectionToRemove) => {
    setSelectedSections(selectedSections.filter(section => section.ID_Section !== sectionToRemove.ID_Section));
  };

  const handleImageSelect = (image) => {
    setSelectedImage(image);
  };

  const validateFields = () => {
    let isValid = true;
    if (!nomFeedback) {
      setNomError(t('RenderFeedback.name_required'));
      isValid = false;
    } else {
      setNomError('');
    }

    if (selectedSections.length === 0) {
      setSectionError(t('RenderFeedback.section_required'));
      isValid = false;
    } else {
      setSectionError('');
    }

    return isValid;
  };

  const handleSubmit = async () => {
    if (!validateFields()) {
      return;
    }
    try {
      setIsLoading(true);
      const feedbackData = {
        Nom_Feedback: nomFeedback,
        Description_Feedback: descriptionFeedback,
        Composition_de_section: selectedSections.map(section => section.ID_Section),
        ID_Etablissement: userSession.ID_Etablissement,
        Lien_Image: selectedImage
      };

      const response = editFeedback
        ? await modifyFeedback({ ID_Feedback: editFeedback.ID_Feedback, ...feedbackData })
        : await addFeedback(feedbackData);

      if (response.error) {
        popUpError(editFeedback ? t('RenderFeedback.error_modify_feedback') : t('RenderFeedback.error_add_feedback'));
      } else {
        handleClose();
        fetchFeedbacks();
        popUpValidation(editFeedback ? t('RenderFeedback.success_modify_feedback') : t('RenderFeedback.success_add_feedback'));
      }
    } catch (err) {
      if (err.originalStatus === 401) {
        alert(t('RenderFeedback.session_expired'));
        window.location = '/loginuser';
      }
      popUpError(editFeedback ? t('RenderFeedback.error_modify_feedback') : t('RenderFeedback.error_add_feedback'));
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteFeedback = async () => {
    try {
      if (feedbackToDelete) {
        const { ID_Feedback } = feedbackToDelete;
        const response = await deleteFeedback({
          ID_Feedback,
        });
        if (response.error) {
          popUpError(t('RenderFeedback.error_delete_feedback'));
        } else {
          setFeedbacks(prevFeedbacks => prevFeedbacks.filter(feedback => feedback.ID_Feedback !== ID_Feedback));
          fetchFeedbacks();
          popUpValidation(t('RenderFeedback.success_delete_feedback'));
          setOpenDeleteDialog(false);
        }
      }
    } catch (err) {
      if (err.originalStatus === 401) {
        alert(t('RenderFeedback.session_expired'));
        window.location = '/loginuser';
      }
      popUpError(t('RenderFeedback.error_delete_feedback'));
    }
  };

  const handleDeleteDialogOpen = (feedback) => {
    setFeedbackToDelete(feedback);
    setOpenDeleteDialog(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box m="1.5rem 2.5rem">
      <Typography variant="h3" gutterBottom style={{ marginTop: '60px', marginBottom: '20px' }}>
        {t('RenderFeedback.title')}
      </Typography>
      <ErrorMessage message={error} setError={setError} />
      <InfoMessage message={message} setInfo={setMessage} />
      <ValidationMessage message={validation} setValidation={setValidation} />
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={5} style={{ textAlign: 'left' }}>
          <Button variant="contained" color="primary" onClick={() => handleOpen()} style={{ height: '55px' }}>
            {t('RenderFeedback.create_new_feedback')}
          </Button>
        </Grid>
      </Grid>
      
      <List style={{ marginTop: '32px' }}>
        {feedbacks.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((feedback, index) => (
          <ListItem key={index} secondaryAction={
            <>
              <IconButton edge="end" aria-label="edit" onClick={() => handleOpen(feedback)}>
                <EditIcon />
              </IconButton>
              <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteDialogOpen(feedback)}>
                <DeleteIcon />
              </IconButton>
            </>
          }>
            <ListItemText 
              primary={feedback.Nom_Feedback} 
              secondary={
                <div>
                  {feedback.Composition_de_section.map(id => (
                    <Chip 
                      key={id}
                      label={sections.find(section => section.ID_Section === id)?.Nom_Section || id} 
                      size="small" 
                      style={{ marginRight: 4, marginBottom: 2 }} 
                    />
                  ))}
                </div>
              } 
            />
          </ListItem>
        ))}
      </List>

      <TablePagination
        component="div"
        count={feedbacks.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 25, 100]}
      />

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{editFeedback ? t('RenderFeedback.edit_feedback') : t('RenderFeedback.add_new_feedback')}</DialogTitle>
        <DialogContent>
          <form>
            <TextField 
              label={t('RenderFeedback.feedback_name')} 
              value={nomFeedback} 
              onChange={(e) => setNomFeedback(e.target.value)} 
              fullWidth 
              margin="normal" 
              error={!!nomError}
              helperText={nomError}
            />
            <TextField 
              label={t('RenderFeedback.feedback_description')} 
              value={descriptionFeedback} 
              onChange={(e) => setDescriptionFeedback(e.target.value)} 
              fullWidth 
              margin="normal" 
            />
            <Box display="flex" flexDirection="column" alignItems="center" mb={2}>
              {selectedSections.map((section) => (
                <Chip
                  key={section.ID_Section}
                  label={section.Nom_Section}
                  onDelete={() => handleRemoveSection(section)}
                  color="primary"
                  style={{ margin: 2 }}
                />
              ))}
              {sectionError && (
                <Typography color="error" variant="caption">
                  {sectionError}
                </Typography>
              )}
            </Box>
            <Select
              value=""
              onChange={handleSectionChange}
              fullWidth
              displayEmpty
            >
              <MenuItem value="" disabled>
                {t('RenderFeedback.select_section')}
              </MenuItem>
              {sections.map((section) => (
                <MenuItem key={section.ID_Section} value={section.ID_Section}>
                  {section.Nom_Section}
                </MenuItem>
              ))}
            </Select>
            <ImageFeedbackCarousel 
              images={images} 
              selectedImage={selectedImage} 
              onImageSelect={handleImageSelect} 
            />
            {showMessage && <div style={{ textAlign: 'center', fontSize: '1em', marginTop: '10px' }}>{messageText}</div>}
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} style={{ backgroundColor: buttonColor, color: textColor }}>{t('RenderFeedback.cancel')}</Button>
          <Button onClick={handleSubmit} style={{ backgroundColor: buttonColor, color: textColor }}>{editFeedback ? t('RenderFeedback.save') : t('RenderFeedback.submit')}</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)}>
        <DialogTitle>{t('RenderFeedback.delete_feedback')}</DialogTitle>
        <DialogContent>
          <Typography>{t('RenderFeedback.confirm_delete_feedback')}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)} style={{ backgroundColor: buttonColor, color: textColor }}>{t('RenderFeedback.cancel')}</Button>
          <Button onClick={handleDeleteFeedback} style={{ backgroundColor: buttonColor, color: textColor }}>{t('RenderFeedback.delete_feedback')}</Button>
        </DialogActions>
      </Dialog>

      {isLoading && <p>{t('RenderFeedback.loading')}</p>}
    </Box>
  );
};

export default RenderFeedback;
