import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Typography, TextField, CircularProgress, MenuItem, Select, InputLabel, FormControl, Button, IconButton } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import styled from '@emotion/styled';
import { useSelector } from 'react-redux';
import { usePublicGetInfoEquipementParticulierMutation, usePublicAddIncidentMutation, usePublicGetInfoRegleSpecialMutation } from 'state/api';
import { useTranslation } from 'react-i18next';

// Utilisation des variables d'environnement pour les URLs
const BASE_URL = process.env.REACT_APP_BASE_URL || 'http://localhost:5001';

// Styled components for custom styling
const FormContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-color: ${(props) => props.couleur_de_fond || '#FFFFFF'};
  color: ${(props) => props.couleur_ecriture || '#000000'};
  font-family: ${(props) => props.police_de_caractere}, sans-serif;
  padding: 0px;
  overflow-y: auto;
`;

const ContentContainer = styled('div')`
  background-color: ${(props) => props.couleur_de_fond || '#F5F5F5'};
  padding: 16px;
  border-radius: 10px;
  
  width: 100%;
  max-width: 700px;
  max-height: 100vh;
  overflow-y: auto;

  @media (max-width: 767px) {
    max-width: 100%;
    max-height: 100%;
    border-radius: 0;
    padding: 2.5%;
  }
`;

const LogoContainer = styled('div')`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 32px;

  img {
    max-height: 60px;
  }
`;

const InputField = styled(TextField)`
  width: 100%;
  margin-bottom: 16px;

  @media (max-width: 767px) {
    margin-left: 2.5%;
    width: 95%;
  }
`;

const FileInputField = styled(TextField)`
  width: 100%;
  margin-bottom: 16px;

  @media (max-width: 767px) {
    margin-left: 2.5%;
    width: 95%;
  }
`;

const SubmitButton = styled(Button)`
  margin-top: 16px;
  background-color: ${(props) => props.couleur_bouton};
  color: ${(props) => props.couleur_bouton_ecriture};
  transition: transform 0.2s;
  &:hover {
    transform: scale(1.05);
    background-color: ${(props) => props.couleur_bouton};
    color: ${(props) => props.couleur_bouton_ecriture};
    transform: translateX(-50%);
  }
  align-self: center;
  margin-left: 50%;
  transform: translateX(-50%);
`;

const FormControlStyled = styled(FormControl)`
  width: 100%;
  margin-bottom: 16px;

  @media (max-width: 767px) {
    margin-left: 2.5%;
    width: 95%;
  }
`;

const AccessDeniedMessage = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: #ffffff;

  .access-denied-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ff0000;
    padding: 20px;
    border-radius: 10px;
    color: #ffffff;
    font-size: 2rem;
    position: relative;

    .icon {
      font-size: 4rem;
      margin-bottom: 16px;
    }

    .home-button {
      position: absolute;
      top: 10px;
      left: 10px;
    }
  }
`;

const EquipementParticulierForm = () => {
  const { t } = useTranslation();
  const { ID_Ep_IT, ID_Ep_SG, ID_Etablissement } = useParams();
  const navigate = useNavigate();
  const [equipement, setEquipement] = useState(null);
  const [loading, setLoading] = useState(true);
  const [personnalisation, setPersonnalisation] = useState({});
  const [formData, setFormData] = useState({
    Description: '',
    Urgence: 4,
    Email: '',
    EmailAutre: '',
    EmailCC: '',
    Fichier: null,
    DemandePour: t('for_me')
  });
  const [errors, setErrors] = useState({});
  const [fetchEquipement] = usePublicGetInfoEquipementParticulierMutation();
  const [publicAddIncident] = usePublicAddIncidentMutation();
  const [getInfoRegleSpecial] = usePublicGetInfoRegleSpecialMutation();
  const [errorMessage, setErrorMessage] = useState('');
  const [accessDenied, setAccessDenied] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const redirecting = useRef(false);

  const userSession = useSelector((state) => state.userAuth);

  const logoUrl = ID_Etablissement
    ? `${BASE_URL}/images/image/${ID_Etablissement}`
    : '';

  useEffect(() => {
    const checkRegleSpecial = async () => {
      try {
        const regleSpecial = await getInfoRegleSpecial({ ID_Etablissement }).unwrap();

        if (!userSession.isAuthenticated || !userSession.user || !userSession.user.email) {
          const redirectPath = encodeURIComponent(window.location.pathname);
          if (!redirecting.current) {
            redirecting.current = true;
            navigate(`/loginclient?redirectTo=${redirectPath}`);
          }
          return;
        }

        if (regleSpecial.ConnexionPrive) {
          const userDomain = userSession.user.email.split('@').pop();
          const isDomainAllowed = regleSpecial.DomainePrive.some(domain => userDomain.endsWith(domain.trim()));

          if (!isDomainAllowed) {
            setAccessDenied(true);
            return;
          }
        }

        fetchEquipementData();
      } catch (error) {
        console.error(t('error_special_rule_check'), error);
        setErrorMessage(t('error_special_rule_check'));
      }
    };

    const fetchEquipementData = async () => {
      try {
        const idToUse = ID_Ep_IT !== "0" ? { ID_Ep_IT } : { ID_Ep_SG };
        const response = await fetchEquipement({ ...idToUse, ID_Etablissement });
        if (response.data) {
          setEquipement(response.data.equipementparticulier);
          setPersonnalisation(response.data.personnalisation);
          if (userSession.user.email) {
            setFormData((prevFormData) => ({
              ...prevFormData,
              Email: userSession.user.email,
            }));
          }
        }
      } catch (error) {
        console.error(t('error_fetching_equipment'), error);
        setErrorMessage(t('error_fetching_equipment'));
      } finally {
        setLoading(false);
      }
    };

    checkRegleSpecial();
  }, [ID_Ep_IT, ID_Ep_SG, ID_Etablissement, fetchEquipement, getInfoRegleSpecial, userSession, navigate, t]);

  const handleChange = (event) => {
    const { name, value, files } = event.target;
    setFormData({
      ...formData,
      [name]: files ? files[0] : value
    });
  };

  const handleUrgenceChange = (event) => {
    setFormData({
      ...formData,
      Urgence: event.target.value
    });
  };

  const handleDemandePourChange = (event) => {
    setFormData({
      ...formData,
      DemandePour: event.target.value,
      Email: '',
      EmailAutre: ''
    });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.Description) newErrors.Description = t('description_required');
    if (formData.DemandePour === t('for_me') && !formData.Email) newErrors.Email = t('my_email_required');
    if (formData.DemandePour === t('for_another') && !formData.EmailAutre) newErrors.EmailAutre = t('other_email_required');
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      const isIT = ID_Ep_IT !== "0";
      const type = isIT ? 'IT' : 'SG';

      const formDataToSend = new FormData();
      formDataToSend.append('type', type);
      formDataToSend.append('ID_Etablissement', ID_Etablissement);
      formDataToSend.append('ID_Noeud', equipement.ID_Noeud);
      formDataToSend.append('ID_Ep_IT', isIT ? ID_Ep_IT : '');
      formDataToSend.append('ID_Ep_SG', !isIT ? ID_Ep_SG : '');
      formDataToSend.append('Description', formData.Description);
      formDataToSend.append('Niveau_urgence_intervention', formData.Urgence);
      formDataToSend.append('Email_signaleur', formData.DemandePour === t('for_me') ? formData.Email : '');
      formDataToSend.append('Adresse_mail_autre', formData.DemandePour === t('for_another') ? formData.EmailAutre : '');
      formDataToSend.append('Email_cc', formData.EmailCC);
      if (formData.Fichier) {
        formDataToSend.append('Attachment', formData.Fichier, formData.Fichier.name);
      }
      formDataToSend.append('ID_Utilisateur', userSession.user.ID_Utilisateur);

      try {
        await publicAddIncident(formDataToSend);
        setSubmitted(true);
      } catch (error) {
        console.error(t('incident_submission_error'), error);
      }
    }
  };

  const handleHomeClick = () => {
    navigate('/infoutilisateur');
  };

  if (accessDenied) {
    return (
      <AccessDeniedMessage>
        <Button
          variant="contained"
          color="primary"
          startIcon={<HomeIcon />}
          onClick={handleHomeClick}
          className="home-button"
          sx={{ position: 'fixed', top: 10, left: 10 }}
        >
          {t('home')}
        </Button>
        <div className="access-denied-box">
          <div className="icon">⚠️</div>
          {t('access_denied')}
        </div>
      </AccessDeniedMessage>
    );
  }

  return (
    <FormContainer {...personnalisation}>
      <ContentContainer {...personnalisation}>
        {logoUrl && (
          <LogoContainer>
            <img src={logoUrl} alt="Logo" />
          </LogoContainer>
        )}
        {loading ? (
          <CircularProgress />
        ) : equipement ? (
          submitted ? (
            <Typography
              variant="h6"
              style={{
                color: personnalisation.couleur_ecriture,
                fontFamily: personnalisation.police_de_caractere,
                textAlign: 'center'
              }}
            >
              {t('incident_submission_success')}
            </Typography>

          ) : (
            <>
              <Typography
                variant="h5"
                style={{
                  color: personnalisation.couleur_ecriture,
                  fontFamily: personnalisation.police_de_caractere,
                  fontWeight: 'bold',
                  marginBottom: '16px',
                }}
              >
                {t('report_incident_for')} {equipement.Nom_Ep}
              </Typography>
              <InputField
                label={t('problem_description')}
                variant="outlined"
                multiline
                rows={4}
                name="Description"
                value={formData.Description}
                onChange={handleChange}
                couleur_de_fond={personnalisation.couleur_de_fond}
                couleur_ecriture={personnalisation.couleur_ecriture}
                error={!!errors.Description}
                helperText={errors.Description}
              />
              <FormControlStyled variant="outlined">
                <InputLabel>{t('urgency_level')}</InputLabel>
                <Select
                  value={formData.Urgence}
                  onChange={handleUrgenceChange}
                  label={t('urgency_level')}
                  name="Urgence"
                  couleur_de_fond={personnalisation.couleur_de_fond}
                  couleur_ecriture={personnalisation.couleur_ecriture}
                >
                  <MenuItem value={4}>{t('urgency_4')}</MenuItem>
                  <MenuItem value={3}>{t('urgency_3')}</MenuItem>
                  <MenuItem value={2}>{t('urgency_2')}</MenuItem>
                  <MenuItem value={1}>{t('urgency_1')}</MenuItem>
                </Select>
              </FormControlStyled>
              <FormControlStyled variant="outlined">
                <InputLabel>{t('request_for')}</InputLabel>
                <Select
                  value={formData.DemandePour}
                  onChange={handleDemandePourChange}
                  label={t('request_for')}
                  name="DemandePour"
                  couleur_de_fond={personnalisation.couleur_de_fond}
                  couleur_ecriture={personnalisation.couleur_ecriture}
                >
                  <MenuItem value={t('for_me')}>{t('for_me')}</MenuItem>
                  <MenuItem value={t('for_another')}>{t('for_another')}</MenuItem>
                </Select>
              </FormControlStyled>
              {formData.DemandePour === t('for_me') ? (
                <InputField
                  label={t('my_email')}
                  variant="outlined"
                  name="Email"
                  value={formData.Email}
                  onChange={handleChange}
                  couleur_de_fond={personnalisation.couleur_de_fond}
                  couleur_ecriture={personnalisation.couleur_ecriture}
                  error={!!errors.Email}
                  helperText={errors.Email}
                />
              ) : (
                <InputField
                  label={t('other_email')}
                  variant="outlined"
                  name="EmailAutre"
                  value={formData.EmailAutre}
                  onChange={handleChange}
                  couleur_de_fond={personnalisation.couleur_de_fond}
                  couleur_ecriture={personnalisation.couleur_ecriture}
                  error={!!errors.EmailAutre}
                  helperText={errors.EmailAutre}
                />
              )}
              <InputField
                label={t('cc_emails')}
                variant="outlined"
                name="EmailCC"
                value={formData.EmailCC}
                onChange={handleChange}
                couleur_de_fond={personnalisation.couleur_de_fond}
                couleur_ecriture={personnalisation.couleur_ecriture}
                helperText={t('cc_emails_helper')}
              />
              <FileInputField
                type="file"
                variant="outlined"
                name="Fichier"
                onChange={handleChange}
                couleur_de_fond={personnalisation.couleur_de_fond}
                couleur_ecriture={personnalisation.couleur_ecriture}
              />
              <SubmitButton
                onClick={handleSubmit}
                couleur_bouton={personnalisation.couleur_bouton}
                couleur_bouton_ecriture={personnalisation.couleur_bouton_ecriture}
              >
                {t('submit')}
              </SubmitButton>
            </>
          )
        ) : (
          <Typography variant="body1" color="error">
            {t('equipment_not_found')}
          </Typography>
        )}
      </ContentContainer>
    </FormContainer>
  );
};

export default EquipementParticulierForm;
