import React from "react";
import { Box, MenuItem, Select, InputLabel, FormControl } from "@mui/material";
import { useTranslation } from 'react-i18next';

const NavbarTicketPartenaires = ({ filters, onFiltersChange }) => {
  const { t } = useTranslation();

  const handleChange = (event) => {
    const { name, value } = event.target;
    onFiltersChange({ [name]: value });
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", marginBottom: "20px" }}>
      <Box sx={{ display: "flex", justifyContent: "center", flexWrap: "wrap" }}>
        <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }}>
          <InputLabel>{t('NavbarTicketPartenaires.equipementTypeLabel')}</InputLabel>
          <Select
            name="equipementType"
            value={filters.equipementType}
            onChange={handleChange}
            label={t('NavbarTicketPartenaires.equipementTypeLabel')}
          >
            <MenuItem value="all">{t('NavbarTicketPartenaires.equipementAll')}</MenuItem>
            <MenuItem value="equipement">{t('NavbarTicketPartenaires.equipement')}</MenuItem>
            <MenuItem value="equipement_particulier">{t('NavbarTicketPartenaires.equipementParticulier')}</MenuItem>
            <MenuItem value="service">{t('NavbarTicketPartenaires.service')}</MenuItem>
          </Select>
        </FormControl>

        <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }}>
          <InputLabel>{t('NavbarTicketPartenaires.dateOrderLabel')}</InputLabel>
          <Select
            name="dateOrder"
            value={filters.dateOrder}
            onChange={handleChange}
            label={t('NavbarTicketPartenaires.dateOrderLabel')}
          >
            <MenuItem value="none">{t('NavbarTicketPartenaires.dateOrderNone')}</MenuItem>
            <MenuItem value="asc">{t('NavbarTicketPartenaires.dateOrderAsc')}</MenuItem>
            <MenuItem value="desc">{t('NavbarTicketPartenaires.dateOrderDesc')}</MenuItem>
          </Select>
        </FormControl>

        <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }}>
          <InputLabel>{t('NavbarTicketPartenaires.etatTicketLabel')}</InputLabel>
          <Select
            name="etatTicket"
            value={filters.etatTicket}
            onChange={handleChange}
            label={t('NavbarTicketPartenaires.etatTicketLabel')}
          >
            <MenuItem value="default">{t('NavbarTicketPartenaires.etatTicketDefault')}</MenuItem>
            <MenuItem value="creation">{t('NavbarTicketPartenaires.etatTicketCreation')}</MenuItem>
            <MenuItem value="assigne">{t('NavbarTicketPartenaires.etatTicketAssigne')}</MenuItem>
            <MenuItem value="en cours">{t('NavbarTicketPartenaires.etatTicketEnCours')}</MenuItem>
            <MenuItem value="termine">{t('NavbarTicketPartenaires.etatTicketTermine')}</MenuItem>
            <MenuItem value="cloture">{t('NavbarTicketPartenaires.etatTicketCloture')}</MenuItem>
            <MenuItem value="abus">{t('NavbarTicketPartenaires.etatTicketAbus')}</MenuItem>
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
};

export default NavbarTicketPartenaires;
