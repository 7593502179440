import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAuthenticated: false,
  user: null,
  etablissements: [],
  loginTime: null,
};

const userAuthSlice = createSlice({
  name: "userAuth",
  initialState,
  reducers: {
    setUserAuthenticated: (state, action) => {
      state.isAuthenticated = action.payload.isAuthenticated;
      state.user = action.payload.user;
      state.etablissements = action.payload.etablissements;
      state.loginTime = Date.now(); // Set login time to current time
    },
    verifyAuthenticated: (state, action) => {
      state.isAuthenticated = action.payload.isAuthenticated;
    },
    setUserInfo: (state, action) => {
      state.etablissements = action.payload.etablissements;
    },
    updateSessionData: (state, action) => {
      state.user = action.payload.user;
      state.etablissements = action.payload.etablissements;
    },
    userLogout: (state) => {
      state.isAuthenticated = false;
      state.user = null;
      state.etablissements = [];
      state.loginTime = null;
    },
  },
});

export const { setUserAuthenticated, verifyAuthenticated, setUserInfo, updateSessionData, userLogout } = userAuthSlice.actions;
export default userAuthSlice.reducer;
