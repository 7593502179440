import React, { useState } from 'react';
import QRCode from 'qrcode.react';
import { Box, Button } from '@mui/material';
import { DownloadOutlined } from "@mui/icons-material";
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';


const QRCodeInformations = ({ ID_Ep_IT, ID_Ep_SG, ID_Etablissement, ID_Noeud, signalement }) => {
  const { t } = useTranslation();
  const [isCopied, setIsCopied] = useState(false);
  const theme = useTheme();

  // Utilisation de la variable d'environnement pour générer le lien
  const link = signalement 
    ? `${process.env.REACT_APP_FRONT_URL}/client/signalement/${ID_Noeud}/${ID_Etablissement}` 
    : `${process.env.REACT_APP_FRONT_URL}/client/equipementparticulier/${ID_Ep_IT}/${ID_Ep_SG}/${ID_Etablissement}`;

  const handleCopyLink = async () => {
    try {
      await navigator.clipboard.writeText(link);
      setIsCopied(true);     
    } catch (error) {
      console.error(t('QRCodeInformations.copy_error'), error);
    }
  };

  const handleDownloadQRCode = () => {
    const canvas = document.querySelector("canvas");
    const imageUrl = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");

    const a = document.createElement("a");
    a.href = imageUrl;
    a.download = signalement ? `qrcode_signalement_${ID_Noeud}.png` : `qrcode_equipement_${ID_Ep_IT}_${ID_Ep_SG}.png`;
    a.click();
  };

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        textAlign="center"
        p={2}
        bgcolor="white"
        borderRadius="12px"
        boxShadow="0px 4px 8px rgba(0, 0, 0, 0.1)"
      >
        <QRCode value={link} size={256} />
      </Box>
      <Box mt={2} textAlign="center">
        <Button
          onClick={handleDownloadQRCode}
          sx={{
            backgroundColor: theme.palette.secondary.light,
            color: theme.palette.background.alt,
            fontSize: "14px",
            fontWeight: "bold",
            padding: "10px 20px",
          }}
        >
          <DownloadOutlined sx={{ mr: "10px" }} />
          {t('QRCodeInformations.download_button')}
        </Button>
      </Box>
    </Box>
  );
};

export default QRCodeInformations;
