import React, { useState, useEffect } from 'react';
import TreeNodeIT from './TreeNodeIT';
import { useGetAllInfoSectorITMutation } from "state/api";
import { useSelector } from 'react-redux';

const TreeIT = () => {
  const [treeData, setTreeData] = useState([]);
  const userSession = useSelector((state) => state.adminAuth);

  const [getAllInfoSectorIT] = useGetAllInfoSectorITMutation();

  const fetchTreeData = async () => {
    try {
      const idEtablissement = userSession.ID_Etablissement;
      const data = { ID_Etablissement: idEtablissement };
      const response = await getAllInfoSectorIT(data).unwrap();
      setTreeData(response);
    } catch (err) {
      if (err.originalStatus === 401) {
        alert('Votre session a expiré. Veuillez vous reconnecter.');
        window.location = '/loginuser';
      }
    }
  };

  useEffect(() => {
    if (userSession.isAuthenticated) {
      fetchTreeData();
    }
  }, [userSession, getAllInfoSectorIT]);

  return (
    <div className="tree-container">
      {treeData.map(node => <TreeNodeIT key={node.ID_Noeud} data={node} fetchTreeData={fetchTreeData} />)}
    </div>
  );
};

export default TreeIT;