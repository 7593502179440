import React, { useState, useEffect, useMemo } from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Typography,
  IconButton, MenuItem, Select, FormControl, InputLabel, useMediaQuery, useTheme,
  FormHelperText, Snackbar, Alert
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import styled from '@emotion/styled';
import { usePublicgetAllInfoSectorMutation, usePublicAddIncidentMutation } from 'state/api';
import { useTranslation } from 'react-i18next';

// Utilisation des variables d'environnement pour les URLs
const BASE_URL = process.env.REACT_APP_BASE_URL || 'http://localhost:5001';

// Styled components
const DialogContentContainer = styled.div`
  display: flex;
  flex-direction: ${props => props.isMobile ? 'column' : 'row'};
  align-items: center;
  justify-content: center;
  text-align: ${props => props.isMobile ? 'center' : 'left'};
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: ${props => props.isMobile ? '16px' : '0'};
  margin-right: ${props => props.isMobile ? '0' : '16px'};
  margin-top: 20px;
  max-width: 200px;
`;

const DemandedeService = ({ open, onClose, selectedService, ID_Etablissement, couleur_ecriture, setValidationMessage }) => {
  const { t } = useTranslation();
  const [besoin, setBesoin] = useState('');
  const [precisionBesoin, setPrecisionBesoin] = useState('');
  const [messageUtilisateur, setMessageUtilisateur] = useState('');
  const [dateDebut, setDateDebut] = useState('');
  const [dateFin, setDateFin] = useState('');
  const [heureDebut, setHeureDebut] = useState('');
  const [heureFin, setHeureFin] = useState('');
  const [lieu, setLieu] = useState('');
  const [fichier, setFichier] = useState(null);
  const [adresseMailDemandeur, setAdresseMailDemandeur] = useState('');
  const [adresseMailCC, setAdresseMailCC] = useState('');
  const [adresseMailDemandePourAutre, setAdresseMailDemandePourAutre] = useState('');
  const [demandePourAutre, setDemandePourAutre] = useState(false);
  const [selectedAdresseOption, setSelectedAdresseOption] = useState('');
  const [lieux, setLieux] = useState([]);
  const [errors, setErrors] = useState({});
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [getAllInfoSector] = usePublicgetAllInfoSectorMutation();
  const [publicAddIncident] = usePublicAddIncidentMutation();

  useEffect(() => {
    if (selectedService && selectedService.Lieu_Requis) {
      const fetchLieux = async () => {
        try {
          const response = await getAllInfoSector({ ID_Etablissement }).unwrap();
          setLieux(flattenLieux(response));
        } catch (error) {
          console.error('Error fetching lieux:', error);
        }
      };

      fetchLieux();
    }
  }, [ID_Etablissement, getAllInfoSector, selectedService]);

  const flattenLieux = (nodes) => {
    const flatten = (node, result = []) => {
      result.push(node);
      if (node.children) {
        node.children.forEach(child => flatten(child, result));
      }
      return result;
    };
    return nodes.flatMap(node => flatten(node));
  };

  const handleDateDebutChange = (e) => {
    const newDateDebut = e.target.value;
    if (dateFin && new Date(newDateDebut) > new Date(dateFin)) {
      setErrors(prevErrors => ({ ...prevErrors, dateDebut: t('date_debut_after_date_fin') }));
    } else {
      setErrors(prevErrors => ({ ...prevErrors, dateDebut: null }));
      setDateDebut(newDateDebut);
    }
  };

  const handleDateFinChange = (e) => {
    const newDateFin = e.target.value;
    if (new Date(newDateFin) < new Date(dateDebut)) {
      setErrors(prevErrors => ({ ...prevErrors, dateFin: t('date_fin_before_date_debut') }));
    } else {
      setErrors(prevErrors => ({ ...prevErrors, dateFin: null }));
      setDateFin(newDateFin);
    }
  };

  const handleHeureDebutChange = (e) => {
    const newHeureDebut = e.target.value;
    if (heureFin && newHeureDebut > heureFin) {
      setErrors(prevErrors => ({ ...prevErrors, heureDebut: t('heure_debut_after_heure_fin') }));
    } else {
      setErrors(prevErrors => ({ ...prevErrors, heureDebut: null }));
      setHeureDebut(newHeureDebut);
    }
  };

  const handleHeureFinChange = (e) => {
    const newHeureFin = e.target.value;
    if (newHeureFin < heureDebut) {
      setErrors(prevErrors => ({ ...prevErrors, heureFin: t('heure_fin_before_heure_debut') }));
    } else {
      setErrors(prevErrors => ({ ...prevErrors, heureFin: null }));
      setHeureFin(newHeureFin);
    }
  };

  const handleSubmit = async () => {
    const newErrors = {};
    if (selectedService.Besoin.length > 0 && !besoin) {
      newErrors.besoin = t('field_required');
    }
    if (selectedService.Precision_Besoin.length > 0 && !precisionBesoin) {
      newErrors.precisionBesoin = t('field_required');
    }
    if (!messageUtilisateur) {
      newErrors.messageUtilisateur = t('field_required');
    }
    if (selectedService.Date_Requise && !dateDebut) {
      newErrors.dateDebut = t('field_required');
    }
    if (selectedService.Date_Requise === 'Intervalle_de_date' && !dateFin) {
      newErrors.dateFin = t('field_required');
    }
    if (selectedService.Date_Requise.includes('heure') && !heureDebut) {
      newErrors.heureDebut = t('field_required');
    }
    if (selectedService.Date_Requise === 'Intervalle_de_date_avec_intervalle_dheure' && !heureFin) {
      newErrors.heureFin = t('field_required');
    }
    if (selectedService.Lieu_Requis && !lieu) {
      newErrors.lieu = t('field_required');
    }
    if (!selectedAdresseOption) {
      newErrors.selectedAdresseOption = t('select_email_option');
    } else if (selectedAdresseOption === t('my_address') && !adresseMailDemandeur) {
      newErrors.adresseMailDemandeur = t('enter_email_address');
    } else if (selectedAdresseOption === t('other_address') && !adresseMailDemandePourAutre) {
      newErrors.adresseMailDemandePourAutre = t('enter_other_email_address');
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    const formData = new FormData();
    const isIT = selectedService.ID_Service_IT !== undefined;
    const type = isIT ? 'IT' : 'SG';

    formData.append('type', type);
    formData.append('ID_Etablissement', ID_Etablissement);
    formData.append('ID_Noeud', lieu);
    formData.append('ID_Service_IT', isIT ? selectedService.ID_Service_IT : '');
    formData.append('ID_Service_SG', !isIT ? selectedService.ID_Service_SG : '');
    formData.append('Besoin', besoin);
    formData.append('Precision_Besoin', precisionBesoin);
    formData.append('Description', messageUtilisateur);
    formData.append('Date_unique', dateDebut);
    formData.append('Intervalle_de_date', dateDebut && dateFin ? `${dateDebut} - ${dateFin}` : '');
    formData.append('Date_unique_avec_heure_unique', dateDebut && heureDebut ? `${dateDebut} ${heureDebut}` : '');
    formData.append('Intervalle_de_date_avec_heure_unique', dateDebut && dateFin && heureDebut ? `${dateDebut} ${heureDebut} - ${dateFin}` : '');
    formData.append('Intervalle_de_date_avec_intervalle_dheure', dateDebut && dateFin && heureDebut && heureFin ? `${dateDebut} ${heureDebut} - ${dateFin} ${heureFin}` : '');
    formData.append('Heure_Debut', heureDebut);
    formData.append('Heure_Fin', heureFin);
    if (selectedAdresseOption === t('other_address')) {
      formData.append('Email_autre_signaleur', adresseMailDemandePourAutre);
    } else {
      formData.append('Email_signaleur', adresseMailDemandeur);
    }
    formData.append('Email_cc', adresseMailCC.split(','));
    formData.append('Attachment', fichier);
    try {
      await publicAddIncident(formData);
      setSnackbarMessage(t('service_request_sent_successfully'));
      setSnackbarSeverity('success');
      setOpenSnackbar(true);
      setValidationMessage(t('service_request_sent_successfully'));
      onClose();
    } catch (error) {
      console.error("Erreur lors de l'envoi de la demande de service:", error);
      setSnackbarMessage(t('error_sending_service_request'));
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFichier(file);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const memoizedImage = useMemo(() => {
    return (
      <ImageContainer isMobile={isMobile}>
        <img
          src={`${BASE_URL}/public/displayimagewithpath/${encodeURIComponent(selectedService.Logo_Requis)}`}
          alt={selectedService.Titre}
          style={{ maxWidth: '200px', borderRadius: '10px', boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.3)' }}
        />
      </ImageContainer>
    );
  }, [selectedService.Logo_Requis, selectedService.Titre, isMobile]);

  if (!selectedService) {
    return null;
  }

  const besoinOptions = selectedService.Besoin.join(',').split(',').map(option => option.trim()).filter(option => option);
  const precisionBesoinOptions = selectedService.Precision_Besoin.join(',').split(',').map(option => option.trim()).filter(option => option);

  const customStyles = {
    color: couleur_ecriture,
  };

  const menuProps = {
    PaperProps: {
      style: {
        backgroundColor: 'white',
        color: couleur_ecriture,
      },
    },
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
        <DialogTitle>
          <Typography variant="h5" component="span" style={{ fontWeight: 'bold', marginRight: '32px' }}>
            {selectedService.Titre}
          </Typography>
          <IconButton onClick={onClose} style={{ position: 'absolute', right: 8, top: 8 }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentContainer isMobile={isMobile}>
            {memoizedImage}
            <Typography variant="h6" gutterBottom>
              {selectedService.Description_Service}
            </Typography>
          </DialogContentContainer>
          <div>
            {besoinOptions.length > 0 && (
              <FormControl fullWidth margin="normal" error={!!errors.besoin}>
                <InputLabel>{t('need')}</InputLabel>
                <Select
                  value={besoin}
                  onChange={(e) => setBesoin(e.target.value)}
                  label={t('need')}
                  MenuProps={menuProps}
                  style={customStyles}
                >
                  {besoinOptions.map((option, index) => (
                    <MenuItem key={index} value={option}>{option}</MenuItem>
                  ))}
                </Select>
                {errors.besoin && <FormHelperText>{errors.besoin}</FormHelperText>}
              </FormControl>
            )}
            {precisionBesoinOptions.length > 0 && (
              <FormControl fullWidth margin="normal" error={!!errors.precisionBesoin}>
                <InputLabel>{t('need_detail')}</InputLabel>
                <Select
                  value={precisionBesoin}
                  onChange={(e) => setPrecisionBesoin(e.target.value)}
                  label={t('need_detail')}
                  MenuProps={menuProps}
                  style={customStyles}
                >
                  {precisionBesoinOptions.map((option, index) => (
                    <MenuItem key={index} value={option}>{option}</MenuItem>
                  ))}
                </Select>
                {errors.precisionBesoin && <FormHelperText>{errors.precisionBesoin}</FormHelperText>}
              </FormControl>
            )}
            {selectedService.Date_Requise && (
              <TextField
                label={t('required_date')}
                type="date"
                fullWidth
                margin="normal"
                value={dateDebut}
                onChange={handleDateDebutChange}
                InputLabelProps={{
                  shrink: true,
                  style: { color: couleur_ecriture },
                }}
                inputProps={{ style: customStyles }}
                error={!!errors.dateDebut}
                helperText={errors.dateDebut}
              />
            )}
            {selectedService.Date_Requise === 'Intervalle_de_date' && (
              <TextField
                label={t('end_date')}
                type="date"
                fullWidth
                margin="normal"
                value={dateFin}
                onChange={handleDateFinChange}
                InputLabelProps={{
                  shrink: true,
                  style: { color: couleur_ecriture },
                }}
                inputProps={{ style: customStyles }}
                error={!!errors.dateFin}
                helperText={errors.dateFin}
              />
            )}
            {selectedService.Date_Requise.includes('heure') && (
              <>
                <TextField
                  label={t('start_time')}
                  type="time"
                  fullWidth
                  margin="normal"
                  value={heureDebut}
                  onChange={handleHeureDebutChange}
                  InputLabelProps={{
                    shrink: true,
                    style: { color: couleur_ecriture },
                  }}
                  inputProps={{ style: customStyles }}
                  error={!!errors.heureDebut}
                  helperText={errors.heureDebut}
                />
                {selectedService.Date_Requise.includes('intervalle_dheure') && (
                  <TextField
                    label={t('end_time')}
                    type="time"
                    fullWidth
                    margin="normal"
                    value={heureFin}
                    onChange={handleHeureFinChange}
                    InputLabelProps={{
                      shrink: true,
                      style: { color: couleur_ecriture },
                    }}
                    inputProps={{ style: customStyles }}
                    error={!!errors.heureFin}
                    helperText={errors.heureFin}
                  />
                )}
              </>
            )}
            {selectedService.Lieu_Requis && (
              <FormControl fullWidth margin="normal" error={!!errors.lieu}>
                <InputLabel>{t('location')}</InputLabel>
                <Select
                  value={lieu}
                  onChange={(e) => setLieu(e.target.value)}
                  label={t('location')}
                  MenuProps={menuProps}
                  style={customStyles}
                >
                  {lieux.map((lieuOption, index) => (
                    <MenuItem key={index} value={lieuOption.ID_Noeud}>{lieuOption.Nom_Element}</MenuItem>
                  ))}
                </Select>
                {errors.lieu && <FormHelperText>{errors.lieu}</FormHelperText>}
              </FormControl>
            )}
            {selectedService.Fichier_Requis && (
              <FormControl fullWidth margin="normal" error={!!errors.fichier}>
                <TextField
                  type="file"
                  onChange={handleFileChange}
                  inputProps={{
                    accept: '.doc,.docx,.pdf,.ppt,.pptx',
                    style: customStyles
                  }}
                  InputLabelProps={{ style: { display: 'none' } }}
                  error={!!errors.fichier}
                  helperText={errors.fichier}
                />
              </FormControl>
            )}
            <TextField
              label={t('user_message')}
              fullWidth
              margin="normal"
              multiline
              rows={4}
              value={messageUtilisateur}
              onChange={(e) => setMessageUtilisateur(e.target.value)}
              InputLabelProps={{
                style: { color: couleur_ecriture },
              }}
              inputProps={{ style: customStyles }}
              error={!!errors.messageUtilisateur}
              helperText={errors.messageUtilisateur || t('field_required')}
            />
            <FormControl fullWidth margin="normal" error={!!errors.selectedAdresseOption}>
              <InputLabel style={{ color: couleur_ecriture }}>{t('email_address')}</InputLabel>
              <Select
                value={selectedAdresseOption}
                onChange={(e) => {
                  const selectedOption = e.target.value;
                  setSelectedAdresseOption(selectedOption);
                  setDemandePourAutre(selectedOption === t('other_address'));
                }}
                label={t('email_address')}
                MenuProps={menuProps}
                style={customStyles}
              >
                <MenuItem value={t('my_address')}>{t('my_address')}</MenuItem>
                <MenuItem value={t('other_address')}>{t('other_address')}</MenuItem>
              </Select>
              {errors.selectedAdresseOption && <FormHelperText>{errors.selectedAdresseOption}</FormHelperText>}
            </FormControl>
            {selectedAdresseOption === t('my_address') && (
              <TextField
                label={t('requester_email_address')}
                fullWidth
                margin="normal"
                value={adresseMailDemandeur}
                onChange={(e) => setAdresseMailDemandeur(e.target.value)}
                InputLabelProps={{
                  style: { color: couleur_ecriture },
                }}
                inputProps={{ style: customStyles }}
                error={!!errors.adresseMailDemandeur}
                helperText={errors.adresseMailDemandeur}
              />
            )}
            {selectedAdresseOption === t('other_address') && (
              <TextField
                label={t('other_requester_email_address')}
                fullWidth
                margin="normal"
                value={adresseMailDemandePourAutre}
                onChange={(e) => setAdresseMailDemandePourAutre(e.target.value)}
                InputLabelProps={{
                  style: { color: couleur_ecriture },
                }}
                inputProps={{ style: customStyles }}
                error={!!errors.adresseMailDemandePourAutre}
                helperText={errors.adresseMailDemandePourAutre}
              />
            )}
            <TextField
              label={t('cc_email_address')}
              fullWidth
              margin="normal"
              value={adresseMailCC}
              onChange={(e) => setAdresseMailCC(e.target.value)}
              helperText={errors.adresseMailCC || t('comma_separated')}
              InputLabelProps={{
                style: { color: couleur_ecriture },
              }}
              inputProps={{ style: customStyles }}
              error={!!errors.adresseMailCC}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">
            {t('cancel')}
          </Button>
          <Button onClick={handleSubmit} color="primary">
            {t('send')}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default DemandedeService;
