import React, { useState } from 'react';
import { AppBar, Toolbar, Button, Typography, useScrollTrigger, CssBaseline, Link, Box, IconButton, Menu, MenuItem, Drawer, List, ListItem, ListItemText } from '@mui/material';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import LanguageIcon from '@mui/icons-material/Language';
import HomeIcon from '@mui/icons-material/Home';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import logoHurflym from '../assets/logo_hurflym_sans_fond.png';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

function ElevationScroll(props) {
  const { children } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

const CustomButton = styled(Button)(({ theme }) => ({
  color: '#fff',
  backgroundColor: theme.palette.secondary.main,
  fontFamily: 'Montserrat, sans-serif',
  fontWeight: 'bold',
  margin: theme.spacing(1),
  width: '80%',
  fontSize: '0.8rem',
  padding: '6px 12px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  '&:hover': {
    backgroundColor: theme.palette.secondary.dark,
    transform: 'scale(1.05)',
    transition: 'background-color 0.3s, transform 0.3s',
  },
  '@media (max-width: 1200px)': {
    fontSize: '0.7rem',
    padding: '6px 16px',
    margin: theme.spacing(0.5),
  }
}));

const InvertedButton = styled(Button)(({ theme }) => ({
  color: theme.palette.secondary.main,
  backgroundColor: '#fff',
  fontFamily: 'Montserrat, sans-serif',
  fontWeight: 'bold',
  margin: theme.spacing(1),
  width: '80%',
  fontSize: '0.8rem',
  padding: '6px 12px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  border: `1px solid ${theme.palette.secondary.main}`,
  '&:hover': {
    backgroundColor: '#f0f0f0',
    transform: 'scale(1.05)',
    transition: 'background-color 0.3s, transform 0.3s',
  },
  '@media (max-width: 1200px)': {
    fontSize: '0.7rem',
    padding: '6px 16px',
    margin: theme.spacing(0.5),
  }
}));

const NavLink = styled(Link)(({ theme }) => ({
  color: 'black',
  fontFamily: 'Montserrat, sans-serif',
  fontWeight: 'bold',
  margin: theme.spacing(1.5), // Réduit l'espacement par défaut
  maxWidth: '150px', // Limite la largeur maximale
  textDecoration: 'none',
  fontSize: '0.9rem',
  whiteSpace: 'nowrap',
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  transition: 'color 0.3s ease',
  '&:hover': {
    textDecoration: 'none',
    color: theme.palette.secondary.light,
  },
  '@media (max-width: 1200px)': {
    fontSize: '0.8rem',
    margin: theme.spacing(1),
    maxWidth: '120px', // Réduit la largeur max pour les écrans plus petits
  }
}));

export default function Navbar(props) {
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileOpen, setMobileOpen] = useState(false);
  const open = Boolean(anchorEl);
  const theme = useTheme();
  const isMobile = useMediaQuery('(max-width:915px)'); // Changé pour 915px

  const { isAuthenticated, user } = useSelector((state) => state.userAuth);

  const handleLanguageMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLanguageMenuClose = (lang) => {
    setAnchorEl(null);
    if (lang) {
      i18n.changeLanguage(lang);
    }
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const userFirstName = user ? user.prenom : '';
  const userLastName = user ? user.nom : '';

  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      sx={{ textAlign: 'center', backgroundColor: 'white', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
    >
      <Box sx={{ position: 'absolute', top: 20, left: 0, right: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Link href="/" underline="none" sx={{ display: 'flex', alignItems: 'center' }}>
          <img src={logoHurflym} alt="Logo Hurflym" style={{ height: '40px', marginRight: '10px' }} />
          <Typography
            variant="h6"
            component="div"
            sx={{
              color: 'black',
              fontFamily: 'Montserrat, sans-serif',
              fontWeight: 'bold',
              letterSpacing: '0.3rem',
              textTransform: 'uppercase',
              cursor: 'pointer',
            }}
          >
            HURFLYM
          </Typography>
        </Link>
      </Box>
      <List sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
        <ListItem button component="a" href="/apropos" sx={{ justifyContent: 'center' }}>
          <ListItemText primary={t('Navbar.APropos')} sx={{ color: 'black', textAlign: 'center' }} />
        </ListItem>
        <ListItem button component="a" href="/abonnements" sx={{ justifyContent: 'center' }}>
          <ListItemText primary={t('Navbar.Abonnements')} sx={{ color: 'black', textAlign: 'center' }} />
        </ListItem>
        <ListItem button component="a" href="/privacy" sx={{ justifyContent: 'center' }}>
          <ListItemText primary={t('Navbar.Confidentialite')} sx={{ color: 'black', textAlign: 'center' }} />
        </ListItem>
        <ListItem button component="a" href="/docdeveloper" sx={{ justifyContent: 'center' }}>
          <ListItemText primary={t('Navbar.Developpeur')} sx={{ color: 'black', textAlign: 'center' }} />
        </ListItem>
      </List>
      <Box sx={{ position: 'absolute', bottom: 20, left: 0, right: 0, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        {isAuthenticated ? (
          <CustomButton href="/listeetablissement" startIcon={<HomeIcon />}>
            {userFirstName} {userLastName}
          </CustomButton>
        ) : (
          <>
            <CustomButton href="/loginuser" fullWidth sx={{ mb: 1 }}>{t('Navbar.Login')}</CustomButton>
            <InvertedButton href="/inscription" fullWidth>{t('Navbar.Signup')}</InvertedButton>
          </>
        )}
        <IconButton onClick={handleLanguageMenuClick} sx={{ color: theme.palette.neutral.main, mt: 1 }}>
          <LanguageIcon />
        </IconButton>
      </Box>
    </Box>
  );

  return (
    <>
      <CssBaseline />
      <ElevationScroll {...props}>
        <AppBar 
          position="fixed" 
          sx={{
            background: 'rgba(255, 255, 255, 0.5)', 
            backdropFilter: 'blur(10px)',
            borderRadius: '20px',
            margin: '20px',
            width: 'calc(100% - 40px)',
            top: '10px'
          }}
        >
          <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', padding: '0 20px', minHeight: '64px' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', width: isMobile ? '80%' : '25%' }}>
              <Link href="/" underline="none" sx={{ display: 'flex', alignItems: 'center' }}>
                <img src={logoHurflym} alt="Logo Hurflym" style={{ height: '40px', marginRight: '10px' }} />
                <Typography
                  variant="h6"
                  component="div"
                  sx={{
                    color: 'black',
                    fontFamily: 'Montserrat, sans-serif',
                    fontWeight: 'bold',
                    letterSpacing: '0.3rem',
                    textTransform: 'uppercase',
                    cursor: 'pointer',
                  }}
                >
                  HURFLYM
                </Typography>
              </Link>
            </Box>

            {isMobile ? (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton
                  onClick={handleLanguageMenuClick}
                  sx={{ color: theme.palette.neutral.main, mr: 1 }}
                >
                  <LanguageIcon />
                </IconButton>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  sx={{ mr: 2 }}
                >
                  <MenuIcon />
                </IconButton>
              </Box>
            ) : (
              <>
                <Box sx={{ 
                  display: 'flex', 
                  justifyContent: 'center', 
                  alignItems: 'center', 
                  width: '50%',
                  height: '64px',
                  gap: '10px', // Espacement fixe entre les éléments
                  padding: '0 20px' // Padding pour éviter le contact avec les bords
                }}>
                  <NavLink href="/apropos">{t('Navbar.APropos')}</NavLink>
                  <NavLink href="/abonnements">{t('Navbar.Abonnements')}</NavLink>
                  <NavLink href="/privacy">{t('Navbar.Confidentialite')}</NavLink>
                  <NavLink href="/docdeveloper">{t('Navbar.Developpeur')}</NavLink>
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '25%', gap: '10px' }}>
                  {isAuthenticated ? (
                    <>
                      <Button href="/listeetablissement" startIcon={<HomeIcon />} sx={{ color: theme.palette.primary.main, fontWeight: 'bold', whiteSpace: 'nowrap' }}>
                        {userFirstName} {userLastName}
                      </Button>
                    </>
                  ) : (
                    <>
                      <CustomButton href="/loginuser" sx={{ maxWidth: '120px' }}>{t('Navbar.Login')}</CustomButton>
                      <InvertedButton href="/inscription" sx={{ maxWidth: '120px' }}>{t('Navbar.Signup')}</InvertedButton>
                    </>
                  )}

                  <IconButton onClick={handleLanguageMenuClick} sx={{ color: theme.palette.neutral.main }}>
                    <LanguageIcon />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={() => handleLanguageMenuClose(null)}
                  >
                    <MenuItem onClick={() => handleLanguageMenuClose('fr')}>Français</MenuItem>
                    <MenuItem onClick={() => handleLanguageMenuClose('en')}>English</MenuItem>
                  </Menu>
                </Box>
              </>
            )}
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <Box component="nav">
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '100%' },
          }}
        >
          <IconButton
            onClick={handleDrawerToggle}
            sx={{ position: 'absolute', right: 8, top: 8, color: 'black' }}
          >
            <CloseIcon />
          </IconButton>
          {drawer}
        </Drawer>
      </Box>
    </>
  );
}
