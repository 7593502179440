import React from "react";
import { List, ListItem, Box, Typography } from "@mui/material";
import { keyframes } from '@emotion/react';
import { useTranslation } from 'react-i18next';

const growShrink = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
`;

const ListeTicketPartenaires = ({ tickets, onSelectTicket, selectedTicket }) => {
  const { t } = useTranslation();

  // Function to determine the display name (Lieu or Portail)
  const renderLieuOuPortail = (ticket) => {
    if (ticket.ID_Noeud && ticket.details.locationDetails) {
      return t('ListeTicketPartenaires.Lieu', { nomElement: ticket.details.locationDetails.Nom_Element });
    } else if (ticket.ID_Portail && ticket.details.etablissementDetails) {
      return t('ListeTicketPartenaires.PortailExterne', { nomEtablissement: ticket.details.etablissementDetails.Nom_Etablissement });
    } else {
      return t('ListeTicketPartenaires.LocalisationNA');
    }
  };

  // Function to determine the display name for the specific details
  const renderNom = (ticket) => {
    if (ticket.Type_de_ticket === "equipement_particulier") {
      return ticket.details.specificDetails?.Nom_Ep || t('ListeTicketPartenaires.NA');
    } else if (ticket.Type_de_ticket === "equipement") {
      return ticket.details.specificDetails?.Nom_Equipement || t('ListeTicketPartenaires.NA');
    } else if (ticket.Type_de_ticket === "service") {
      return ticket.details.specificDetails?.Titre || t('ListeTicketPartenaires.NA');
    } else {
      return t('ListeTicketPartenaires.NA');
    }
  };

  return (
    <List>
      {tickets.map((ticket) => {
        const isSelected =
          selectedTicket &&
          ((selectedTicket.ID_Incident_IT && selectedTicket.ID_Incident_IT === ticket.ID_Incident_IT) ||
            (selectedTicket.ID_Incident_SG && selectedTicket.ID_Incident_SG === ticket.ID_Incident_SG));

        return (
          <ListItem
            key={ticket.ID_Incident_IT || ticket.ID_Incident_SG}
            button
            onClick={() => onSelectTicket(ticket)}
            sx={{
              animation: isSelected ? `${growShrink} 2s infinite` : 'none',
              boxShadow: isSelected ? '0 0 10px rgba(0, 0, 0, 0.5)' : 'none',
              borderRadius: '8px',
              overflow: 'hidden',
              transition: 'transform 0.3s ease-in-out',
              '&:hover': {
                transform: 'scale(1.05)',
                boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
              },
              marginBottom: '8px',
              display: 'flex',
              alignItems: 'center',
              padding: '16px',
              border: '1px solid #ddd',
            }}
          >
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                {`${t(`ListeTicketPartenaires.${ticket.Type_de_ticket}`)} - ${renderNom(ticket)}`}
              </Typography>
              <Typography variant="body2" sx={{ fontStyle: 'italic' }}>
                {renderLieuOuPortail(ticket)}
              </Typography>
            </Box>
          </ListItem>
        );
      })}
    </List>
  );
};

export default ListeTicketPartenaires;
