import React, { useState, useEffect } from 'react';
import {
  Box, Typography, Button, TextField, Dialog, DialogTitle, DialogContent, DialogActions,
  Grid, List, ListItem, ListItemText, IconButton, TablePagination
} from '@mui/material';
import { useTranslation } from 'react-i18next'; // Import the useTranslation hook
import {
  useGetAllInfoSignalementITMutation, useAddSignalementITMutation,
  useModifySignalementITMutation, useDeleteSignalementITMutation
} from 'state/api';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import ErrorMessage from '../ErrorMessage';
import ValidationMessage from '../ValidationMessage';
import InfoMessage from '../InfoMessage';
import ListeEquipementSignalementIT from './ListeEquipementSignalementIT';

const RenderSignalementIT = () => {
  const { t } = useTranslation(); // Initialize the useTranslation hook
  const [openDialog, setOpenDialog] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedSignalement, setSelectedSignalement] = useState(null);
  const [nomSignalement, setNomSignalement] = useState('');
  const [idNoeud, setIDNoeud] = useState(0);
  const [listeEquipement, setListeEquipement] = useState([]);
  const [isFirstLoad, setIsFirstLoad] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [signalements, setSignalements] = useState([]);
  const [nomSignalementRecherche, setNomSignalementRecherche] = useState('');

  const theme = useTheme();
  const buttonColor = theme.palette.primary.main;
  const textColor = theme.palette.mode === 'dark' ? theme.palette.primary.light : theme.palette.secondary.main;

  const userSession = useSelector((state) => state.adminAuth);

  const [getAllInfosSignalementIT] = useGetAllInfoSignalementITMutation();
  const [addSignalementIT] = useAddSignalementITMutation();
  const [modifySignalementIT] = useModifySignalementITMutation();
  const [deleteSignalementIT] = useDeleteSignalementITMutation();

  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [validation, setValidation] = useState('');

  const popUpError = (message) => {
    setError(message);
  };

  const popUpInfo = (message) => {
    setMessage(message);
  };

  const popUpValidation = (message) => {
    setValidation(message);
  };

  const handleOpenDialog = (signalement = null) => {
    if (signalement) {
      setSelectedSignalement(signalement);
      setNomSignalement(signalement.Nom_signalement);
      setIDNoeud(signalement.ID_Noeud);
      setIsFirstLoad(true);
      setListeEquipement(signalement.Equipement);
      setEditMode(true);
    } else {
      setSelectedSignalement(null);
      setNomSignalement('');
      setIDNoeud(0);
      setListeEquipement([]);
      setEditMode(false);
    }
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedSignalement(null);
  };

  const handleSave = async () => {
    try {
      setIsLoading(true);
      const signalementData = {
        Nom_signalement: nomSignalement,
        ID_Etablissement: userSession.ID_Etablissement,
        ID_Noeud: idNoeud,
        Equipement: listeEquipement,
      };
      let response;
      if (editMode && selectedSignalement) {
        signalementData.ID_Signalement_IT = selectedSignalement.ID_Signalement_IT;
        response = await modifySignalementIT(signalementData);
      } else {
        response = await addSignalementIT(signalementData);
      }

      if (response.error) {
        popUpError(editMode ? t('RenderSignalementIT.edit_error') : t('RenderSignalementIT.create_error'));
      } else {
        handleCloseDialog();
        fetchSignalements();
        popUpValidation(editMode ? t('RenderSignalementIT.edit_success') : t('RenderSignalementIT.create_success'));
      }
    } catch (err) {
      popUpError(t('RenderSignalementIT.server_error'));
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteSignalement = async (signalement) => {
    try {
      const response = await deleteSignalementIT({ ID_Signalement_IT: signalement.ID_Signalement_IT });
      if (response.error) {
        popUpError(t('RenderSignalementIT.delete_error'));
      } else {
        fetchSignalements();
        popUpValidation(t('RenderSignalementIT.delete_success'));
      }
    } catch (err) {
      popUpError(t('RenderSignalementIT.delete_error'));
    }
  };

  const fetchSignalements = async () => {
    try {
      setIsLoading(true);
      const response = await getAllInfosSignalementIT({ ID_Etablissement: userSession.ID_Etablissement });
      if (response.data) {
        setSignalements(response.data);
      }
    } catch (err) {
      popUpError(t('RenderSignalementIT.fetch_error'));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchSignalements();
  }, [getAllInfosSignalementIT, userSession.ID_Etablissement]);

  useEffect(() => {
    const fetchFilteredSignalements = async () => {
      try {
        setIsLoading(true);
        const response = await getAllInfosSignalementIT({ ID_Etablissement: userSession.ID_Etablissement });
        if (response.data) {
          const filteredSignalements = response.data.filter(signalement =>
            signalement.Nom_signalement.toLowerCase().includes(nomSignalementRecherche.toLowerCase())
          );
          setSignalements(filteredSignalements);
        }
      } catch (err) {
        popUpError(t('RenderSignalementIT.fetch_error'));
      } finally {
        setIsLoading(false);
      }
    };

    fetchFilteredSignalements();
  }, [nomSignalementRecherche, getAllInfosSignalementIT, userSession.ID_Etablissement]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box m="1.5rem 2.5rem">
      <Typography variant="h3" gutterBottom style={{ marginTop: '60px', marginBottom: '20px' }}>
        {t('RenderSignalementIT.manage_reports')}
      </Typography>
      <ErrorMessage message={error} setError={setError} />
      <InfoMessage message={message} setInfo={setMessage} />
      <ValidationMessage message={validation} setValidation={setValidation} />
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={5} style={{ textAlign: 'left' }}>
          <Button variant="contained" color="primary" onClick={() => handleOpenDialog()} style={{ height: '55px' }}>
            {t('RenderSignalementIT.create_new_report')}
          </Button>
        </Grid>
        <Grid item xs={7} style={{ textAlign: 'right', display: 'flex', alignItems: 'center' }}>
          <TextField label={t('RenderSignalementIT.report_name')} value={nomSignalementRecherche} onChange={(e) => setNomSignalementRecherche(e.target.value)} />
        </Grid>
      </Grid>

      <List style={{ marginTop: '32px' }}>
        {signalements.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((signalement, index) => (
          <ListItem key={index} secondaryAction={
            <>
              <IconButton edge="end" aria-label="edit" onClick={() => handleOpenDialog(signalement)}>
                <EditIcon />
              </IconButton>
              <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteSignalement(signalement)}>
                <DeleteIcon />
              </IconButton>
            </>
          }>
            <ListItemText primary={signalement.Nom_signalement} />
          </ListItem>
        ))}
      </List>

      <TablePagination
        component="div"
        count={signalements.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 25, 100]}
      />

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        maxWidth="lg"
        fullWidth={true}
        PaperProps={{
          style: {
            width: '70%',
            height: '80%',
          },
        }}
      >
        <DialogTitle>{editMode ? t('RenderSignalementIT.edit_report') : t('RenderSignalementIT.add_new_report')}</DialogTitle>
        <DialogContent>
          <form>
            <TextField
              label={t('RenderSignalementIT.report_name')}
              value={nomSignalement}
              onChange={(e) => setNomSignalement(e.target.value)}
              fullWidth
              margin="normal"
            />
            <ListeEquipementSignalementIT
              liste_equipement={listeEquipement}
              setListeEquipement={setListeEquipement}
              isFirstLoad={isFirstLoad}
              setIsFirstLoad={setIsFirstLoad}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} style={{ backgroundColor: buttonColor, color: textColor }}>{t('RenderSignalementIT.cancel')}</Button>
          <Button onClick={handleSave} style={{ backgroundColor: buttonColor, color: textColor }}>{editMode ? t('RenderSignalementIT.save') : t('RenderSignalementIT.submit')}</Button>
        </DialogActions>
      </Dialog>

      {isLoading && <p>{t('RenderSignalementIT.loading')}</p>}
    </Box>
  );
};

export default RenderSignalementIT;
