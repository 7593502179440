import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setUserAuthenticated, setUserInfo } from './../../state/userAuth';
import './inscription.css';
import { useInscriptionMutation } from 'state/api';

const countryCodes = [
  { code: '+43', country: 'Austria', flag: '🇦🇹' },
  { code: '+32', country: 'Belgium', flag: '🇧🇪' },
  { code: '+359', country: 'Bulgaria', flag: '🇧🇬' },
  { code: '+385', country: 'Croatia', flag: '🇭🇷' },
  { code: '+357', country: 'Cyprus', flag: '🇨🇾' },
  { code: '+420', country: 'Czech Republic', flag: '🇨🇿' },
  { code: '+45', country: 'Denmark', flag: '🇩🇰' },
  { code: '+372', country: 'Estonia', flag: '🇪🇪' },
  { code: '+358', country: 'Finland', flag: '🇫🇮' },
  { code: '+33', country: 'France', flag: '🇫🇷' },
  { code: '+49', country: 'Germany', flag: '🇩🇪' },
  { code: '+30', country: 'Greece', flag: '🇬🇷' },
  { code: '+36', country: 'Hungary', flag: '🇭🇺' },
  { code: '+353', country: 'Ireland', flag: '🇮🇪' },
  { code: '+39', country: 'Italy', flag: '🇮🇹' },
  { code: '+371', country: 'Latvia', flag: '🇱🇻' },
  { code: '+370', country: 'Lithuania', flag: '🇱🇹' },
  { code: '+352', country: 'Luxembourg', flag: '🇱🇺' },
  { code: '+356', country: 'Malta', flag: '🇲🇹' },
  { code: '+31', country: 'Netherlands', flag: '🇳🇱' },
  { code: '+48', country: 'Poland', flag: '🇵🇱' },
  { code: '+351', country: 'Portugal', flag: '🇵🇹' },
  { code: '+40', country: 'Romania', flag: '🇷🇴' },
  { code: '+421', country: 'Slovakia', flag: '🇸🇰' },
  { code: '+386', country: 'Slovenia', flag: '🇸🇮' },
  { code: '+34', country: 'Spain', flag: '🇪🇸' },
  { code: '+46', country: 'Sweden', flag: '🇸🇪' },
  { code: '+44', country: 'UK', flag: '🇬🇧' },
  { code: '+1', country: 'USA', flag: '🇺🇸' },
];

function InscriptionClient() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [pseudo, setPseudo] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [name, setName] = useState('');
  const [prenom, setPrenom] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [authError, setAuthError] = useState(null);
  const [passwordError, setPasswordError] = useState('');
  const [selectedCountryCode, setSelectedCountryCode] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [isMobile, setIsMobile] = useState(false);

  const [inscription, { isLoading }] = useInscriptionMutation();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const validatePassword = (password) => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return regex.test(password);
  };

  const validateEmail = (email) => {
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return regex.test(email);
  };

  const validatePhoneNumber = (number) => {
    const regex = /^\+?([0-9]{1,3})?[-. ]?(\d{1,3}[-. ]?)*\d{1,4}$/;
    return regex.test(number);
  };

  const isFormValid = () => {
    return name && prenom && pseudo && email && password && confirmPassword && phoneNumber &&
      password === confirmPassword &&
      !passwordError && !emailError && !phoneError;
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    if (!validatePassword(newPassword)) {
      setPasswordError('Le mot de passe doit contenir au moins 8 caractères, dont une majuscule, un chiffre et un caractère spécial.');
    } else {
      setPasswordError('');
    }
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    if (!validateEmail(newEmail)) {
      setEmailError('Veuillez entrer une adresse email valide.');
    } else {
      setEmailError('');
    }
  };

  const handlePhoneNumberChange = (e) => {
    const newPhone = e.target.value;
    setPhoneNumber(newPhone);
    if (!validatePhoneNumber(newPhone)) {
      setPhoneError('Veuillez entrer un numéro de téléphone valide. Exemple: +33 07 80 80 80 80');
    } else {
      setPhoneError('');
    }
  };

  const handleSignup = async (event) => {
    event.preventDefault();
    setAuthError(null);

    if (!isFormValid()) {
      setAuthError('Veuillez remplir tous les champs correctement avant de soumettre.');
      return;
    }

    if (password !== confirmPassword) {
      setAuthError('Les mots de passe ne correspondent pas.');
      return;
    }

    if (!validatePassword(password) || !validateEmail(email) || !validatePhoneNumber(phoneNumber)) {
      setAuthError('Veuillez corriger les erreurs avant de soumettre.');
      return;
    }

    try {
      const userData = { pseudo, password, email, name, prenom, phoneNumber: `${selectedCountryCode} ${phoneNumber}` };
      await inscription(userData).unwrap();
      navigate('/accueilpublic');
    } catch (error) {
      if (error.data && error.data.message) {
        const message = error.data.message;

        if (message.includes("name")) setAuthError("Erreur: Nom invalide ou manquant.");
        else if (message.includes("prenom")) setAuthError("Erreur: Prénom invalide ou manquant.");
        else if (message.includes("pseudo")) setAuthError("Erreur: Pseudo invalide ou manquant.");
        else if (message.includes("email")) setAuthError("Erreur: Email invalide ou déjà utilisé.");
        else if (message.includes("password")) setAuthError("Erreur: Mot de passe invalide.");
        else if (message.includes("phoneNumber")) setAuthError("Erreur: Numéro de téléphone invalide.");
        else setAuthError(message);
      } else {
        setAuthError("Une erreur s'est produite lors de l'inscription.");
      }
    }
  };

  const filteredCountryCodes = countryCodes.filter((country) =>
    country.country.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="all-container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
      <div className="inner-container" style={{ 
        width: isMobile ? 'calc(100% - 40px)' : '40%', 
        minWidth: isMobile ? 'auto' : '400px',
        margin: isMobile ? '20px' : '0',
        padding: '20px',
        boxSizing: 'border-box'
      }}>
        <form onSubmit={handleSignup} className="signup-form">
          {authError && <p className="signup-error">{authError}</p>}
          <div className="signup-header">
            <h2>Inscrivez-vous</h2>
            <p>Veuillez remplir les champs ci-dessous pour créer un compte.</p>
          </div>

          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Nom"
            className="signup-input"
            id="name"
          />
          <input
            type="text"
            value={prenom}
            onChange={(e) => setPrenom(e.target.value)}
            placeholder="Prénom"
            className="signup-input"
            id="prenom"
          />
          <input
            type="email"
            value={email}
            onChange={handleEmailChange}
            placeholder="Email"
            className="signup-input"
            id="email"
          />
          <input
            type="text"
            value={pseudo}
            onChange={(e) => setPseudo(e.target.value)}
            placeholder="Pseudo"
            className="signup-input"
            id="pseudo"
          />
          <input
            type="password"
            value={password}
            onChange={handlePasswordChange}
            placeholder="Mot de passe"
            className="signup-input"
            id="password"
          />
          <input
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            placeholder="Confirmez le mot de passe"
            className="signup-input"
            id="confirmPassword"
          />
          <div className="phone-container">
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
                setSelectedCountryCode('');
              }}
              placeholder="Rechercher ou sélectionner un pays"
              className="signup-input"
              list="countryList"
              id="countryInput"
            />
            <datalist id="countryList">
              {filteredCountryCodes.map((country) => (
                <option key={country.code} value={`${country.flag} ${country.country} ${country.code}`} onClick={() => setSelectedCountryCode(country.code)}>
                  {country.flag} {country.country} {country.code}
                </option>
              ))}
            </datalist>
            <input
              type="text"
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
              placeholder="Numéro de téléphone"
              className="signup-input"
              id="phoneNumber"
              style={{ width: '70%' }}
            />
          </div>
          <button type="submit" className="signup-button" disabled={isLoading}>S'inscrire</button>
        </form>
      </div>
    </div>
  );
}

export default InscriptionClient;
