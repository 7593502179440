import React, { useState, useEffect } from 'react';
import { Button, Container, Typography } from '@mui/material';
import { styled } from '@mui/system';
import FormulaireAcceuil from './FormulaireAcceuil';
import FormulaireCategoriePrincipale from './FormulaireCategoriPrincipale';
import FormulaireCategorieSecondaire from './FormulaireCategoriSecondaire';
import FormulaireEnvoi from './FormulaireEnvoi';
import CategorieSecondaire from './FormulaireCategoriSecondaire';


const CategoryContainer = styled(Container)({
  padding: '10px',
  borderRadius: '5px',
});

const CategoryTitle = styled(Typography)({
  marginBottom: '20px',
});

const CategoryButton = styled(Button)({
  marginTop: '10px',
  marginRight: '10px',
});

const FormulaireSignalement = ({ liste_categorie, liste_equipement, detail_equipement, personnalisation, mail_de_destination }) => {
  const [selectedCategory, setSelectedCategory] = useState('');
  const [firstLoad, setFirstLoad] = useState(0);
  const [categorieEquipement, setCategorieEquipement] = useState([]);
  const [categoriePrincipale, setCategoriePrincipale] = useState([]);

  const [listeCategorieSecondaire, setListeCategorieSecondaire] = useState([]);

  const [categoriePrincipaleActuelle, setCategoriePrincipalActuelle] = useState(null);
  const [categorieSecondaireActuelle, setCategorieSecondaireActuelle] = useState(null);
  const [shown, setShown] = useState("A");

  const [dataSignalement, setDataSignalement] = useState([]);

  useEffect(() => {
    // Vérifie si les listes ne sont pas vides
    if (liste_categorie.length !== 0 && liste_equipement.length !== 0 && detail_equipement.length !== 0 && firstLoad===0) {
      setFirstLoad(1); // Si elles ne sont pas vides, on met firstLoad à 1
      const categorieEquipementArray = liste_equipement.map((equipement) => equipement.ID_Categorie_Equipement);
      setCategorieEquipement(categorieEquipementArray);
      setCategorieEquipement(categorieEquipementArray);
      const categoriePrincipaleArray = [];
      liste_categorie.forEach((equipement) => {
        equipement.enfants.forEach((enfant) => {
          if (categorieEquipementArray.includes(enfant.ID_Categorie)) {
            categoriePrincipaleArray.push(equipement.ID_Categorie);
            return;
          }
        });
      });
      setCategoriePrincipale(categoriePrincipaleArray);
    const categoriesPrincipalesAvecSecondaires = liste_categorie.map(categoriePrincipale => {
      const categoriesSecondaires = categoriePrincipale.enfants.filter(categorieSecondaire => 
        categorieEquipementArray.includes(categorieSecondaire.ID_Categorie)
      ).map(categorie => categorie.ID_Categorie);
      if (categoriesSecondaires.length > 0) {
        return {
          ID_Categorie_Principale: categoriePrincipale.ID_Categorie,
          Categories_Secondaires: categoriesSecondaires
        };
      }
      return null;
    }).filter(categorie => categorie !== null);

    setListeCategorieSecondaire(categoriesPrincipalesAvecSecondaires);
    }
  }, [liste_categorie, liste_equipement, detail_equipement, firstLoad, personnalisation]);
  

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    
  };

  return (
    <CategoryContainer>
      <FormulaireAcceuil categorie_principale={categoriePrincipaleActuelle} categorie_secondaire={categorieSecondaireActuelle} shown={shown} liste_categorie_principale={categoriePrincipale} liste_complete_categorie={liste_categorie} style={personnalisation} setShown={setShown} setCategoriePrincipale={setCategoriePrincipalActuelle}/>
      <FormulaireCategoriePrincipale categorie_principale={categoriePrincipaleActuelle} categorie_secondaire={categorieSecondaireActuelle} shown={shown} setShown={setShown} liste_categorie_secondaire={listeCategorieSecondaire} liste_complete_categorie={liste_categorie} style={personnalisation} setCategoriePrincipale={setCategoriePrincipalActuelle} setCategorieSecondaire={setCategorieSecondaireActuelle}/>
      <FormulaireCategorieSecondaire categorie_secondaire={categorieSecondaireActuelle} shown={shown} setShown={setShown} liste_equipement={liste_equipement} style={personnalisation} setCategorieSecondaire={setCategorieSecondaireActuelle} setDataSignalement={setDataSignalement} dataSignalement={dataSignalement}/>
      <FormulaireEnvoi categorie_principale={categoriePrincipaleActuelle} categorie_secondaire={categorieSecondaireActuelle} shown={shown} setShown={setShown} dataSignalement={dataSignalement} setCategorieSecondaire={setCategorieSecondaireActuelle} style={personnalisation} mail_de_destination={mail_de_destination} detail_equipement={detail_equipement} liste_equipement={liste_equipement}/>
    </CategoryContainer>
  );
};

export default FormulaireSignalement;
