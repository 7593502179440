import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button, TextField, Typography, Container, Box, Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useInscriptionUtilisateurEtablissementMutation } from 'state/api';

const InscriptionEtablissement = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [inscriptionEtablissement, { isLoading, isError }] = useInscriptionUtilisateurEtablissementMutation();

  const query = new URLSearchParams(location.search);
  const token = query.get('token');

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [name, setName] = useState('');
  const [prenom, setPrenom] = useState('');
  const [pseudo, setPseudo] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const validatePassword = (password) => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return regex.test(password);
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    if (!validatePassword(newPassword)) {
      setPasswordError(t('InscriptionEtablissement.passwordError'));
    } else {
      setPasswordError('');
    }
  };

  const handleSignup = async (event) => {
    event.preventDefault();
    if (password !== confirmPassword) {
      setPasswordError(t('InscriptionEtablissement.passwordMismatch'));
      return;
    }

    if (passwordError || !email || !name || !prenom || !pseudo || !phoneNumber) {
      console.error(t('InscriptionEtablissement.fillFieldsError'));
      return;
    }

    try {
      await inscriptionEtablissement({ token, email, password, name, prenom, pseudo, phoneNumber }).unwrap();
      navigate('/accueilpublic');
    } catch (error) {
      console.error(t('InscriptionEtablissement.signupError'), error);
    }
  };

  return (
    <Box 
      display="flex" 
      alignItems="center" 
      justifyContent="center" 
      minHeight="100vh"
      sx={{ backgroundColor: '#f5f5f5' }}
    >
      <Container maxWidth="md">
        <Paper elevation={3} sx={{ padding: 4, width: '100%', maxWidth: '500px', margin: 'auto' }}>
          <Typography component="h1" variant="h5" marginBottom={2} textAlign="center">
            {t('InscriptionEtablissement.title')}
          </Typography>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label={t('InscriptionEtablissement.nameLabel')}
            value={name}
            onChange={e => setName(e.target.value)}
            helperText={t('InscriptionEtablissement.nameHelper')}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label={t('InscriptionEtablissement.prenomLabel')}
            value={prenom}
            onChange={e => setPrenom(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label={t('InscriptionEtablissement.pseudoLabel')}
            value={pseudo}
            onChange={e => setPseudo(e.target.value)}
            helperText={t('InscriptionEtablissement.pseudoHelper')}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label={t('InscriptionEtablissement.emailLabel')}
            value={email}
            onChange={e => setEmail(e.target.value)}
            helperText={t('InscriptionEtablissement.emailHelper')}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label={t('InscriptionEtablissement.passwordLabel')}
            type="password"
            value={password}
            onChange={handlePasswordChange}
            helperText={t('InscriptionEtablissement.passwordHelper')}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label={t('InscriptionEtablissement.confirmPasswordLabel')}
            type="password"
            value={confirmPassword}
            onChange={e => setConfirmPassword(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label={t('InscriptionEtablissement.phoneNumberLabel')}
            value={phoneNumber}
            onChange={e => setPhoneNumber(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleSignup}
            disabled={isLoading}
            sx={{ mt: 3, mb: 2 }}
          >
            {t('InscriptionEtablissement.submitButton')}
          </Button>
          {passwordError && <Typography color="error">{passwordError}</Typography>}
        </Paper>
      </Container>
    </Box>
  );
};

export default InscriptionEtablissement;
