import React, { useState } from 'react';
import { Button, IconButton } from '@mui/material';
import styled from '@emotion/styled';
import MenuIcon from '@mui/icons-material/Menu';

const SidebarContainer = styled('div')`
  width: ${(props) => (props.isOpen ? '15%' : '0')};
  height: 100%;
  background-color: ${(props) => props.couleur_de_case || '#F0F0F0'};
  padding: ${(props) => (props.isOpen ? '32px 16px' : '0')};
  box-shadow: 5px 0 10px ${(props) => props.couleur_dombre_de_case || 'rgba(0, 0, 0, 0.1)'};
  transition: width 0.3s, padding 0.3s;
  overflow: hidden;
  position: relative;
  
  @media (max-width: 767px) {
    width: ${(props) => (props.isOpen ? '100%' : '0')};
    padding: ${(props) => (props.isOpen ? '32px 16px' : '0')};
  }
`;

const SidebarItem = styled(Button)`
  width: 100%;
  margin-bottom: 8px;
  background-color: ${(props) => props.couleur_de_case || '#FFFFFF'};
  color: ${(props) => props.couleur_bouton_ecriture || '#000000'};
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 8px;
  text-transform: none;
  font-size: 1.2em;
  font-weight: bold;
  font-family: ${(props) => props.police_de_caractere}, sans-serif;
  text-align: left;
`;

const MenuButton = styled(IconButton)`
  position: absolute;
  top: 16px;
  left: ${(props) => (props.isOpen ? 'calc(15% + 16px)' : '16px')};
  transition: left 0.3s;
  z-index: 1000;
  
  @media (max-width: 767px) {
    left: ${(props) => (props.isOpen ? 'calc(100% - 48px)' : '16px')};
  }
`;

const SidebarServices = ({ categories, onCategorySelect, styleProps }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <MenuButton isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
        <MenuIcon />
      </MenuButton>
      <SidebarContainer
        isOpen={isOpen}
        couleur_de_case={styleProps.couleur_de_case}
        couleur_dombre_de_case={styleProps.couleur_dombre_de_case}
      >
        {categories.map((category) => (
          <SidebarItem
            key={category.id}
            onClick={() => onCategorySelect(category.Titre)}
            couleur_de_case={styleProps.couleur_de_case}
            couleur_bouton_ecriture={styleProps.couleur_bouton_ecriture}
            police_de_caractere={styleProps.police_de_caractere}
            style={{
              fontWeight: category.id === 'Tous' ? 'bold' : 'normal',
              fontSize: category.id === 'Tous' ? '1.5em' : '1.2em',
            }}
          >
            {category.Titre}
          </SidebarItem>
        ))}
      </SidebarContainer>
    </>
  );
};

export default SidebarServices;
