import React from 'react';
import { Box, Typography, Paper, IconButton, Tooltip } from "@mui/material";
import { ContentCopy } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next'; // Import pour la traduction

const General = () => {
  const theme = useTheme();
  const { t } = useTranslation(); // Hook pour la traduction

  // Fonction pour copier le texte dans le presse-papiers
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
  };

  const codeStyle = {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    padding: '16px',
    borderRadius: '8px',
    marginBottom: '20px',
    position: 'relative',
  };

  const explanationStyle = {
    color: theme.palette.text.secondary,
    marginBottom: '20px',
  };

  return (
    <Box>
      {/* Titre principal */}
      <Typography variant="h4" gutterBottom sx={{ marginTop: '80px', marginBottom: '40px' }}>
        {t('General.DeveloperDocumentation')}
      </Typography>

      <Typography variant="body1" paragraph>
        {t('General.WelcomeMessage')}
      </Typography>

      {/* Section gestion des comptes machines */}
      <Typography variant="h5" gutterBottom sx={{ marginTop: '80px', marginBottom: '40px' }}>
        {t('General.Section1Title')}
      </Typography>
      <Typography variant="body1" paragraph>
        {t('General.Section1Content')}
      </Typography>

      {/* Section webhooks et souscriptions */}
      <Typography variant="h5" gutterBottom sx={{ marginTop: '80px', marginBottom: '40px' }}>
        {t('General.Section2Title')}
      </Typography>
      <Typography variant="body1" paragraph>
        {t('General.Section2Content')}
      </Typography>

      {/* Exemple de fichier .env */}
      <Typography variant="h6" gutterBottom sx={{ marginTop: '80px', marginBottom: '40px' }}>
        {t('General.EnvExampleTitle')}
      </Typography>
      <Typography variant="body2" sx={explanationStyle}>
        {t('General.EnvExampleDescription')}
      </Typography>
      <Paper sx={codeStyle}>
        <pre>
{`# ${t('General.EnvExampleComment')}
PORT=Votre_port_disponible_pour_ecouter
WEBHOOK_SERVER_URL=http://hurflym.com/api/subscribe  # ${t('General.EnvWebhookServerUrlComment')}
MACHINE_API_KEY=Votre_clé_API_machine_disponible_sur_lapplication  # ${t('General.EnvMachineApiKeyComment')}
ETABLISSEMENT_ID=Votre_ID_Etablissement_disponible_sur_lapplication  # ${t('General.EnvEtablissementIdComment')}
WEBHOOK_URL=Votre_URL_pour_recevoir_les_webhooks  # ${t('General.EnvWebhookUrlComment')}`}
        </pre>
        <Tooltip title={t('General.Copy')}>
          <IconButton
            onClick={() => copyToClipboard(`# ${t('General.EnvExampleComment')}
PORT=Votre_port_disponible_pour_ecouter
WEBHOOK_SERVER_URL=http://hurflym.com/api/subscribe  
MACHINE_API_KEY=Votre_clé_API_machine_disponible_sur_lapplication  
ETABLISSEMENT_ID=Votre_ID_Etablissement_disponible_sur_lapplication  
WEBHOOK_URL=Votre_URL_pour_recevoir_les_webhooks`)}
            sx={{ position: 'absolute', top: 8, right: 8 }}
          >
            <ContentCopy />
          </IconButton>
        </Tooltip>
      </Paper>

      {/* Exemple de fichier server.js */}
      <Typography variant="h6" gutterBottom sx={{ marginTop: '80px', marginBottom: '40px' }}>
        {t('General.ServerJsExampleTitle')}
      </Typography>
      <Typography variant="body2" sx={explanationStyle}>
        {t('General.ServerJsExampleDescription')}
      </Typography>
      <Paper sx={codeStyle}>
        <pre>
{`require('dotenv').config();
const express = require('express');
const axios = require('axios');

const app = express();
app.use(express.json());

const PORT = process.env.PORT;
const WEBHOOK_SERVER_URL = process.env.WEBHOOK_SERVER_URL;
const MACHINE_API_KEY = process.env.MACHINE_API_KEY;
const ETABLISSEMENT_ID = process.env.ETABLISSEMENT_ID;
const WEBHOOK_URL = process.env.WEBHOOK_URL;

// ${t('General.SubscribeRouteComment')}
app.post('/subscribe', async (req, res) => {
  try {
    const subscriptionData = {
      apiKey: MACHINE_API_KEY,
      etablissementId: ETABLISSEMENT_ID,
      subscriptionType: 'incidentIT',
      webhookUrl: WEBHOOK_URL,
    };

    const response = await axios.post(WEBHOOK_SERVER_URL, subscriptionData);

    res.status(200).json({ message: '${t('General.SubscriptionSuccess')}', data: response.data });
  } catch (error) {
    res.status(500).json({ message: '${t('General.SubscriptionError')}', error: error.message });
  }
});

// ${t('General.WebhookRouteComment')}
app.post('/webhook', (req, res) => {
    res.status(200).send('${t('General.NotificationSuccess')}');
});

// ${t('General.StartServerComment')}
app.listen(PORT, () => {
});
`}
        </pre>
        <Tooltip title={t('General.Copy')}>
          <IconButton
            onClick={() => copyToClipboard(`require('dotenv').config();
const express = require('express');
const axios = require('axios');

const app = express();
app.use(express.json());

const PORT = process.env.PORT;
const WEBHOOK_SERVER_URL = process.env.WEBHOOK_SERVER_URL;
const MACHINE_API_KEY = process.env.MACHINE_API_KEY;
const ETABLISSEMENT_ID = process.env.ETABLISSEMENT_ID;
const WEBHOOK_URL = process.env.WEBHOOK_URL;

// ${t('General.SubscribeRouteComment')}
app.post('/subscribe', async (req, res) => {
  try {
    const subscriptionData = {
      apiKey: MACHINE_API_KEY,
      etablissementId: ETABLISSEMENT_ID,
      subscriptionType: 'incidentIT',
      webhookUrl: WEBHOOK_URL,
    };

    const response = await axios.post(WEBHOOK_SERVER_URL, subscriptionData);
    console.log('${t('General.SubscriptionSuccess')}:', response.data);

    res.status(200).json({ message: '${t('General.SubscriptionSuccess')}', data: response.data });
  } catch (error) {
    console.error('${t('General.SubscriptionError')}:', error.message);
    res.status(500).json({ message: '${t('General.SubscriptionError')}', error: error.message });
  }
});

// ${t('General.WebhookRouteComment')}
app.post('/webhook', (req, res) => {
    console.log('${t('General.NotificationReceived')}:', req.body);
    res.status(200).send('${t('General.NotificationSuccess')}');
});

// ${t('General.StartServerComment')}
app.listen(PORT, () => {
  console.log(\`${t('General.ServerListening')} \${PORT}\`);
});`)}
            sx={{ position: 'absolute', top: 8, right: 8 }}
          >
            <ContentCopy />
          </IconButton>
        </Tooltip>
      </Paper>

      {/* Exemples de réponses API */}
      <Typography variant="h6" gutterBottom sx={{ marginTop: '80px', marginBottom: '40px' }}>
        {t('General.ApiResponseExamplesTitle')}
      </Typography>
      <Typography variant="body2" sx={explanationStyle}>
        {t('General.ApiResponseExamplesDescription')}
      </Typography>
      <Paper sx={codeStyle}>
        <pre>
{`{
  "message": "${t('General.SubscriptionSuccess')}",
  "data": {
    "message": "${t('General.SubscriptionSuccessMessage')}",
    "subscriptionApiKey": "f01d41867325e9caa5394794a0690d..."
  }
}`}
        </pre>
        <Tooltip title={t('General.Copy')}>
          <IconButton
            onClick={() => copyToClipboard(`{
  "message": "${t('General.SubscriptionSuccess')}",
  "data": {
    "message": "${t('General.SubscriptionSuccessMessage')}",
    "subscriptionApiKey": "f01d41867325e9caa5394794a0690d..."
  }
}`)}
            sx={{ position: 'absolute', top: 8, right: 8 }}
          >
            <ContentCopy />
          </IconButton>
        </Tooltip>
      </Paper>

      {/* Exemple de notification webhook */}
      <Typography variant="body1" paragraph>
        {t('General.WebhookNotificationExample')}
      </Typography>
      <Paper sx={codeStyle}>
        <pre>
{`{
  "ID_Incident": "string" ("66ada9153769bcfddc902496"),
  "ID_Etablissement": "number" (1),
  "ID_Ep_IT": "number" (17),
  "Titre_ticket": "string" ("${t('General.ExampleTicketTitle')}"),
  "Priorite": "number" (1),
  "Severite": "number" (1),
  "Description": "array of strings" (["test", "${t('General.IncidentDetected')}"]),
  "Email_signaleur": "array of strings" (["example@example.com"]),
  "Creation": "string (ISO 8601 date)" ("2024-08-03T03:50:45.090Z"),
  "Etat_du_ticket": "string" ("creation")
}`}
        </pre>
        <Tooltip title={t('General.Copy')}>
          <IconButton
            onClick={() => copyToClipboard(`{
  "ID_Incident": "string" ("66ada9153769bcfddc902496"),
  "ID_Etablissement": "number" (1),
  "ID_Ep_IT": "number" (17),
  "Titre_ticket": "string" ("${t('General.ExampleTicketTitle')}"),
  "Priorite": "number" (1),
  "Severite": "number" (1),
  "Description": "array of strings" (["test", "${t('General.IncidentDetected')}"]),
  "Email_signaleur": "array of strings" (["example@example.com"]),
  "Creation": "string (ISO 8601 date)" ("2024-08-03T03:50:45.090Z"),
  "Etat_du_ticket": "string" ("creation")
}`)}
            sx={{ position: 'absolute', top: 8, right: 8 }}
          >
            <ContentCopy />
          </IconButton>
        </Tooltip>
      </Paper>
    </Box>
  );
};

export default General;
