import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Typography, Container, Box } from '@mui/material';
import { useRejoindreEtablissementMutation } from 'state/api';
import { useTranslation } from 'react-i18next';

const RejoindreEtablissement = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [rejoindreEtablissement, { isSuccess, isError, error }] = useRejoindreEtablissementMutation();

  const query = new URLSearchParams(location.search);
  const token = query.get('token');

  const handleAcceptInvitation = async () => {
    try {
      await rejoindreEtablissement({ token }).unwrap();
      navigate('/acceuilpublic');
    } catch (err) {
      console.error('Erreur lors de l\'acceptation de l\'invitation:', err);
    }
  };

  useEffect(() => {
    if (isError) {
      console.error("Erreur lors de la rejoindre l'établissement:", error);
    }
  }, [isError, error]);

  return (
    <Box display="flex" alignItems="center" justifyContent="center" minHeight="100vh">
      <Container maxWidth="sm">
        <Box textAlign="center" padding={4} boxShadow={3}>
          <Typography component="h1" variant="h5" marginBottom={2}>
            {t('RejoindreEtablissement.title')}
          </Typography>
          <Button
            type="button"
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleAcceptInvitation}
          >
            {t('RejoindreEtablissement.acceptButton')}
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default RejoindreEtablissement;
