import React, { useState } from 'react';
import { useLoginPostAdminMutation } from 'state/api';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setAdminAuthenticated } from './../../state/adminAuth';
import { useTranslation } from 'react-i18next';
import './login.css';

function AdminLogin() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [adminLogin, { error, isLoading }] = useLoginPostAdminMutation();

  const handleAdminLogin = async (event) => {
    event.preventDefault();
    try {
      const response = await adminLogin({ username, password }).unwrap();
      dispatch(setAdminAuthenticated({ isAuthenticated: true, admin: response }));
      navigate('/admindashboard');
    } catch (err) {
      if (err.originalStatus === 401) {
        alert('Votre session a expiré. Veuillez vous reconnecter.');
        window.location = '/loginuser';
      }
    }
  };

  return (
    <div className="admin-login-container">
      <form onSubmit={handleAdminLogin}>
        <label htmlFor="admin-username">{t('AdminLogin.UsernameLabel')}</label>
        <input
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          placeholder="Username"
          className="login-input"
          id="admin-username"
        />
        <label htmlFor="admin-password">{t('AdminLogin.PasswordLabel')}</label>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          className="login-input"
          id="admin-password"
        />
        <button type="submit" className="login-button">{t('AdminLogin.LoginButton')}</button>
      </form>
      {error && <p className="login-error">{t('AdminLogin.LoginError')}{error.error}</p>}
      {isLoading && <p className="login-loading">{t('AdminLogin.LoadingMessage')}</p>}
    </div>
  );
}

export default AdminLogin;
