import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  Alert,
  ListItem,
  ListItemText,
  IconButton,
  TablePagination,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ImageCarousel from '../ImageCarouselCategorieSignalementIT';
import {
  useGetAllInfoCategorieSignalementITMutation,
  useAddCategorieSignalementITMutation,
  useModifyCategorieSignalementITMutation,
  useDeleteCategorieSignalementITMutation,
  useGetAllImageCategorieSignalementITMutation
} from 'state/api';

const RenderCategorieSignalementIT = () => {
  const { t } = useTranslation();
  const [categories, setCategories] = useState([]);
  const [images, setImages] = useState([]);
  const [open, setOpen] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [categoryToDelete, setCategoryToDelete] = useState(null);
  const [editingCategory, setEditingCategory] = useState(null);
  const [name, setName] = useState('');
  const [parentId, setParentId] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const [fileError, setFileError] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState('all');

  const [getAllCategories] = useGetAllInfoCategorieSignalementITMutation();
  const [getAllImages] = useGetAllImageCategorieSignalementITMutation();
  const [addCategory] = useAddCategorieSignalementITMutation();
  const [modifyCategory] = useModifyCategorieSignalementITMutation();
  const [deleteCategory] = useDeleteCategorieSignalementITMutation();

  const userSession = useSelector((state) => state.adminAuth);

  const fetchCategories = async () => {
    const fetchedCategories = await getAllCategories({ ID_Etablissement: userSession.ID_Etablissement, search, filter });
    setCategories(fetchedCategories.data || []);
  };

  useEffect(() => {
    fetchCategories();
  }, [getAllCategories, search, filter]);

  useEffect(() => {
    const fetchImages = async () => {
      const fetchedImages = await getAllImages();
      setImages(fetchedImages.data.images || []);
    };
    fetchImages();
  }, [getAllImages]);

  const handleOpenNewCategoryDialog = () => {
    setEditingCategory(null);
    setName('');
    setParentId('');
    setSelectedImage(null);
    setFileError(false);
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
    setEditingCategory(null);
    setFileError(false);
  };

  const handleDeleteConfirmation = (category) => {
    setCategoryToDelete(category);
    setConfirmationOpen(true);
  };

  const handleAddOrEditCategory = async () => {
    if (!editingCategory && !selectedImage) {
      setFileError(true);
      return;
    }

    const categoryData = {
      Nom_Categorie: name,
      ID_Etablissement: userSession.ID_Etablissement,
      ID_Categorie_Precedente: parentId === '' ? 0 : parentId,
      Lien_Image: selectedImage,
    };

    if (editingCategory) {
      categoryData.ID_Categorie_Signalement_IT = editingCategory.ID_Categorie_Signalement_IT;
    }

    if (editingCategory) {
      await modifyCategory(categoryData);
    } else {
      await addCategory(categoryData);
    }
    setOpen(false);
    setName('');
    setParentId('');
    setSelectedImage(null);
    setFileError(false);
    fetchCategories();
  };

  const handleEdit = async (category) => {
    setEditingCategory(category);
    setName(category.Nom_Categorie || '');
    setParentId(category.ID_Categorie_Precedente || '');
    setSelectedImage(category.Lien_Image || '');
    setOpen(true);
  };

  const handleDelete = async () => {
    await deleteCategory({ ID_Categorie_Signalement_IT: categoryToDelete.ID_Categorie_Signalement_IT });
    setConfirmationOpen(false);
    setCategoryToDelete(null);
    fetchCategories();
  };

  const handleCloseConfirmationDialog = () => {
    setConfirmationOpen(false);
    setCategoryToDelete(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleImageSelect = (image) => {
    setSelectedImage(image);
  };

  const getParentCategoryName = (parentId) => {
    if (parentId === 0) {
      return t("RenderCategorieSignalementIT.primary_category");
    }
    const parentCategory = categories.find(cat => cat.ID_Categorie_Signalement_IT === parentId);
    return parentCategory ? parentCategory.Nom_Categorie : t("RenderCategorieSignalementIT.unknown_category");
  };

  return (
    <Box m="1.5rem 2.5rem">
      <Typography variant="h3" gutterBottom style={{ marginTop: '60px', marginBottom: '20px' }}>
        {t("RenderCategorieSignalementIT.manage_categories")}
      </Typography>
      <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
        <Button variant="contained" color="primary" onClick={handleOpenNewCategoryDialog}>
          {t("RenderCategorieSignalementIT.add_category")}
        </Button>
        <Box display="flex" alignItems="center">
          <TextField
            label={t("RenderCategorieSignalementIT.search")}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            style={{ marginRight: '20px' }}
          />
          <FormControl style={{ width: '200px' }}>
            <InputLabel id="filter-label">{t("RenderCategorieSignalementIT.filter_by")}</InputLabel>
            <Select
              labelId="filter-label"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            >
              <MenuItem value="all">{t("RenderCategorieSignalementIT.all")}</MenuItem>
              <MenuItem value="primary">{t("RenderCategorieSignalementIT.primary_categories")}</MenuItem>
              <MenuItem value="secondary">{t("RenderCategorieSignalementIT.secondary_categories")}</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>
      <List style={{ marginTop: '20px' }}>
        {categories.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((category) => (
          <ListItem key={category.ID_Categorie_Signalement_IT} secondaryAction={
            <>
              <IconButton edge="end" aria-label={t("RenderCategorieSignalementIT.edit")} onClick={() => handleEdit(category)}>
                <EditIcon />
              </IconButton>
              <IconButton edge="end" aria-label={t("RenderCategorieSignalementIT.delete")} onClick={() => handleDeleteConfirmation(category)}>
                <DeleteIcon />
              </IconButton>
            </>
          }>
            <ListItemText primary={category.Nom_Categorie} secondary={category.ID_Categorie_Precedente === 0 ? t("RenderCategorieSignalementIT.primary_category") : `${t("RenderCategorieSignalementIT.parent_category")}: ${getParentCategoryName(category.ID_Categorie_Precedente)}`} />
          </ListItem>
        ))}
      </List>
      <TablePagination
        component="div"
        count={categories.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      />

      {/* Dialog for Adding/Editing Category */}
      <Dialog open={open} onClose={handleCloseDialog}>
        <DialogTitle>{editingCategory ? t("RenderCategorieSignalementIT.edit_category") : t("RenderCategorieSignalementIT.add_new_category")}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label={t("RenderCategorieSignalementIT.category_name")}
            type="text"
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <FormControl fullWidth margin="dense">
            <InputLabel id="parent-category-label">{t("RenderCategorieSignalementIT.parent_category")}</InputLabel>
            <Select
              labelId="parent-category-label"
              value={parentId}
              onChange={(e) => setParentId(e.target.value)}
            >
              <MenuItem value="">
                <em>{t("RenderCategorieSignalementIT.no_parent")}</em>
              </MenuItem>
              {categories.filter(cat => cat.ID_Categorie_Precedente === 0 && cat.ID_Categorie_Signalement_IT !== editingCategory?.ID_Categorie_Signalement_IT).map(cat => (
                <MenuItem key={cat.ID_Categorie_Signalement_IT} value={cat.ID_Categorie_Signalement_IT}>
                  {cat.Nom_Categorie}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <ImageCarousel images={images} selectedImage={selectedImage} onImageSelect={handleImageSelect} />
          {fileError && <Alert severity="error">{t("RenderCategorieSignalementIT.image_required")}</Alert>}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>{t("RenderCategorieSignalementIT.cancel")}</Button>
          <Button onClick={handleAddOrEditCategory}>{editingCategory ? t("RenderCategorieSignalementIT.edit") : t("RenderCategorieSignalementIT.add_category")}</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={confirmationOpen} onClose={handleCloseConfirmationDialog}>
        <DialogTitle>{t("RenderCategorieSignalementIT.confirm_deletion")}</DialogTitle>
        <DialogContent>
          <Typography>{t("RenderCategorieSignalementIT.delete_confirmation_text")}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmationDialog}>{t("RenderCategorieSignalementIT.cancel")}</Button>
          <Button onClick={handleDelete} color="error">{t("RenderCategorieSignalementIT.delete_category")}</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default RenderCategorieSignalementIT;
